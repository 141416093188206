import React, { useEffect, useState } from "react";

import AlertsView from "./alerts-view";

import { useBottomScrollListener } from "react-bottom-scroll-listener";
import useAlerts from "../../utils/useAlerts";
import { useParams } from "react-router-dom";

const TABS = {
  COYOTE: "coyote",
  LOST_PETS: "lost_pets",
  FOUND_PETS: "found_pets",
  REPORTED: "reported",
};

const Alerts = () => {
  const { type } = useParams();
  const [visibleDrawerForReports, setVisibleDrawerForReports] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(null);
  const [sortType, setSortType] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("desc");

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const {
    onGetAlerts,
    onGetAlertReports,
    onDeleteAlert,

    alerts,
    alertsCounters,
    reports,
    setReports,
    isAlertLoading,
    isLastPage,
    alertPage,
    isBottomLoading,
    setSelectedAlert,
  } = useAlerts();

  const openDrawerWithReports = async (alertId) => {
    await onGetAlertReports(alertId);
    await setVisibleDrawerForReports(true);
  };

  const closeDrawerWithReports = () => {
    setVisibleDrawerForReports(false);
  };

  const opendeleteAlertModal = (id) => {
    setDeleteModalVisible(id);
  };

  const closedeleteAlertModal = () => {
    setDeleteModalVisible(null);
  };

  const deleteAlert = async (notification) => {
    const deleteCallback = (deleteResult) => {
      setReports(deleteResult);
      setDeleteModalVisible(null);
    };

    await onDeleteAlert(notification, deleteCallback);
  };

  const gettingAlertsList = async () => {
    await onGetAlerts(
      {
        page: 1,
        size: 10,
        sort: sortType,
        sortDirection: sortDirection,
        type,
        startDate,
        endDate,
        cities,
        states,
      },
      false,
      "",
    );
  };

  const nextAlerts = async () => {
    await onGetAlerts(
      {
        page: alertPage + 1,
        size: 10,
        sort: sortType,
        sortDirection: sortDirection,
        type,
        startDate,
        endDate,
        cities,
        states,
      },
      true,
      "",
      "Fail to get next list of alerts",
    );
  };

  useBottomScrollListener(() => {
    !isLastPage && nextAlerts();
  });

  useEffect(() => {
    gettingAlertsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, sortType, sortDirection, startDate, endDate, cities, states]);

  return (
    <AlertsView
      alerts={alerts}
      alertsCounters={alertsCounters}
      tab={type}
      visibleDrawerForReports={visibleDrawerForReports}
      deleteModalVisible={deleteModalVisible}
      reportsData={reports}
      sortType={sortType}
      setSortType={setSortType}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      TABS={TABS}
      skeletVisible={isAlertLoading}
      loading={isBottomLoading}
      setSelectedAlert={setSelectedAlert}
      openDrawerWithReports={openDrawerWithReports}
      closeDrawerWithReports={closeDrawerWithReports}
      opendeleteAlertModal={opendeleteAlertModal}
      deleteAlert={deleteAlert}
      closedeleteAlertModal={closedeleteAlertModal}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      setCities={setCities}
      setStates={setStates}
    />
  );
};

export default Alerts;
