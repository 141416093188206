import React from "react";

import "./styles.scss";

import { Confirm, Paper, Button, Modal } from "../../../ui-kit";

const ModalsView = (props) => {
  return (
    <div id="--modals">
      <Paper className="confirm-modal">
        <h4>Модальное окно подтвержедния</h4>
        <article>
          <span>
            Компонент <code>{"<Confirm />"}</code>
            предназначен для отображения модального окна с возможностью нажатия на одну или две кнопки. Для отображения
            кнопок нужно передать в компонент свойство <code>buttons</code>, который является массивом объектов, где
            объект должен содержать теже пропсы, что и компонент <code>{"<Button />"}</code>.
          </span>
          <br />
          <code>{'<Confirm title="Some string" subtitle="Some subtitle" />'}</code>
        </article>
        <aside>
          <Button label="Открыть" onClick={props.handleConfirmVisible} />
        </aside>
      </Paper>
      <Paper className="confirm-modal">
        <h4>Модальное окно</h4>
        <article>
          <span>
            Компонент <code>{"<Modal />"}</code> предназначен для отображения всплывающего окна поверх основного
            контента. Модальное окно может иметь различтные размеры: xs, sm, md, lg , xl.
          </span>
          <br />
          <code>{'<Modal title="Some string" maxWidth="xs|sm|md|lg|xl" />'}</code>
        </article>
        <aside>
          <Button label="Открыть" onClick={props.handleModalVisible} />
        </aside>
      </Paper>
      <Modal
        onClose={props.handleModalVisible}
        maxWidth="md"
        open={props.modalVisible}
        title="Lorem ipsum dolor sit amet"
      >
        <div id="--modal-example">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Pulvinar sapien et ligula ullamcorper malesuada proin libero nunc. Faucibus ornare
            suspendisse sed nisi lacus sed viverra tellus. Purus non enim praesent elementum facilisis leo. Nunc
            lobortis mattis aliquam faucibus. Sit amet porttitor eget dolor morbi non arcu risus. Integer malesuada nunc
            vel risus commodo viverra maecenas. Ultrices eros in cursus turpis. Massa sapien faucibus et molestie ac. Ut
            ornare lectus sit amet est placerat in egestas. In nisl nisi scelerisque eu ultrices vitae.
          </p>
          <br />
          <p>
            Cum sociis natoque penatibus et. Viverra nibh cras pulvinar mattis nunc. Quam vulputate dignissim
            suspendisse in est ante in. Congue nisi vitae suscipit tellus mauris a diam. Adipiscing vitae proin sagittis
            nisl rhoncus mattis rhoncus. Pretium quam vulputate dignissim suspendisse in est ante in. Vulputate
            dignissim suspendisse in est ante in nibh mauris cursus. Nisl vel pretium lectus quam id. Magna etiam tempor
            orci eu. Eget nulla facilisi etiam dignissim diam. Facilisis leo vel fringilla est ullamcorper eget. Et
            molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Nisl purus in mollis nunc sed id semper
            risus. Quis enim lobortis scelerisque fermentum dui faucibus in ornare quam.
          </p>
          <br />
          <p>
            Eget est lorem ipsum dolor sit. Congue mauris rhoncus aenean vel. Cras semper auctor neque vitae tempus quam
            pellentesque. Sed viverra tellus in hac. Sed viverra tellus in hac habitasse. Nunc faucibus a pellentesque
            sit amet. Dui nunc mattis enim ut. Arcu cursus euismod quis viverra. Pellentesque habitant morbi tristique
            senectus et netus et malesuada fames. Faucibus pulvinar elementum integer enim neque volutpat ac tincidunt.
            Ullamcorper dignissim cras tincidunt lobortis feugiat vivamus. Vel orci porta non pulvinar neque laoreet
            suspendisse. In fermentum et sollicitudin ac. Felis imperdiet proin fermentum leo vel orci porta non. In
            nibh mauris cursus mattis molestie a iaculis. Mauris rhoncus aenean vel elit scelerisque mauris pellentesque
            pulvinar pellentesque.
          </p>
          <br />
          <div className="bottom">
            <Button label="Закрыть" onClick={props.handleModalVisible} />
          </div>
        </div>
      </Modal>
      <Confirm
        onClose={props.handleConfirmVisible}
        title="Подтвердите действие"
        visible={props.confirmVisible}
        subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        buttons={[
          {
            label: "Подтвердить",
            onClick: () => {
              props.handleConfirmVisible();
            },
          },
          {
            label: "Отменить",
            outline: true,
            onClick: () => {
              props.handleConfirmVisible();
            },
          },
        ]}
      />
    </div>
  );
};

export default ModalsView;
