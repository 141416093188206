/**
 * Function for validate input object based on a validation scheme
 * @param {Object} validateObj - Object with input properties for validation
 * @param {Object<Function>} validateScheme - Validation scheme
 */
export const validateObject = (validateObj, validateScheme) => {
  return Object.entries(validateObj).reduce(
    (result, [key, value]) => {
      const isValid = validateScheme[key](value);
      result.isValid = Boolean(result.isValid * isValid); // Discrete multiplication
      result.data = {
        ...result.data,
        [key]: { value, isValid },
      };
      return result;
    },
    { isValid: true, data: {} },
  );
};
