import React from "react";

import { Icon } from "../../ui-kit";
import { Table, Skeleton } from "../../ui-kit";

import "./styles.scss";

const SkeletManagers = () => (
  <div className="summary_table">
    <Table
      head={() => (
        <>
          <tr className="radius_table_heading">
            <th>
              <Skeleton width="fit-content">
                <h4>**********</h4>
              </Skeleton>
            </th>
            <th>
              <Skeleton width="fit-content">
                <h4>**********</h4>
              </Skeleton>
            </th>
            <th></th>
          </tr>
        </>
      )}
    >
      <>
        {new Array(6).fill(null).map((item, index) => (
          <tr key={index} className="radius_table_body">
            <td>
              <Skeleton width="fit-content">
                <span>**********</span>
              </Skeleton>
            </td>
            <td>
              <Skeleton width="fit-content">
                <span>**********</span>
              </Skeleton>
            </td>
            <td className="radius_table_edit">
              <div className="radius_table_edit_wrapper">
                <Skeleton width="fit-content">
                  <Icon name="pen" width={24} height={24} />
                </Skeleton>
              </div>
            </td>
          </tr>
        ))}
        <tr className="radius_table_body">
          <td>
            <Skeleton width="fit-content">
              <h4>**********</h4>
            </Skeleton>
          </td>
          <td>
            <Skeleton width="fit-content">
              <span>**********</span>
            </Skeleton>
          </td>
          <td className="radius_table_edit">
            <div className="radius_table_edit_wrapper">
              <Skeleton width="fit-content">
                <div className="radius_table_iconWrapper">
                  <Icon name="pen" width={24} height={24} />
                </div>
              </Skeleton>
            </div>
          </td>
        </tr>
      </>
    </Table>
  </div>
);

export default SkeletManagers;
