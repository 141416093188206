import STRINGS from "../../localization";
import { Button, EditableTextArea, Form } from "../../ui-kit";
import "./styles.scss";
import { SectionHeader } from "../../components/section-header/SectionHeader";
import { Typography } from "@mui/material";
import React from "react";

const CopyrightPolicy = (props) => {
  return (
    <>
      <SectionHeader>
        <Typography variant={"h1"}>{STRINGS.components.sidebar.copyrightPolicy}</Typography>
        <Button
          label={props.disable ? STRINGS.components.buttons.edit : STRINGS.components.buttons.cancel}
          onClick={() => (props.disable ? props.setDisable(!props.disable) : props.cancel())}
          type="primary"
          size="small"
          disabled={props.loading}
        />
      </SectionHeader>
      <div className="copyright-policy-container">
        <Form onSubmit={props.saveChanges}>
          <EditableTextArea
            value={props.editedText}
            onChange={props.handleTextChange}
            modules={{
              toolbar: [["bold", "italic"]],
            }}
            readOnly={props.disable}
          />
          <div className="footer">
            <Button
              className="button"
              label={STRINGS.components.buttons.save}
              size="medium"
              typeButton="submit"
              loading={props.loading}
              disabled={props.disable}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default CopyrightPolicy;
