import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import "./styles.scss";
import colors from "../../styles/colors";

import { Confirm, Icon } from "../../ui-kit";

import CurrentUserThunks from "../../store/current-user/thunks";

/**
 * @component Header
 * @example
 * <Header
 *  onClick={() => {}}
 *  burgerState={false}
 * />
 */
const Header = (props) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const onCloseModal = (logout = false) => {
    setShowModal(false);
    if (logout) {
      dispatch(CurrentUserThunks.logout());
    }
  };

  return (
    <>
      <header id="--header-root">
        <div className="burger">
          <button onClick={() => props.onClick(!props.burgerState)}>
            <Icon name="burger" width={22} height={22} color={colors.color_gray._4} />
          </button>
        </div>
        <div className="content">
          <div className="search" />
          <div className="actions">
            <button className="logout" onClick={() => setShowModal(true)}>
              <Icon name="logout" width={28} height={28} color={colors.color_gray._4} />
            </button>
          </div>
        </div>
      </header>
      <Confirm
        fullWidth
        visible={showModal}
        title="Confirm action"
        onClose={() => setShowModal(false)}
        subtitle="Do you really want to log out?"
        buttons={[
          { label: "Yes, I’m sure", onClick: () => onCloseModal(true) },
          { label: "Cancel", outline: true, onClick: () => setShowModal(false) },
        ]}
      />
    </>
  );
};

/**
 * Properties
 */

Header.propTypes = {
  onClick: PropTypes.func,
  burgerState: PropTypes.bool,
};
Header.defaultProps = {
  onClick: () => {},
  burgerState: false,
};

export default Header;
