import { initializeApp } from "firebase/app";
import { connectAuthEmulator } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

if (process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST) {
  connectAuthEmulator(getAuth(), `http://${process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST}`);
}

if (process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_HOST) {
  connectStorageEmulator(getStorage(), ...process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_HOST.split(":"));
}

if (firebaseConfig.measurementId) {
  getAnalytics();
}

export const storage = getStorage();
export const auth = getAuth();
