import { api, debug } from "../index";
import { HTTP_STATUS, URL } from "../config";

export default class AlertsService {
  static #API_ENDPOINTS = {
    list: "alert/list",
    alert: "alert",
    reports: "alertReport/list",
    research: "scientificResearchResult/list",
  };

  static async gettingAlertList({
    page = 1,
    size = 10,
    sort = "createdAt",
    sortDirection = "desc",
    type,
    uid,
    startDate,
    endDate,
    cities,
    states,
  }) {
    const typeQueryByType = {
      reported: "reportsCount>0",
      user: `authorId==${uid}`,
      lost_pets: "type==pets;tag_in_lost_dog,lost_cat,lost_other_pet",
      found_pets: "type==pets;tag_in_found_dog,found_cat,found_other_pet",
    };
    const queries = type ? [typeQueryByType[type] ?? `type==${type}`] : [];
    if (startDate) {
      queries.push(`createdAt>=${startDate.toISOString()}`);
    }
    if (endDate) {
      queries.push(`createdAt<=${endDate.toISOString()}`);
    }
    if (cities?.length) {
      queries.push(`city_contains_any_${cities}`);
    }
    if (states?.length) {
      queries.push(`state_contains_any_${states}`);
    }

    let filterQuery = queries.length ? `filter=${queries.join(";")}` : "";

    const query = `pagination=page:${page};size:${size}&sort=${sort}:${sortDirection}&${filterQuery};archivedAt==null&dependencies=true`;

    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.list}?${query}`, {
        headers: {
          "content-type": "application/json",
        },
      });
      debug.success("Alert request Result", request);

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.list}?${query}`, error);
      throw await error.response?.json();
    }
  }

  static async patchAlert(alertId, body) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.alert}?_id=${alertId}`, {
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(body),
      });

      debug.success("Alert update result", request);

      return request;
    } catch (error) {
      debug.error(`Failed with UPDATE alert by path: ${URL}${this.#API_ENDPOINTS.alert}?_id=${alertId}`, error);
    }
  }

  static async deleteAlert(alertId) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.alert}?_id=${alertId}&soft=true`);

      debug.success("Alert delete result", request);

      if (request.status !== HTTP_STATUS.DELETED) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request;
    } catch (error) {
      debug.error(`Failed with DELETE alert by path: ${URL}${this.#API_ENDPOINTS.alert}?_id=${alertId}`, error);
    }
  }

  static async gettingReportsInformation(alertId) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.reports}?filter=alertId==${alertId}&dependencies=true`,
        {
          headers: {
            "content-type": "application/json",
          },
        },
      );

      debug.success("Reports getting result", request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET reports by path: ${URL}${
          this.#API_ENDPOINTS.reports
        }?filter=alertId==${alertId}&dependencies=true`,
        error,
      );
    }
  }

  static async gettingSurveyInformation({ page = 1, size = 10, start, end }) {
    try {
      const request = await api.get(
        `${URL}${
          this.#API_ENDPOINTS.research
        }?pagination=page:${page};size:${size}&filter=createdAt>=${start};createdAt<=${end}&dependencies=true`,
        {
          headers: {
            "content-type": "application/json",
          },
        },
      );

      debug.success("Servey getting result", request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET survey information by path: ${URL}${
          this.#API_ENDPOINTS.research
        }?pagination=page:${page};size=${size}filter=createdAt>=${start};createdAt<=${end}&dependencies=true`,
        error,
      );
    }
  }
}
