import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

import { Button } from "../";

/**
 * @component Confirm
 * @example
 * <Confirm
 *  visible={false}
 *  buttons={[
 *    {
 *      label: 'Cancel',
 *      onClick: () => {},
 *    }
 *  ]}
 * />
 */
const Confirm = (props) => {
  return (
    <Dialog fullWidth={props.fullWidth} maxWidth="xs" open={props.visible} onClose={props.onClose}>
      <DialogTitle marginY={1}>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.subtitle}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", margin: 1 }}>
        {props.buttons.map((item, index) => (
          <Button key={`confirm-button-${index}`} {...item} />
        ))}
      </DialogActions>
    </Dialog>
  );
};

/**
 * Properties
 */

Confirm.propTypes = {
  visible: PropTypes.bool,
  buttons: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClose: PropTypes.func,
  fullWidth: PropTypes.bool,
};
Confirm.defaultProps = {
  buttons: [],
  visible: false,
  title: null,
  subtitle: null,
  onClose: () => {},
  fullWidth: false,
};

export default Confirm;
