import React, { useMemo } from "react";
import STRINGS from "../../localization";

import { Button, Input, Paper } from "../../ui-kit";
import "./styles.scss";
import background from "../../assets/images/background.jpeg";
import logo from "../../assets/images/logo.png";

const AuthorizationView = (props) => {
  const paperClassRender = useMemo(
    () => `auth-box ${props.loading ? "disabled" : ""}`,
    [props.loading]
  );

  const renderData = () => {
    switch (props.screen) {
      case "auth":
        return (
          <>
            <Paper className={paperClassRender}>
              <>
                <h1>{STRINGS.screens.authorization.auth}</h1>
                <div>
                  <p>{STRINGS.screens.authorization.fill}</p>
                  <div className="fields">
                    <Input
                      label={STRINGS.screens.authorization.login}
                      inputType={
                        !props.loginFocus ||
                        props.validation.data?.email?.isValid
                          ? "default"
                          : "danger"
                      }
                      size="large"
                      maxLength={120}
                      value={props.login}
                      onChange={props.setLogin}
                      isValid={props.validation.data?.email?.isValid}
                      focus={props.loginFocus}
                      onBlur={() => props.setLoginFocus(true)}
                    />
                    <Input
                      label={STRINGS.screens.authorization.password}
                      inputType={
                        !props.passwordFocus ||
                        props.validation.data?.password?.isValid
                          ? "default"
                          : "danger"
                      }
                      size="large"
                      type="password"
                      maxLength={32}
                      value={props.password}
                      onChange={props.setPassword}
                      isValid={props.validation.data?.password?.isValid}
                      focus={props.passwordFocus}
                      onBlur={() => props.setPasswordFocus(true)}
                    />
                  </div>
                  <div className="enter">
                    <Button
                      label={STRINGS.screens.authorization.auth}
                      size="large"
                      disabled={props.loading || !props.validation.isValid}
                      type="primary"
                      loading={props.loading}
                      onClick={props.authorization}
                    />
                  </div>
                </div>
              </>
            </Paper>
            <div
              className="forgot-password"
              onClick={() => props.setScreen("reset")}
            >
              {STRINGS.screens.authorization.forgotPassword}
            </div>
          </>
        );
      case "reset":
        return (
          <>
            <Paper className={paperClassRender}>
              <h1>{STRINGS.screens.authorization.resetPassword}</h1>
              <div>
                <p>{STRINGS.screens.authorization.fillForReset}</p>
                <div className="fields">
                  <Input
                    label={STRINGS.screens.authorization.login}
                    inputType={
                      !props.loginFocus || props.validation.data?.email?.isValid
                        ? "default"
                        : "danger"
                    }
                    size="large"
                    maxLength={120}
                    value={props.login}
                    onChange={props.setLogin}
                    isValid={props.validation.data?.email?.isValid}
                    focus={props.loginFocus}
                    onBlur={() => props.setLoginFocus(true)}
                  />
                </div>
                {props.error && (
                  <div className="error">
                    {STRINGS.screens.authorization[props.error]}
                  </div>
                )}
                <div className="enter">
                  <Button
                    label={STRINGS.screens.authorization.sendInstructions}
                    size="large"
                    disabled={props.loading || !props.validation.isValid}
                    type="primary"
                    loading={props.loading}
                    onClick={props.resetPasswordRequest}
                  />
                </div>
              </div>
            </Paper>
            <div
              className="forgot-password"
              onClick={() => {
                props.setError("");
                props.setScreen("auth");
              }}
            >
              {STRINGS.screens.authorization.auth}
            </div>
          </>
        );
      case "confirm":
        return (
          <Paper className={paperClassRender}>
            <h1>{STRINGS.screens.authorization.resetPassword}</h1>
            <div>
              <p>{STRINGS.screens.authorization.confirmReset}</p>
              <div className="enter">
                <Button
                  label={STRINGS.screens.authorization.auth}
                  size="large"
                  disabled={props.loading}
                  type="primary"
                  loading={props.loading}
                  onClick={() => props.setScreen("auth")}
                />
              </div>
            </div>
          </Paper>
        );
      default:
        return;
    }
  };

  return (
    <div id="--auth-root">
      <div className="container">{renderData()}</div>
      <div className="background">
        <div className="title">
          <h1>Welcome to</h1>
          <img src={logo} alt="first page" />
          <h1>admin!</h1>
        </div>
        <img src={background} alt="first page" />
      </div>
    </div>
  );
};

export default AuthorizationView;
