import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
import convertPxToRem from "../../styles/rem";

/**
 * @component Skeleton
 * @example
 * <Skeleton width={40} height={40} value="some text"/>
 */
const Skeleton = (props) => {
  return (
    <div
      style={{
        maxWidth: typeof props.width === "number" ? convertPxToRem(props.width) : props.width,
        maxHeight: typeof props.height === "number" ? convertPxToRem(props.height) : props.height,
      }}
      className={`skeleton ${props.className}`}
    >
      {props.children ? props.children : <span>{props.value}</span>}
    </div>
  );
};

/**
 * Properties
 */

Skeleton.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
  className: PropTypes.string,
};
Skeleton.defaultProps = {
  width: "auto",
  height: "auto",
  value: "&nbsp;",
  className: "",
};

export default Skeleton;
