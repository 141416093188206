import React, { useCallback, useEffect, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useNavigate } from "react-router-dom";

import UserService from "../../services/user";
import { Toast } from "../../ui-kit";

import UsersView from "./users-view";
import { SortMode } from "../../components/label-and-sort/LabelAndSort";

const Users = () => {
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState({});
  const [page, setPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(true);
  const [loading, setLoading] = useState(true);

  const setColumnSort = useCallback((column, mode) => {
    setSort((prevSort) => {
      const { [column]: oldValue, ...newSort } = prevSort;
      if (mode === SortMode.none) {
        return newSort ?? {};
      }
      return { ...newSort, [column]: mode };
    });
  }, []);

  const changeUserStatus = useCallback(async (_id, status) => {
    setLoading(true);
    try {
      const updatedUser = await UserService.updateUser(_id, { status });
      setUsers((prev) => prev.map((user) => (user._id === _id ? updatedUser : user)));
      Toast.success("Success");
    } catch (error) {
      Toast.error("Fail");
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteUser = useCallback(async (_id) => {
    setLoading(true);
    try {
      await UserService.deleteUser(_id);
      setUsers((prev) => prev.filter((user) => user._id !== _id));
      Toast.success("Success");
    } catch (error) {
      Toast.error("Fail");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const { data, last } = await UserService.getUsers({
        page: page,
        size: 10,
        filter: filter.join(";"),
        sort: Object.entries(sort)
          .map(([k, v]) => `${k}:${v}`)
          .join(";"),
      });
      setUsers((prev) => (page === 1 ? data : [...prev, ...data]));
      setIsLastPage(last);
    } catch (error) {
      Toast.error("Failed getting users");
    } finally {
      setLoading(false);
    }
  }, [filter, page, sort]);

  useBottomScrollListener(() => {
    if (!loading && !isLastPage) {
      setPage((prev) => prev + 1);
    }
  });

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    setPage(1);
  }, [filter, sort]);

  const onTableClick = useCallback(
    (event, _id) => {
      navigate({
        pathname: `/users/user`,
        search: new URLSearchParams({ id: _id }).toString(),
      });
    },
    [navigate],
  );

  return (
    <UsersView
      onTableClick={onTableClick}
      users={users}
      setFilter={setFilter}
      setColumnSort={setColumnSort}
      loading={loading}
      page={page}
      changeUserStatus={changeUserStatus}
      deleteUser={deleteUser}
    />
  );
};

export default Users;
