import React, { useMemo } from "react";

import "./styles.scss";

import { Button, ConfirmWithInput, Drawer, Icon, Loader, Paper, Select } from "../../ui-kit";

import STRINGS from "../../localization";
import colors from "../../styles/colors";
import Skelet from "./skelet";
import { getBeautyEngDateString } from "../../utils/datetime";
import { FirebaseImage } from "../../components";
import { DateRangeFilter } from "../../components/date-range-filter/DateRangeFilter";
import { LocationFilter } from "../../components/location-filter/LocationFilter";
import { Box, IconButton, styled, Typography } from "@mui/material";
import { SortIcon } from "../../components/label-and-sort/SortIcon";
import Skeleton from "../../ui-kit/skeleton";
import { Link as MuiLink } from "react-router-dom";
import { DownloadButton } from "../../components/download/DownloadButton";
import { alertsDataExporter } from "./alertsDataExporter";
import { SectionHeader } from "../../components/section-header/SectionHeader";
import { AlertCard } from "./AlertCard";

const Link = styled(MuiLink)({
  textDecoration: "none",
  color: "inherit",
});

const AlertsView = (props) => {
  const paginationProgress = useMemo(() => {
    if (props.skeletVisible) {
      return (
        <Box width={60}>
          <Skeleton width={60} height={15} />
        </Box>
      );
    }

    if (!props.alerts.length) {
      return null;
    }

    return (
      <Typography style={{ fontWeight: "bold", fontSize: 11, display: "inline" }} color={"textSecondary"}>
        (1 - {props.alerts.length} of {props.alertsCounters?.resulting})
      </Typography>
    );
  }, [props.alerts.length, props.alertsCounters?.resulting, props.skeletVisible]);

  const renderContent = () => props.alerts.map((alert) => <AlertCard alert={alert} {...props} />);

  return (
    <div className="alerts-page">
      <SectionHeader>
        <Typography variant="h1">{STRINGS.components.sidebar.alerts}</Typography>
        <DownloadButton
          label={"Download"}
          onClick={(format) => alertsDataExporter.export(props.alerts, `alerts-${new Date().toISOString()}`, format)}
          disabled={!props.alerts.length}
        />
      </SectionHeader>

      <Paper>
        <>
          <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"} justifyContent={"space-between"} gap={4}>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <Typography variant="body2" color="textSecondary">
                Date
              </Typography>
              <DateRangeFilter
                size={"small"}
                onChangeStartDate={props.setStartDate}
                onChangeEndDate={props.setEndDate}
              />
            </Box>
            <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} gap={1}>
              <Typography variant="body2" color="textSecondary">
                {STRINGS.screens.alertScreen.location}
              </Typography>
              <LocationFilter size={"small"} onChangeCities={props.setCities} onChangeStates={props.setStates} />
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"row"} gap={2} paddingTop={"20px"}>
            <Box display={"flex"} flexDirection={"row"} minWidth={240}>
              <Select
                label={STRINGS.screens.alertScreen.orderBy}
                value={props.sortType}
                size={"large"}
                selectType="default"
                options={[
                  { key: "reportsCount", label: STRINGS.screens.alertScreen.numberOfReports },
                  { key: "createdAt", label: STRINGS.screens.alertScreen.creationDate },
                  { key: "city", label: STRINGS.screens.alertScreen.city },
                  { key: "state", label: STRINGS.screens.alertScreen.state },
                ]}
                onChange={props.setSortType}
              />
              <IconButton
                size={"small"}
                onClick={() => props.setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"))}
              >
                <SortIcon mode={props.sortDirection} />
              </IconButton>
            </Box>
          </Box>
        </>
      </Paper>

      <div className="container-for-header">
        <Link to={"/alerts/coyote"}>
          <h1 className={props.tab === props.TABS.COYOTE ? "active-bottom" : "not-active-bottom"}>
            {STRINGS.screens.alertScreen.coyote} {props.tab === props.TABS.COYOTE && paginationProgress}
          </h1>
        </Link>

        <Link to={"/alerts/lost_pets"}>
          <h1 className={props.tab === props.TABS.LOST_PETS ? "active-bottom" : "not-active-bottom"}>
            {STRINGS.screens.alertScreen.lostPets} {props.tab === props.TABS.LOST_PETS && paginationProgress}
          </h1>
        </Link>

        <Link to={"/alerts/found_pets"}>
          <h1 className={props.tab === props.TABS.FOUND_PETS ? "active-bottom" : "not-active-bottom"}>
            {STRINGS.screens.alertScreen.foundPets} {props.tab === props.TABS.FOUND_PETS && paginationProgress}
          </h1>
        </Link>

        <Link to={"/alerts/reported"}>
          <h1 className={props.tab === props.TABS.REPORTED ? "active-bottom" : "not-active-bottom"}>
            {STRINGS.screens.alertScreen.reportedAlerts} {props.tab === props.TABS.REPORTED && paginationProgress}
          </h1>
        </Link>
      </div>
      {props.skeletVisible ? <Skelet /> : <div className="container-for-alerts">{renderContent()}</div>}
      {props.bottomLoading && <Loader width={20} height={20} color="dark" />}
      {props.reportsData && (
        <Drawer anchor="right" visible={props.visibleDrawerForReports} onClose={props.closeDrawerWithReports}>
          <div className="drawer-alerts">
            <h1>
              {props.reportsData.length > 1 ? STRINGS.screens.alertScreen.reports : STRINGS.screens.alertScreen.report}
            </h1>
            {props.reportsData.map((report) => {
              const typeOfReport = {
                spam: STRINGS.screens.alertScreen.reportTypes.spam,
                "inappropriate-photo": STRINGS.screens.alertScreen.reportTypes.badPhoto,
                other: STRINGS.screens.alertScreen.reportTypes.other,
              };
              return (
                <div className="container-for-reports text" key={report._id}>
                  <h3>{STRINGS.screens.alertScreen.author}</h3>
                  <span>{report.authorId?.nickname}</span>
                  <h3>{STRINGS.screens.alertScreen.reportType}</h3>
                  <span>{typeOfReport[report.type]}</span>
                  {report.type === "other" && (
                    <>
                      <h3>{STRINGS.screens.alertScreen.description}</h3>
                      <span>{report.description}</span>
                    </>
                  )}
                </div>
              );
            })}
            <div className="footer">
              <Button
                className="button"
                label={STRINGS.components.buttons.cancel}
                size="medium"
                type="default"
                onClick={props.closeDrawerWithReports}
                loading={props.loading}
                typeButton="button"
              />
            </div>
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default AlertsView;
