import React from "react";
import PropTypes from "prop-types";
import { DatePicker } from "@mui/x-date-pickers";

import "./styles.scss";

/**
 * @component InputDate
 * @example
 * <InputDate
 *   label="Дата регистрации"
 *   inputType="default"
 *   size="large"
 *   maxDate={new Date()}
 *   inputValue={getBeautyDateString(lastContact)}
 *   value={new Date()}
 *   onChange={() => {}}
 * />
 */
const InputDate = (props) => {
  return (
    <DatePicker
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
      disablePast={props.disablePast}
      disableFuture={props.disableFuture}
      maxDate={props.maxDate}
      minDate={props.minDate}
      autoOk
      okLabel={null}
      cancelLabel={null}
    />
  );
};

/**
 * Properties
 */

InputDate.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  label: PropTypes.string,
  inputType: PropTypes.oneOf(["default", "primary", "secondary", "disabled"]),
  inputValue: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  component: PropTypes.any,
};
InputDate.defaultProps = {
  inputValue: "",
  value: null,
  onChange: () => {},
  disabled: false,
  disablePast: null,
  disableFuture: null,
  maxDate: undefined,
  minDate: undefined,
  label: "",
  size: "medium",
  inputType: "primary",
  component: null,
};

export default InputDate;
