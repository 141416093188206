import React from "react";
import PropTypes from "prop-types";

import { Icon, Table, Tooltip } from "../../ui-kit";
import RadiusModal from "../../components/modals/radius-modal";
import Skelet from "./skelet";
import SkeletForArea from "./skeletForArea";

import "./styles.scss";
import STRINGS from "../../localization";
import { SectionHeader } from "../../components/section-header/SectionHeader";
import { Typography } from "@mui/material";

const View = (props) => {
  return (
    <>
      <RadiusModal
        onClose={() => props.setShowModal("")}
        open={props.showModal === "edit"}
        title="Edit radius"
        data={props.selectedRadius}
        onSave={props.saveChanges}
      />
      <SectionHeader>
        <Typography variant="h1">{STRINGS.screens.radiusAndArea.radius}</Typography>
      </SectionHeader>
      <div>
        <div className="radius_table radius">
          {props.skeletVisible ? (
            <Skelet />
          ) : (
            <div className="tables">
              <Table
                className={"radius_table_paper"}
                head={() => (
                  <>
                    <tr className="radius_table_heading">
                      <th>
                        <h4>{STRINGS.screens.radiusAndArea.number}</h4>
                      </th>
                      <th>
                        <h4>
                          {STRINGS.screens.radiusAndArea.meters}/{STRINGS.screens.radiusAndArea.yards}
                        </h4>
                      </th>
                      <th></th>
                    </tr>
                  </>
                )}
              >
                <>
                  {props.radius?.ranges &&
                    props.radius?.ranges[0]?.values.map((item, index) => (
                      <tr key={item} className="radius_table_body">
                        <td>{index + 1}</td>
                        <td>{item}</td>
                        <td className="radius_table_edit">
                          <div className="radius_table_edit_wrapper">
                            <Tooltip title="Edit">
                              <div
                                className="radius_table_iconWrapper"
                                onClick={(e) => {
                                  e.preventDefault();
                                  props.setShowModal("edit");
                                  props.setSelectedRadius({
                                    index,
                                    _id: props.radius._id,
                                    meters: item,
                                    type: "radius",
                                  });
                                }}
                              >
                                <Icon name="pen" width={24} height={24} />
                              </div>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  <tr className="radius_table_body">
                    <td>
                      <h4>{STRINGS.screens.radiusAndArea.maxValue}</h4>
                    </td>
                    <td>{props.radius?.ranges && props.radius?.ranges[0]?.max}</td>
                    <td className="radius_table_edit">
                      <div className="radius_table_edit_wrapper">
                        <Tooltip title="Edit">
                          <div
                            className="radius_table_iconWrapper"
                            onClick={(e) => {
                              e.preventDefault();
                              props.setSelectedRadius({
                                _id: props.radius._id,
                                meters: props.radius?.ranges && props.radius?.ranges[0]?.max,
                                type: "max",
                              });
                              props.setShowModal("edit");
                            }}
                          >
                            <Icon name="pen" width={24} height={24} />
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                </>
              </Table>
            </div>
          )}
        </div>
      </div>
      <SectionHeader>
        <Typography variant="h1">{STRINGS.screens.radiusAndArea.area}</Typography>
      </SectionHeader>
      <div>
        <div className="radius_table">
          {props.skeletVisible ? (
            <SkeletForArea />
          ) : (
            <div className="tables">
              <Table
                head={() => (
                  <>
                    <tr className="radius_table_heading">
                      <th></th>
                      <th>
                        <h4>{STRINGS.screens.radiusAndArea.km2}</h4>
                      </th>
                      <th />
                    </tr>
                  </>
                )}
              >
                <>
                  <tr className="radius_table_body">
                    <td>
                      <h4>{STRINGS.screens.radiusAndArea.maxValue}</h4>
                    </td>
                    <td>{props.area?.ranges && props.area?.ranges[0]?.max}</td>
                    <td className="radius_table_edit">
                      <div className="radius_table_edit_wrapper">
                        <Tooltip title="Edit">
                          <div
                            className="radius_table_iconWrapper"
                            onClick={(e) => {
                              e.preventDefault();
                              props.setShowModal("edit");
                              props.setSelectedRadius({
                                _id: props.area._id,
                                meters: props.area?.ranges && props.area?.ranges[0]?.max,
                                type: "area",
                              });
                            }}
                          >
                            <Icon name="pen" width={24} height={24} />
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                </>
              </Table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

View.propTypes = {
  showModal: PropTypes.string,
  setShowModal: PropTypes.func,
  saveChanges: PropTypes.func,
};

View.defaultProps = {
  showModal: "",
  setShowModal: () => {},
  saveChanges: () => {},
};

export default View;
