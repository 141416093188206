import React from "react";

import "./styles.scss";

import { Paper, Button } from "../../../ui-kit";

const ToastsView = (props) => {
  return (
    <div id="--toasts">
      <Paper className="toasts-example">
        <h4>Toasts (Уведомления)</h4>
        <article>
          <span>
            Вызовите метод класса <code>Toast</code> для того чтобы отобразить всплывающее окно в правом нижнем углу.
            Методы принимают по одному параметру, который используется для отображения текста внутри уведомления.
          </span>
        </article>
        <aside className="row">
          <Button label="Info" onClick={props.callInfoToast} />
          <Button type="secondary" label="Success" onClick={props.callSuccessToast} />
          <Button type="danger" label="Error" onClick={props.callErrorToast} />
        </aside>
      </Paper>
    </div>
  );
};

export default ToastsView;
