import { useEffect, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import PromotionsService from "../../services/promotions";
import { Toast } from "../../ui-kit";
import PromotionsView from "./promotions-view";

const Promotions = () => {
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [promotions, setPromotions] = useState([]);
  const [selectedPromotion, setSelectedPromotion] = useState();
  const [blockedPositions, setBlockedPositions] = useState([]);
  const [showModal, setShowModal] = useState("");
  const [page, setPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(true);
  const [bottomLoading, setBottomLoading] = useState(false);

  const configureStatus = (status) => {
    switch (status) {
      case "created":
        return { status: "active" };
      case "disabled":
        return { status: "active" };
      default:
        return { status: "disabled" };
    }
  };

  const changeStatus = async (elem) => {
    setButtonLoading(elem._id);
    try {
      let temp = [...promotions];
      const status = configureStatus(elem.status).status;
      await PromotionsService.editPromotion(elem._id, { status });
      temp.find((el) => el._id === elem._id).status = status;
      setPromotions(temp);
      Toast.success("Success");
    } catch (error) {
      Toast.error("Fail");
    }
    setButtonLoading();
  };

  const getPromotions = async () => {
    setLoading(true);
    try {
      const result = await PromotionsService.getPromotions({
        page: 1,
        size: 10,
      });
      if (result) {
        setPromotions(result.data);
        setBlockedPositions(result.data.map((item) => item.frequency));
        setIsLastPage(result?.last);
      }
    } catch (error) {
      Toast.error("Fail");
    }
    setLoading(false);
  };

  const getNextPage = async () => {
    setBottomLoading(true);
    try {
      const result = await PromotionsService.getPromotions({
        page: page + 1,
        size: 10,
      });
      setBottomLoading(false);
      setPage((old) => old + 1);
      setPromotions((prev) => [...prev, ...result?.data]);
      setIsLastPage(result?.last);
    } catch (err) {
      Toast.error("fail");
      setBottomLoading(false);
    }
  };

  const editPromotion = async (data) => {
    try {
      const copy = [...promotions];
      const promotionIndex = copy.findIndex((el) => el._id === selectedPromotion._id);
      copy[promotionIndex] = {
        ...copy[promotionIndex],
        ...data,
        _id: selectedPromotion._id,
      };

      await PromotionsService.editPromotion(selectedPromotion._id, data);
      setPromotions(copy);
      setBlockedPositions(copy.map((item) => item.frequency));
      Toast.success("Success");
    } catch (error) {
      Toast.error("Fail");
    }
    setSelectedPromotion();
  };

  const addPromotion = async (data) => {
    try {
      const result = await PromotionsService.createPromotion(data);
      const newData = [...promotions, result];
      setPromotions(newData);
      setBlockedPositions(newData.map((item) => item.frequency));
      Toast.success("Success");
    } catch (error) {
      Toast.error("Fail");
    }
  };

  const deletePromotion = async () => {
    try {
      await PromotionsService.deletePromotion(selectedPromotion);
      setShowModal("");

      const newData = promotions.filter((item) => item._id !== selectedPromotion);
      setPromotions(newData);
      setBlockedPositions(newData.map((item) => item.frequency));
      Toast.success("Success");
    } catch (error) {
      Toast.error("Fail");
    }
    setSelectedPromotion();
  };

  useBottomScrollListener(() => {
    if (!bottomLoading && !isLastPage) {
      getNextPage();
    }
  });

  useEffect(() => {
    getPromotions();
  }, []);

  return (
    <PromotionsView
      promotions={promotions}
      setPromotions={setPromotions}
      showModal={showModal}
      setShowModal={setShowModal}
      loading={loading}
      setLoading={setLoading}
      changeStatus={changeStatus}
      selectedPromotion={selectedPromotion}
      setSelectedPromotion={setSelectedPromotion}
      editPromotion={editPromotion}
      deletePromotion={deletePromotion}
      addPromotion={addPromotion}
      buttonLoading={buttonLoading}
      page={page}
      blockedPositions={blockedPositions}
    />
  );
};

export default Promotions;
