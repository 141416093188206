import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { getDownloadURL, ref } from "firebase/storage";

import { storage } from "../../services/firebase";

/**
 * @component HeaderInput
 * @example
 * <FirebaseImage
 *  url={''}
 *  className={''}
 * />
 */

const FirebaseImage = ({ url, className }) => {
  const [image, setImage] = useState(" ");
  const [error, setError] = useState("");

  const getCurrentImage = async (img) => {
    try {
      const currentImage = await getDownloadURL(ref(storage, img));
      setImage(currentImage); // set from "gs..." to "https..."
    } catch (e) {
      setError(e?.code ?? String(e));
    }
  };

  useEffect(() => {
    url && getCurrentImage(url);
  }, [url]);

  return <img className={className} src={image} alt={error} />;
};

FirebaseImage.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
};
FirebaseImage.defaultProps = {
  url: "",
  className: "",
};

export default FirebaseImage;
