import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
} from "firebase/auth";
import { auth } from "../firebase/index";

export default class AuthService {
  static #LOCAL_STORAGE_KEYS = {
    token: "@authentication/auth_token",
    refreshToken: "@authentication/auth_refresh_token",
  };

  static setAuthToken({ token, refreshToken }) {
    localStorage.setItem(this.#LOCAL_STORAGE_KEYS.token, token);
    localStorage.setItem(this.#LOCAL_STORAGE_KEYS.refreshToken, refreshToken);
  }

  static getAuthToken() {
    return localStorage.getItem(this.#LOCAL_STORAGE_KEYS.token);
  }
  static getRefreshToken() {
    try {
      const token = localStorage.getItem(this.#LOCAL_STORAGE_KEYS.refreshToken);
      if (token) {
        return token;
      }
      return Promise.reject(false);
    } catch (error) {
      throw error;
    }
  }
  static removeAuthTokens() {
    localStorage.removeItem(this.#LOCAL_STORAGE_KEYS.token);
    localStorage.removeItem(this.#LOCAL_STORAGE_KEYS.refreshToken);
  }

  static async auth(email, password) {
    try {
      const resp = await signInWithEmailAndPassword(auth, email, password);
      return resp?.user;
    } catch (error) {
      throw error;
    }
  }

  static async resetPasswordRequest(emailForReset) {
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, emailForReset);
    } catch (error) {
      throw error;
    }
  }

  static async reauthenticate(currentPassword) {
    try {
      const auth = getAuth();
      const credential = EmailAuthProvider.credential(auth.currentUser.email, currentPassword);
      const result = await reauthenticateWithCredential(auth.currentUser, credential);
      return result;
    } catch (error) {
      throw error;
    }
  }

  static async changePassword(newPassword) {
    try {
      await updatePassword(getAuth().currentUser, newPassword);
    } catch (error) {
      throw error;
    }
  }
}
