const en = {
  utils: {
    today: "Today",
    yesterday: "Yesterday",
  },
  filter: {
    individual: "Показать только физические лица",
    entity: "Показать только юридические лица",
    charity: "Показать благотворительные организация",
    active: "Показать активных пользователей",
    disable: "Показать неактивных пользователей",
  },
  generic: {
    state: "State",
    city: "City",
    yes: "Yes",
    no: "No",
  },
  components: {
    header: {
      langSelection: "Language selection",
      search: "Search",
      _search: "Найти",
      empty: "Ничего не найдено",
    },
    statuses: {
      active: "Active",
      disabled: "Disabled",
      suspended: "Suspended",
    },
    select: {
      empty: "Not specified",
    },
    date: {
      date: "MM/DD/YYYY",
    },
    modals: {
      confirm: "Yes, delete it",
      approve: "Yes, approve",
      cancel: "No, cancel",
      signatureForInputInThePostDeletion: "The text from this message will be sent to the user (optional)",
    },
    sidebar: {
      radiusAndArea: "Radius and area",
      surveyResults: "Survey results",
      alerts: "Alerts",
      weRememberYou: "We remember you",
      dashboard: "Dashboard",
      notifications: "Notifications",
      promotions: "Promotions",
      settings: "Settings",
      changePassword: "Change Password",
      contact: "Contact",
      ourStory: "Our Story",
      privacyPolicy: "Privacy Policy",
      coyoteResources: "Coyote Resources",
      termsOfService: "Terms of service",
      copyrightPolicy: "Copyright Policy",
      cookiePolicy: "Cookie Policy",
      notice: "Notice",
      faq: "FAQ",
      users: "Users",
      arendators: "Арендаторы",
      landlords: "Арендодатели",
      development: "Development",
      storybook: "Storybook",
      buttons: "Buttons",
      cards: "Cards",
      selectionControls: "Selection Controls",
      textFields: "Text Fields",
      datePicker: "Date picker",
      dropdownInput: "Dropdown input",
      rentalObjects: "Объекты аренды",
      skelet: "Skelet",
      toasts: "Toasts",
      drawer: "Drawer",
      icons: "Icons",
      modals: "Modals",
      dropzone: "Dropzone",
    },
    gallery: {
      look: "Просмотреть",
      showMore: "Показать еще",
      empty: "Изображений пока нет",
    },
    buttons: {
      save: "Save",
      cancel: "Cancel",
      create: "Create",
      edit: "Edit",
      send: "Send",
      download: "Download",
    },
  },
  screens: {
    surveyResults: {
      author: "Author",
      date: "Date",
      surveyQuestions: [
        "Did the coyote(s) attack/bite a pet or person?",
        "Did the coyote(s) chase a pet or person?",
        "Did the coyote(s) exhibit any fear or wariness of you?",
        "Did the coyote(s) follow a pet or person?",
        "If there was a pet involved in this encounter, was it on-leash or off-leash?",
        "Number of coyotes, Adults",
        "Number of coyotes, Juveniles",
      ],
      noPolls: "No polls were completed for the period you selected",
      coordinates: "Alert coordinates",
    },
    radiusAndArea: {
      radius: "Radius",
      area: "Area",
      number: "Number",
      meters: "Meters",
      yards: "Yards",
      maxValue: "Maximum value",
      sizeOfArea: "Size of area",
      km2: "Square kilometres",
    },
    dashboards: {
      startDate: "Start date",
      endDate: "End date",
      number: "Number of",
      numberOfReports: "Alert Reports",
      numberOfAlertsCoyote: "Coyotes Alerts",
      numberOfAlertsFoundPets: "Found Pets Alerts",
      numberOfAlertsLostPets: "Lost Pets Alerts",
      numberOfUsers: "Users",
    },
    weRememberYou: {
      author: "Author",
      animalName: "Animal name",
      weRememberYou: "We remember you",
      forApproval: "For approval (moderation)",
      activePosts: "Active posts",
      seeMore: "See more",
      approve: "Approve",
      photo: "Photo",
      questionAboutDeletingPost: "Do you really want to delete the post?",
      irreversible: "The deletion will be irreversible.",
      questionAboutPostApproval: "Do you really want to approval this post?",
      dateOfDeath: "Date of death",
      placeOfDeath: "Place of death",
    },
    alertScreen: {
      type: "Alert type",
      report: "Report",
      reports: "Reports",
      coyote: "Coyotes",
      lostPets: "Lost Pets",
      foundPets: "Found Pets",
      reportedAlerts: "Reported Alerts",
      author: "Author",
      resolved: "Resolved",
      notResolved: "Open",
      date: "Date",
      surveyResults: "Survey results",
      coordinates: "Alert coordinates",
      reportType: "Report type",
      description: "Description",
      contact: "Contact",
      reportTypes: {
        spam: "Spam",
        badPhoto: "Inappropriate photo",
        other: "Other",
      },
      questionAboutDeletingAlert: "Do you really want to delete this alert?",
      irreversible: "The deletion will be irreversible.",
      seeOneRetort: "See report",
      seeReports: "See reports",
      creationDate: "Creation Date",
      numberOfReports: "Number Of Reports",
      location: "Location",
      city: "City",
      state: "State",
      orderBy: "Order By",
    },
    notifications: {
      title: "Title",
      description: "Description",
      subject: "Subject",
      questionAboutDeletingNotification: "Do you really want to delete the Notification?",
      irreversible: "The deletion will be irreversible.",
      addNotification: {
        emailNotification: "Add e-mail notification",
        pushNotification: "Add push notification",
        textMessageNotification: "Add text notification",
      },
      typeOfNotifications: {
        push: "Push notifications",
        sms: "Text message notifications",
        email: "E-mail notifications",
      },
    },
    dashboard: {
      startDate: "Start date",
      endDate: "End date",
    },
    promotions: {
      promotions: "Promotions",
      name: "Name",
      title: "Title of the promotion",
      description: "Description",
      picture: "Picture",
      startDate: "Start date",
      endDate: "End date",
      status: "Status",
      pos: "Position",
      active: "active",
      disabled: "disabled",
      position: "Position of the promotion",
      editPromotion: "Edit promotion",
      addPromotion: "Add promotion",
      edit: "Edit",
      delete: "Delete",
      manage: "Manage",
    },
    faq: {
      faq: "FAQ",
      question: "Question",
      answer: "Answer",
      position: "Position of the promotion",
      editQuestion: "Edit question",
      addQuestion: "Add question",
      edit: "Edit",
      delete: "Delete",
      manage: "Manage",
      deleteTitle: "Do you really want to delete the Question?",
      deleteSubTitle: "The deletion will be irreversible.",
    },
    users: {
      username: "Username",
      mobile: "Mobile",
      email: "Email",
      city: "City",
      state: "State",
      status: "Status",
      active: "active",
      suspended: "Suspended",
      name: "Author name",
      alert: "Alert",
      creationDate: "Creation Date",
      type: "Type",
      coordinates: "Alert coordinates",
      delete: "Delete",
    },
    user: {
      username: "Username",
      phone: "Phone",
      email: "Email",
      address: "Address",
      country: "Country",
      state: "State",
      city: "City",
      reports: "Reports",
      userInfo: "User Information",
      userAlerts: "User-created Alerts",
    },
    settings: {
      changePassword: "Change Password",
      settings: "Settings",
      oldPassword: "Current Password",
      newPassword: "New Password",
      confirmPassword: "Confirm New Password",
      success: "Password changed successfully",
    },
    contact: {
      contact: "Contact",
      email: "Email",
      invalid: "Invalid email",
    },
    storybook: {
      switch: "Переключатели",
      switchDescription: "Включает или выключает состояние отдельного параметра.",
      radioBtn: "Радио-кнопки",
      radioBtnDescription: "Радиокнопки позволяют пользователю выбрать один вариант из набора.",
      checkbox: "Флажки",
      checkboxDescription: "Флажки позволяют пользователю выбрать один или несколько элементов из набора.",
      female: "Женский",
      male: "Мужской",
      other: "Другое",
      disabled: "(Отключенный вариант)",
      apple: "Яблоко",
      kiwi: "Киви",
      banana: "Банан",
      containedButtons: "Кнопки с заливкой",
      containedButtonsDescription:
        "Кнопки с заливкой имеют залитое пространство внутри и содержат текст. Для использования достаточно использовать свойство type.",
      outlinedButtons: "Outlined кнопки",
      outlinedButtonsDescription: "Outlined кнопки не имеют заливки и обладают выделенными границами.",
      buttonSizes: "Размеры кнопок",
      buttonSizesDescription: "Для изменеия размеров кнопки достаточно передать свойсто size.",
      buttonIconTitle: "Кнопки содержащие иконки",
      buttonIcon:
        "В компонент Button есть возможность внедрить иконку. Для внедрения иконки достаточно обязательно передать два свойства: ",
      _buttonIcon: "В качестве дополнительных свойств, есть возможность передачи свойств ",
      component: "В компонент",
      possible: "есть возможность передачи любой существующей иконки из компонета",
      property: "Для этого достаточно передать свойство",
      _property: "В качестве дополнительных свойств, есть возможность передачи свойств",
      textFields: "Поля ввода",
      textFieldsDescription: "Текстовы поля предназначены для записи строк в стейт.",
      _component: "Компонент",
      _textFieldsDescription:
        "предназначен для ввода большого количества символов. Для изменения внешнего вида требуется передать свойство ",
      textFieldsSizes: "Размеры текстовых полей",
      textFieldsSizesDescription:
        "Текстовы поля могут иметь различные размеры. Для изменения размеров требуется передать свойство ",
      textArea: "TextArea",
      date: "Поле выбора даты",
      dateDescription: " предназначен для выбора даты.",
      dateSize: "Размеры компонета поля выбора даты",
      _dateSize: "Поля выбора даты могут иметь различные размеры. Для изменения размеров требуется передать свойство ",
      nativeComponent: "Нативный компонент",
      dropdown: "Компонент dropdown",
      dropdownSizes: "Размеры компонента dropdown",
      _dropdownSizes: "может иметь различные размеры. Для изменения размеров требуется передать свойство",
    },
    landlords: {
      filter: "Фильтр",
      reset: "Сбросить",
      manager: "Менеджер",
      from: "С",
      to: "По",
      apply: "Применить",
      _phoneHelp:
        "Первый номер телефона будет являться основным в дальнейшем. Все последующие номера будут добавлены как дополнительные",
      new: "Новый арендодатель",
      name: "Наименование",
      countOfObjects: "Количество объектов",
      registrationDate: "Дата регистрации",
      contact: "Ближайший контакт",
      status: "Статус",
      active: "Активный",
      inactive: "Неактивный",
      landlords: "Арендодатели",
      phoneIsUse: "🧐 Данный номер телефон уже занят!",
      emailIsUse: "🧐 Данная почта уже занята!",
      vatIdIsUse: "🧐 Данный VAT ID Number (ИНН) уже занят!",
      numberIsUse: "🧐 Данный Number (КПП) уже занят!",
      commercialRegistrIsUse: "🧐 Данный Commercial registr (ОГРН) уже занят!",
      similarPhones: "🧐 Проверьте корректность ввода номеров телефонов!",
      nameIsUse: "🧐 Введенное название организации уже занято!",
      empty: "Пусто",
      actions: "Статус просмотра",

      entity: "Юридическое лицо",
      individual: "Физическое лицо",
      nameInput: "Наименование *",
      address: "Адрес *",
      index: "Индекс *",
      country: "Страна *",
      district: "Район *",
      area: "Регион *",
      city: "Город *",
      phone: "Телефон *",
      _phone: "Телефон *",
      email: "E-mail *",
      vatId: "VAT ID Number (ИНН) *",
      number: "Number (КПП) *",
      commercialRegistr: "Commercial registr (ОГРН) *",
      charitableOrganization: "Компания является благотворительной организацией",
      responsiblePerson: "Ответственное лицо",
      appeal: "Обращение *",
      namePersonCharge: "Имя *",
      secondNamePersonCharge: "Фамилия *",
      position: "Должность *",
      cancel: "Отменить",
      create: "Создать",
      site: "Сайт",
      change: "Изменить",

      yes: "Подтвердить",
      removeText: "Вы уверены что хотите безвозвратно удалить примечание?",
      confirmAction: "Подтвердите действие",
      unsavedData: "Имеются несохраненные данные. Вы действительно хотите покинуть страницу?",
      leave: "Покинуть",
      continueCreation: "Продолжить создание",
      newLandlord: "Новый арендодатель",
      roleChange: "Вы уверены что хотите изменить роль пользователя? Все данные из формы будут стерты.",
      removeSuccess: "🎉 Примечание успешно удалено!",

      mr: "Мистер",
      mrs: "Миссис",

      newSuccess: "🎉 Новый арендодатель успешно создан!",
      viewed: "— Желтым цветом отображаются арендодатели, которые были недавно созданы и которых нужно просмотреть.",
      red: "— Красным цветом отображаются арендодатели, которые ожидают звонок в ближайшее время",
    },
    landlordViewing: {
      loadMore: "Загрузить еще",
      remove: "Удаление",
      success: "🎉 Данные о арендодателе успешно изменены!",
      author: "Автор",
      lastContact: "Предыдущий контакт",
      type: "Способ",
      note: "Примечание",
      addNote: "Добавить примечание",
      nextContact: "Следующий контакт",
      landlord: "Арендодатель",
      rentalObjects: "Объекты аренды",

      name: "Наименование",
      address: "Адрес",
      index: "Индекс",
      country: "Страна",
      district: "Район",
      area: "Регион",
      city: "Город",
      phone: "Телефон",
      email: "E-mail",
      vatId: "VAT ID Number (ИНН)",
      number: "Number (КПП)",
      commercialRegistr: "Commercial registr (ОГРН)",
      charitableOrganization: "Компания является благотворительной организацией",
      responsiblePerson: "Ответственное лицо",
      appeal: "Обращение",
      namePersonCharge: "Имя",
      secondNamePersonCharge: "Фамилия",
      position: "Должность",
      registrationDate: "Дата регистрации",
      site: "Сайт",
      saveChanges: "Сохранить изменения",

      notesEmpty: "Примечания пока отсутствуют",
      phoneCommunication: "Разговор по телефону",
      notificationByMail: "Письменное уведомление на почту",

      ceateNewNote: "Создание примечания",
      managerName: "Автор",
      create: "Создать",
      cancel: "Отменить",
      newNoteSuccessAdded: "🎉 Новое примечание успешно добавлено!",
      statusSuccessChanged: "🎉 Статус арендодателя успешно изменен!",
      viewed: "🎉 Статус просмотренный / непросмотренный успешно изменен!",
      deactivate: "Деактивировать",
      activate: "Активировать",

      _cancel: "Отмена",
      confirmDeactivateStatus: "Вы уверены, что хотите деактивировать аккаунт арендодателя",
      confirmActivateStatus: "Вы уверены, что хотите активировать аккаунт арендодателя",
      cancelChanges: "Внимание, подтвердите отмену изменений. Обратить данное действия будет невозможно!",
      agreeCancel: "Подтвердить отмену изменений",
      _cancelChanges: "Отменить изменения",
    },
    tenants: {
      photo: "Фото",
      name: "Имя",
      secondName: "Фамилия",
      registrationDate: "Дата регистрации",
      status: "Статус",
      tenants: "Арендаторы",
    },
    tenatViewing: {
      statusSuccessChanged: "🎉 Статус арендатора успешно изменен!",
      youAreSure: "Вы действительно хотите дективировать аккаунт арендатора?",
      _youAreSure: "Вы действительно хотите активировать аккаунт арендатора?",
      email: "Email",
      list: "Список ограничений",
      emergency: "Телефон для экстренной связи",
      phone: "Телефон",
      registrationDate: "Дата регистации в системе",
      city: "Город проживания",
      birth: "Дата рождения",
      male: "Мужской",
      female: "Женский",
      gender: "Пол",
      secondName: "Фамилиия",
      name: "Имя",
    },
    profile: {
      upload: "Загрузить",
      bestResult:
        "Для достижения наилучшего результата используйте изображение размером не менее 128 на 128 пикселей в формате .jpg.",
      name: "Имя",
      secondName: "Фамилия",
      position: "Должность",
      log: "Логин",
      phone: "Телефон",
      email: "Email",
      saveChanges: "Сохранить изменения",

      changePassword: "Смена пароля",
      old: "Старый пароль",
      new: "Новый пароль",
      repeat: "Повторите пароль",
      change: "Изменить пароль",
      unsaved: "Вы уверены что хотите покинуть страницу? У вас имеются несохраненные данные!",
      success: "🎉 Данные о пользователе успешно обновлены!",

      error: "🧐 Изображение должно иметь формат .jpg или .png!",
      _error: "🧐 Изображение не должно весить более 2-ух мегабайт!",

      passwordChanged: "🎉 Пароль успешно изменен!",
    },
    alerts: {
      alerts: "Панель аналитики",
      landlords: "Арендодатели",
      tenants: "Арендаторы",
      totalUsers: "Всего пользователей",
      totalObjects: "Объектов на платформе",
      totalLandlords: "Количество арендодателей",
      totalTenants: "Количество арендаторов",
      moderationLandlords: "Количество арендодателей, ожидающих модерацию",
      moderationObjects: "Количество объектов, ожидающих модерацию",
      show: "Показать",
      last24: "за поледние 24 часа",
      noneData: "Нет данных",
    },
    authorization: {
      auth: "Log in",
      login: "Login",
      password: "Password",
      go: "Log in",
      fill: "Fill in all required information below to log in.",
      welcome: "Welcome to Instalert admin!",
      solution: "The best solution for your business.",
      forgotPassword: "Forgot password",
      resetPassword: "Reset password",
      sendInstructions: "Send instructions",
      notFound: "Email not found",
      invalid: "Invalid email",
      fillForReset: "Enter your email below to reset your password",
      confirmReset:
        "We sent a password reset link to your email, please follow the instructions and change your password.",
    },
    rentalObjects: {
      viewed: "— Желтым цветом оторбражаются объекты, которые были недавно созданы и которые нужно просмотреть.",
      cretorName: "Наименование арендодателя",
      title: "Название объекта",
      area: "Регион",
      type: "Тип",
      totalSeats: "Количество мест всего",
      freeSeats: "Количество мест свободных",
      paid: "Стоимость аренды в месяц",
      creationDate: "Дата создания",
      status: "Статус",
      rentalObjects: "Объекты аренды",
      addObject: "Добавить новый объект",
      house: "Дом",
      flat: "Квартира",
    },
    viewingRentalObject: {
      edit: "Начните редактировать название",
      description: "Описание",
      furniture: "Наличие мебели",
      _furniture: "Помещение с мебелью",
      withOutFurniture: "Помещение без мебели",
      facilities: "Удобства",
      security: "Безопасность",
      type: "Тип жилья",
      flat: "Квартира",
      house: "Дом",
      date: "Дата создания коливинга",
      address: "Адрес",
      postIndex: "Индекс",
      district: "Район",
      area: "Регион",
      city: "Город",
      floor: "Этаж",
      map: "TODO: MAP",
      totalFloors: "Всего этажей",
      square: "Общая площадь",
      totalRooms: "Количество комнат",
      util: "Коммунальные платежи, € / месяц",
      room: "Комната №",
      busy: "(занята)",
      _square: "Площадь, м²",
      fullRent: "Арендная плата, € / месяц",
      _description: "Описание комнаты",
      entry: "Вход",
      badroom: "Спальня",
      bathroom: "Ванная",
      kitchen: "Кухня",
      commonArea: "Общие зоны",
      parkingLot: "Парковка",
      equipment: "Обородувание",
      housePlan: "Подробные характеристики помещений",
      documents: "Документы необходимые арендатору для заключения договора",
      tab: "Покомнатно",
      _tab: "Объект полностью",
      empty: "(пустой объект)",
      roomBusy: "Комната занята",
      number: "№",
      coldRent: "Холодная арендная плата, € / месяц",
      rent: "Арендная плата с учетом комунальных платежей, € / месяц",
      _2square: "м²",
      perMonth: "€ / месяц",
      gender: "Пол жильца",
      age: "Возраст",
      firstRoom: "Добавьте информацию о первой комнате",
      eur: "€",
      _rent: "Арендная плата",
      settings: {
        wife: "Wi-fi",
        tv: "Телевизор",
        cond: "Кондиционер",
        iron: "Утюг",
        hair: "Фен",
        cupboard: "Шкаф",
        smoke: "Датчик дыма",
        _smoke: "Детектор угарного газа",
        health: "Аптечка",
        fireball: "Огнетушитель",
        utilities: "Коммунальные услуги",
        heating: "Отопление",
        cleaning: "Уборка",
        stairs: "Доступ без лестниц или ступенек",
        lightRoad: "Световая дорожка ко входу",
        entry: "Широкий вход",
        _stairs: "Бесступенчатый доступ ко входу",
        door: "Ширина двери минимум 100 см",
        bad: "Достаточно пространства вокруг кровати",
        wc: "Дополнительное пространства вокруг туалета",
        _wc: "Туалет на подходящей высоте для инвалидов",
        _2wc: "Неподвижные поручни для унитаза",
        shower: "Дополнительное пространства вокруг душа",
        _shower: "Неподвижные поручни в душе",
        _2shower: "Безбарьерный душ",
        _3shower: "Сиденье для душа",
        handShower: "Ручной душ",
        bathtubSeat: "Ванна с сиденьем",
        elevators: "Банные лифты",
        sink: "Раковина доступная сидя",
        mirror: "Поворотное зеркало",
        personalItems: "Варианты хранения личной гигиены",
        square: "Жилая площадь, м²",
        place: "Площадь мест общего пользования, м²",
        _square: "Площадь гостиной, м²",
        kitchenSquare: "Площадь кухни, м²",
        _1square: "Площадь прихожей / коридора, м²",
        wcCount: "Количество санузлов, кол-во",
        wcSquare: "Общая площадь санузлов, м²",
        entryCount: "Количество отдельных входов с улицы, кол-во",
        _2square: "Площадь террасы / балкона / лоджии/ патио, м²",
        lift: "Лифт",
        agree: "Есть",
        disagree: "Нету",
        item: "Пандус",
        parking: "Парковка",
        area: "Внутренняя территория",
        yes: "Да",
        no: "Нет",
        workArea: "Рабочие зоны можно достать сидя",
        disabled: "Достаточно места для поворота в инвалидной коляске",
        boxs: "Шкафы могут быть достигнуты сидя",
        kitchen: "Гостинная и кухня отделены",
        parkingLot: "Имеется парковочное место для людей с ограниченными возможностями",
        equipment: "Мобильный подъемник",
        _equipment: "Бассейн с подъемником",
        _1equipment: "Потолочный подъемник",
        pasport: "Удостоверение личности / Паспорт",
        salary: "Справка о зарплате за последние 3 месяца или налоговая деклорация за последний год",
        contract: "Рабочий договор",
        notice: "Текущее пенсионное уведомление",
        schufa: "Schufa справка",
        certificate: "Свидетельство о свободе от арендной задолженности",
        guarantee: "Поручительство как гарантия оплаты аренды",
        resume: "Резюме",
        insurance: "Уведомление страховой компании о степени по уходу",
        diagnoses: "Медицинские диагнозы, выписки врачей",
        report: "Отчет о предоставлении медицинской помощи, необходимых процедур",
        other: "Другие документы",
      },
    },
    rentalObjectCreation: {
      previousStep: "Предыдущий шаг",
      next: "Далее",
      leave: "Вы уверены, что хотите прекратить создание объета аренды?",
      titleAndDescription: {
        titleAndDescription: "Заголовок и описание",
        addTitle: "Добавьте заголовок объявления",
        name: "Наименование объекта",
        addDescription: "Расскажите будущим жильцам об особенностях предлагаемого жилья",
        description: "Описание объекта",
        next: "Далее",
        recomendation: "Рекомендация",
        titleHelp: "Заголовок должен быть краткий, но ёмкий, чтобы отражать ценность для арендатора.",
        descriptionHelp:
          "В описании укажите преимущества инфраструктуры, например, близость остановок общественного транспорта, торгового центра или учреждений здравоохранения.",
      },
      location: {
        title: "Где находится Ваше жильё?",
        description:
          "Bведитe адреc вручную или перетяните указатель в нужное место. Точное местоположение будет доступно только подтвержденным арендаторам.",
        street: "Улица, номер дома",
        postIndex: "Индекс",
        city: "Город",
        district: "Регион",
        country: "Страна",
        rent: "Сдаётся только жителям данного региона",
      },
      rentType: {
        type: "Тип жилья",
        description: "Какое описание наиболее соответствует вашему объекту?",
        house: "Дом",
        _house: "Отдельно стоящее здание, обычно есть придомовая территория и ограждение.",
        flat: "Квартира",
        _flat: "Помещение в многоквартирном доме или жилом комплексе.",
        full: "Объект сдаётся только полностью",
        care: "На объекте предоставляется уход",
        help: "Помощь, интенсивный уход, при деменции или вентиляции легких",
        _care: "Выберите тип коливинга (c уходом)",
        _help: "Помощь",
        notHelp: "Помощь не нужна",
        partialHelp: "Частичная помощь",
        directorHelp: "Помощь руководства",
        fullHelp: "Полная помощь",
        dementia: "При деменции",
        start: "Начало деменции",
        easy: "Легкая деменция",
        medium: "Умеренная деменция",
        hard: "Тяжелая деменция",
        ventilation: "С вентиляцией легких",
        _vent: "Трахеостомическая трубка с домашней вентиляцией",
        _ventWithout: "Трахеостомическая трубка без домашней вентиляции",
        intensive: "Интенсивный уход",
        withOutCare: "Выберите тип коливинга (без ухода)",
        old: "Старше 50 лет",
        active: "Активные пенсионеры",
        few: "Несколько поколений",
        rent: "Проживание за помощь по хозяйству",
      },
      houseParam: {
        title: "Параметры дома",
        totalFloors: "Сколько этажей у дома?",
        _totalFloors: "Количество этажей",
        totalSquare: "Какая общая площадь дома?",
        _totalSquare: "Площадь, м²",
        totalRooms: "Сколько комнат всего?",
        _totalRooms: "Количество комнат",
        busy: "Уже есть занятые комнаты",
        totalBusyRooms: "Сколько комнат занято",
        _totalBusyRooms: "Количество занятых комнат",
      },
      flatParam: {
        title: "Параметры квартиры",
        totalFloors: "Сколько этажей у дома?",
        _totalFloors: "Количество этажей",
        currentFloor: "На каком этаже находится квартира?",
        _currentFloor: "Этаж расположения",
        totalSquare: "Какая общая площадь квартиры?",
        _totalSquare: "Площадь, м2",
        totalRooms: "Сколько комнат всего?",
        _totalRooms: "Количество комнат",
        busy: "Уже есть занятые комнаты",
        totalBusyRooms: "Сколько комнат занято",
        _totalBusyRooms: "Количество занятых комнат",
      },
      fullRent: {
        title: "Арендная плата",
        description: "Укажите сумму коммунальных платежей и холодную арендную плату.",
        helpTitle: "Холодная аренда",
        helpText: "Это часть аренды без учета коммунальных платежей.",
        total: "Всего:",
        unit: " €/мес",
        cleaning: "Уборка, €/мес",
        heating: "Отопление, €/мес",
        util: "Коммунальные услуги, €/мес",
        coldRent: "Холодная аренда, €/мес",
      },
      emptyRentalObject: {
        title: "Арендная плата",
        description:
          "Введите площадь комнаты, значение холодной аренды – часть аренды без учета коммунальных платежей – и значение теплой аренда – часть аренды с учетом коммунальных платежей.",
        room: "Комната",
        square: "Площадь, м²",
        coldRent: "Холодная аренда, €/мес",
        rent: "Теплая аренда, €/мес",
        add: "Добавить комнату",
      },
      steps: {
        title: "Заголовок и описание",
        location: "Местоположение",
        rentalType: "Тип жилья",
        rentalObjectProperties: "Параметры объекта",
        rental: "Аренда",
        roomsProperties: "Параметры помещений",
        security: "Удобства и безопасность",
        specialNeeds: "Особые потребности",
        photosAndVideos: "Фотографии и видео",
        descriptionOfRooms: "Описание комнат",
        requiredDocuments: "Необходимые документы",
        publication: "Публикация",
      },
    },
  },
};

export default en;
