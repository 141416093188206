import React, { useState, useEffect } from "react";
import validator from "validator";
import { validateObject } from "../../../utils/input-functions";

import TextFieldsView from "./text-fields-view";

const SCHEME = {
  email: (value) => validator.isEmail(value),
  userName: (value) => value?.length > 0,
};

const TextFieldsContainer = () => {
  const [inputTextFields, setInputTextFields] = useState({
    input_0: "Default",
    input_1: "",
    input_2: "",
    input_3: "Disabled",
    input_4: "Арбуз",
    input_5: "",
    input_6: "Яблоко",
    input_7: "Danger",
  });

  const [textAreaField, setTextAreaField] = useState({
    textarea_0: "",
    textarea_1: "",
  });

  const [userName, setUserName] = useState({
    value: "Алексей Глазунов",
    focus: false,
  });

  const [email, setEmail] = useState({
    value: "",
    focus: false,
  });

  const [validation, setValidation] = useState({ isValid: false, data: {} });

  useEffect(() => {
    setValidation(validateObject({ email: email.value, userName: userName.value }, SCHEME));
  }, [email.value, userName.value]);

  return (
    <TextFieldsView
      email={email}
      userName={userName}
      textAreaField={textAreaField}
      setUserName={setUserName}
      setTextAreaField={setTextAreaField}
      setEmail={setEmail}
      inputTextFields={inputTextFields}
      setInputTextFields={setInputTextFields}
      validation={validation}
    />
  );
};

export default TextFieldsContainer;
