import { api, debug } from "../index";
import { HTTP_STATUS, URL } from "../config";

export default class UserService {
  static #API_ENDPOINTS = {
    user: "user",
    userStats: "user/stats",
    users: "user/list",
  };

  static async getUser(uid) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.user}?fbUid=${uid}`);

      debug.success("Authentication request result", request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      const requestData = await request.json();

      if (requestData) {
        return requestData;
      }

      return Promise.reject("No Data");
    } catch (globalError) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.user}?uid=${uid}`, globalError);
      throw await globalError.response?.json();
    }
  }

  static async getUserById(uid) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.user}?_id=${uid}`, {
        headers: {
          "content-type": "application/json",
        },
      });

      debug.success("Authentication request result", request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      const requestData = await request.json();

      if (requestData) {
        return requestData;
      }

      return Promise.reject("No Data");
    } catch (globalError) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.user}?_id=${uid}`, globalError);
      throw await globalError.response?.json();
    }
  }

  static async getUserStats(uid) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.userStats}?_id=${uid}`, {
        headers: {
          "content-type": "application/json",
        },
      });

      debug.success("Authentication request result", request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      const requestData = await request.json();

      if (requestData) {
        return requestData;
      }

      return Promise.reject("No Data");
    } catch (globalError) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.userStats}?_id=${uid}`, globalError);
      throw await globalError.response?.json();
    }
  }

  static async getUsers({ page = 1, size = 10, filter = "", dependencies = false, sort = "" }) {
    try {
      const query = new URLSearchParams({
        pagination: `page:${page};size:${size}`,
        filter: filter ? `${filter};archivedAt==null` : "archivedAt==null",
        sort,
        dependencies,
      });
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.users}?${query.toString()}`, {
        headers: {
          "content-type": "application/json",
        },
      });

      debug.success("Authentication request result", request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      const requestData = await request.json();

      if (requestData) {
        return requestData;
      }

      return Promise.reject("No Data");
    } catch (globalError) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.users}?pagination=page:${page};size=${size}`,
        globalError,
      );
      throw await globalError.response?.json();
    }
  }

  static async updateUser(id, data) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.user}?_id=${id}`, {
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      });

      debug.success("Authentication request result", request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      const response = await request.json();

      if (response) {
        return response;
      }

      return Promise.reject("No Data");
    } catch (globalError) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.user}?_id=${id}`, globalError);
      throw await globalError.response?.json();
    }
  }

  static async deleteUser(id) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.user}?_id=${id}`);
      if (request.status !== HTTP_STATUS.DELETED) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }
    } catch (globalError) {
      throw await globalError.response?.json();
    }
  }
}
