import React, { useEffect, useMemo, useState } from "react";
import US_STATES from "../../constants/us-states";
import { InputTextField } from "../../ui-kit/input-text-field";
import STRINGS from "../../localization";
import US_CITIES from "../../constants/us-cities";
import { Box, Autocomplete } from "@mui/material";

export const LocationFilter: React.FC<{
  onChangeCities: (value: string[]) => void;
  onChangeStates: (value: string[]) => void;
  size?: "small" | "medium";
}> = ({ onChangeCities, onChangeStates, size = "medium" }) => {
  const [states, setStates] = useState<typeof US_STATES | undefined>();
  const [cities, setCities] = useState<typeof US_CITIES | undefined>();

  useEffect(() => {
    if (states) {
      onChangeStates(states.map(({ abbreviation }) => abbreviation));
    }
  }, [onChangeStates, states]);

  useEffect(() => {
    if (cities) {
      onChangeCities(cities.map(({ city }) => city));
    }
  }, [cities, onChangeCities]);

  const citiesOptions: typeof US_CITIES = useMemo(() => {
    if (!states?.length) {
      return [];
    }
    const selectedStates = new Set(states.map(({ abbreviation }) => abbreviation));
    return US_CITIES.filter(({ state }) => selectedStates.has(state));
  }, [states]);

  const noOptionsText = useMemo(() => (states?.length ? "No city found" : "Select state(s) first"), [states?.length]);
  return (
    <Box display={"flex"} flexDirection={"row"} gap={2}>
      <Autocomplete
        multiple
        size={size}
        value={states ?? []}
        autoComplete
        onChange={(_e, values) => setStates(values)}
        limitTags={1}
        style={{ width: 200, height: 56 }}
        options={US_STATES}
        getOptionLabel={(option) => option.abbreviation}
        ChipProps={{ size: "small" }}
        renderInput={(params) => (
          <InputTextField
            color={"secondary"}
            {...params}
            value={params}
            label={STRINGS.generic.state}
            variant="outlined"
          />
        )}
      />

      <Autocomplete
        multiple
        size={size}
        value={cities ?? []}
        onChange={(e, values) => {
          setCities(values);
        }}
        limitTags={1}
        style={{ width: 300, height: 56 }}
        aria-multiline="false"
        options={citiesOptions}
        getOptionLabel={(option) => option.city}
        noOptionsText={noOptionsText}
        ChipProps={{ size: "small" }}
        renderInput={(params) => (
          <InputTextField
            color={"secondary"}
            {...params}
            value={params}
            label={STRINGS.generic.city}
            variant="outlined"
          />
        )}
      />
    </Box>
  );
};
