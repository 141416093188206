import React from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/lib/integration/react";
import { createRoot } from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./styles/reset.scss";
import "./styles/styles.scss";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { createTheme, ThemeProvider } from "@mui/material";
import colors from "./styles/colors";
import rem from "./styles/rem";

const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: colors.color_red._0,
      light: colors.color_red._1,
      dark: colors.color_red._0,
    },
    secondary: {
      main: colors.color_basic._0,
    },
  },
  typography: {
    h1: {
      fontSize: rem(28),
      fontWeight: 500,
      lineHeight: rem(36),
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: rem(32),
          fontSize: 14,
          fontWeight: 700,
          textTransform: "none",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: rem(12),
        },
      },
    },
  },
});

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider theme={defaultMaterialTheme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <App />
            <ToastContainer
              theme={"colored"}
              position="bottom-right"
              autoClose={3000}
              hideProgressBar
              newestOnTop={false}
              limit={5}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </LocalizationProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
);

reportWebVitals();
