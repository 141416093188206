import React from "react";
import PropTypes from "prop-types";
import { DateTimePicker } from "@mui/x-date-pickers";
import "./styles.scss";

/**
 * @component InputDate
 * @example
 * <InputDate
 *   label="Дата регистрации"
 *   inputType="default"
 *   size="large"
 *   maxDate={new Date()}
 *   inputValue={getBeautyDateString(lastContact)}
 *   value={new Date()}
 *   onChange={() => {}}
 * />
 */
const InputDateTime = (props) => {
  const handleDateChange = (date) => {
    props.onChange(date);
  };

  return (
    <DateTimePicker
      slotProps={{
        textField: { style: { borderRadius: "6px", width: 260 }, size: props.size, color: "secondary" },
        field: { clearable: true, onClear: () => handleDateChange(null) },
      }}
      value={props.value}
      onAccept={handleDateChange}
      disabled={props.disabled}
      disablePast={props.disablePast}
      disableFuture={props.disableFuture}
      maxDate={props.maxDate}
      minDate={props.minDate}
    />
  );
};

/**
 * Properties
 */

InputDateTime.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  label: PropTypes.string,
  inputType: PropTypes.oneOf(["default", "primary", "secondary", "disabled"]),
  inputValue: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  component: PropTypes.any,
};
InputDateTime.defaultProps = {
  inputValue: "",
  value: null,
  onChange: () => {},
  disabled: false,
  disablePast: null,
  disableFuture: null,
  maxDate: undefined,
  minDate: undefined,
  label: "",
  size: "medium",
  inputType: "primary",
  component: null,
};

export default InputDateTime;
