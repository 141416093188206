import React from "react";
import { Box, Drawer as MuiDrawer } from "@mui/material";
import PropTypes from "prop-types";

import "./styles.scss";
import { styled } from "@mui/system";

const DrawerContent = styled(Box)({
  list: {
    width: "auto",
    minWidth: "20rem",
  },
});

/**
 * @component Drawer
 * @example
 * <Drawer
 *  onClick={() => {}}
 *  visible={false}
 *  anchor="left"
 * />
 */
const Drawer = (props) => {
  return (
    <div>
      <MuiDrawer anchor={props.anchor} open={props.visible} onClose={props.onClose ? props.onClose : props.onClick}>
        <DrawerContent role="presentation">{props.children}</DrawerContent>
      </MuiDrawer>
    </div>
  );
};

/**
 * Properties
 */

Drawer.propTypes = {
  onClick: PropTypes.func,
  visible: PropTypes.bool,
  children: PropTypes.element,
  anchor: PropTypes.oneOf(["left", "right"]),
  onClose: PropTypes.func,
};
Drawer.defaultProps = {
  onClick: () => {},
  visible: false,
  children: null,
  anchor: "right",
  onClose: null,
};

export default Drawer;
