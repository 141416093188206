import { api, debug } from "../index";
import { HTTP_STATUS, URL } from "../config";

export default class PostsService {
  static #API_ENDPOINTS = {
    list: "post/list",
    post: "post",
  };

  static async gettingPostList({ page = 1, size = 20, status }) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.list}?pagination=page:${page};size:${size}&dependencies=true&filter=status==${status}`,
        {
          headers: {
            "content-type": "application/json",
          },
        },
      );
      debug.success("Post request result", request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.list}?pagination=page:${page};size:${size}&dependencies=true&filter=status==${status}`,
      );
      throw await error.response?.json();
    }
  }

  static async deletePost(postUid) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.post}?_id=${postUid}`, {
        headers: {
          "content-type": "application/json",
        },
      });

      debug.success("Post delete result", request);

      if (request.status !== HTTP_STATUS.DELETED) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request;
    } catch (error) {
      debug.error(`Failed with DELETE post by path: ${URL}${this.#API_ENDPOINTS.post}?uid=${postUid}`, error);
    }
  }
  static async postConfirmation(postId) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.post}?_id=${postId}`, {
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ status: "approved" }),
      });

      debug.success("Content update result", request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request;
    } catch (error) {
      debug.error(`Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.post}?uid=${postId}`, error);
      throw await error.response?.json();
    }
  }
}
