import { useState } from "react";

import AlertsService from "../services/alerts";
import { Toast } from "../ui-kit";

const useAlerts = () => {
  const [alerts, setAlerts] = useState([]);
  const [alertsCounters, setAlertsCounters] = useState();
  const [selectedAlert, setSelectedAlert] = useState("");
  const [reports, setReports] = useState([]);

  const [isLastPage, setIsLastPage] = useState(true);
  const [alertPage, setAlertPage] = useState(1);

  const [isAlertLoading, setIsAlertLoading] = useState(true);
  const [isReportsLoading, setIsReportsLoading] = useState(true);
  const [isBottomLoading, setIsBottomLoading] = useState(false);

  const setLoading = (loading, isNext = false) => {
    isNext ? setIsBottomLoading(loading) : setIsAlertLoading(loading);
  };

  const onGetAlerts = async (
    getProps,
    isNext = false,
    successMsg = "",
    errorMsg = "Failed to get the list of alerts",
  ) => {
    setLoading(true, isNext);
    try {
      const result = await AlertsService.gettingAlertList(getProps);

      setAlerts((prev) => (isNext ? [...prev, ...result?.data] : result?.data));
      setAlertsCounters(result?.length);
      setIsLastPage(result?.last);
      setAlertPage((old) => (isNext ? old + 1 : 1));

      successMsg && Toast.success(successMsg);
    } catch (error) {
      Toast.error(errorMsg);
    } finally {
      setLoading(false, isNext);
    }
  };

  const onGetAlertReports = async (alertId = "") => {
    setIsReportsLoading(true);
    try {
      const result = await AlertsService.gettingReportsInformation(!!alertId ? alertId : selectedAlert);
      setReports(result?.data);
    } catch (error) {
      Toast.error("Failed to get list of reports");
    }
    setIsReportsLoading(false);
  };

  const onDeleteAlert = async (notification, callBack = () => {}) => {
    setLoading(true);
    try {
      await AlertsService.patchAlert(selectedAlert, {
        deletionReason: {
          en: notification.title,
          es: notification.title,
        },
      });
      await AlertsService.deleteAlert(selectedAlert);
      setAlerts((prev) => prev.filter((item) => item._id !== selectedAlert));

      callBack([]);
      Toast.success("Alert delete successfully");
    } catch (error) {
      Toast.error("Alert delete fail");
    } finally {
      setLoading(false);
      setSelectedAlert("");
    }
  };

  return {
    // CRUD
    onGetAlerts,
    onGetAlertReports,
    onDeleteAlert,

    // constants
    alerts,
    setAlerts,
    alertsCounters,
    reports,
    setReports,
    isAlertLoading,
    setIsAlertLoading,
    isReportsLoading,
    isLastPage,
    // setIsLastPage,
    alertPage,
    isBottomLoading,
    selectedAlert,
    setSelectedAlert,
  };
};

export default useAlerts;
