import React, { useState } from "react";

import DatePickerView from "./date-picker-view";

const DatePickerContainer = () => {
  const [date, setDate] = useState({
    value_0: null,
    value_1: null,
    value_2: new Date(new Date().getTime() - 100000000000),
    value_3: new Date(),
    value_4: null,
    value_5: new Date(),
    value_6: null,
  });
  return <DatePickerView date={date} setDate={setDate} />;
};

export default DatePickerContainer;
