import React from "react";

import "./styles.scss";

import { Input, Paper, Skeleton, Button, Switch } from "../../../ui-kit";

const SkeletView = (props) => {
  const handleSkelet = () => {
    props.setEnable((old) => !old);
  };
  return (
    <div id="--skeleton">
      <Paper className="skelet-example">
        <h4>Skeleton (Скелет)</h4>
        <article>
          <span>
            Отображайте макет вашего приложения перед загрузкой данных, чтобы уменьшить дискомфорт от загрузки.
          </span>
          <br />
          <code>{"<Skeleton />"}</code>
        </article>
        <aside className="row">
          <Switch
            checked={props.enable}
            label={props.enable ? "Отключить скелет" : "Включить скелет"}
            onChange={handleSkelet}
          />
          {props.enable ? (
            <>
              <Skeleton width="100%">
                <Input />
              </Skeleton>
              <Skeleton width="100%">
                <Input />
              </Skeleton>

              <Skeleton width="fit-content">
                <Button label="Продолжить" />
              </Skeleton>
            </>
          ) : (
            <>
              <Input label="Имя пользователя" value="Niko Belic" />
              <Input label="Почта" value="n.belic@tapston.com" />
              <Button label="Продолжить" />
            </>
          )}
        </aside>
      </Paper>
    </div>
  );
};

export default SkeletView;
