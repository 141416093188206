import { api, debug } from "../index";
import { HTTP_STATUS, URL } from "../config";

export default class LanguagesService {
  static #API_ENDPOINTS = {
    languages: "language/list",
  };

  static async getLanguages() {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.languages}`, {
        headers: {
          "content-type": "application/json",
        },
      });

      debug.success("Authentication request result", request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      const requestData = await request.json();

      if (requestData) {
        return requestData;
      }

      return Promise.reject("No Data");
    } catch (globalError) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.languages}`, globalError);
      throw await globalError.response?.json();
    }
  }
}
