import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import "./styles.scss";
import colors from "../../styles/colors";

import STRINGS from "../../localization";

import { Icon, Drawer } from "../../ui-kit";
import { HRowItem, HRowDropdownItem } from "..";

import { useWindowDimensions } from "../../utils/hooks";

import Debug from "../../services/debug";

/**
 * @component Sidebar
 * @example
 * <Sidebar
 *  onClick={() => {}}
 *  sidebarVisible={false}
 * />
 */
const Sidebar = (props) => {
  const [pathname, setPathname] = useState("");
  const user = useSelector((state) => state.currentUser.data);

  const { width } = useWindowDimensions();
  const location = useLocation();

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  useEffect(() => {
    setPathname(window.location.pathname);
  }, []);

  const SidebarView = () => {
    return (
      <div id="--sidebar-root">
        <Link to="/" className="sidebar-logo">
          <Icon name="logo" width={240} height={60} color={colors.color_white._2} />
        </Link>
        <div>
          <HRowItem
            setSidebarVisible={props.onClick}
            name={STRINGS.components.sidebar.alerts}
            to="/alerts"
            icon="location"
            active={pathname === "/alerts"}
          />
          <HRowItem
            setSidebarVisible={props.onClick}
            name={STRINGS.components.sidebar.surveyResults}
            to="/surveyResults"
            icon="surveyResults"
            active={pathname === "/surveyResults"}
          />
          <HRowItem
            setSidebarVisible={props.onClick}
            name={STRINGS.components.sidebar.radiusAndArea}
            to="/radius"
            icon="vector"
            active={pathname === "/radius"}
          />
          <HRowItem
            setSidebarVisible={props.onClick}
            name={STRINGS.components.sidebar.notifications}
            to="/notifications"
            icon="mail"
            active={pathname === "/notifications"}
          />
          <HRowItem
            setSidebarVisible={props.onClick}
            name={STRINGS.components.sidebar.promotions}
            to="/promotions"
            icon="folder"
            active={pathname === "/promotions"}
          />
          <HRowItem
            setSidebarVisible={props.onClick}
            name={STRINGS.components.sidebar.dashboard}
            to="/dashboard"
            icon="dashboard"
            active={pathname === "/dashboard"}
          />
          <HRowItem
            setSidebarVisible={props.onClick}
            name={STRINGS.components.sidebar.weRememberYou}
            to="/we-remember-you"
            icon="pets"
            active={pathname === "/we-remember-you"}
          />
          <HRowItem
            setSidebarVisible={props.onClick}
            name={STRINGS.components.sidebar.users}
            to="/users"
            icon="users"
            active={pathname === "/users"}
          />
          <HRowDropdownItem
            setSidebarVisible={props.onClick}
            name={STRINGS.components.sidebar.settings}
            icon="gear"
            initialVisible={false}
            linkList={[
              {
                name: STRINGS.components.sidebar.changePassword,
                to: "/settings/changepassword",
                active: pathname === "/settings/changepassword",
              },
              {
                name: STRINGS.components.sidebar.contact,
                to: "/settings/contact",
                active: pathname === "/settings/contact",
              },
              {
                name: STRINGS.components.sidebar.ourStory,
                to: "/settings/ourstory",
                active: pathname === "/settings/ourstory",
              },
              {
                name: STRINGS.components.sidebar.coyoteResources,
                to: "/settings/coyote-resources",
                active: pathname === "/settings/coyote-resources",
              },
              {
                name: STRINGS.components.sidebar.privacyPolicy,
                to: "/settings/privacy-policy",
                active: pathname === "/settings/privacy-policy",
              },
              {
                name: STRINGS.components.sidebar.copyrightPolicy,
                to: "/settings/copyright-policy",
                active: pathname === "/settings/copyright-policy",
              },
              {
                name: STRINGS.components.sidebar.cookiePolicy,
                to: "/settings/cookie-policy",
                active: pathname === "/settings/cookie-policy",
              },
              {
                name: STRINGS.components.sidebar.termsOfService,
                to: "/settings/terms-of-service",
                active: pathname === "/settings/terms-of-service",
              },
              {
                name: STRINGS.components.sidebar.notice,
                to: "/settings/notice",
                active: pathname === "/settings/notice",
              },
              {
                name: STRINGS.components.sidebar.faq,
                to: "/settings/faq",
                active: pathname === "/settings/faq",
              },
            ]}
          />

          {Debug.isDevelopment && (
            <>
              <p>{STRINGS.components.sidebar.development}</p>
              <HRowDropdownItem
                setSidebarVisible={props.onClick}
                name={STRINGS.components.sidebar.storybook}
                icon="layer"
                linkList={[
                  {
                    name: STRINGS.components.sidebar.buttons,
                    to: "/storybook/buttons",
                    active: pathname === "/storybook/buttons",
                  },
                  {
                    name: STRINGS.components.sidebar.drawer,
                    to: "/storybook/drawer",
                    active: pathname === "/storybook/drawer",
                  },
                  {
                    name: STRINGS.components.sidebar.selectionControls,
                    to: "/storybook/selection-controls",
                    active: pathname === "/storybook/selection-controls",
                  },
                  {
                    name: STRINGS.components.sidebar.toasts,
                    to: "/storybook/toasts",
                    active: pathname === "/storybook/toasts",
                  },
                  {
                    name: STRINGS.components.sidebar.textFields,
                    to: "/storybook/text-fields",
                    active: pathname === "/storybook/text-fields",
                  },
                  {
                    name: STRINGS.components.sidebar.datePicker,
                    to: "/storybook/date-picker",
                    active: pathname === "/storybook/date-picker",
                  },
                  {
                    name: STRINGS.components.sidebar.icons,
                    to: "/storybook/icons",
                    active: pathname === "/storybook/icons",
                  },
                  {
                    name: STRINGS.components.sidebar.dropdownInput,
                    to: "/storybook/dropdown",
                    active: pathname === "/storybook/dropdown",
                  },
                  {
                    name: STRINGS.components.sidebar.skelet,
                    to: "/storybook/skelet",
                    active: pathname === "/storybook/skelet",
                  },
                  {
                    name: STRINGS.components.sidebar.modals,
                    to: "/storybook/modals",
                    active: pathname === "/storybook/modals",
                  },
                  {
                    name: STRINGS.components.sidebar.dropzone,
                    to: "/storybook/dropzone",
                    active: pathname === "/storybook/dropzone",
                  },
                ]}
              />
            </>
          )}
        </div>
        <Link to="/profile" className="navigation-manager">
          <div className="user-data">
            <p className="name">{user.nickname}</p>
            <p className="mail">{user.email}</p>
          </div>
        </Link>
      </div>
    );
  };

  if (width > 992) {
    return SidebarView();
  } else {
    return (
      <Drawer anchor="left" visible={props.sidebarVisible} onClick={() => props.onClick(!props.sidebarVisible)}>
        {SidebarView()}
      </Drawer>
    );
  }
};

/**
 * Properties
 */

Sidebar.propTypes = {
  onClick: PropTypes.func,
  sidebarVisible: PropTypes.bool,
};
Sidebar.defaultProps = {
  onClick: () => {},
  sidebarVisible: false,
};

export default Sidebar;
