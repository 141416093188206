import { useEffect, useState } from "react";
import AdminSettingsService from "../../services/adminSettings";
import { Toast } from "../../ui-kit";
import PrivacyPolicyView from "./privacyPolicy-view";
import { textTransformation } from "../../utils/text-transformation";

const PrivacyPolicy = () => {
  let [editedText, setEditedText] = useState("");
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(true);
  const [uid, setUid] = useState();

  const saveChanges = async () => {
    editedText = textTransformation(editedText);
    setLoading(true);
    try {
      if (uid) {
        await AdminSettingsService.editAdminSetting(uid, { privacyPolicy: { en: editedText, es: editedText } });
      } else {
        const result = await AdminSettingsService.createSetting({ privacyPolicy: { en: editedText, es: editedText } });
        if (result?._id) {
          setUid(result?._id);
        }
      }
      Toast.success("Success");
    } catch (error) {
      Toast.error("Fail");
    }
    setLoading(false);
    setDisable(true);
  };

  const getPrivacyPolicy = async () => {
    setLoading(true);
    try {
      const result = await AdminSettingsService.getSettings({ page: 1, size: 10 });
      const data = result.data[0];
      if (data?._id) {
        setEditedText(data?.privacyPolicy?.en);
        setUid(data?._id);
      }
    } catch (error) {
      Toast.error("Fail");
    }
    setLoading(false);
  };

  const handleTextChange = (text) => {
    if (text === "<p><br></p>") {
      setEditedText("");
    } else {
      setEditedText(text);
    }
  };

  const cancel = async () => {
    await getPrivacyPolicy();
    setDisable(true);
  };

  /* useEffect(() => {
    setValidation(
      validateObject(
        { editedText },
        SCHEME,
      ),
    );
  }, [editedText]); */

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  return (
    <PrivacyPolicyView
      editedText={editedText}
      setEditedText={setEditedText}
      disable={disable}
      setDisable={setDisable}
      loading={loading}
      saveChanges={saveChanges}
      handleTextChange={handleTextChange}
      cancel={cancel}
    />
  );
};

export default PrivacyPolicy;
