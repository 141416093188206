import React, { useCallback, useState } from "react";
import { ButtonGroup, MenuItem, Popover, Button } from "@mui/material";
import colors from "../../styles/colors";

interface Props {
  label: string;
  onClick: (format: "csv" | "json") => void | Promise<void>;
  disabled: boolean;
}

export const DownloadButton: React.FC<Props> = ({ onClick, label, disabled }) => {
  const [format, setFormat] = useState<"csv" | "json">("csv");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const onDownload = useCallback(async () => {
    await onClick(format);
  }, [format, onClick]);

  const changeFormat = useCallback(
    (format: "csv" | "json") => {
      setFormat(format);
      setAnchorEl(null);
    },
    [setFormat, setAnchorEl],
  );

  return (
    <>
      <ButtonGroup disabled={disabled} size={"small"}>
        <Button
          sx={{ width: 120, borderRight: `1px solid ${colors.color_white._1}` }}
          variant="contained"
          color="primary"
          onClick={onDownload}
          children={label}
        />
        <Button
          sx={{ width: 80, borderLeft: `1px solid ${colors.color_white._1}` }}
          variant={"contained"}
          color={"primary"}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          children={`.${format}`}
        />
      </ButtonGroup>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        anchorPosition={{ top: 0, left: 10 }}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem sx={{ width: 80 }} onClick={() => changeFormat("csv")}>
          .csv
        </MenuItem>
        <MenuItem style={{ width: 80 }} onClick={() => changeFormat("json")}>
          .json
        </MenuItem>
      </Popover>
    </>
  );
};
