import { DataExporter } from "../../utils/DataExporter";

export const usersDataExporter = new DataExporter(
  {
    id: "_id",
    nickname: "nickname",
    phone: "phone",
    email: "email",
    city: (row) => row.address?.city,
    state: (row) => row.address?.state,
    createdAt: "createdAt",
    status: "status",
  },
  {
    id: "_id",
    nickname: "nickname",
    phone: "phone",
    email: "email",
    city: (row) => row.address?.city,
    state: (row) => row.address?.state,
    createdAt: "createdAt",
    status: "status",
  },
);
