export enum ApiAlertTypeEnum {
  Coyote = "coyote",
  Pets = "pets",
  Traffic = "traffic",
}

export enum ApiAlertTag {
  LostDog = "lost_dog",
  LostCat = "lost_cat",
  LostOtherPet = "lost_other_pet",
  FoundDog = "found_dog",
  FoundCat = "found_cat",
  FoundOtherPet = "found_other_pet",
}

export enum ApiContactTypeEnum {
  Email = "email",
  Phone = "phone",
  Other = "other",
}

export interface ApiGeopoint {
  latitude: number;
  latitudeDelta?: number;
  longitude: number;
  longitudeDelta?: number;
}

export type ApiEnumValues<E> = E extends { [key: string]: infer U } ? U : never;

export interface ApiContactType {
  type: ApiEnumValues<typeof ApiContactTypeEnum>;
  contact: string;
}

export type TranslatableType = Record<string, string>;

export interface ApiUser {
  _id: string;
  nickname: string;
  archivedAt?: Date | null;
  address: ApiAddressType;
}

export interface ApiAlertWithDependencies extends ApiAlert {
  authorId: ApiUser;
}

export interface ApiAddressType {
  geopoint?: ApiGeopoint;

  country?: string;

  state?: string;

  city?: string;

  otherDetails?: string;
}

export interface ApiAlert {
  _id?: string;
  type: ApiAlertTypeEnum;
  tag?: ApiAlertTag;
  city?: string;
  state?: string;
  snapshot: string;
  photo?: string | null;
  description?: string | null;
  contacts?: ApiContactType[] | null;
  geopoint: ApiGeopoint;
  geofenceIds?: string[];
  bookmarkedForIds?: string[] | null;
  pinnedForIds?: string[] | null;
  createdAt: Date;
  updatedAt?: Date | null;
  archivedAt?: Date | null;
  questionSentAt?: Date | null;
  resolved?: boolean | null;
  resolvedAt?: Date | null;
  deletionReason?: TranslatableType;
  authorId?: string | ApiUser | null;
  hiddenForIds?: string[] | null;
  reportsCount?: number;
}
