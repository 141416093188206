import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { TextareaAutosize } from "@mui/material";

import "./styles.scss";
import { Icon } from "../";
import colors from "../../styles/colors";

/**
 * @component TextArea
 * @example
 * <TextArea
 *  onChange={() => {}}
 *  value="Some text",
 *  textareaType="primary",
 *  rows={12}
 *  label="label"
 * />
 */
const TextArea = (props) => {
  return useMemo(() => {
    const renderClassName = () => {
      let str = "textarea-container";
      if (props.textareaType) {
        str += ` ${textareaConfigList[props.textareaType].basicClass}`;
      }
      return str;
    };
    return (
      <div className={renderClassName()} disabled={props.disabled}>
        {props.autosize ? (
          <TextareaAutosize
            value={props.value}
            aria-label="minimum height"
            onChange={(e) => props.onChange(e.target.value)}
            rowsmin={props.rowsmin}
            className="autosize"
            placeholder={props.placeholder}
            disabled={props.disabled}
            maxLength={props.maxLength}
            onBlur={(e) => props.onBlur(e.target.value)}
            style={{ resize: "none" }}
          />
        ) : (
          <textarea
            value={props.value}
            aria-label="minimum height"
            onChange={(e) => props.onChange(e.target.value)}
            rowsmin={1}
            placeholder={props.placeholder}
            rows={props.rows}
            disabled={props.disabled}
            maxLength={props.maxLength}
            onBlur={(e) => props.onBlur(e.target.value)}
          />
        )}
        {props.isValid && (
          <div className="is-valid">
            <Icon name="check" width={14} height={14} color={colors.color_green._0} />
          </div>
        )}
        {!props.isValid && props.focus && (
          <div className="is-danger">
            <Icon name="danger" width={20} height={20} color={colors.color_red._0} />
          </div>
        )}
        <label>{props.label}</label>
      </div>
    );
  }, [props]);
};

/**
 * Styles
 */

const textareaConfigList = {
  default: {
    basicClass: "default",
  },
  primary: {
    basicClass: "primary",
  },
  secondary: {
    basicClass: "secondary",
  },
  danger: {
    basicClass: "danger",
  },
};

/**
 * Properties
 */

TextArea.propTypes = {
  placeholder: PropTypes.string,
  textareaType: PropTypes.oneOf(["default", "primary", "secondary", "disabled", "danger"]),
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  autosize: PropTypes.bool,
  rowsmin: PropTypes.number,
  onBlur: PropTypes.func,
  isValid: PropTypes.bool,
};
TextArea.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  value: "",
  placeholder: " ",
  textareaType: "primary",
  rows: 6,
  disabled: false,
  label: null,
  maxLength: 3000,
  autosize: false,
  rowsmin: 1,
  isValid: false,
};

export default TextArea;
