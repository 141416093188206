import React from "react";
import PropTypes from "prop-types";
import { Switch } from "@mui/material";

import "./styles.scss";
import colors from "../../styles/colors";
import { styled } from "@mui/material";

const WZSwitchView = styled((props) => {
  return (
    <button className="toggle" disabled={props.disabled} onClick={props.onChange}>
      <Switch color="primary" classes={props.classes} checked={props.checked} disabled={props.disabled} />
      {props.label && <label>{props.label}</label>}
    </button>
  );
})({
  switchBase: {
    color: colors.color_white._0,
    "&$checked": {
      color: colors.color_black._0,
    },
    "&$checked + $track": {
      backgroundColor: colors.color_black._2,
    },
  },
  checked: {},
  track: {},
});

/**
 * @component Switch
 * @example
 * <Switch
 *  checked={false}
 *  onChange={() => {}}
 *  label="Car"
 *  disabled={true}
 * />
 */
const WZSwitch = (props) => {
  return (
    <WZSwitchView
      checked={props.checked}
      onChange={() => props.onChange(!props.checked)}
      disabled={props.disabled}
      label={props.label}
    />
  );
};

WZSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
WZSwitch.defaultProps = {
  checked: false,
  onChange: () => {},
  disabled: false,
};

export default WZSwitch;
