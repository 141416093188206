import React, { useCallback, useEffect, useState } from "react";
import StatisticsService from "../../services/statistics";

import DashboardView from "./dashboard-view";
import { dashboardDataExporter } from "./dashboardDataExporter";
import { Toast } from "../../ui-kit";

const Dashboard = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [data, setData] = useState([]);
  const [skeletVisible, setSkeletVisible] = useState(true);

  const getStatisticsInformation = useCallback(async ({ startDate, endDate, states, cities }) => {
    const baseFilters = { startDate, endDate, states, cities };

    try {
      const responses = await Promise.all([
        StatisticsService.gettingStatisticsInformation({ ...baseFilters, collections: "users" }),
        StatisticsService.gettingStatisticsInformation({ ...baseFilters, collections: "alerts", type: "coyote" }),
        StatisticsService.gettingStatisticsInformation({
          ...baseFilters,
          collections: "alerts",
          type: "pets",
          tags: ["lost_dog", "lost_cat", "lost_other_pet"],
        }),
        StatisticsService.gettingStatisticsInformation({
          ...baseFilters,
          collections: "alerts",
          type: "pets",
          tags: ["found_cat", "found_dog", "found_other_pet"],
        }),
        StatisticsService.gettingStatisticsInformation({ ...baseFilters, collections: "alertReports" }),
      ]);

      setData([
        ["numberOfUsers", responses[0].users],
        ["numberOfAlertsCoyote", responses[1].alerts],
        ["numberOfAlertsLostPets", responses[2].alerts],
        ["numberOfAlertsFoundPets", responses[3].alerts],
        ["numberOfReports", responses[4].alertReports],
      ]);
    } catch {
      Toast.error("Failed getting dashboard data");
    } finally {
      setSkeletVisible(false);
    }
  }, []);

  useEffect(() => {
    getStatisticsInformation({ startDate, endDate, states, cities });
  }, [getStatisticsInformation, startDate, endDate, cities, states]);

  const onDownload = useCallback(
    (format) => {
      dashboardDataExporter.export(data, "dashboard", format);
    },
    [data],
  );

  return (
    <DashboardView
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      setStates={setStates}
      setCities={setCities}
      data={data}
      skeletVisible={skeletVisible}
      onDownload={onDownload}
    />
  );
};

export default Dashboard;
