const colors = {
  color_white: {
    _0: "#fff",
    _1: "#f7f9fc",
    _2: "#eeeeee",
    _3: "rgba(238, 238, 238, 0.9)",
  },
  color_gray: {
    _0: "#f6f6f6",
    _1: "#e0e0e0",
    _2: "#f2f2f2b9",
    _3: "#d5d5d5",
    _4: "#9e9e9e",
    _5: "#aaaaaa",
  },
  color_black: {
    _0: "#2c2c2c",
    _1: "rgba(0, 0, 0, 0.87)",
    _2: "rgba(0, 0, 0, 0.23)",
    _3: "rgba(0, 0, 0, 0.12)",
    _4: "rgba(0, 0, 0, 0.04)",
    _5: "#232323",
    _6: "#292929",
  },
  color_red: {
    _0: "#ee0045",
    _1: "rgba(244, 67, 54, 0.6)",
    _2: "rgba(244, 67, 54, 0.2)",
    _3: "rgba(244, 67, 54, 0.1)",
  },
  color_yellow: {
    _0: "#ffd000",
    _1: "rgba(255, 208, 0, 0.3)",
    _2: "rgba(255, 208, 0, 0.2)",
  },
  color_green: {
    _0: "#4caf50",
    _1: "rgba(76, 175, 80, 0.2)",
    _2: "rgba(76, 175, 80, 0.1)",
  },
  color_primary: {
    _0: "#376fd0",
    _1: "#264d91",
    _2: "rgba(55, 111, 208, 0.5)",
    _3: "rgba(55, 111, 208, 0.04)",
  },
  color_secondary: {
    _0: "#4782da",
    _1: "#315b98",
    _2: "rgba(71, 130, 218, 0.5)",
    _3: "rgba(71, 130, 218, 0.04)",
  },
  color_basic: {
    _0: "#212d3e",
    _1: "#233044",
    _2: "#c5d5f1",
  },

  color_text: {
    primary: "#110c1a",
    error: "#ee0045",
    secondary: "#c8c6cc",
    white: "#ffffff",
  },
};

export default colors;
