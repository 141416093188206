import React, { useState } from "react";
import PropTypes from "prop-types";
import { TimePicker as MuiTimePicker } from "@mui/x-date-pickers";
import "./styles.scss";

/**
 * @component TimePicker
 * @example
 * <TimePicker />
 */
const TimePicker = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = (date) => {
    props.onChange(date);
  };

  return (
    <MuiTimePicker
      ampm={false}
      value={props.value}
      onChange={handleDateChange}
      open={isOpen}
      disabled={props.disabled}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      orientation="portrait"
    />
  );
};

/**
 * Properties
 */

TimePicker.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  inputType: PropTypes.oneOf(["default", "primary", "secondary", "disabled"]),
  inputValue: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
};
TimePicker.defaultProps = {
  inputValue: "",
  value: null,
  onChange: () => {},
  disabled: false,
  label: "",
  size: "medium",
  inputType: "default",
};

export default TimePicker;
