import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";

import "./styles.scss";
import colors from "../../styles/colors";
import Icon from "../icons";

const CustomDropzone = (props) => {
  const handleDelete = useCallback(
    (path) => {
      props.onChange(null);
    },
    [props],
  );
  const { getRootProps, getInputProps } = useDropzone({
    accept: props.accept,
    multiple: props.multiple,
    maxFiles: props.maxFiles,
    onDrop: (acceptedFiles) => {
      props.onChange(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        }),
      );
    },
  });

  useEffect(
    () => () => {
      URL.revokeObjectURL(props.files?.preview);
    },
    [props.files],
  );
  return (
    <>
      <div
        className={`cust-uploading-files ${props.dropzoneType} ${props.className}`}
        style={props.files === null ? {} : { backgroundColor: "white" }}
      >
        {props.files === null ? (
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <div className="container-description">
              <div className="span-color-gray">
                <Icon name="plus" width={16} height={16} color="black" />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="buttons-container" onClick={() => handleDelete(props.files.path)}>
              {!props.isDelete && !props.files.other ? (
                <Icon className="button delete" name="cross" width={14} height={14} color={"black"} />
              ) : null}
            </div>
            <div className="file-information-container">
              <div className="file-container">
                <div className="image-container">
                  <div
                    style={
                      props.files.type === "image/jpeg" ||
                      props.files.type === "image/png" ||
                      props.files.type === "image/svg+xml"
                        ? {
                            backgroundImage: `url(${props.files?.preview || props.files})`,
                          }
                        : {}
                    }
                    className="image"
                  >
                    {props.files.type === "audio/mpeg" ? (
                      <Icon name={"mp3"} width={48} height={48} color={colors.color_gray._2} />
                    ) : props.files.type === "video/mp4" ? (
                      <Icon name={"mp4"} width={48} height={48} color={colors.color_gray._2} />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

/**
 * Properties
 */

CustomDropzone.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  files: PropTypes.object,
  accept: PropTypes.object,
  disabled: PropTypes.bool,
  skelet: PropTypes.bool,
};
CustomDropzone.defaultProps = {
  className: "",
  title: null,
  multiple: true,
  maxFiles: 3,
  onChange: () => {},
  files: {},
  accept: { "image/*": [] },
  disabled: false,
  skelet: false,
};

export default CustomDropzone;
