import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import ROUTES from "./routes";
import LocalizationActions from "./store/localization/actions";
import { useComponentWillMount } from "./utils/hooks";
import LocalizationThunks from "./store/localization/thunks";

const App = () => {
  const dispatch = useDispatch();

  useComponentWillMount(() => {
    dispatch(LocalizationActions.initial());
  });

  useEffect(() => {
    dispatch(LocalizationThunks.getLanguages());
  }, [dispatch]);

  return <RouterProvider router={createBrowserRouter(ROUTES)} />;
};

export default App;
