import STRINGS from "./localized-strings";

class Localization {
  /**
   * Keys for AsyncStorage and others
   */
  static #LOCALIZATION_KEYS = {
    CURRENT_LANGUAGE: "@localization/current_language",
  };

  /**
   * Available languages in the application
   */
  static LANGUAGES = {
    en: "en",
  };

  /**
   * Get current language code from async storage
   * @returns {String}
   */
  static getLanguageFromAsyncStorage() {
    try {
      return window.localStorage.getItem(this.#LOCALIZATION_KEYS.CURRENT_LANGUAGE) || null;
    } catch {
      return null;
    }
  }

  /**
   * Set language code to async storage
   * @param {String} languageCode - code of the language
   */
  static setLanguageToLocalStorage(languageCode) {
    try {
      window.localStorage.setItem(this.#LOCALIZATION_KEYS.CURRENT_LANGUAGE, languageCode);
    } catch (error) {
      //
    }
  }

  /**
   * Clear current language code in async storage
   * @returns {Promise<void>}
   */
  static clearLanguageInLocalStorage() {
    try {
      window.localStorage.removeItem(this.#LOCALIZATION_KEYS.CURRENT_LANGUAGE);
    } catch (error) {
      //
    }
  }

  /**
   * Change language if it exist
   * @param {String} languageCode - code of the language
   */
  static setLanguage(languageCode) {
    if (this.LANGUAGES[languageCode]) {
      try {
        this.setLanguageToLocalStorage(languageCode);
        STRINGS.setLanguage(languageCode);
        return true;
      } catch (error) {
        return false;
      }
    }
    return false;
  }
}

export default Localization;
