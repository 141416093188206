import { Button, Confirm, Icon } from "../../ui-kit";

import STRINGS from "../../localization";
import colors from "../../styles/colors";
import QuestionModal from "../../components/modals/questions-modal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SkeletManagers from "./skelet";
import "./styles.scss";
import { SectionHeader } from "../../components/section-header/SectionHeader";
import { Typography } from "@mui/material";
import React from "react";

const FaqView = (props) => {
  return (
    <>
      <QuestionModal
        onClose={() => {
          props.setShowModal("");
          props.setQuestionForEdit("");
        }}
        open={props.showModal === "add"}
        title={STRINGS.screens.faq.addQuestion}
        saveChanges={props.addQuestion}
      />
      <QuestionModal
        open={props.showModal === "edit"}
        onClose={() => {
          props.setShowModal("");
          props.setQuestionForEdit("");
        }}
        title={STRINGS.screens.faq.editQuestion}
        selectedQuestion={props.questionForEdit}
        saveChanges={props.editQuestion}
      />
      <SectionHeader>
        <Typography variant={"h1"}>{STRINGS.screens.faq.faq}</Typography>
        <Button
          icon={{ name: "plus", side: "left" }}
          label={STRINGS.screens.faq.addQuestion}
          onClick={() => props.setShowModal("add")}
          type="primary"
          size="small"
          disabled={props.loading}
        />
      </SectionHeader>
      {props.loading ? (
        <SkeletManagers />
      ) : (
        <DragDropContext onDragEnd={props.onDragEnd}>
          <Droppable droppableId="general">
            {(provided1) => (
              <div id="faq-container" ref={provided1.innerRef}>
                {props.questions.map((question, index) => (
                  <Draggable key={`question${index}`} draggableId={`question${index}`} index={index}>
                    {(provided2, snapshot2) => (
                      <>
                        <aside
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                          className={`paper ${snapshot2.isDragging ? "isDragging" : ""}`}
                          data-loading={props.loading}
                          style={props.getItemStyle(provided2.draggableProps.style)}
                        >
                          <button className="drag" {...provided2.dragHandleProps}>
                            <Icon name="drag-handle" width={28} height={28} color={colors.color_basic._0} />
                          </button>
                          <button
                            className="edit"
                            onClick={() => {
                              props.setQuestionForEdit({ ...question, index });
                              props.setShowModal("edit");
                            }}
                          >
                            <Icon
                              name="pen"
                              width={24}
                              height={24}
                              color={colors.color_basic._0}
                              className={{
                                display: "block",
                                "margin-left": "auto",
                                "margin-right": "auto",
                              }}
                            />
                          </button>
                          <button
                            className="delete"
                            onClick={() => {
                              props.setQuestionForEdit(index);
                              props.setShowModal("delete");
                            }}
                          >
                            <Icon
                              name="cross"
                              width={24}
                              height={24}
                              color={colors.color_basic._0}
                              className={{
                                display: "block",
                                "margin-left": "auto",
                                "margin-right": "auto",
                              }}
                            />
                          </button>
                          <div className="content-all">
                            <h4 className="title">{STRINGS.screens.faq.question}</h4>
                            <p className="text">{question.question?.en}</p>
                            <h4 className="title">{STRINGS.screens.faq.answer}</h4>
                            <p className="text">{question.answer?.en}</p>
                          </div>
                        </aside>
                      </>
                    )}
                  </Draggable>
                ))}
                <Confirm
                  onClose={() => props.setShowModal("")}
                  title={STRINGS.screens.faq.deleteTitle}
                  visible={props.showModal === "delete"}
                  subtitle={STRINGS.screens.faq.deleteSubTitle}
                  buttons={[
                    {
                      label: "Yes, I am sure",
                      onClick: () => {
                        props.deleteQuestion();
                      },
                    },
                    {
                      label: "No, cancel",
                      outline: true,
                      onClick: () => {
                        props.setShowModal("");
                        props.setQuestionForEdit("");
                      },
                    },
                  ]}
                />
                {provided1.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

export default FaqView;
