import { api, debug } from "../index";
import { HTTP_STATUS, URL } from "../config";

export default class GeofenceAdminConfigService {
  static #API_ENDPOINTS = {
    geofenceAdminConfig: "geofenceAdminConfig",
    list: "geofenceAdminConfig/list",
  };

  static async getGeofenceAdminConfig() {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.list}`, {
        headers: {
          "content-type": "application/json",
        },
      });

      debug.success("Authentication request result", request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      const requestData = await request.json();

      if (requestData) {
        return requestData;
      }

      return Promise.reject("No Data");
    } catch (globalError) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.list}`, globalError);
      throw await globalError.response?.json();
    }
  }

  static async updateGeofenceAdminConfig(id, data) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.geofenceAdminConfig}?_id=${id}`, {
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      });

      debug.success("Authentication request result", request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      const requestData = await request.json();

      if (requestData) {
        return requestData;
      }

      return Promise.reject("No Data");
    } catch (globalError) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.geofenceAdminConfig}?uid=${id}`,
        globalError,
      );
      throw await globalError.response?.json();
    }
  }
}
