import React from "react";

import ToastsView from "./toasts-view";

import { Toast } from "../../../ui-kit";

const ToastsContainer = () => {
  const callInfoToast = () => {
    Toast.info("Вы вызвали информационное уведомление!");
  };

  const callSuccessToast = () => {
    Toast.success("Вы вызвали уведомление с успешным статусом!");
  };

  const callErrorToast = () => {
    Toast.error("Вы вызвали уведомление сигнализирующее ошибку!");
  };

  return (
    <ToastsView callInfoToast={callInfoToast} callSuccessToast={callSuccessToast} callErrorToast={callErrorToast} />
  );
};

export default ToastsContainer;
