import STRINGS from "../localization";

export const translateStatus: (status: string) => { status: string; button: "secondary" | "warning" } | undefined = (
  status,
) => {
  switch (status) {
    case "active":
      return { status: STRINGS.components.statuses.active, button: "secondary" };
    case "disabled":
      return { status: STRINGS.components.statuses.disabled, button: "warning" };
    case "suspended":
      return { status: STRINGS.components.statuses.suspended, button: "warning" };
    default:
      return;
  }
};
