import React from "react";

import { Paper, Select } from "../../../ui-kit";
import "./styles.scss";

const MANAGERS = [
  { label: "Pavel Skrypko", key: 2 },
  { label: "Aleksej Glazunov", key: 1 },
  { label: "Roman Ravkov", key: 3 },
  { label: "Maksim Attsetski", key: 3 },
];

const USERS = [
  { label: "Jhon Clark", key: 2 },
  { label: "Maria Sidrova", key: 1 },
];

const BASKET = [];

const DropdownView = (props) => {
  return (
    <div id="--dropdowns">
      <Paper className="dropdown-types">
        <h4>Компонент dropdown</h4>
        <article>
          <span>
            Компонент<code>{"<Select />"}</code>
            предназначен для выбора даты.
          </span>
          <br />
          <code>{'<Select inputType="default|primary|secondary" />'}</code>
        </article>
        <aside>
          <Select
            selectType="default"
            label="Default"
            options={USERS}
            value={props.selectValue.value_0}
            onChange={(item) => props.setSelectValue({ ...props.selectValue, value_0: item })}
          />
          <Select
            selectType="primary"
            label="Primary"
            options={MANAGERS}
            value={props.selectValue.value_1}
            onChange={(item) => props.setSelectValue({ ...props.selectValue, value_1: item })}
          />
          <Select
            selectType="secondary"
            label="Secondary"
            options={BASKET}
            value={props.selectValue.value_2}
            onChange={(item) => props.setSelectValue({ ...props.selectValue, value_2: item })}
          />
        </aside>
      </Paper>
      <Paper className="dropdown-sizes">
        <h4>Размеры компонента dropdown</h4>
        <p className="sb-date-description">
          Компонент <code>{"<Select />"}</code>может иметь различные размеры. Для изменения размеров требуется передать
          свойство size.
          <br />
          <code>{'<Select size="small|medium|large" />'}</code>
        </p>
        <aside>
          <Select
            selectType="default"
            label="Small"
            size="small"
            options={BASKET}
            value={props.selectValue.value_4}
            onChange={(item) => props.setSelectValue({ ...props.selectValue, value_4: item })}
          />
          <Select
            selectType="default"
            label="Medium"
            options={USERS}
            value={props.selectValue.value_5}
            onChange={(item) => props.setSelectValue({ ...props.selectValue, value_5: item })}
          />
          <Select
            selectType="default"
            label="Large"
            size="large"
            options={MANAGERS}
            value={props.selectValue.value_6}
            onChange={(item) => props.setSelectValue({ ...props.selectValue, value_6: item })}
          />
        </aside>
      </Paper>
    </div>
  );
};

export default DropdownView;
