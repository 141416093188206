import { api, debug } from "../index";
import { URL } from "../config";

export default class StatisticsService {
  static #API_ENDPOINTS = {
    statistics: "statistics/entitiesAmount",
  };

  static async gettingStatisticsInformation({ collections, startDate, endDate, type, states, cities, tags }) {
    const addressKey = collections === "users" ? "address" : "authorId.address";

    const filters = [];
    if (startDate) {
      filters.push(["createdAt", ">=", startDate.toISOString()]);
    }
    if (endDate) {
      filters.push(["createdAt", "<=", endDate.toISOString()]);
    }

    if (type) {
      filters.push(["type", "==", type]);
    }

    if (tags) {
      filters.push(["tag", "_in_", tags]);
    }

    if (states.length) {
      filters.push([`${addressKey}.state`, "_contains_any_", states]);
    }

    if (cities.length) {
      filters.push([`${addressKey}.city`, "_contains_any_", cities]);
    }

    const search = new URLSearchParams({
      collections,
      filter: filters.map((f) => f.join("")).join(";"),
    });

    const url = `${URL}${this.#API_ENDPOINTS.statistics}?${search.toString()}`;

    try {
      const response = await api.get(url, { headers: { "content-type": "application/json" } });

      debug.success("Servey getting statistics information", response);

      return response.json();
    } catch (e) {
      debug.error(`Failed with GET statistics information by path: ${url}`, e);
      throw e;
    }
  }
}
