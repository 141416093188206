import React from "react";
import PropTypes from "prop-types";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles.scss";

/**
 * @component EditableTextArea
 */
const EditableTextArea = (props) => {
  return (
    <div className="editable-textarea-container" title={props.title} disabled={props.readOnly}>
      <ReactQuill
        style={{ svg: "30px" }}
        value={props.value}
        onChange={props.onChange}
        modules={props.modules}
        readOnly={props.readOnly}
      />
    </div>
  );
};

/**
 * Properties
 */

EditableTextArea.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  style: PropTypes.object,
  modules: PropTypes.object,
  readOnly: PropTypes.bool,
};
EditableTextArea.defaultProps = {
  onChange: () => {},
  value: "",
  style: {},
  modules: {},
  readOnly: false,
};

export default EditableTextArea;
