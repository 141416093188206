import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "@mui/material";

import "./styles.scss";

import { Loader, Icon } from "../";

import Skeleton from "../skeleton";

/**
 * @component Modal
 * @example
 * <Modal
 *  onClose={() => {}}
 *  open={false}
 *  title="title"
 *  loading={false}
 * />
 */
const Modal = (props) => {
  return (
    <Dialog fullWidth maxWidth={props.maxWidth} onClose={!props.loading && props.onClose} open={props.open}>
      <div className="modal-root">
        {props.title && (
          <div className="header">
            {props.skelet ? (
              <Skeleton width="fit-content">
                <h2>{props.title || "*********************"}</h2>
              </Skeleton>
            ) : (
              <h2>{props.title}</h2>
            )}
            <button onClick={props.onClose}>
              <Icon width={24} height={24} name="cross" />
            </button>
          </div>
        )}
        <>{props.children}</>
        {props.loading && (
          <div className="loading">
            <Loader color="light" />
          </div>
        )}
      </div>
    </Dialog>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  loading: PropTypes.bool,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired,
  skelet: PropTypes.bool,
};

Modal.defaultProps = {
  onClose: () => {},
  open: false,
  title: null,
  loading: false,
  skelet: false,
};

export default Modal;
