import { useEffect, useState } from "react";
import { Toast } from "../../ui-kit";
import { validateObject } from "../../utils/input-functions";
import ContactView from "./contact-view";
import isEmail from "validator/es/lib/isEmail";
import AdminSettingsService from "../../services/adminSettings";

const SCHEME = {
  email1: (value) => value.length > 0 && isEmail(value),
  email2: (value) => value.length > 0 && isEmail(value),
};

const initialState = {
  value: "",
  focus: false,
};
const Contact = () => {
  const [email1, setEmail1] = useState(initialState);
  const [email2, setEmail2] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [uid, setUid] = useState();

  const [validation, setValidation] = useState({ isValid: false, data: {} });

  const saveChanges = async () => {
    setLoading(true);
    try {
      if (uid) {
        await AdminSettingsService.editAdminSetting(uid, {
          contacts: [
            { type: "email", contact: email1.value },
            { type: "email", contact: email2.value },
          ],
        });
      } else {
        const result = await AdminSettingsService.createSetting({
          contacts: [
            { type: "email1", contact: email1.value },
            { type: "email2", contact: email2.value },
          ],
        });
        if (result?._id) {
          setUid(result?._id);
        }
      }
      Toast.success("Success");
    } catch (error) {
      Toast.error("Fail");
    }
    setLoading(false);
  };

  const getContacts = async () => {
    setLoading(true);
    try {
      const result = await AdminSettingsService.getSettings({ page: 1, size: 10 });
      const data = result.data[0];
      if (data?._id) {
        setEmail1((prev) => ({ ...prev, value: data?.contacts ? data?.contacts[0]?.contact : "" }));
        setEmail2((prev) => ({ ...prev, value: data?.contacts ? data?.contacts[1]?.contact : "" }));
        setUid(data?._id);
      }
    } catch (error) {
      Toast.error("Fail");
    }
    setLoading(false);
  };

  useEffect(() => {
    setValidation(
      validateObject(
        {
          email1: email1.value,
          email2: email2.value,
        },
        SCHEME,
      ),
    );
  }, [email1, email2]);

  useEffect(() => {
    getContacts();
  }, []);

  return (
    <ContactView
      email1={email1}
      email2={email2}
      setEmail1={setEmail1}
      setEmail2={setEmail2}
      validation={validation}
      saveChanges={saveChanges}
      loading={loading}
    />
  );
};

export default Contact;
