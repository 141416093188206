import React from "react";
import "./styles.scss";
import { Skeleton, Paper } from "../../ui-kit";

const Skelet = () => (
  <div className="container-for-survey">
    {new Array(10).fill(null).map((_, i) => (
      <Skeleton key={i} variant="rect">
        <Paper className="paper-skeleton-for-survey"></Paper>
      </Skeleton>
    ))}
  </div>
);
export default Skelet;
