import React from "react";

import "./styles.scss";

import { Paper } from "../../ui-kit";

import STRINGS from "../../localization";
import Skelet from "./skelet";
import { DownloadButton } from "../../components/download/DownloadButton";
import { Box, Typography } from "@mui/material";
import { DateRangeFilter } from "../../components/date-range-filter/DateRangeFilter";
import { SectionHeader } from "../../components/section-header/SectionHeader";

const SurveyResultsView = (props) => {
  return (
    <>
      <SectionHeader>
        <Typography variant="h1">{STRINGS.components.sidebar.surveyResults}</Typography>
        <DownloadButton
          type={"primary"}
          label={STRINGS.components.buttons.download}
          onClick={props.onDownload}
          disabled={!props.surveyData?.length}
        />
      </SectionHeader>
      <Paper>
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={4}
          alignItems={"flex-end"}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
        >
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Typography variant="body2" color="textSecondary">
              Date
            </Typography>
            <Box display={"flex"} flexDirection={"row"} gap={1}>
              <DateRangeFilter
                size={"small"}
                initialEndDate={props.endDate}
                initialStartDate={props.startDate}
                onChangeStartDate={props.setStartDate}
                onChangeEndDate={props.setEndDate}
              />
            </Box>
          </Box>
        </Box>
      </Paper>
      {props.skeletVisible ? (
        <Skelet />
      ) : (
        <div className="container-for-survey">
          {!props.surveyData || props.surveyData.length === 0 ? (
            <span>{STRINGS.screens.surveyResults.noPolls}</span>
          ) : (
            props.surveyData.map((survey) => (
              <div key={survey._id}>
                <Paper className="paper">
                  <div className={"content-all info"}>
                    <h4 className="text">{STRINGS.screens.surveyResults.date}</h4>
                    <span className="style-for-span">{new Date(survey.createdAt).toLocaleDateString()}</span>
                    <h4 className="text">{STRINGS.screens.surveyResults.coordinates}</h4>
                    <a
                      target="blank"
                      href={`https://www.google.com/maps/search/?api=1&query=${survey.alertId?.geopoint.latitude}%2C${survey.alertId?.geopoint.longitude}`}
                      className="link"
                    >
                      {survey.alertId?.geopoint.latitude}, {survey.alertId?.geopoint.longitude}
                    </a>
                    {STRINGS.screens.surveyResults.surveyQuestions.map((question, inx) => (
                      <React.Fragment key={inx}>
                        <h3 className="text-question">{question}</h3>
                        <span className="style-for-span">
                          {survey?.obj?.[question.replaceAll(" ", "")]
                            ? survey?.obj?.[question.replaceAll(" ", "")]
                            : "-"}
                        </span>
                      </React.Fragment>
                    ))}
                  </div>
                </Paper>
              </div>
            ))
          )}
        </div>
      )}
    </>
  );
};

export default SurveyResultsView;
