import { api, debug } from "../index";
import { URL } from "../config";

export default class SurveyService {
  static #API_ENDPOINTS = {
    research: "scientificResearchResult/list",
  };

  static async gettingSurveyInformation({ page = 1, size = 10, start, end }) {
    const filters = [];
    if (start) {
      filters.push(`createdAt>=${start}`);
    }
    if (end) {
      filters.push(`createdAt<=${end}`);
    }
    const filter = filters.length ? `&filter=${filters.join(";")}` : "";

    const url = `${URL}${this.#API_ENDPOINTS.research}?pagination=page:${page};size:${size}&dependencies=true${filter}`;

    try {
      const request = await api.get(url, { headers: { "content-type": "application/json" } });

      debug.success("Survey getting result", request);

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET survey information by path: ${url}`, error);
    }
  }
}
