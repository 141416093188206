import React from "react";
import "./styles.scss";
import { Skeleton, Paper } from "../../ui-kit";

const Skelet = () => (
  <div className="container">
    {new Array(10).fill(null).map((_, i) => (
      <Skeleton variant="rect" key={i}>
        <Paper className="paper-skeleton-for-post"></Paper>
      </Skeleton>
    ))}
  </div>
);
export default Skelet;
