const initialState = {
  currentLanguage: "",
  languages: [],
};

const LOCALIZATION_SET_CURRENT_LANGUAGE = "LOCALIZATION_SET_CURRENT_LANGUAGE";
const LOCALIZATION_SET_LANGUAGES = "LOCALIZATION_SET_LANGUAGES";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCALIZATION_SET_CURRENT_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.payload,
      };
    case LOCALIZATION_SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

export const localizationSetCurrentLanguage = (payload) => ({
  type: LOCALIZATION_SET_CURRENT_LANGUAGE,
  payload,
});
export const localizationSetLanguages = (payload) => ({
  type: LOCALIZATION_SET_LANGUAGES,
  payload,
});
