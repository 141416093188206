import { TextField } from "@mui/material";
import colors from "../../styles/colors";
import { styled } from "@mui/system";

export const InputTextField = styled(TextField)({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: `0.75rem`,
      color: colors.color_black._1,
      backgroundColor: colors.color_white._0,
      fontWeight: "thin",
      overflow: "visible",
    },
  },
});
