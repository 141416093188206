import React, { useState } from "react";

import {
  Buttons,
  SelectionControls,
  TextField,
  DatePicker,
  Dropdown,
  Skelet,
  Toasts,
  Drawer,
  Icons,
  Modals,
  Dropzone,
  Radius,
  Alerts,
  Notifications,
  Promotions,
  Dashboard,
  WeRememberYou,
  ChangePassword,
  OurStory,
  PrivacyPolicy,
  CoyoteResources,
  Notice,
  CopyrightPolicy,
  CookiePolicy,
  TermsOfService,
  Users,
  User,
  Contact,
  Faq,
  SurveyResults,
  Authorization,
} from "./pages";
import { Navigate, Outlet, RouteObject } from "react-router-dom";
import { Header, Sidebar } from "./components";
import { useSelector } from "react-redux";

export const Root = () => {
  const [sidebarVisible, setSidebarVisible] = useState();
  const user = useSelector((store: any) => store.currentUser);

  if (!user.authorized) {
    return <Navigate to={"/login"} />;
  }

  return (
    <div id="main">
      <Sidebar onClick={setSidebarVisible} sidebarVisible={sidebarVisible} />
      <div className="root-container">
        <Header onClick={setSidebarVisible} burgerState={sidebarVisible} />
        <div className="root-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

const ROUTES: RouteObject[] = [
  {
    path: "login",
    element: <Authorization />,
  },
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Navigate to={"/dashboard"} />,
      },
      {
        index: true,
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/we-remember-you",
        element: <WeRememberYou />,
      },
      {
        path: "/notifications",
        element: <Notifications />,
      },
      {
        path: "/radius",
        element: <Radius />,
      },
      {
        path: "/alerts",
        element: <Navigate to={"/alerts/coyote"} />,
      },
      {
        path: "/alerts/:type",
        element: <Alerts />,
      },
      {
        path: "/surveyResults",
        element: <SurveyResults />,
      },
      {
        path: "/promotions",
        element: <Promotions />,
      },
      {
        path: `/users/user`,
        element: <User />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/settings/changepassword",
        element: <ChangePassword />,
      },
      {
        path: "/settings/ourstory",
        element: <OurStory />,
      },
      {
        path: "/settings/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/settings/coyote-resources",
        element: <CoyoteResources />,
      },
      {
        path: "/settings/terms-of-service",
        element: <TermsOfService />,
      },
      {
        path: "/settings/copyright-policy",
        element: <CopyrightPolicy />,
      },
      {
        path: "/settings/cookie-policy",
        element: <CookiePolicy />,
      },
      {
        path: "/settings/notice",
        element: <Notice />,
      },
      {
        path: "/settings/contact",
        element: <Contact />,
      },
      {
        path: "/settings/faq",
        element: <Faq />,
      },
      {
        path: "/storybook/buttons",
        element: <Buttons />,
      },
      {
        path: "/storybook/selection-controls",
        element: <SelectionControls />,
      },
      {
        path: "/storybook/text-fields",
        element: <TextField />,
      },
      {
        path: "/storybook/date-picker",
        element: <DatePicker />,
      },
      {
        path: "/storybook/dropdown",
        element: <Dropdown />,
      },
      {
        path: "/storybook/skelet",
        element: <Skelet />,
      },
      {
        path: "/storybook/toasts",
        element: <Toasts />,
      },
      {
        path: "/storybook/drawer",
        element: <Drawer />,
      },
      {
        path: "/storybook/icons",
        element: <Icons />,
      },
      {
        path: "/storybook/modals",
        element: <Modals />,
      },
      {
        path: "/storybook/dropzone",
        element: <Dropzone />,
      },
    ],
  },
];

export default ROUTES;
