import STRINGS from "../../localization";
import { Button, ConfirmWithInput, Drawer, Icon, Loader, Paper, Table, Tooltip } from "../../ui-kit";
import { getBeautyEngDateString } from "../../utils/datetime";
import SkeletManagers from "./skelet";
import "./styles.scss";
import { translateStatus } from "../../utils/translate-status";
import { SectionHeader } from "../../components/section-header/SectionHeader";
import { Breadcrumbs, Typography } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import { Link } from "react-router-dom";

const UserView = (props) => {
  const onCloseDeleteModal = () => {
    props.setShowModal("");
    props.setSelectedAlert("");
  };

  const renderContent = () => {
    switch (props.page) {
      case "userData":
        return props.loading ? (
          <SkeletManagers />
        ) : (
          <Paper>
            <>
              <div className="row">
                <span>{STRINGS.screens.user.username}:</span>
                <span>{props.user?.nickname}</span>
              </div>
              <div className="row">
                <span>{STRINGS.screens.user.phone}:</span>
                <span>{props.user?.phone ? props.user?.phone : "-"}</span>
              </div>
              <div className="row">
                <span>{STRINGS.screens.user.email}:</span>
                <span>{props.user?.email ? props.user?.email : "-"}</span>
              </div>

              <div className="row">
                <span>{STRINGS.screens.user.country}:</span>
                <span>{props.user?.address?.country}</span>
              </div>
              <div className="row">
                <span>{STRINGS.screens.user.state}:</span>
                <span>{props.user?.address?.state ? props.user?.address?.state : "-"}</span>
              </div>
              <div className="row">
                <span>{STRINGS.screens.user.city}:</span>
                <span>{props.user?.address?.city}</span>
              </div>
              <div className="row">
                <span>{STRINGS.screens.user.reports}:</span>
                <span>
                  {props.userStats?.reports > 0 &&
                    `${props.userStats?.reports} report(s) received from ${props.userStats?.uniqueReporters} user(s) for ${props.userStats?.uniqueAlerts} alert(s)`}
                  {!props.userStats?.reports && `No reports received`}
                </span>
              </div>
              <Button
                onClick={() => {
                  props.changeUserStatus(props.user?._id, props.user.status === "active" ? "suspended" : "active");
                }}
                loading={props.buttonLoading}
                outline
                label={translateStatus(props.user?.status).status}
                type={translateStatus(props.user?.status).button}
              />
            </>
          </Paper>
        );
      case "alerts":
        return (
          <>
            <div className="user_table">
              <Table
                className="user_page_paper"
                head={() => (
                  <tr className="user_table_heading">
                    <th>{STRINGS.screens.users.name}</th>
                    <th>{STRINGS.screens.users.creationDate}</th>
                    <th>{STRINGS.screens.users.type}</th>
                    <th>{STRINGS.screens.users.coordinates}</th>
                    <th />
                  </tr>
                )}
                disabledShadow
              >
                {props.alerts?.map((item) => (
                  <tr key={item._id} className="user_table_body">
                    <td>{item.authorId?.nickname}</td>
                    <td>{getBeautyEngDateString(new Date(item.createdAt))}</td>
                    <td>{item.type}</td>
                    <td>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://www.google.com/maps/@${item?.geopoint?.latitude},${item?.geopoint?.longitude},16z?hl=ru`}
                        className="link"
                      >
                        <div>{`latitude: "${item?.geopoint?.latitude}",`}</div>
                        <div>{`longitude: "${item?.geopoint?.longitude}"`}</div>
                      </a>
                    </td>
                    <td className="user_table_edit">
                      <div className="user_table_edit_wrapper">
                        {item.reportsCount > 0 && (
                          <Button
                            type="primary"
                            label="See reports"
                            size="small"
                            onClick={async () => {
                              await props.setSelectedAlert(item._id);
                              await props.setShowModal("reports");
                            }}
                            className="openReports"
                          />
                        )}
                        <Tooltip title={STRINGS.screens.promotions.delete}>
                          <div
                            className="user_table_edit_iconWrapper"
                            onClick={(event) => {
                              event.stopPropagation();
                              props.setSelectedAlert(item._id);
                              props.setShowModal("delete");
                            }}
                          >
                            <Icon name="trash-outline" width={24} height={24} />
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
                {props.alerts?.length === 0 && (
                  <tr className="empty">
                    <td colSpan={5}>No alerts created</td>
                  </tr>
                )}
              </Table>
            </div>
            <Drawer anchor="right" visible={props.showModal === "reports"} onClose={onCloseDeleteModal}>
              {props.reportsLoading ? (
                <Loader color="dark" width={50} height={50} class="center" />
              ) : (
                <div className="drawer-user">
                  <h1 className="title-for-drawer">{STRINGS.screens.alertScreen.report}</h1>
                  {props.reports.map((report) => {
                    const typeOfReport = {
                      spam: STRINGS.screens.alertScreen.reportTypes.spam,
                      "inappropriate-photo": STRINGS.screens.alertScreen.reportTypes.badPhoto,
                      other: STRINGS.screens.alertScreen.reportTypes.other,
                    };
                    return (
                      <div className="container-for-reports text" key={report._id}>
                        <h3>{STRINGS.screens.alertScreen.author}</h3>
                        <span>{report.authorId?.nickname}</span>
                        <h3>{STRINGS.screens.alertScreen.reportType}</h3>
                        <span>{typeOfReport[report.type]}</span>
                        {report.type === "other" && (
                          <>
                            <h3>{STRINGS.screens.alertScreen.description}</h3>
                            <span>{report.description}</span>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </Drawer>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <ConfirmWithInput
        onClose={onCloseDeleteModal}
        title={STRINGS.screens.alertScreen.questionAboutDeletingAlert}
        visible={props.showModal === "delete"}
        onOkClick={(notification) => props.deleteAlert(notification)}
        quantitySimbols={300}
        loading={props.loading}
      />

      <SectionHeader>
        <Breadcrumbs style={{ color: "inherit" }} separator={<NavigateNext fontSize="medium" />}>
          <Link style={{ textDecoration: "none", color: "inherit" }} to={"/users"}>
            <Typography variant={"h1"}>Users</Typography>
          </Link>
          <Typography variant={"h1"}>{props.user?.nickname}</Typography>
        </Breadcrumbs>
      </SectionHeader>
      <div className="container-for-header">
        <h1
          onClick={() => !props.loading && props.changePage("userData")}
          className={props.page === "userData" ? "active-bottom" : "not-active-bottom"}
        >
          {STRINGS.screens.user.userInfo}
        </h1>
        <h1
          onClick={() => !props.loading && props.changePage("alerts")}
          className={props.page === "alerts" ? "active-bottom" : "not-active-bottom"}
        >
          {STRINGS.screens.user.userAlerts}
        </h1>
      </div>
      <div className="user_container">{renderContent()}</div>
    </>
  );
};

export default UserView;
