import React, { useMemo } from "react";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

export const SortIcon: React.FC<{ mode: "asc" | "desc" | "none" }> = ({ mode }) => {
  const IconByMode = useMemo(
    () => ({
      none: <ArrowUpward fontSize={"small"} className={"icon"} color={"disabled"} />,
      asc: <ArrowUpward fontSize={"small"} />,
      desc: <ArrowDownward fontSize={"small"} />,
    }),
    [],
  );

  return IconByMode[mode];
};
