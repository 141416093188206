import { loginSuccess, loginFailure } from "./current-user";

import AuthService from "../../services/auth";
import UserService from "../../services/user";
import { resetRedux } from "../";

import { Toast } from "../../ui-kit";
import { auth } from "../../services/firebase";

/**
 * Redux thunks for current user.
 */
class CurrentUserThunks {
  /**
   *
   * @param email
   * @param password
   * @param loadingDisable
   * @return {Dispatch}
   */
  static authentication({ email, password }, loadingDisable) {
    return async (dispatch) => {
      try {
        const response = await AuthService.auth(email, password);
        const { role, nickname, language } = await UserService.getUser(response?.uid);

        if (role === "admin") {
          AuthService.setAuthToken({ token: response?.accessToken, refreshToken: null });

          dispatch(
            loginSuccess({
              email,
              phoneNumber: response.phoneNumber,
              role,
              nickname,
              language,
            }),
          );
        } else {
          dispatch(CurrentUserThunks.logout());
          throw new Error("not an admin");
        }
      } catch (e) {
        const isInclude = (str) => String(e).includes(str);
        let currentError = "Unable to authorize";
        console.log(e);

        switch (true) {
          case isInclude("not an admin"):
            currentError = "You do not have Administrator access";
            break;
          case isInclude("not-found"):
            currentError = "User not found";
            break;
          case isInclude("wrong-password"):
            currentError = "Your password is wrong";
            break;
          default:
            break;
        }

        Toast.error(`Error! ${currentError}!`);
        dispatch(loginFailure(e));
      } finally {
        loadingDisable();
      }
    };
  }

  static logout() {
    return async (dispatch) => {
      try {
        AuthService.removeAuthTokens();
        dispatch(resetRedux());
        await auth.signOut();
      } catch (error) {
        console.log(error);
      }
    };
  }
}

export default CurrentUserThunks;
