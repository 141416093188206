import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Icon } from "../../ui-kit";
import "./styles.scss";
import colors from "../../styles/colors";

/**
 * @component HRowItem
 * @example
 * <HRowItem
 *  to="/"
 *  icon="dashboard"
 * />
 */
const HRowItem = (props) => {
  const renderBasicContent = () => (
    <div className="row">
      {props.icon && <Icon name={props.icon} color={colors.color_text.primary} width={24} height={24} />}
      {props.name && <span>{props.name}</span>}
    </div>
  );

  return (
    <nav>
      <Link
        to={props.to}
        onClick={() => props.setSidebarVisible(false)}
        className="sidebar-row-link"
        data-active={props.active}
      >
        {props.data ? (
          <>
            {renderBasicContent()}
            <div className="analytics-box">
              <span>{props.data > 999 ? "+999" : props.data}</span>
            </div>
          </>
        ) : (
          renderBasicContent()
        )}
      </Link>
    </nav>
  );
};

/**
 * Properties
 */

HRowItem.propTypes = {
  to: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  setSidebarVisible: PropTypes.func,
  data: PropTypes.object,
  active: PropTypes.bool,
};
HRowItem.defaultProps = {
  to: "/",
  icon: null,
  name: null,
  data: null,
  setSidebarVisible: () => {},
  active: false,
};

export default HRowItem;
