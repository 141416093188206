import React, { useState, useEffect } from "react";

import "./styles.scss";

import { Button } from "../../ui-kit";
import { TextField } from "../../ui-kit";
import STRINGS from "../../localization";
import { NOTIFICATIONS_TYPES } from "../../constants/notificationTypes";

const AddNotification = (props) => {
  const [title, setTitle] = useState(props.title ? props.title : "");
  const [description, setDescription] = useState(props.description ? props.description : "");
  const [subject, setSubject] = useState(props.subject ? props.subject : "");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const checkDisable = (text) => text?.split("").every((el) => el === " ");

    const titleDisable = checkDisable(title);
    const descriptionDisable = checkDisable(description);

    if (props.notificationType === NOTIFICATIONS_TYPES.PUSH) {
      setIsButtonDisabled(titleDisable || descriptionDisable);
    } else if (props.notificationType === NOTIFICATIONS_TYPES.SMS) {
      setIsButtonDisabled(descriptionDisable);
    } else if (props.notificationType === NOTIFICATIONS_TYPES.EMAIL) {
      setIsButtonDisabled(titleDisable || descriptionDisable || checkDisable(subject));
    }
  }, [title, description, subject, props.notificationType]);

  return (
    <div className="create-notification-container">
      {props.notificationType === NOTIFICATIONS_TYPES.PUSH ? (
        <>
          <div className="input">
            <TextField quantitySimbols={300} title={"Title"} onChange={(title) => setTitle(title)} value={title} />
          </div>
          <TextField
            quantitySimbols={300}
            title={"Description"}
            onChange={(description) => setDescription(description)}
            value={description}
          />
        </>
      ) : null}
      {props.notificationType === NOTIFICATIONS_TYPES.SMS ? (
        <div>
          <div className="input-text-message">
            <TextField
              quantitySimbols={300}
              title={"Description"}
              onChange={(description) => setDescription(description)}
              value={description}
            />
          </div>
        </div>
      ) : null}
      {props.notificationType === NOTIFICATIONS_TYPES.EMAIL ? (
        <div>
          <div className="input">
            <TextField quantitySimbols={300} title={"Title"} onChange={(title) => setTitle(title)} value={title} />
          </div>
          <div className="input">
            <TextField
              quantitySimbols={300}
              title={"Subject"}
              onChange={(subject) => setSubject(subject)}
              value={subject}
            />
          </div>
          <TextField
            quantitySimbols={300}
            title={"Description"}
            onChange={(description) => setDescription(description)}
            value={description}
          />
        </div>
      ) : null}
      <div className="footer">
        <Button
          className="cancel"
          label={STRINGS.components.buttons.cancel}
          size="medium"
          type="default"
          onClick={() => {
            props.cancelNotificationCreation();
          }}
        />
        <Button
          disabled={isButtonDisabled}
          type={"primary"}
          label={STRINGS.components.buttons.send}
          onClick={() => {
            props.cancelNotificationCreation();
            props.onSave(title, description, subject);
          }}
          className="button-save"
        />
      </div>
    </div>
  );
};

export default AddNotification;
