import React from "react";

import "./styles.scss";

import { Paper, Button, Drawer, Input } from "../../../ui-kit";

const DrawerView = (props) => {
  return (
    <div id="--drawer">
      <Paper className="drawer-example">
        <>
          <h4>Drawer</h4>
          <article>
            <span>
              Боковые панели содержат дополнительную информацию и закрепляются по левую или правую сторону окна
              браузера.
            </span>
            <br />
            <code>{'<Drawer anchor="left|right" />'}</code>
          </article>
          <aside>
            <Button type="default" label="Слева" onClick={props.handleLeftDrawer} />
            <Button type="default" label="Справа" onClick={props.handleRightDrawer} />
          </aside>
        </>
      </Paper>
      <Drawer anchor="left" visible={props.leftVisible} onClose={props.handleLeftDrawer}>
        <div id="--drawer-left-example-content">
          <h2>Данные о пользователе</h2>
          <Input label="Фамилия" value="Гаврилов" />
          <Input label="Имя" value="Анатолий" />
          <Input label="Отчество" value="Степанович" />
          <Button label="Сохранить" />
        </div>
      </Drawer>
      <Drawer anchor="right" visible={props.rightVisible} onClose={props.handleRightDrawer}>
        <div id="--drawer-right-example-content">
          <h2>Данные о пользователе</h2>
          <Input label="Фамилия" value="Гаврилов" />
          <Input label="Имя" value="Анатолий" />
          <Input label="Отчество" value="Степанович" />
          <Button label="Сохранить" />
        </div>
      </Drawer>
    </div>
  );
};

export default DrawerView;
