const US_CITIES = [
  { city: "Abbeville", state: "LA" },
  { city: "Aberdeen", state: "MD" },
  {
    city: "Aberdeen",
    state: "MS",
  },
  { city: "Aberdeen", state: "SD" },
  { city: "Aberdeen", state: "WA" },
  {
    city: "Abilene",
    state: "TX",
  },
  { city: "Abilene", state: "KS" },
  { city: "Abingdon", state: "VA" },
  {
    city: "Abington",
    state: "MA",
  },
  { city: "Absecon", state: "NJ" },
  { city: "Accokeek", state: "MD" },
  {
    city: "Acton",
    state: "MA",
  },
  { city: "Acushnet", state: "MA" },
  { city: "Acworth", state: "GA" },
  {
    city: "Ada",
    state: "OK",
  },
  { city: "Adams", state: "MA" },
  { city: "Addison", state: "IL" },
  {
    city: "Addison",
    state: "TX",
  },
  { city: "Adelanto", state: "CA" },
  { city: "Adelphi", state: "MD" },
  {
    city: "Adrian",
    state: "MI",
  },
  { city: "Affton", state: "MO" },
  { city: "Agawam", state: "MA" },
  {
    city: "Agoura Hills",
    state: "CA",
  },
  { city: "Ahuimanu", state: "HI" },
  { city: "Aiea", state: "HI" },
  {
    city: "Aiken",
    state: "SC",
  },
  { city: "Air Force Academy", state: "CO" },
  { city: "Airmont", state: "NY" },
  {
    city: "Akron",
    state: "OH",
  },
  { city: "Alabaster", state: "AL" },
  { city: "Alachua", state: "FL" },
  {
    city: "Alameda",
    state: "CA",
  },
  { city: "Alamo", state: "CA" },
  { city: "Alamo", state: "TX" },
  {
    city: "Alamo Heights",
    state: "TX",
  },
  { city: "Alamogordo", state: "NM" },
  { city: "Alamosa", state: "CO" },
  {
    city: "Albany",
    state: "GA",
  },
  { city: "Albany", state: "CA" },
  { city: "Albany", state: "NY" },
  {
    city: "Albany",
    state: "OR",
  },
  { city: "Albemarle", state: "NC" },
  { city: "Albert Lea", state: "MN" },
  {
    city: "Albertville",
    state: "AL",
  },
  { city: "Albion", state: "MI" },
  { city: "Albion", state: "NY" },
  {
    city: "Albuquerque",
    state: "NM",
  },
  { city: "Alcoa", state: "TN" },
  { city: "Alden", state: "NY" },
  {
    city: "Alderwood Manor",
    state: "WA",
  },
  { city: "Aldine", state: "TX" },
  { city: "Alexander City", state: "AL" },
  {
    city: "Alexandria",
    state: "IN",
  },
  { city: "Alexandria", state: "MN" },
  { city: "Alexandria", state: "KY" },
  {
    city: "Alexandria",
    state: "LA",
  },
  { city: "Alexandria", state: "VA" },
  { city: "Algonquin", state: "IL" },
  {
    city: "Alhambra",
    state: "CA",
  },
  { city: "Alice", state: "TX" },
  { city: "Aliquippa", state: "PA" },
  {
    city: "Aliso Viejo",
    state: "CA",
  },
  { city: "Allegany", state: "NY" },
  { city: "Allen", state: "TX" },
  {
    city: "Allen Park",
    state: "MI",
  },
  { city: "Allendale", state: "MI" },
  { city: "Allendale", state: "NJ" },
  {
    city: "Allentown",
    state: "PA",
  },
  { city: "Alliance", state: "OH" },
  { city: "Alliance", state: "NE" },
  {
    city: "Allouez",
    state: "WI",
  },
  { city: "Alma", state: "MI" },
  { city: "Aloha", state: "OR" },
  {
    city: "Alondra Park",
    state: "CA",
  },
  { city: "Alpena", state: "MI" },
  { city: "Alpharetta", state: "GA" },
  {
    city: "Alpine",
    state: "CA",
  },
  { city: "Alpine", state: "UT" },
  { city: "Alsip", state: "IL" },
  {
    city: "Alta Sierra",
    state: "CA",
  },
  { city: "Altadena", state: "CA" },
  { city: "Altamont", state: "OR" },
  {
    city: "Altamont",
    state: "NY",
  },
  { city: "Altamonte Springs", state: "FL" },
  { city: "Alton", state: "IL" },
  {
    city: "Altoona",
    state: "IA",
  },
  { city: "Altoona", state: "PA" },
  { city: "Altoona", state: "WI" },
  {
    city: "Altus",
    state: "OK",
  },
  { city: "Alum Rock", state: "CA" },
  { city: "Alvin", state: "TX" },
  {
    city: "Amarillo",
    state: "TX",
  },
  { city: "Ambler", state: "PA" },
  { city: "Ambridge", state: "PA" },
  {
    city: "American Canyon",
    state: "CA",
  },
  { city: "American Fork", state: "UT" },
  { city: "Americus", state: "GA" },
  {
    city: "Ames",
    state: "IA",
  },
  { city: "Amesbury", state: "MA" },
  { city: "Amherst", state: "NH" },
  {
    city: "Amherst",
    state: "MA",
  },
  { city: "Amherst", state: "OH" },
  { city: "Amherst", state: "NY" },
  {
    city: "Amherst Center",
    state: "MA",
  },
  { city: "Amityville", state: "NY" },
  { city: "Ammon", state: "ID" },
  {
    city: "Amory",
    state: "MS",
  },
  { city: "Amsterdam", state: "NY" },
  {
    city: "Anaconda-Deer Lodge County",
    state: "MT",
  },
  { city: "Anacortes", state: "WA" },
  { city: "Anadarko", state: "OK" },
  {
    city: "Anaheim",
    state: "CA",
  },
  { city: "Anchorage", state: "AK" },
  { city: "Andalusia", state: "AL" },
  {
    city: "Anderson",
    state: "CA",
  },
  { city: "Anderson", state: "IN" },
  { city: "Anderson", state: "SC" },
  {
    city: "Anderson Mill",
    state: "TX",
  },
  { city: "Andover", state: "FL" },
  { city: "Andover", state: "MN" },
  {
    city: "Andover",
    state: "MA",
  },
  { city: "Andover", state: "KS" },
  { city: "Andrews", state: "TX" },
  {
    city: "Andrews AFB",
    state: "MD",
  },
  { city: "Angleton", state: "TX" },
  { city: "Angola", state: "IN" },
  {
    city: "Ankeny",
    state: "IA",
  },
  { city: "Ann Arbor", state: "MI" },
  { city: "Annandale", state: "VA" },
  {
    city: "Annapolis",
    state: "MD",
  },
  { city: "Anniston", state: "AL" },
  { city: "Anoka", state: "MN" },
  {
    city: "Ansonia",
    state: "CT",
  },
  { city: "Anthony", state: "NM" },
  { city: "Antigo", state: "WI" },
  {
    city: "Antioch",
    state: "IL",
  },
  { city: "Antioch", state: "CA" },
  { city: "Apache Junction", state: "AZ" },
  {
    city: "Apex",
    state: "NC",
  },
  { city: "Apollo Beach", state: "FL" },
  { city: "Apopka", state: "FL" },
  {
    city: "Apple Valley",
    state: "CA",
  },
  { city: "Apple Valley", state: "MN" },
  { city: "Appleton", state: "WI" },
  {
    city: "Applewood",
    state: "CO",
  },
  { city: "Aptos", state: "CA" },
  { city: "Aquia Harbour", state: "VA" },
  {
    city: "Arab",
    state: "AL",
  },
  { city: "Arabi", state: "LA" },
  { city: "Aransas Pass", state: "TX" },
  {
    city: "Arbutus",
    state: "MD",
  },
  { city: "Arcadia", state: "CA" },
  { city: "Arcadia", state: "FL" },
  {
    city: "Arcadia",
    state: "NY",
  },
  { city: "Arcata", state: "CA" },
  { city: "Archbald", state: "PA" },
  {
    city: "Archdale",
    state: "NC",
  },
  { city: "Arden Hills", state: "MN" },
  { city: "Arden-Arcade", state: "CA" },
  {
    city: "Ardmore",
    state: "OK",
  },
  { city: "Ardmore", state: "PA" },
  { city: "Arkadelphia", state: "AR" },
  {
    city: "Arkansas City",
    state: "KS",
  },
  { city: "Arlington", state: "MA" },
  { city: "Arlington", state: "TX" },
  {
    city: "Arlington",
    state: "NY",
  },
  { city: "Arlington", state: "VA" },
  { city: "Arlington", state: "WA" },
  {
    city: "Arlington Heights",
    state: "IL",
  },
  { city: "Arnold", state: "MO" },
  { city: "Arnold", state: "MD" },
  {
    city: "Arroyo Grande",
    state: "CA",
  },
  { city: "Artesia", state: "CA" },
  { city: "Artesia", state: "NM" },
  {
    city: "Artondale",
    state: "WA",
  },
  { city: "Arvada", state: "CO" },
  { city: "Arvin", state: "CA" },
  {
    city: "Asbury Park",
    state: "NJ",
  },
  { city: "Asheboro", state: "NC" },
  { city: "Asheville", state: "NC" },
  {
    city: "Ashland",
    state: "OR",
  },
  { city: "Ashland", state: "OH" },
  { city: "Ashland", state: "VA" },
  {
    city: "Ashland",
    state: "WI",
  },
  { city: "Ashland", state: "NJ" },
  { city: "Ashland", state: "MA" },
  {
    city: "Ashland",
    state: "KY",
  },
  { city: "Ashland", state: "CA" },
  { city: "Ashtabula", state: "OH" },
  {
    city: "Ashwaubenon",
    state: "WI",
  },
  { city: "Aspen Hill", state: "MD" },
  { city: "Astoria", state: "OR" },
  {
    city: "Atascadero",
    state: "CA",
  },
  { city: "Atascocita", state: "TX" },
  { city: "Atchison", state: "KS" },
  {
    city: "Athens",
    state: "AL",
  },
  { city: "Athens", state: "TX" },
  { city: "Athens", state: "TN" },
  {
    city: "Athens",
    state: "OH",
  },
  { city: "Athens-Clarke County", state: "GA" },
  { city: "Atherton", state: "CA" },
  {
    city: "Athol",
    state: "MA",
  },
  { city: "Atkinson", state: "NH" },
  { city: "Atlanta", state: "GA" },
  {
    city: "Atlantic",
    state: "IA",
  },
  { city: "Atlantic Beach", state: "FL" },
  { city: "Atlantic City", state: "NJ" },
  {
    city: "Atmore",
    state: "AL",
  },
  { city: "Attalla", state: "AL" },
  { city: "Attica", state: "NY" },
  {
    city: "Attleboro",
    state: "MA",
  },
  { city: "Atwater", state: "CA" },
  { city: "Auburn", state: "CA" },
  {
    city: "Auburn",
    state: "AL",
  },
  { city: "Auburn", state: "GA" },
  { city: "Auburn", state: "IN" },
  {
    city: "Auburn",
    state: "MA",
  },
  { city: "Auburn", state: "ME" },
  { city: "Auburn", state: "NY" },
  {
    city: "Auburn",
    state: "WA",
  },
  { city: "Auburn Hills", state: "MI" },
  { city: "Auburndale", state: "FL" },
  {
    city: "Audubon",
    state: "NJ",
  },
  { city: "Audubon", state: "PA" },
  { city: "August", state: "CA" },
  {
    city: "Augusta",
    state: "ME",
  },
  { city: "Augusta", state: "KS" },
  { city: "Augusta-Richmond County", state: "GA" },
  {
    city: "Aurora",
    state: "IL",
  },
  { city: "Aurora", state: "CO" },
  { city: "Aurora", state: "MO" },
  {
    city: "Aurora",
    state: "NY",
  },
  { city: "Aurora", state: "OH" },
  { city: "Austin", state: "TX" },
  {
    city: "Austin",
    state: "MN",
  },
  { city: "Austintown", state: "OH" },
  { city: "Avenal", state: "CA" },
  {
    city: "Avenel",
    state: "NJ",
  },
  { city: "Aventura", state: "FL" },
  { city: "Avocado Heights", state: "CA" },
  {
    city: "Avon",
    state: "CT",
  },
  { city: "Avon", state: "IN" },
  { city: "Avon", state: "OH" },
  {
    city: "Avon",
    state: "NY",
  },
  { city: "Avon Lake", state: "OH" },
  { city: "Avon Park", state: "FL" },
  {
    city: "Avondale",
    state: "AZ",
  },
  { city: "Ayer", state: "MA" },
  { city: "Azalea Park", state: "FL" },
  {
    city: "Azle",
    state: "TX",
  },
  { city: "Aztec", state: "NM" },
  { city: "Azusa", state: "CA" },
  {
    city: "Babylon",
    state: "NY",
  },
  { city: "Back Mountain", state: "PA" },
  { city: "Bacliff", state: "TX" },
  {
    city: "Bailey’s Crossroads",
    state: "VA",
  },
  { city: "Bainbridge", state: "GA" },
  { city: "Bainbridge Island", state: "WA" },
  {
    city: "Baker",
    state: "LA",
  },
  { city: "Baker City", state: "OR" },
  { city: "Bakersfield", state: "CA" },
  {
    city: "Balch Springs",
    state: "TX",
  },
  { city: "Baldwin", state: "PA" },
  { city: "Baldwin", state: "NY" },
  {
    city: "Baldwin Harbor",
    state: "NY",
  },
  { city: "Baldwin Park", state: "CA" },
  { city: "Baldwinsville", state: "NY" },
  {
    city: "Ballenger Creek",
    state: "MD",
  },
  { city: "Ballston", state: "NY" },
  { city: "Ballwin", state: "MO" },
  {
    city: "Baltimore",
    state: "MD",
  },
  { city: "Bangor", state: "ME" },
  { city: "Bangor Trident Base", state: "WA" },
  {
    city: "Banning",
    state: "CA",
  },
  { city: "Baraboo", state: "WI" },
  { city: "Barberton", state: "OH" },
  {
    city: "Barclay-Kingston",
    state: "NJ",
  },
  { city: "Bardstown", state: "KY" },
  { city: "Barnhart", state: "MO" },
  {
    city: "Barnstable Town",
    state: "MA",
  },
  { city: "Barre", state: "VT" },
  { city: "Barrington", state: "RI" },
  {
    city: "Barrington",
    state: "NJ",
  },
  { city: "Barrington", state: "NH" },
  { city: "Barrington", state: "IL" },
  {
    city: "Barstow",
    state: "CA",
  },
  { city: "Bartlesville", state: "OK" },
  { city: "Bartlett", state: "IL" },
  {
    city: "Bartlett",
    state: "TN",
  },
  { city: "Barton", state: "NY" },
  { city: "Bartonville", state: "IL" },
  {
    city: "Bartow",
    state: "FL",
  },
  { city: "Bastrop", state: "LA" },
  { city: "Batavia", state: "IL" },
  {
    city: "Batavia",
    state: "NY",
  },
  { city: "Batesville", state: "MS" },
  { city: "Batesville", state: "IN" },
  {
    city: "Batesville",
    state: "AR",
  },
  { city: "Bath", state: "ME" },
  { city: "Bath", state: "NY" },
  {
    city: "Baton Rouge",
    state: "LA",
  },
  { city: "Battle Creek", state: "MI" },
  { city: "Battle Ground", state: "WA" },
  {
    city: "Bay City",
    state: "TX",
  },
  { city: "Bay City", state: "MI" },
  { city: "Bay Minette", state: "AL" },
  {
    city: "Bay Point",
    state: "CA",
  },
  { city: "Bay Shore", state: "NY" },
  { city: "Bay St. Louis", state: "MS" },
  {
    city: "Bay Village",
    state: "OH",
  },
  { city: "Bayonet Point", state: "FL" },
  { city: "Bayonne", state: "NJ" },
  {
    city: "Bayou Cane",
    state: "LA",
  },
  { city: "Bayport", state: "NY" },
  { city: "Bayshore Gardens", state: "FL" },
  {
    city: "Baytown",
    state: "TX",
  },
  { city: "Bayville", state: "NY" },
  { city: "Baywood", state: "NY" },
  {
    city: "Baywood-Los Osos",
    state: "CA",
  },
  { city: "Beach Park", state: "IL" },
  { city: "Beachwood", state: "OH" },
  {
    city: "Beachwood",
    state: "NJ",
  },
  { city: "Beacon", state: "NY" },
  { city: "Beacon Square", state: "FL" },
  {
    city: "Bear",
    state: "DE",
  },
  { city: "Beatrice", state: "NE" },
  { city: "Beaufort", state: "SC" },
  {
    city: "Beaumont",
    state: "TX",
  },
  { city: "Beaumont", state: "CA" },
  { city: "Beaver Dam", state: "WI" },
  {
    city: "Beaver Falls",
    state: "PA",
  },
  { city: "Beavercreek", state: "OH" },
  { city: "Beaverton", state: "OR" },
  {
    city: "Beckett Ridge",
    state: "OH",
  },
  { city: "Beckley", state: "WV" },
  { city: "Bedford", state: "VA" },
  {
    city: "Bedford",
    state: "TX",
  },
  { city: "Bedford", state: "OH" },
  { city: "Bedford", state: "NY" },
  {
    city: "Bedford",
    state: "NH",
  },
  { city: "Bedford", state: "MA" },
  { city: "Bedford", state: "IN" },
  {
    city: "Bedford Heights",
    state: "OH",
  },
  { city: "Bee Ridge", state: "FL" },
  { city: "Beech Grove", state: "IN" },
  {
    city: "Beecher",
    state: "MI",
  },
  { city: "Beekman", state: "NY" },
  { city: "Beeville", state: "TX" },
  {
    city: "Bel Air",
    state: "MD",
  },
  { city: "Bel Air North", state: "MD" },
  { city: "Bel Air South", state: "MD" },
  {
    city: "Belchertown",
    state: "MA",
  },
  { city: "Belen", state: "NM" },
  { city: "Belfast", state: "ME" },
  {
    city: "Bell",
    state: "CA",
  },
  { city: "Bell Gardens", state: "CA" },
  {
    city: "Bella Vista",
    state: "AR",
  },
  { city: "Bellair-Meadowbrook Terrace", state: "FL" },
  {
    city: "Bellaire",
    state: "TX",
  },
  { city: "Bellbrook", state: "OH" },
  { city: "Belle Chasse", state: "LA" },
  {
    city: "Belle Glade",
    state: "FL",
  },
  { city: "Belle Haven", state: "VA" },
  {
    city: "Bellefontaine",
    state: "OH",
  },
  { city: "Bellefontaine Neighbors", state: "MO" },
  {
    city: "Bellefonte",
    state: "PA",
  },
  { city: "Belleville", state: "NJ" },
  { city: "Belleville", state: "IL" },
  {
    city: "Bellevue",
    state: "KY",
  },
  { city: "Bellevue", state: "NE" },
  { city: "Bellevue", state: "OH" },
  {
    city: "Bellevue",
    state: "PA",
  },
  { city: "Bellevue", state: "WI" },
  { city: "Bellevue", state: "WA" },
  {
    city: "Bellevue Town",
    state: "WI",
  },
  { city: "Bellflower", state: "CA" },
  { city: "Bellingham", state: "MA" },
  {
    city: "Bellingham",
    state: "WA",
  },
  { city: "Bellmawr", state: "NJ" },
  { city: "Bellmead", state: "TX" },
  {
    city: "Bellmore",
    state: "NY",
  },
  { city: "Bellview", state: "FL" },
  { city: "Bellwood", state: "IL" },
  {
    city: "Belmar",
    state: "NJ",
  },
  { city: "Belmont", state: "MA" },
  { city: "Belmont", state: "NH" },
  {
    city: "Belmont",
    state: "NC",
  },
  { city: "Belmont", state: "CA" },
  { city: "Beloit", state: "WI" },
  {
    city: "Belpre",
    state: "OH",
  },
  { city: "Belton", state: "MO" },
  { city: "Belton", state: "TX" },
  {
    city: "Beltsville",
    state: "MD",
  },
  { city: "Belvedere Park", state: "GA" },
  { city: "Belvidere", state: "IL" },
  {
    city: "Bemidji",
    state: "MN",
  },
  { city: "Benbrook", state: "TX" },
  { city: "Bend", state: "OR" },
  {
    city: "Benicia",
    state: "CA",
  },
  { city: "Bennettsville", state: "SC" },
  { city: "Bennington", state: "VT" },
  {
    city: "Bennsville",
    state: "MD",
  },
  { city: "Bensenville", state: "IL" },
  { city: "Benton", state: "IL" },
  {
    city: "Benton",
    state: "AR",
  },
  { city: "Benton Harbor", state: "MI" },
  { city: "Bentonville", state: "AR" },
  {
    city: "Berea",
    state: "KY",
  },
  { city: "Berea", state: "OH" },
  { city: "Berea", state: "SC" },
  {
    city: "Bergenfield",
    state: "NJ",
  },
  { city: "Berkeley", state: "MO" },
  { city: "Berkeley", state: "CA" },
  {
    city: "Berkeley Heights",
    state: "NJ",
  },
  { city: "Berkley", state: "MI" },
  { city: "Berkley", state: "CO" },
  {
    city: "Berlin",
    state: "CT",
  },
  { city: "Berlin", state: "NJ" },
  { city: "Berlin", state: "NH" },
  {
    city: "Bermuda Dunes",
    state: "CA",
  },
  { city: "Bernalillo", state: "NM" },
  { city: "Bernardsville", state: "NJ" },
  {
    city: "Berwick",
    state: "ME",
  },
  { city: "Berwick", state: "PA" },
  { city: "Berwyn", state: "IL" },
  {
    city: "Bessemer",
    state: "AL",
  },
  { city: "Bethalto", state: "IL" },
  { city: "Bethany", state: "OK" },
  {
    city: "Bethel",
    state: "CT",
  },
  { city: "Bethel Park", state: "PA" },
  { city: "Bethesda", state: "MD" },
  {
    city: "Bethlehem",
    state: "NY",
  },
  { city: "Bethlehem", state: "PA" },
  { city: "Bethpage", state: "NY" },
  {
    city: "Bettendorf",
    state: "IA",
  },
  { city: "Beverly", state: "MA" },
  { city: "Beverly Hills", state: "MI" },
  {
    city: "Beverly Hills",
    state: "FL",
  },
  { city: "Beverly Hills", state: "CA" },
  { city: "Bexley", state: "OH" },
  {
    city: "Biddeford",
    state: "ME",
  },
  { city: "Big Flats", state: "NY" },
  { city: "Big Lake", state: "MN" },
  {
    city: "Big Rapids",
    state: "MI",
  },
  { city: "Big Spring", state: "TX" },
  { city: "Billerica", state: "MA" },
  {
    city: "Billings",
    state: "MT",
  },
  { city: "Biloxi", state: "MS" },
  { city: "Binghamton", state: "NY" },
  {
    city: "Birmingham",
    state: "MI",
  },
  { city: "Birmingham", state: "AL" },
  { city: "Bisbee", state: "AZ" },
  {
    city: "Bismarck",
    state: "ND",
  },
  { city: "Bixby", state: "OK" },
  { city: "Black Forest", state: "CO" },
  {
    city: "Black Jack",
    state: "MO",
  },
  { city: "Black Mountain", state: "NC" },
  {
    city: "Blackfoot",
    state: "ID",
  },
  { city: "Blackhawk-Camino Tassajara", state: "CA" },
  {
    city: "Blacklick Estates",
    state: "OH",
  },
  { city: "Blacksburg", state: "VA" },
  { city: "Blackstone", state: "MA" },
  {
    city: "Blackwell",
    state: "OK",
  },
  { city: "Bladensburg", state: "MD" },
  { city: "Blaine", state: "MN" },
  {
    city: "Blair",
    state: "NE",
  },
  { city: "Blakely", state: "PA" },
  { city: "Bloomfield", state: "NJ" },
  {
    city: "Bloomfield",
    state: "NM",
  },
  { city: "Bloomfield", state: "CT" },
  {
    city: "Bloomfield Township",
    state: "MI",
  },
  { city: "Blooming Grove", state: "NY" },
  { city: "Bloomingdale", state: "NJ" },
  {
    city: "Bloomingdale",
    state: "FL",
  },
  { city: "Bloomingdale", state: "IL" },
  { city: "Bloomingdale", state: "TN" },
  {
    city: "Bloomington",
    state: "IL",
  },
  { city: "Bloomington", state: "IN" },
  { city: "Bloomington", state: "CA" },
  {
    city: "Bloomington",
    state: "MN",
  },
  { city: "Bloomsburg", state: "PA" },
  { city: "Blue Ash", state: "OH" },
  {
    city: "Blue Bell",
    state: "PA",
  },
  { city: "Blue Island", state: "IL" },
  { city: "Blue Springs", state: "MO" },
  {
    city: "Bluefield",
    state: "WV",
  },
  { city: "Bluffton", state: "IN" },
  { city: "Blythe", state: "CA" },
  {
    city: "Blytheville",
    state: "AR",
  },
  { city: "Boardman", state: "OH" },
  { city: "Boaz", state: "AL" },
  {
    city: "Boca Del Mar",
    state: "FL",
  },
  { city: "Boca Raton", state: "FL" },
  { city: "Boerne", state: "TX" },
  {
    city: "Bogalusa",
    state: "LA",
  },
  { city: "Bogota", state: "NJ" },
  { city: "Bohemia", state: "NY" },
  {
    city: "Boise City",
    state: "ID",
  },
  { city: "Bolingbrook", state: "IL" },
  { city: "Bolivar", state: "MO" },
  {
    city: "Bon Air",
    state: "VA",
  },
  { city: "Bonadelle Ranchos-Madera Ranchos", state: "CA" },
  {
    city: "Bonham",
    state: "TX",
  },
  { city: "Bonita", state: "CA" },
  { city: "Bonita Springs", state: "FL" },
  {
    city: "Bonner Springs",
    state: "KS",
  },
  { city: "Bonney Lake", state: "WA" },
  { city: "Boone", state: "IA" },
  {
    city: "Boone",
    state: "NC",
  },
  { city: "Booneville", state: "MS" },
  { city: "Boonton", state: "NJ" },
  {
    city: "Boonville",
    state: "MO",
  },
  { city: "Boonville", state: "IN" },
  { city: "Borger", state: "TX" },
  {
    city: "Bossier City",
    state: "LA",
  },
  { city: "Boston", state: "MA" },
  { city: "Boston", state: "NY" },
  {
    city: "Bostonia",
    state: "CA",
  },
  { city: "Bothell", state: "WA" },
  { city: "Boulder", state: "CO" },
  {
    city: "Boulder City",
    state: "NV",
  },
  { city: "Boulder Hill", state: "IL" },
  { city: "Bound Brook", state: "NJ" },
  {
    city: "Bountiful",
    state: "UT",
  },
  { city: "Bourbonnais", state: "IL" },
  { city: "Bourne", state: "MA" },
  {
    city: "Bow",
    state: "NH",
  },
  { city: "Bowie", state: "MD" },
  { city: "Bowleys Quarters", state: "MD" },
  {
    city: "Bowling Green",
    state: "KY",
  },
  { city: "Bowling Green", state: "OH" },
  { city: "Boxford", state: "MA" },
  {
    city: "Boyes Hot Springs",
    state: "CA",
  },
  { city: "Boynton Beach", state: "FL" },
  { city: "Bozeman", state: "MT" },
  {
    city: "Bradenton",
    state: "FL",
  },
  { city: "Bradford", state: "PA" },
  { city: "Bradley", state: "IL" },
  {
    city: "Brainerd",
    state: "MN",
  },
  { city: "Braintree", state: "MA" },
  { city: "Brandon", state: "MS" },
  {
    city: "Brandon",
    state: "FL",
  },
  { city: "Branford", state: "CT" },
  { city: "Branson", state: "MO" },
  {
    city: "Brattleboro",
    state: "VT",
  },
  { city: "Brawley", state: "CA" },
  { city: "Brazil", state: "IN" },
  {
    city: "Brea",
    state: "CA",
  },
  { city: "Breaux Bridge", state: "LA" },
  { city: "Brecksville", state: "OH" },
  {
    city: "Bremerton",
    state: "WA",
  },
  { city: "Brenham", state: "TX" },
  { city: "Brent", state: "FL" },
  {
    city: "Brentwood",
    state: "CA",
  },
  { city: "Brentwood", state: "NY" },
  { city: "Brentwood", state: "MO" },
  {
    city: "Brentwood",
    state: "PA",
  },
  { city: "Brentwood", state: "TN" },
  { city: "Brevard", state: "NC" },
  {
    city: "Brewer",
    state: "ME",
  },
  { city: "Brewster", state: "MA" },
  { city: "Briarcliff Manor", state: "NY" },
  {
    city: "Bridge City",
    state: "LA",
  },
  { city: "Bridge City", state: "TX" },
  { city: "Bridgeport", state: "WV" },
  {
    city: "Bridgeport",
    state: "CT",
  },
  { city: "Bridgeport", state: "MI" },
  { city: "Bridgeton", state: "MO" },
  {
    city: "Bridgeton",
    state: "NJ",
  },
  { city: "Bridgetown North", state: "OH" },
  { city: "Bridgeview", state: "IL" },
  {
    city: "Bridgewater",
    state: "MA",
  },
  { city: "Brier", state: "WA" },
  { city: "Brigantine", state: "NJ" },
  {
    city: "Brigham City",
    state: "UT",
  },
  { city: "Brighton", state: "MI" },
  { city: "Brighton", state: "NY" },
  {
    city: "Brighton",
    state: "CO",
  },
  { city: "Bristol", state: "CT" },
  { city: "Bristol", state: "RI" },
  {
    city: "Bristol",
    state: "TN",
  },
  { city: "Bristol", state: "VA" },
  { city: "Bristol", state: "PA" },
  {
    city: "Broadview",
    state: "IL",
  },
  { city: "Broadview Heights", state: "OH" },
  { city: "Broadview Park", state: "FL" },
  {
    city: "Brockport",
    state: "NY",
  },
  { city: "Brockton", state: "MA" },
  { city: "Broken Arrow", state: "OK" },
  {
    city: "Bronxville",
    state: "NY",
  },
  { city: "Brook Park", state: "OH" },
  { city: "Brookfield", state: "CT" },
  {
    city: "Brookfield",
    state: "IL",
  },
  { city: "Brookfield", state: "WI" },
  { city: "Brookhaven", state: "PA" },
  {
    city: "Brookhaven",
    state: "NY",
  },
  { city: "Brookhaven", state: "MS" },
  { city: "Brookings", state: "SD" },
  {
    city: "Brookline",
    state: "MA",
  },
  { city: "Brooklyn", state: "OH" },
  { city: "Brooklyn", state: "CT" },
  {
    city: "Brooklyn Center",
    state: "MN",
  },
  { city: "Brooklyn Park", state: "MN" },
  { city: "Brooklyn Park", state: "MD" },
  {
    city: "Brookside",
    state: "DE",
  },
  { city: "Brooksville", state: "FL" },
  { city: "Broomall", state: "PA" },
  {
    city: "Broomfield",
    state: "CO",
  },
  { city: "Brown Deer", state: "WI" },
  { city: "Brownfield", state: "TX" },
  {
    city: "Browns Mills",
    state: "NJ",
  },
  { city: "Brownsburg", state: "IN" },
  { city: "Brownsville", state: "FL" },
  {
    city: "Brownsville",
    state: "TX",
  },
  { city: "Brownsville", state: "TN" },
  {
    city: "Brownsville-Bawcomville",
    state: "LA",
  },
  { city: "Brownwood", state: "TX" },
  { city: "Brunswick", state: "ME" },
  {
    city: "Brunswick",
    state: "GA",
  },
  { city: "Brunswick", state: "OH" },
  { city: "Brunswick", state: "NY" },
  {
    city: "Brushy Creek",
    state: "TX",
  },
  { city: "Bryan", state: "TX" },
  { city: "Bryan", state: "OH" },
  {
    city: "Bryant",
    state: "AR",
  },
  { city: "Bryn Mawr-Skyway", state: "WA" },
  { city: "Buckeye", state: "AZ" },
  {
    city: "Bucyrus",
    state: "OH",
  },
  { city: "Budd Lake", state: "NJ" },
  { city: "Buechel", state: "KY" },
  {
    city: "Buena Park",
    state: "CA",
  },
  { city: "Buena Vista", state: "MI" },
  { city: "Buena Vista", state: "VA" },
  {
    city: "Buffalo",
    state: "MN",
  },
  { city: "Buffalo", state: "NY" },
  { city: "Buffalo Grove", state: "IL" },
  {
    city: "Buford",
    state: "GA",
  },
  { city: "Bull Run", state: "VA" },
  { city: "Bullhead City", state: "AZ" },
  {
    city: "Burbank",
    state: "CA",
  },
  { city: "Burbank", state: "IL" },
  { city: "Burien", state: "WA" },
  {
    city: "Burkburnett",
    state: "TX",
  },
  { city: "Burke", state: "VA" },
  { city: "Burleson", state: "TX" },
  {
    city: "Burley",
    state: "ID",
  },
  { city: "Burlingame", state: "CA" },
  { city: "Burlington", state: "CT" },
  {
    city: "Burlington",
    state: "KY",
  },
  { city: "Burlington", state: "IA" },
  { city: "Burlington", state: "NC" },
  {
    city: "Burlington",
    state: "MA",
  },
  { city: "Burlington", state: "NJ" },
  { city: "Burlington", state: "VT" },
  {
    city: "Burlington",
    state: "WA",
  },
  { city: "Burlington", state: "WI" },
  { city: "Burnsville", state: "MN" },
  {
    city: "Burr Ridge",
    state: "IL",
  },
  { city: "Burrillville", state: "RI" },
  { city: "Burton", state: "SC" },
  {
    city: "Burton",
    state: "MI",
  },
  { city: "Burtonsville", state: "MD" },
  { city: "Busti", state: "NY" },
  {
    city: "Butler",
    state: "NJ",
  },
  { city: "Butler", state: "PA" },
  { city: "Butte-Silver Bow", state: "MT" },
  {
    city: "Buxton",
    state: "ME",
  },
  { city: "Byram", state: "MS" },
  { city: "Cabot", state: "AR" },
  {
    city: "Cadillac",
    state: "MI",
  },
  { city: "Cahokia", state: "IL" },
  { city: "Cairo", state: "GA" },
  {
    city: "Cairo",
    state: "NY",
  },
  { city: "Calabasas", state: "CA" },
  { city: "Caldwell", state: "NJ" },
  {
    city: "Caldwell",
    state: "ID",
  },
  { city: "Caledonia", state: "WI" },
  { city: "Calexico", state: "CA" },
  {
    city: "Calhoun",
    state: "GA",
  },
  { city: "California", state: "MD" },
  { city: "California City", state: "CA" },
  {
    city: "Calimesa",
    state: "CA",
  },
  { city: "Calipatria", state: "CA" },
  { city: "Callaway", state: "FL" },
  {
    city: "Calumet City",
    state: "IL",
  },
  { city: "Calumet Park", state: "IL" },
  { city: "Calverton", state: "MD" },
  {
    city: "Camano",
    state: "WA",
  },
  { city: "Camarillo", state: "CA" },
  { city: "Camas", state: "WA" },
  {
    city: "Cambria",
    state: "CA",
  },
  { city: "Cambridge", state: "MD" },
  { city: "Cambridge", state: "MA" },
  {
    city: "Cambridge",
    state: "OH",
  },
  { city: "Camden", state: "SC" },
  { city: "Camden", state: "NJ" },
  {
    city: "Camden",
    state: "AR",
  },
  { city: "Cameron", state: "MO" },
  { city: "Cameron Park", state: "CA" },
  {
    city: "Camillus",
    state: "NY",
  },
  { city: "Camp Hill", state: "PA" },
  {
    city: "Camp Pendleton North",
    state: "CA",
  },
  { city: "Camp Pendleton South", state: "CA" },
  { city: "Camp Springs", state: "MD" },
  {
    city: "Camp Verde",
    state: "AZ",
  },
  { city: "Campbell", state: "CA" },
  { city: "Campbell", state: "OH" },
  {
    city: "Campbellsville",
    state: "KY",
  },
  { city: "Canandaigua", state: "NY" },
  { city: "Canby", state: "OR" },
  {
    city: "Candler-McAfee",
    state: "GA",
  },
  { city: "Canfield", state: "OH" },
  { city: "Canon City", state: "CO" },
  {
    city: "Canonsburg",
    state: "PA",
  },
  { city: "Canton", state: "OH" },
  { city: "Canton", state: "GA" },
  {
    city: "Canton",
    state: "IL",
  },
  { city: "Canton", state: "CT" },
  { city: "Canton", state: "NY" },
  {
    city: "Canton",
    state: "MS",
  },
  { city: "Canton", state: "MA" },
  { city: "Canton", state: "MI" },
  {
    city: "Canyon",
    state: "TX",
  },
  { city: "Canyon Lake", state: "TX" },
  { city: "Canyon Lake", state: "CA" },
  {
    city: "Canyon Rim",
    state: "UT",
  },
  { city: "Cape Canaveral", state: "FL" },
  { city: "Cape Coral", state: "FL" },
  {
    city: "Cape Elizabeth",
    state: "ME",
  },
  { city: "Cape Girardeau", state: "MO" },
  { city: "Cape St. Claire", state: "MD" },
  {
    city: "Capitola",
    state: "CA",
  },
  { city: "Carbondale", state: "IL" },
  { city: "Carbondale", state: "PA" },
  {
    city: "Carencro",
    state: "LA",
  },
  { city: "Caribou", state: "ME" },
  { city: "Carlisle", state: "PA" },
  {
    city: "Carlsbad",
    state: "NM",
  },
  { city: "Carlsbad", state: "CA" },
  { city: "Carmel", state: "IN" },
  {
    city: "Carmel",
    state: "NY",
  },
  { city: "Carmichael", state: "CA" },
  { city: "Carnegie", state: "PA" },
  {
    city: "Carney",
    state: "MD",
  },
  { city: "Carneys Point", state: "NJ" },
  { city: "Carnot-Moon", state: "PA" },
  {
    city: "Carol City",
    state: "FL",
  },
  { city: "Carol Stream", state: "IL" },
  { city: "Carpentersville", state: "IL" },
  {
    city: "Carpinteria",
    state: "CA",
  },
  { city: "Carrboro", state: "NC" },
  { city: "Carroll", state: "IA" },
  {
    city: "Carrollton",
    state: "MI",
  },
  { city: "Carrollton", state: "GA" },
  { city: "Carrollton", state: "TX" },
  {
    city: "Carson",
    state: "CA",
  },
  { city: "Carson City", state: "NV" },
  { city: "Carteret", state: "NJ" },
  {
    city: "Cartersville",
    state: "GA",
  },
  { city: "Carthage", state: "MO" },
  { city: "Carthage", state: "TX" },
  {
    city: "Caruthersville",
    state: "MO",
  },
  { city: "Carver", state: "MA" },
  { city: "Cary", state: "IL" },
  {
    city: "Cary",
    state: "NC",
  },
  { city: "Casa de Oro-Mount Helix", state: "CA" },
  {
    city: "Casa Grande",
    state: "AZ",
  },
  { city: "Casas Adobes", state: "AZ" },
  { city: "Cascade-Fairwood", state: "WA" },
  {
    city: "Casper",
    state: "WY",
  },
  { city: "Casselberry", state: "FL" },
  { city: "Castle Rock", state: "CO" },
  {
    city: "Castle Shannon",
    state: "PA",
  },
  { city: "Castlewood", state: "CO" },
  { city: "Castro Valley", state: "CA" },
  {
    city: "Castroville",
    state: "CA",
  },
  { city: "Catalina", state: "AZ" },
  { city: "Catalina Foothills", state: "AZ" },
  {
    city: "Catasauqua",
    state: "PA",
  },
  { city: "Cathedral City", state: "CA" },
  { city: "Catonsville", state: "MD" },
  {
    city: "Catskill",
    state: "NY",
  },
  { city: "Cave Spring", state: "VA" },
  { city: "Cayce", state: "SC" },
  {
    city: "Cazenovia",
    state: "NY",
  },
  { city: "Cedar City", state: "UT" },
  { city: "Cedar Falls", state: "IA" },
  {
    city: "Cedar Grove",
    state: "NJ",
  },
  { city: "Cedar Hill", state: "TX" },
  { city: "Cedar Hills", state: "OR" },
  {
    city: "Cedar Lake",
    state: "IN",
  },
  { city: "Cedar Mill", state: "OR" },
  { city: "Cedar Park", state: "TX" },
  {
    city: "Cedar Rapids",
    state: "IA",
  },
  { city: "Cedarburg", state: "WI" },
  { city: "Cedarhurst", state: "NY" },
  {
    city: "Cedartown",
    state: "GA",
  },
  { city: "Celina", state: "OH" },
  { city: "Center Line", state: "MI" },
  {
    city: "Center Moriches",
    state: "NY",
  },
  { city: "Center Point", state: "AL" },
  { city: "Centereach", state: "NY" },
  {
    city: "Centerville",
    state: "OH",
  },
  { city: "Centerville", state: "UT" },
  { city: "Central Falls", state: "RI" },
  {
    city: "Central Islip",
    state: "NY",
  },
  { city: "Central Manchester", state: "CT" },
  { city: "Central Point", state: "OR" },
  {
    city: "Centralia",
    state: "WA",
  },
  { city: "Centralia", state: "IL" },
  { city: "Centreville", state: "VA" },
  {
    city: "Century Village",
    state: "FL",
  },
  { city: "Ceres", state: "CA" },
  { city: "Cerritos", state: "CA" },
  {
    city: "Chalco",
    state: "NE",
  },
  { city: "Chalmette", state: "LA" },
  { city: "Chambersburg", state: "PA" },
  {
    city: "Chamblee",
    state: "GA",
  },
  { city: "Champaign", state: "IL" },
  { city: "Champlin", state: "MN" },
  {
    city: "Chandler",
    state: "AZ",
  },
  { city: "Chanhassen", state: "MN" },
  { city: "Channahon", state: "IL" },
  {
    city: "Channelview",
    state: "TX",
  },
  { city: "Chantilly", state: "VA" },
  { city: "Chanute", state: "KS" },
  {
    city: "Chaparral",
    state: "NM",
  },
  { city: "Chapel Hill", state: "NC" },
  { city: "Chappaqua", state: "NY" },
  {
    city: "Charles City",
    state: "IA",
  },
  { city: "Charleston", state: "IL" },
  { city: "Charleston", state: "WV" },
  {
    city: "Charleston",
    state: "SC",
  },
  { city: "Charlestown", state: "RI" },
  { city: "Charlotte", state: "NC" },
  {
    city: "Charlotte",
    state: "MI",
  },
  { city: "Charlottesville", state: "VA" },
  { city: "Charlton", state: "MA" },
  {
    city: "Charter Oak",
    state: "CA",
  },
  { city: "Chaska", state: "MN" },
  { city: "Chatham", state: "MA" },
  {
    city: "Chatham",
    state: "NJ",
  },
  { city: "Chatham", state: "IL" },
  { city: "Chattanooga", state: "TN" },
  {
    city: "Cheat Lake",
    state: "WV",
  },
  { city: "Cheektowaga", state: "NY" },
  { city: "Chehalis", state: "WA" },
  {
    city: "Chelmsford",
    state: "MA",
  },
  { city: "Chelsea", state: "MA" },
  { city: "Chenango", state: "NY" },
  {
    city: "Cheney",
    state: "WA",
  },
  { city: "Cherry Hill Mall", state: "NJ" },
  { city: "Cherryland", state: "CA" },
  {
    city: "Chesapeake",
    state: "VA",
  },
  { city: "Chesapeake Ranch Estates-Drum Point", state: "MD" },
  {
    city: "Cheshire",
    state: "CT",
  },
  { city: "Chester", state: "NY" },
  { city: "Chester", state: "VA" },
  {
    city: "Chester",
    state: "SC",
  },
  { city: "Chester", state: "PA" },
  { city: "Chesterfield", state: "MO" },
  {
    city: "Chesterton",
    state: "IN",
  },
  { city: "Chestnut Ridge", state: "NY" },
  { city: "Cheval", state: "FL" },
  {
    city: "Cheverly",
    state: "MD",
  },
  { city: "Cheviot", state: "OH" },
  { city: "Chevy Chase", state: "MD" },
  {
    city: "Cheyenne",
    state: "WY",
  },
  { city: "Chicago", state: "IL" },
  { city: "Chicago Heights", state: "IL" },
  {
    city: "Chicago Ridge",
    state: "IL",
  },
  { city: "Chickasaw", state: "AL" },
  { city: "Chickasha", state: "OK" },
  {
    city: "Chico",
    state: "CA",
  },
  { city: "Chicopee", state: "MA" },
  { city: "Childress", state: "TX" },
  {
    city: "Chili",
    state: "NY",
  },
  { city: "Chillicothe", state: "MO" },
  { city: "Chillicothe", state: "OH" },
  {
    city: "Chillum",
    state: "MD",
  },
  { city: "Chino", state: "CA" },
  { city: "Chino Hills", state: "CA" },
  {
    city: "Chino Valley",
    state: "AZ",
  },
  { city: "Chippewa Falls", state: "WI" },
  { city: "Choctaw", state: "OK" },
  {
    city: "Chowchilla",
    state: "CA",
  },
  { city: "Christiansburg", state: "VA" },
  { city: "Chubbuck", state: "ID" },
  {
    city: "Chula Vista",
    state: "CA",
  },
  { city: "Cicero", state: "IL" },
  { city: "Cicero", state: "NY" },
  {
    city: "Cimarron Hills",
    state: "CO",
  },
  { city: "Cincinnati", state: "OH" },
  { city: "Cinco Ranch", state: "TX" },
  {
    city: "Circleville",
    state: "OH",
  },
  { city: "Citrus", state: "CA" },
  { city: "Citrus Heights", state: "CA" },
  {
    city: "Citrus Park",
    state: "FL",
  },
  { city: "Citrus Ridge", state: "FL" },
  { city: "City of The Dalles", state: "OR" },
  {
    city: "Claiborne",
    state: "LA",
  },
  { city: "Clairton", state: "PA" },
  { city: "Clanton", state: "AL" },
  {
    city: "Claremont",
    state: "CA",
  },
  { city: "Claremont", state: "NH" },
  { city: "Claremore", state: "OK" },
  {
    city: "Clarence",
    state: "NY",
  },
  { city: "Clarendon Hills", state: "IL" },
  { city: "Clarion", state: "PA" },
  {
    city: "Clark",
    state: "NJ",
  },
  { city: "Clarksburg", state: "WV" },
  { city: "Clarksdale", state: "MS" },
  {
    city: "Clarkson",
    state: "NY",
  },
  { city: "Clarkston", state: "GA" },
  {
    city: "Clarkston",
    state: "WA",
  },
  { city: "Clarkston Heights-Vineland", state: "WA" },
  {
    city: "Clarkstown",
    state: "NY",
  },
  { city: "Clarksville", state: "IN" },
  { city: "Clarksville", state: "AR" },
  {
    city: "Clarksville",
    state: "TN",
  },
  { city: "Claverack", state: "NY" },
  { city: "Clawson", state: "MI" },
  {
    city: "Clay",
    state: "NY",
  },
  { city: "Claymont", state: "DE" },
  { city: "Clayton", state: "CA" },
  {
    city: "Clayton",
    state: "MO",
  },
  { city: "Clayton", state: "NJ" },
  { city: "Clayton", state: "OH" },
  {
    city: "Clayton",
    state: "NC",
  },
  { city: "Clear Lake", state: "IA" },
  { city: "Clearfield", state: "PA" },
  {
    city: "Clearfield",
    state: "UT",
  },
  { city: "Clearlake", state: "CA" },
  { city: "Clearwater", state: "FL" },
  {
    city: "Cleburne",
    state: "TX",
  },
  { city: "Clemmons", state: "NC" },
  { city: "Clemson", state: "SC" },
  {
    city: "Clermont",
    state: "FL",
  },
  { city: "Cleveland", state: "MS" },
  { city: "Cleveland", state: "TN" },
  {
    city: "Cleveland",
    state: "TX",
  },
  { city: "Cleveland", state: "OH" },
  { city: "Cleveland Heights", state: "OH" },
  {
    city: "Clewiston",
    state: "FL",
  },
  { city: "Cliffside Park", state: "NJ" },
  { city: "Clifton", state: "NJ" },
  {
    city: "Clifton",
    state: "CO",
  },
  { city: "Clifton Heights", state: "PA" },
  { city: "Clifton Park", state: "NY" },
  {
    city: "Clinton",
    state: "MS",
  },
  { city: "Clinton", state: "MO" },
  { city: "Clinton", state: "IA" },
  {
    city: "Clinton",
    state: "MD",
  },
  { city: "Clinton", state: "MI" },
  { city: "Clinton", state: "MA" },
  {
    city: "Clinton",
    state: "CT",
  },
  { city: "Clinton", state: "IL" },
  { city: "Clinton", state: "OK" },
  {
    city: "Clinton",
    state: "NC",
  },
  { city: "Clinton", state: "TN" },
  { city: "Clinton", state: "SC" },
  {
    city: "Clinton",
    state: "UT",
  },
  { city: "Clive", state: "IA" },
  { city: "Cloquet", state: "MN" },
  {
    city: "Closter",
    state: "NJ",
  },
  { city: "Cloverdale", state: "CA" },
  { city: "Cloverleaf", state: "TX" },
  {
    city: "Cloverly",
    state: "MD",
  },
  { city: "Clovis", state: "NM" },
  { city: "Clovis", state: "CA" },
  {
    city: "Clute",
    state: "TX",
  },
  { city: "Clyde", state: "OH" },
  { city: "Coachella", state: "CA" },
  {
    city: "Coalinga",
    state: "CA",
  },
  { city: "Coatesville", state: "PA" },
  { city: "Cobleskill", state: "NY" },
  {
    city: "Cochituate",
    state: "MA",
  },
  { city: "Cockeysville", state: "MD" },
  { city: "Cocoa", state: "FL" },
  {
    city: "Cocoa Beach",
    state: "FL",
  },
  { city: "Coconut Creek", state: "FL" },
  { city: "Cody", state: "WY" },
  {
    city: "Coeur d’Alene",
    state: "ID",
  },
  { city: "Coeymans", state: "NY" },
  { city: "Coffeyville", state: "KS" },
  {
    city: "Cohasset",
    state: "MA",
  },
  { city: "Cohoes", state: "NY" },
  { city: "Colchester", state: "VT" },
  {
    city: "Colchester",
    state: "CT",
  },
  { city: "Coldwater", state: "MI" },
  { city: "Colesville", state: "MD" },
  {
    city: "College",
    state: "AK",
  },
  { city: "College Park", state: "GA" },
  { city: "College Park", state: "MD" },
  {
    city: "College Place",
    state: "WA",
  },
  { city: "College Station", state: "TX" },
  { city: "Collegedale", state: "TN" },
  {
    city: "Collegeville",
    state: "PA",
  },
  { city: "Colleyville", state: "TX" },
  {
    city: "Collier Manor-Cresthaven",
    state: "FL",
  },
  { city: "Collierville", state: "TN" },
  { city: "Collingdale", state: "PA" },
  {
    city: "Collingswood",
    state: "NJ",
  },
  { city: "Collins", state: "NY" },
  { city: "Collinsville", state: "IL" },
  {
    city: "Collinsville",
    state: "VA",
  },
  { city: "Colonia", state: "NJ" },
  { city: "Colonial Heights", state: "VA" },
  {
    city: "Colonial Heights",
    state: "TN",
  },
  { city: "Colonial Park", state: "PA" },
  { city: "Colonie", state: "NY" },
  {
    city: "Colorado Springs",
    state: "CO",
  },
  { city: "Colton", state: "CA" },
  { city: "Columbia", state: "IL" },
  {
    city: "Columbia",
    state: "MO",
  },
  { city: "Columbia", state: "MS" },
  { city: "Columbia", state: "MD" },
  {
    city: "Columbia",
    state: "PA",
  },
  { city: "Columbia", state: "TN" },
  { city: "Columbia", state: "SC" },
  {
    city: "Columbia City",
    state: "IN",
  },
  { city: "Columbia Heights", state: "MN" },
  { city: "Columbine", state: "CO" },
  {
    city: "Columbus",
    state: "IN",
  },
  { city: "Columbus", state: "GA" },
  { city: "Columbus", state: "MS" },
  {
    city: "Columbus",
    state: "NE",
  },
  { city: "Columbus", state: "OH" },
  { city: "Commack", state: "NY" },
  {
    city: "Commerce",
    state: "TX",
  },
  { city: "Commerce", state: "CA" },
  { city: "Commerce City", state: "CO" },
  {
    city: "Compton",
    state: "CA",
  },
  { city: "Comstock Park", state: "MI" },
  { city: "Concord", state: "MA" },
  {
    city: "Concord",
    state: "MO",
  },
  { city: "Concord", state: "NH" },
  { city: "Concord", state: "NY" },
  {
    city: "Concord",
    state: "CA",
  },
  { city: "Concord", state: "NC" },
  { city: "Congers", state: "NY" },
  {
    city: "Conley",
    state: "GA",
  },
  { city: "Conneaut", state: "OH" },
  { city: "Connellsville", state: "PA" },
  {
    city: "Connersville",
    state: "IN",
  },
  { city: "Conning Towers-Nautilus Park", state: "CT" },
  { city: "Conover", state: "NC" },
  {
    city: "Conroe",
    state: "TX",
  },
  { city: "Conshohocken", state: "PA" },
  { city: "Converse", state: "TX" },
  {
    city: "Conway",
    state: "SC",
  },
  { city: "Conway", state: "FL" },
  { city: "Conway", state: "AR" },
  {
    city: "Conway",
    state: "NH",
  },
  { city: "Conyers", state: "GA" },
  { city: "Cookeville", state: "TN" },
  {
    city: "Coolidge",
    state: "AZ",
  },
  { city: "Coon Rapids", state: "MN" },
  { city: "Cooper City", state: "FL" },
  {
    city: "Coos Bay",
    state: "OR",
  },
  { city: "Copiague", state: "NY" },
  { city: "Coppell", state: "TX" },
  {
    city: "Copperas Cove",
    state: "TX",
  },
  { city: "Coral Gables", state: "FL" },
  { city: "Coral Hills", state: "MD" },
  {
    city: "Coral Springs",
    state: "FL",
  },
  { city: "Coral Terrace", state: "FL" },
  { city: "Coralville", state: "IA" },
  {
    city: "Coram",
    state: "NY",
  },
  { city: "Coraopolis", state: "PA" },
  { city: "Corbin", state: "KY" },
  {
    city: "Corcoran",
    state: "CA",
  },
  { city: "Cordele", state: "GA" },
  { city: "Corinth", state: "MS" },
  {
    city: "Corinth",
    state: "TX",
  },
  { city: "Cornelius", state: "OR" },
  { city: "Cornelius", state: "NC" },
  {
    city: "Corning",
    state: "NY",
  },
  { city: "Corning", state: "CA" },
  { city: "Cornwall", state: "NY" },
  {
    city: "Corona",
    state: "CA",
  },
  { city: "Coronado", state: "CA" },
  { city: "Corpus Christi", state: "TX" },
  {
    city: "Corrales",
    state: "NM",
  },
  { city: "Corry", state: "PA" },
  { city: "Corsicana", state: "TX" },
  {
    city: "Corte Madera",
    state: "CA",
  },
  { city: "Cortez", state: "CO" },
  { city: "Cortland", state: "NY" },
  {
    city: "Cortland",
    state: "OH",
  },
  { city: "Cortlandt", state: "NY" },
  { city: "Cortlandville", state: "NY" },
  {
    city: "Corvallis",
    state: "OR",
  },
  { city: "Coshocton", state: "OH" },
  { city: "Costa Mesa", state: "CA" },
  {
    city: "Cotati",
    state: "CA",
  },
  { city: "Coto de Caza", state: "CA" },
  { city: "Cottage Grove", state: "MN" },
  {
    city: "Cottage Grove",
    state: "OR",
  },
  { city: "Cottage Lake", state: "WA" },
  { city: "Cottonwood", state: "AZ" },
  {
    city: "Cottonwood Heights",
    state: "UT",
  },
  { city: "Cottonwood West", state: "UT" },
  {
    city: "Cottonwood-Verde Village",
    state: "AZ",
  },
  { city: "Council Bluffs", state: "IA" },
  { city: "Country Club", state: "CA" },
  {
    city: "Country Club",
    state: "FL",
  },
  { city: "Country Club Estates", state: "GA" },
  {
    city: "Country Club Hills",
    state: "IL",
  },
  { city: "Country Walk", state: "FL" },
  { city: "Covedale", state: "OH" },
  {
    city: "Coventry",
    state: "RI",
  },
  { city: "Coventry", state: "CT" },
  { city: "Covina", state: "CA" },
  {
    city: "Covington",
    state: "GA",
  },
  { city: "Covington", state: "KY" },
  { city: "Covington", state: "LA" },
  {
    city: "Covington",
    state: "WA",
  },
  { city: "Covington", state: "VA" },
  { city: "Covington", state: "TN" },
  {
    city: "Coweta",
    state: "OK",
  },
  { city: "Coxsackie", state: "NY" },
  { city: "Crafton", state: "PA" },
  {
    city: "Craig",
    state: "CO",
  },
  { city: "Cranford", state: "NJ" },
  { city: "Cranston", state: "RI" },
  {
    city: "Crawford",
    state: "NY",
  },
  { city: "Crawfordsville", state: "IN" },
  { city: "Cresskill", state: "NJ" },
  {
    city: "Crest Hill",
    state: "IL",
  },
  { city: "Crestline", state: "CA" },
  { city: "Creston", state: "IA" },
  {
    city: "Crestview",
    state: "FL",
  },
  { city: "Crestwood", state: "IL" },
  { city: "Crestwood", state: "MO" },
  {
    city: "Crestwood Village",
    state: "NJ",
  },
  { city: "Crete", state: "NE" },
  { city: "Crete", state: "IL" },
  {
    city: "Creve Coeur",
    state: "MO",
  },
  { city: "Crockett", state: "TX" },
  { city: "Crofton", state: "MD" },
  {
    city: "Cromwell",
    state: "CT",
  },
  { city: "Crookston", state: "MN" },
  { city: "Cross Lanes", state: "WV" },
  {
    city: "Crossett",
    state: "AR",
  },
  { city: "Crossville", state: "TN" },
  { city: "Croton-on-Hudson", state: "NY" },
  {
    city: "Crowley",
    state: "TX",
  },
  { city: "Crowley", state: "LA" },
  { city: "Crown Point", state: "IN" },
  {
    city: "Croydon",
    state: "PA",
  },
  { city: "Crystal", state: "MN" },
  { city: "Crystal City", state: "TX" },
  {
    city: "Crystal Lake",
    state: "IL",
  },
  { city: "Cudahy", state: "CA" },
  { city: "Cudahy", state: "WI" },
  {
    city: "Cuero",
    state: "TX",
  },
  { city: "Cullman", state: "AL" },
  { city: "Culpeper", state: "VA" },
  {
    city: "Culver City",
    state: "CA",
  },
  { city: "Cumberland", state: "ME" },
  { city: "Cumberland", state: "MD" },
  {
    city: "Cumberland",
    state: "RI",
  },
  { city: "Cumberland Hill", state: "RI" },
  { city: "Cupertino", state: "CA" },
  {
    city: "Cushing",
    state: "OK",
  },
  { city: "Cutler", state: "FL" },
  { city: "Cutler Ridge", state: "FL" },
  {
    city: "Cutlerville",
    state: "MI",
  },
  { city: "Cuyahoga Falls", state: "OH" },
  { city: "Cynthiana", state: "KY" },
  {
    city: "Cypress",
    state: "CA",
  },
  { city: "Cypress Gardens", state: "FL" },
  { city: "Cypress Lake", state: "FL" },
  {
    city: "D’Iberville",
    state: "MS",
  },
  { city: "Dade City", state: "FL" },
  { city: "Dale City", state: "VA" },
  {
    city: "Dalhart",
    state: "TX",
  },
  { city: "Dallas", state: "TX" },
  { city: "Dallas", state: "OR" },
  {
    city: "Dalton",
    state: "MA",
  },
  { city: "Dalton", state: "GA" },
  { city: "Daly City", state: "CA" },
  {
    city: "Damascus",
    state: "MD",
  },
  { city: "Dana Point", state: "CA" },
  { city: "Danbury", state: "CT" },
  {
    city: "Dania Beach",
    state: "FL",
  },
  { city: "Danvers", state: "MA" },
  { city: "Danville", state: "CA" },
  {
    city: "Danville",
    state: "KY",
  },
  { city: "Danville", state: "IN" },
  { city: "Danville", state: "IL" },
  {
    city: "Danville",
    state: "VA",
  },
  { city: "Daphne", state: "AL" },
  { city: "Darby", state: "PA" },
  {
    city: "Darby Township",
    state: "PA",
  },
  { city: "Darien", state: "CT" },
  { city: "Darien", state: "IL" },
  {
    city: "Darlington",
    state: "SC",
  },
  { city: "Darnestown", state: "MD" },
  { city: "Dartmouth", state: "MA" },
  {
    city: "Davenport",
    state: "IA",
  },
  { city: "Davidson", state: "NC" },
  { city: "Davie", state: "FL" },
  {
    city: "Davis",
    state: "CA",
  },
  { city: "Dayton", state: "OH" },
  { city: "Dayton", state: "NJ" },
  {
    city: "Dayton",
    state: "TN",
  },
  { city: "Daytona Beach", state: "FL" },
  { city: "De Bary", state: "FL" },
  {
    city: "De Land",
    state: "FL",
  },
  { city: "De Pere", state: "WI" },
  { city: "De Ridder", state: "LA" },
  {
    city: "De Soto",
    state: "MO",
  },
  { city: "De Witt", state: "NY" },
  { city: "Dearborn", state: "MI" },
  {
    city: "Dearborn Heights",
    state: "MI",
  },
  { city: "Decatur", state: "IN" },
  { city: "Decatur", state: "IL" },
  {
    city: "Decatur",
    state: "GA",
  },
  { city: "Decatur", state: "AL" },
  { city: "Decorah", state: "IA" },
  {
    city: "Dedham",
    state: "MA",
  },
  { city: "Deer Park", state: "NY" },
  { city: "Deer Park", state: "TX" },
  {
    city: "Deerfield",
    state: "IL",
  },
  { city: "Deerfield Beach", state: "FL" },
  { city: "Deerpark", state: "NY" },
  {
    city: "Defiance",
    state: "OH",
  },
  { city: "DeForest", state: "WI" },
  { city: "DeKalb", state: "IL" },
  {
    city: "Del Aire",
    state: "CA",
  },
  { city: "Del City", state: "OK" },
  { city: "Del Rio", state: "TX" },
  {
    city: "Delafield",
    state: "WI",
  },
  { city: "Delano", state: "CA" },
  { city: "Delavan", state: "WI" },
  {
    city: "Delaware",
    state: "OH",
  },
  { city: "Delhi", state: "CA" },
  { city: "Delmar", state: "NY" },
  {
    city: "Delphos",
    state: "OH",
  },
  { city: "Delray Beach", state: "FL" },
  { city: "Delta", state: "CO" },
  {
    city: "Deltona",
    state: "FL",
  },
  { city: "Deming", state: "NM" },
  { city: "Demopolis", state: "AL" },
  {
    city: "Denham Springs",
    state: "LA",
  },
  { city: "Denison", state: "IA" },
  { city: "Denison", state: "TX" },
  {
    city: "Dennis",
    state: "MA",
  },
  { city: "Dent", state: "OH" },
  { city: "Denton", state: "TX" },
  {
    city: "Dentsville",
    state: "SC",
  },
  { city: "Denver", state: "CO" },
  { city: "Depew", state: "NY" },
  {
    city: "Derby",
    state: "CO",
  },
  { city: "Derby", state: "CT" },
  { city: "Derby", state: "KS" },
  {
    city: "Derry",
    state: "NH",
  },
  { city: "Des Moines", state: "IA" },
  { city: "Des Moines", state: "WA" },
  {
    city: "Des Peres",
    state: "MO",
  },
  { city: "Des Plaines", state: "IL" },
  { city: "Desert Hot Springs", state: "CA" },
  {
    city: "DeSoto",
    state: "TX",
  },
  { city: "Destin", state: "FL" },
  { city: "Destrehan", state: "LA" },
  {
    city: "Detroit",
    state: "MI",
  },
  { city: "Detroit Lakes", state: "MN" },
  { city: "Devils Lake", state: "ND" },
  {
    city: "Dewey-Humboldt",
    state: "AZ",
  },
  { city: "Dexter", state: "MO" },
  { city: "Diamond Bar", state: "CA" },
  {
    city: "Dickinson",
    state: "ND",
  },
  { city: "Dickinson", state: "TX" },
  { city: "Dickson", state: "TN" },
  {
    city: "Dickson City",
    state: "PA",
  },
  { city: "Dighton", state: "MA" },
  { city: "Dillon", state: "SC" },
  {
    city: "Dinuba",
    state: "CA",
  },
  { city: "Discovery Bay", state: "CA" },
  { city: "Dishman", state: "WA" },
  {
    city: "Dix Hills",
    state: "NY",
  },
  { city: "Dixon", state: "CA" },
  { city: "Dixon", state: "IL" },
  {
    city: "Dobbs Ferry",
    state: "NY",
  },
  { city: "Dock Junction", state: "GA" },
  { city: "Doctor Phillips", state: "FL" },
  {
    city: "Dodge City",
    state: "KS",
  },
  { city: "Dolton", state: "IL" },
  { city: "Donaldsonville", state: "LA" },
  {
    city: "Donna",
    state: "TX",
  },
  { city: "Doral", state: "FL" },
  { city: "Doraville", state: "GA" },
  {
    city: "Dormont",
    state: "PA",
  },
  { city: "Dothan", state: "AL" },
  { city: "Douglas", state: "AZ" },
  {
    city: "Douglas",
    state: "GA",
  },
  { city: "Douglas", state: "MA" },
  { city: "Douglasville", state: "GA" },
  {
    city: "Dover",
    state: "DE",
  },
  { city: "Dover", state: "NH" },
  { city: "Dover", state: "NY" },
  {
    city: "Dover",
    state: "NJ",
  },
  { city: "Dover", state: "OH" },
  { city: "Dowagiac", state: "MI" },
  {
    city: "Downers Grove",
    state: "IL",
  },
  { city: "Downey", state: "CA" },
  { city: "Downingtown", state: "PA" },
  {
    city: "Doylestown",
    state: "PA",
  },
  { city: "Dracut", state: "MA" },
  { city: "Draper", state: "UT" },
  {
    city: "Drexel Heights",
    state: "AZ",
  },
  { city: "Drexel Hill", state: "PA" },
  { city: "Druid Hills", state: "GA" },
  {
    city: "Dry Run",
    state: "OH",
  },
  { city: "Dryden", state: "NY" },
  { city: "Du Quoin", state: "IL" },
  {
    city: "Duarte",
    state: "CA",
  },
  { city: "Dublin", state: "CA" },
  { city: "Dublin", state: "GA" },
  {
    city: "Dublin",
    state: "OH",
  },
  { city: "DuBois", state: "PA" },
  { city: "Dubuque", state: "IA" },
  {
    city: "Dudley",
    state: "MA",
  },
  { city: "Duluth", state: "MN" },
  { city: "Duluth", state: "GA" },
  {
    city: "Dumas",
    state: "TX",
  },
  { city: "Dumbarton", state: "VA" },
  { city: "Dumont", state: "NJ" },
  {
    city: "Dunbar",
    state: "WV",
  },
  { city: "Duncan", state: "OK" },
  { city: "Duncanville", state: "TX" },
  {
    city: "Dundalk",
    state: "MD",
  },
  { city: "Dunedin", state: "FL" },
  { city: "Dunellen", state: "NJ" },
  {
    city: "Dunkirk",
    state: "NY",
  },
  { city: "Dunmore", state: "PA" },
  { city: "Dunn", state: "NC" },
  {
    city: "Dunn Loring",
    state: "VA",
  },
  { city: "Dunwoody", state: "GA" },
  { city: "Duquesne", state: "PA" },
  {
    city: "Durango",
    state: "CO",
  },
  { city: "Durant", state: "OK" },
  { city: "Durham", state: "CT" },
  {
    city: "Durham",
    state: "NC",
  },
  { city: "Durham", state: "NH" },
  { city: "Duxbury", state: "MA" },
  {
    city: "Dyer",
    state: "IN",
  },
  { city: "Dyersburg", state: "TN" },
  { city: "Eagan", state: "MN" },
  {
    city: "Eagle",
    state: "ID",
  },
  { city: "Eagle Mountain", state: "TX" },
  { city: "Eagle Pass", state: "TX" },
  {
    city: "Earlimart",
    state: "CA",
  },
  { city: "Easley", state: "SC" },
  { city: "East Alton", state: "IL" },
  {
    city: "East Aurora",
    state: "NY",
  },
  { city: "East Bethel", state: "MN" },
  { city: "East Brainerd", state: "TN" },
  {
    city: "East Bridgewater",
    state: "MA",
  },
  { city: "East Brunswick", state: "NJ" },
  { city: "East Chicago", state: "IN" },
  {
    city: "East Cleveland",
    state: "OH",
  },
  { city: "East Compton", state: "CA" },
  { city: "East Falmouth", state: "MA" },
  {
    city: "East Fishkill",
    state: "NY",
  },
  { city: "East Foothills", state: "CA" },
  {
    city: "East Glenville",
    state: "NY",
  },
  { city: "East Grand Forks", state: "MN" },
  {
    city: "East Grand Rapids",
    state: "MI",
  },
  { city: "East Greenbush", state: "NY" },
  { city: "East Greenwich", state: "RI" },
  {
    city: "East Haddam",
    state: "CT",
  },
  { city: "East Hampton", state: "CT" },
  { city: "East Hampton", state: "NY" },
  {
    city: "East Hartford",
    state: "CT",
  },
  { city: "East Haven", state: "CT" },
  { city: "East Hemet", state: "CA" },
  {
    city: "East Highland Park",
    state: "VA",
  },
  { city: "East Hill-Meridian", state: "WA" },
  { city: "East Hills", state: "NY" },
  {
    city: "East Islip",
    state: "NY",
  },
  { city: "East La Mirada", state: "CA" },
  { city: "East Lake", state: "FL" },
  {
    city: "East Lansing",
    state: "MI",
  },
  { city: "East Liverpool", state: "OH" },
  {
    city: "East Longmeadow",
    state: "MA",
  },
  { city: "East Los Angeles", state: "CA" },
  { city: "East Lyme", state: "CT" },
  {
    city: "East Massapequa",
    state: "NY",
  },
  { city: "East Meadow", state: "NY" },
  { city: "East Millcreek", state: "UT" },
  {
    city: "East Moline",
    state: "IL",
  },
  { city: "East Norriton", state: "PA" },
  { city: "East Northport", state: "NY" },
  {
    city: "East Orange",
    state: "NJ",
  },
  { city: "East Palo Alto", state: "CA" },
  { city: "East Pasadena", state: "CA" },
  {
    city: "East Patchogue",
    state: "NY",
  },
  { city: "East Peoria", state: "IL" },
  { city: "East Perrine", state: "FL" },
  {
    city: "East Point",
    state: "GA",
  },
  { city: "East Porterville", state: "CA" },
  {
    city: "East Providence",
    state: "RI",
  },
  { city: "East Renton Highlands", state: "WA" },
  {
    city: "East Ridge",
    state: "TN",
  },
  { city: "East Riverdale", state: "MD" },
  { city: "East Rochester", state: "NY" },
  {
    city: "East Rockaway",
    state: "NY",
  },
  { city: "East Rutherford", state: "NJ" },
  {
    city: "East San Gabriel",
    state: "CA",
  },
  { city: "East St. Louis", state: "IL" },
  {
    city: "East Stroudsburg",
    state: "PA",
  },
  { city: "East Wenatchee Bench", state: "WA" },
  { city: "East Windsor", state: "CT" },
  {
    city: "East York",
    state: "PA",
  },
  { city: "Eastchester", state: "NY" },
  { city: "Easthampton", state: "MA" },
  {
    city: "Eastlake",
    state: "OH",
  },
  { city: "Easton", state: "PA" },
  { city: "Easton", state: "MD" },
  {
    city: "Easton",
    state: "MA",
  },
  { city: "Easton", state: "CT" },
  { city: "Eastpointe", state: "MI" },
  {
    city: "Eastwood",
    state: "MI",
  },
  { city: "Eaton", state: "OH" },
  { city: "Eatonton", state: "GA" },
  {
    city: "Eatontown",
    state: "NJ",
  },
  { city: "Eau Claire", state: "WI" },
  { city: "Echelon", state: "NJ" },
  {
    city: "Economy",
    state: "PA",
  },
  { city: "Ecorse", state: "MI" },
  { city: "Eden", state: "NY" },
  {
    city: "Eden",
    state: "NC",
  },
  { city: "Eden Isle", state: "LA" },
  { city: "Eden Prairie", state: "MN" },
  {
    city: "Edgemere",
    state: "MD",
  },
  { city: "Edgewater", state: "NJ" },
  { city: "Edgewater", state: "FL" },
  {
    city: "Edgewood",
    state: "KY",
  },
  { city: "Edgewood", state: "MD" },
  { city: "Edgewood", state: "WA" },
  {
    city: "Edina",
    state: "MN",
  },
  { city: "Edinboro", state: "PA" },
  { city: "Edinburg", state: "TX" },
  {
    city: "Edison",
    state: "NJ",
  },
  { city: "Edmond", state: "OK" },
  { city: "Edmonds", state: "WA" },
  {
    city: "Edwards",
    state: "CO",
  },
  { city: "Edwardsville", state: "IL" },
  { city: "Effingham", state: "IL" },
  {
    city: "Eglin AFB",
    state: "FL",
  },
  { city: "Egypt Lake-Leto", state: "FL" },
  { city: "Eidson Road", state: "TX" },
  {
    city: "El Cajon",
    state: "CA",
  },
  { city: "El Campo", state: "TX" },
  { city: "El Centro", state: "CA" },
  {
    city: "El Cerrito",
    state: "CA",
  },
  { city: "El Dorado", state: "AR" },
  { city: "El Dorado", state: "KS" },
  {
    city: "El Dorado Hills",
    state: "CA",
  },
  { city: "El Mirage", state: "AZ" },
  { city: "El Monte", state: "CA" },
  {
    city: "El Paso",
    state: "TX",
  },
  { city: "El Paso de Robles", state: "CA" },
  { city: "El Reno", state: "OK" },
  {
    city: "El Rio",
    state: "CA",
  },
  { city: "El Segundo", state: "CA" },
  { city: "El Sobrante", state: "CA" },
  {
    city: "Elbridge",
    state: "NY",
  },
  { city: "Eldersburg", state: "MD" },
  { city: "Elfers", state: "FL" },
  {
    city: "Elgin",
    state: "IL",
  },
  { city: "Elizabeth", state: "NJ" },
  { city: "Elizabeth City", state: "NC" },
  {
    city: "Elizabethton",
    state: "TN",
  },
  { city: "Elizabethtown", state: "PA" },
  { city: "Elizabethtown", state: "KY" },
  {
    city: "Elk City",
    state: "OK",
  },
  { city: "Elk Grove", state: "CA" },
  { city: "Elk Grove Village", state: "IL" },
  {
    city: "Elk Plain",
    state: "WA",
  },
  { city: "Elk River", state: "MN" },
  { city: "Elkhart", state: "IN" },
  {
    city: "Elkhorn",
    state: "NE",
  },
  { city: "Elkhorn", state: "WI" },
  { city: "Elkins", state: "WV" },
  {
    city: "Elko",
    state: "NV",
  },
  { city: "Elkridge", state: "MD" },
  { city: "Elkton", state: "MD" },
  {
    city: "Ellensburg",
    state: "WA",
  },
  { city: "Ellicott", state: "NY" },
  { city: "Ellicott City", state: "MD" },
  {
    city: "Ellington",
    state: "CT",
  },
  { city: "Ellisville", state: "MO" },
  { city: "Ellsworth", state: "ME" },
  {
    city: "Ellwood City",
    state: "PA",
  },
  { city: "Elm Grove", state: "WI" },
  { city: "Elma", state: "NY" },
  {
    city: "Elmhurst",
    state: "IL",
  },
  { city: "Elmira", state: "NY" },
  { city: "Elmont", state: "NY" },
  {
    city: "Elmwood Park",
    state: "NJ",
  },
  { city: "Elmwood Park", state: "IL" },
  { city: "Elon College", state: "NC" },
  {
    city: "Eloy",
    state: "AZ",
  },
  { city: "Elsmere", state: "KY" },
  { city: "Elwood", state: "IN" },
  {
    city: "Elwood",
    state: "NY",
  },
  { city: "Elyria", state: "OH" },
  { city: "Emerson", state: "NJ" },
  {
    city: "Emeryville",
    state: "CA",
  },
  { city: "Emmaus", state: "PA" },
  { city: "Emporia", state: "KS" },
  {
    city: "Encinitas",
    state: "CA",
  },
  { city: "Endicott", state: "NY" },
  { city: "Endwell", state: "NY" },
  {
    city: "Enfield",
    state: "CT",
  },
  { city: "Englewood", state: "CO" },
  { city: "Englewood", state: "FL" },
  {
    city: "Englewood",
    state: "NJ",
  },
  { city: "Englewood", state: "OH" },
  { city: "Enid", state: "OK" },
  {
    city: "Ennis",
    state: "TX",
  },
  { city: "Ensley", state: "FL" },
  { city: "Enterprise", state: "AL" },
  {
    city: "Enterprise",
    state: "NV",
  },
  { city: "Enumclaw", state: "WA" },
  { city: "Ephrata", state: "WA" },
  {
    city: "Ephrata",
    state: "PA",
  },
  { city: "Erie", state: "PA" },
  { city: "Erie", state: "CO" },
  {
    city: "Erlanger",
    state: "KY",
  },
  { city: "Erlton-Ellisburg", state: "NJ" },
  { city: "Erwin", state: "NY" },
  {
    city: "Escanaba",
    state: "MI",
  },
  { city: "Escondido", state: "CA" },
  { city: "Esopus", state: "NY" },
  {
    city: "Espanola",
    state: "NM",
  },
  { city: "Essex", state: "MD" },
  { city: "Essex", state: "CT" },
  {
    city: "Essex",
    state: "VT",
  },
  { city: "Essex Junction", state: "VT" },
  { city: "Estelle", state: "LA" },
  {
    city: "Estero",
    state: "FL",
  },
  { city: "Estherville", state: "IA" },
  { city: "Euclid", state: "OH" },
  {
    city: "Eufaula",
    state: "AL",
  },
  { city: "Eugene", state: "OR" },
  { city: "Euless", state: "TX" },
  {
    city: "Eunice",
    state: "LA",
  },
  { city: "Eureka", state: "CA" },
  { city: "Eureka", state: "MO" },
  {
    city: "Eustis",
    state: "FL",
  },
  { city: "Evans", state: "GA" },
  { city: "Evans", state: "CO" },
  {
    city: "Evans",
    state: "NY",
  },
  { city: "Evanston", state: "IL" },
  { city: "Evanston", state: "WY" },
  {
    city: "Evansville",
    state: "IN",
  },
  { city: "Everett", state: "MA" },
  { city: "Everett", state: "WA" },
  {
    city: "Evergreen",
    state: "MT",
  },
  { city: "Evergreen", state: "CO" },
  { city: "Evergreen Park", state: "IL" },
  {
    city: "Ewa Beach",
    state: "HI",
  },
  { city: "Ewing", state: "NJ" },
  { city: "Excelsior Springs", state: "MO" },
  {
    city: "Exeter",
    state: "NH",
  },
  { city: "Exeter", state: "CA" },
  { city: "Exeter", state: "RI" },
  {
    city: "Fabens",
    state: "TX",
  },
  { city: "Fair Lawn", state: "NJ" },
  { city: "Fair Oaks", state: "CA" },
  {
    city: "Fair Oaks",
    state: "GA",
  },
  { city: "Fair Plain", state: "MI" },
  { city: "Fairbanks", state: "AK" },
  {
    city: "Fairborn",
    state: "OH",
  },
  { city: "Fairdale", state: "KY" },
  { city: "Fairfax", state: "CA" },
  {
    city: "Fairfax",
    state: "VA",
  },
  { city: "Fairfield", state: "OH" },
  { city: "Fairfield", state: "NJ" },
  {
    city: "Fairfield",
    state: "CA",
  },
  { city: "Fairfield", state: "AL" },
  { city: "Fairfield", state: "CT" },
  {
    city: "Fairfield",
    state: "IA",
  },
  { city: "Fairfield", state: "ME" },
  { city: "Fairhaven", state: "MA" },
  {
    city: "Fairhope",
    state: "AL",
  },
  { city: "Fairland", state: "MD" },
  { city: "Fairlawn", state: "OH" },
  {
    city: "Fairless Hills",
    state: "PA",
  },
  { city: "Fairmont", state: "WV" },
  { city: "Fairmont", state: "MN" },
  {
    city: "Fairmount",
    state: "NY",
  },
  { city: "Fairview", state: "OR" },
  { city: "Fairview", state: "NJ" },
  {
    city: "Fairview",
    state: "CA",
  },
  { city: "Fairview", state: "GA" },
  { city: "Fairview Heights", state: "IL" },
  {
    city: "Fairview Park",
    state: "OH",
  },
  { city: "Fairview Shores", state: "FL" },
  { city: "Fairwood", state: "WA" },
  {
    city: "Fall River",
    state: "MA",
  },
  { city: "Fallbrook", state: "CA" },
  { city: "Fallon", state: "NV" },
  {
    city: "Falls Church",
    state: "VA",
  },
  { city: "Fallsburg", state: "NY" },
  { city: "Fallston", state: "MD" },
  {
    city: "Falmouth",
    state: "ME",
  },
  { city: "Falmouth", state: "MA" },
  { city: "Fanwood", state: "NJ" },
  {
    city: "Fargo",
    state: "ND",
  },
  { city: "Faribault", state: "MN" },
  { city: "Farmers Branch", state: "TX" },
  {
    city: "Farmersville",
    state: "CA",
  },
  { city: "Farmingdale", state: "NY" },
  { city: "Farmington", state: "NM" },
  {
    city: "Farmington",
    state: "NY",
  },
  { city: "Farmington", state: "UT" },
  { city: "Farmington", state: "MN" },
  {
    city: "Farmington",
    state: "MO",
  },
  { city: "Farmington", state: "ME" },
  { city: "Farmington", state: "MI" },
  {
    city: "Farmington",
    state: "CT",
  },
  { city: "Farmington Hills", state: "MI" },
  { city: "Farmingville", state: "NY" },
  {
    city: "Farmville",
    state: "VA",
  },
  { city: "Farragut", state: "TN" },
  { city: "Farrell", state: "PA" },
  {
    city: "Fayetteville",
    state: "NC",
  },
  { city: "Fayetteville", state: "TN" },
  { city: "Fayetteville", state: "AR" },
  {
    city: "Fayetteville",
    state: "GA",
  },
  { city: "Feasterville-Trevose", state: "PA" },
  {
    city: "Federal Heights",
    state: "CO",
  },
  { city: "Federal Way", state: "WA" },
  { city: "Fenton", state: "NY" },
  {
    city: "Fenton",
    state: "MI",
  },
  { city: "Fergus Falls", state: "MN" },
  { city: "Ferguson", state: "MO" },
  {
    city: "Fern Creek",
    state: "KY",
  },
  { city: "Fern Park", state: "FL" },
  { city: "Fernandina Beach", state: "FL" },
  {
    city: "Ferndale",
    state: "MD",
  },
  { city: "Ferndale", state: "MI" },
  { city: "Ferndale", state: "WA" },
  {
    city: "Fernley",
    state: "NV",
  },
  { city: "Fernway", state: "PA" },
  { city: "Ferry Pass", state: "FL" },
  {
    city: "Festus",
    state: "MO",
  },
  { city: "Fillmore", state: "CA" },
  { city: "Findlay", state: "OH" },
  {
    city: "Finneytown",
    state: "OH",
  },
  { city: "Fishers", state: "IN" },
  { city: "Fishkill", state: "NY" },
  {
    city: "Fitchburg",
    state: "WI",
  },
  { city: "Fitchburg", state: "MA" },
  { city: "Fitzgerald", state: "GA" },
  {
    city: "Five Corners",
    state: "WA",
  },
  { city: "Five Forks", state: "SC" },
  { city: "Flagstaff", state: "AZ" },
  {
    city: "Flat Rock",
    state: "MI",
  },
  { city: "Flatwoods", state: "KY" },
  { city: "Flint", state: "MI" },
  {
    city: "Floral Park",
    state: "NY",
  },
  { city: "Florence", state: "SC" },
  { city: "Florence", state: "OR" },
  {
    city: "Florence",
    state: "KY",
  },
  { city: "Florence", state: "AZ" },
  { city: "Florence", state: "AL" },
  {
    city: "Florence-Graham",
    state: "CA",
  },
  { city: "Florence-Roebling", state: "NJ" },
  { city: "Florham Park", state: "NJ" },
  {
    city: "Florida City",
    state: "FL",
  },
  { city: "Florida Ridge", state: "FL" },
  { city: "Florin", state: "CA" },
  {
    city: "Florissant",
    state: "MO",
  },
  { city: "Flossmoor", state: "IL" },
  { city: "Flower Mound", state: "TX" },
  {
    city: "Flowing Wells",
    state: "AZ",
  },
  { city: "Flushing", state: "MI" },
  { city: "Folcroft", state: "PA" },
  {
    city: "Foley",
    state: "AL",
  },
  { city: "Folsom", state: "CA" },
  { city: "Folsom", state: "PA" },
  {
    city: "Fond du Lac",
    state: "WI",
  },
  { city: "Fontana", state: "CA" },
  { city: "Foothill Farms", state: "CA" },
  {
    city: "Foothill Ranch",
    state: "CA",
  },
  { city: "Fords", state: "NJ" },
  { city: "Forest", state: "VA" },
  {
    city: "Forest Acres",
    state: "SC",
  },
  { city: "Forest City", state: "NC" },
  { city: "Forest City", state: "FL" },
  {
    city: "Forest Glen",
    state: "MD",
  },
  { city: "Forest Grove", state: "OR" },
  { city: "Forest Hill", state: "TX" },
  {
    city: "Forest Hills",
    state: "PA",
  },
  { city: "Forest Hills", state: "MI" },
  { city: "Forest Lake", state: "MN" },
  {
    city: "Forest Park",
    state: "IL",
  },
  { city: "Forest Park", state: "GA" },
  { city: "Forest Park", state: "OH" },
  {
    city: "Forestdale",
    state: "AL",
  },
  { city: "Forestville", state: "MD" },
  { city: "Forestville", state: "OH" },
  {
    city: "Forrest City",
    state: "AR",
  },
  { city: "Fort Ann", state: "NY" },
  { city: "Fort Atkinson", state: "WI" },
  {
    city: "Fort Belvoir",
    state: "VA",
  },
  { city: "Fort Benning South", state: "GA" },
  { city: "Fort Bliss", state: "TX" },
  {
    city: "Fort Bragg",
    state: "NC",
  },
  { city: "Fort Bragg", state: "CA" },
  { city: "Fort Campbell North", state: "KY" },
  {
    city: "Fort Carson",
    state: "CO",
  },
  { city: "Fort Collins", state: "CO" },
  { city: "Fort Dix", state: "NJ" },
  {
    city: "Fort Dodge",
    state: "IA",
  },
  { city: "Fort Drum", state: "NY" },
  { city: "Fort Hood", state: "TX" },
  {
    city: "Fort Hunt",
    state: "VA",
  },
  { city: "Fort Knox", state: "KY" },
  { city: "Fort Lauderdale", state: "FL" },
  {
    city: "Fort Lee",
    state: "NJ",
  },
  { city: "Fort Lee", state: "VA" },
  { city: "Fort Leonard Wood", state: "MO" },
  {
    city: "Fort Lewis",
    state: "WA",
  },
  { city: "Fort Lupton", state: "CO" },
  { city: "Fort Madison", state: "IA" },
  {
    city: "Fort Meade",
    state: "MD",
  },
  { city: "Fort Mill", state: "SC" },
  { city: "Fort Mitchell", state: "KY" },
  {
    city: "Fort Morgan",
    state: "CO",
  },
  { city: "Fort Myers", state: "FL" },
  { city: "Fort Myers Beach", state: "FL" },
  {
    city: "Fort Oglethorpe",
    state: "GA",
  },
  { city: "Fort Payne", state: "AL" },
  { city: "Fort Pierce", state: "FL" },
  {
    city: "Fort Pierce North",
    state: "FL",
  },
  { city: "Fort Polk South", state: "LA" },
  { city: "Fort Riley North", state: "KS" },
  {
    city: "Fort Rucker",
    state: "AL",
  },
  { city: "Fort Salonga", state: "NY" },
  { city: "Fort Scott", state: "KS" },
  {
    city: "Fort Smith",
    state: "AR",
  },
  { city: "Fort Stewart", state: "GA" },
  { city: "Fort Stockton", state: "TX" },
  {
    city: "Fort Thomas",
    state: "KY",
  },
  { city: "Fort Valley", state: "GA" },
  {
    city: "Fort Walton Beach",
    state: "FL",
  },
  { city: "Fort Washington", state: "MD" },
  { city: "Fort Wayne", state: "IN" },
  {
    city: "Fort Worth",
    state: "TX",
  },
  { city: "Fortuna", state: "CA" },
  { city: "Fortuna Foothills", state: "AZ" },
  {
    city: "Foster City",
    state: "CA",
  },
  { city: "Fostoria", state: "OH" },
  { city: "Fountain", state: "CO" },
  {
    city: "Fountain Hills",
    state: "AZ",
  },
  { city: "Fountain Inn", state: "SC" },
  { city: "Fountain Valley", state: "CA" },
  {
    city: "Fountainbleau",
    state: "FL",
  },
  { city: "Four Corners", state: "OR" },
  { city: "Fox Lake", state: "IL" },
  {
    city: "Fox Point",
    state: "WI",
  },
  { city: "Foxborough", state: "MA" },
  { city: "Framingham", state: "MA" },
  {
    city: "Franconia",
    state: "VA",
  },
  { city: "Frankfort", state: "NY" },
  { city: "Frankfort", state: "IN" },
  {
    city: "Frankfort",
    state: "KY",
  },
  { city: "Frankfort", state: "IL" },
  { city: "Frankfort Square", state: "IL" },
  {
    city: "Franklin",
    state: "KY",
  },
  { city: "Franklin", state: "IN" },
  { city: "Franklin", state: "LA" },
  {
    city: "Franklin",
    state: "MA",
  },
  { city: "Franklin", state: "NH" },
  { city: "Franklin", state: "OH" },
  {
    city: "Franklin",
    state: "VA",
  },
  { city: "Franklin", state: "PA" },
  { city: "Franklin", state: "WI" },
  {
    city: "Franklin",
    state: "TN",
  },
  { city: "Franklin Lakes", state: "NJ" },
  { city: "Franklin Park", state: "IL" },
  {
    city: "Franklin Park",
    state: "PA",
  },
  { city: "Franklin Square", state: "NY" },
  { city: "Fraser", state: "MI" },
  {
    city: "Frederick",
    state: "MD",
  },
  { city: "Fredericksburg", state: "VA" },
  { city: "Fredericksburg", state: "TX" },
  {
    city: "Fredonia",
    state: "NY",
  },
  { city: "Freehold", state: "NJ" },
  { city: "Freeport", state: "ME" },
  {
    city: "Freeport",
    state: "IL",
  },
  { city: "Freeport", state: "NY" },
  { city: "Freeport", state: "TX" },
  {
    city: "Freetown",
    state: "MA",
  },
  { city: "Fremont", state: "CA" },
  { city: "Fremont", state: "OH" },
  {
    city: "Fremont",
    state: "NE",
  },
  { city: "Fresno", state: "TX" },
  { city: "Fresno", state: "CA" },
  {
    city: "Fridley",
    state: "MN",
  },
  { city: "Friendly", state: "MD" },
  { city: "Friendswood", state: "TX" },
  {
    city: "Frisco",
    state: "TX",
  },
  { city: "Front Royal", state: "VA" },
  { city: "Frostburg", state: "MD" },
  {
    city: "Fruit Cove",
    state: "FL",
  },
  { city: "Fruita", state: "CO" },
  { city: "Fruitvale", state: "CO" },
  {
    city: "Fruitville",
    state: "FL",
  },
  { city: "Fullerton", state: "CA" },
  { city: "Fullerton", state: "PA" },
  {
    city: "Fulton",
    state: "NY",
  },
  { city: "Fulton", state: "MO" },
  { city: "Fultondale", state: "AL" },
  {
    city: "Fuquay-Varina",
    state: "NC",
  },
  { city: "Gadsden", state: "AL" },
  { city: "Gaffney", state: "SC" },
  {
    city: "Gages Lake",
    state: "IL",
  },
  { city: "Gahanna", state: "OH" },
  { city: "Gainesville", state: "TX" },
  {
    city: "Gainesville",
    state: "GA",
  },
  { city: "Gainesville", state: "FL" },
  { city: "Gaithersburg", state: "MD" },
  {
    city: "Galax",
    state: "VA",
  },
  { city: "Galena Park", state: "TX" },
  { city: "Galesburg", state: "IL" },
  {
    city: "Galion",
    state: "OH",
  },
  { city: "Gallatin", state: "TN" },
  { city: "Galliano", state: "LA" },
  {
    city: "Gallup",
    state: "NM",
  },
  { city: "Galt", state: "CA" },
  { city: "Galveston", state: "TX" },
  {
    city: "Gantt",
    state: "SC",
  },
  { city: "Garden Acres", state: "CA" },
  { city: "Garden City", state: "GA" },
  {
    city: "Garden City",
    state: "KS",
  },
  { city: "Garden City", state: "ID" },
  { city: "Garden City", state: "MI" },
  {
    city: "Garden City",
    state: "SC",
  },
  { city: "Garden City", state: "NY" },
  { city: "Garden City Park", state: "NY" },
  {
    city: "Garden Grove",
    state: "CA",
  },
  { city: "Garden Home-Whitford", state: "OR" },
  { city: "Gardena", state: "CA" },
  {
    city: "Gardendale",
    state: "AL",
  },
  { city: "Gardere", state: "LA" },
  { city: "Gardiner", state: "ME" },
  {
    city: "Gardner",
    state: "KS",
  },
  { city: "Gardner", state: "MA" },
  { city: "Gardnerville Ranchos", state: "NV" },
  {
    city: "Garfield",
    state: "NJ",
  },
  { city: "Garfield Heights", state: "OH" },
  { city: "Garland", state: "TX" },
  {
    city: "Garner",
    state: "NC",
  },
  { city: "Garrison", state: "MD" },
  { city: "Gary", state: "IN" },
  {
    city: "Gastonia",
    state: "NC",
  },
  { city: "Gates", state: "NY" },
  { city: "Gates-North Gates", state: "NY" },
  {
    city: "Gatesville",
    state: "TX",
  },
  { city: "Gautier", state: "MS" },
  { city: "Geddes", state: "NY" },
  {
    city: "Genesee",
    state: "WI",
  },
  { city: "Geneseo", state: "NY" },
  { city: "Geneseo", state: "IL" },
  {
    city: "Geneva",
    state: "IL",
  },
  { city: "Geneva", state: "NY" },
  { city: "Geneva", state: "OH" },
  {
    city: "Georgetown",
    state: "TX",
  },
  { city: "Georgetown", state: "SC" },
  { city: "Georgetown", state: "GA" },
  {
    city: "Georgetown",
    state: "MA",
  },
  { city: "Georgetown", state: "KY" },
  { city: "Georgetown County", state: "SC" },
  {
    city: "Gering",
    state: "NE",
  },
  { city: "German Flatts", state: "NY" },
  { city: "Germantown", state: "TN" },
  {
    city: "Germantown",
    state: "WI",
  },
  { city: "Germantown", state: "MD" },
  { city: "Gettysburg", state: "PA" },
  {
    city: "Gibsonton",
    state: "FL",
  },
  { city: "Gifford", state: "FL" },
  { city: "Gig Harbor", state: "WA" },
  {
    city: "Gilbert",
    state: "AZ",
  },
  { city: "Gilford", state: "NH" },
  { city: "Gillette", state: "WY" },
  {
    city: "Gilroy",
    state: "CA",
  },
  { city: "Girard", state: "OH" },
  { city: "Gladeview", state: "FL" },
  {
    city: "Gladewater",
    state: "TX",
  },
  { city: "Gladstone", state: "OR" },
  { city: "Gladstone", state: "MO" },
  {
    city: "Glasgow",
    state: "KY",
  },
  { city: "Glasgow", state: "DE" },
  { city: "Glassboro", state: "NJ" },
  {
    city: "Glastonbury",
    state: "CT",
  },
  { city: "Glastonbury Center", state: "CT" },
  { city: "Glen Allen", state: "VA" },
  {
    city: "Glen Avon",
    state: "CA",
  },
  { city: "Glen Burnie", state: "MD" },
  { city: "Glen Carbon", state: "IL" },
  {
    city: "Glen Cove",
    state: "NY",
  },
  { city: "Glen Ellyn", state: "IL" },
  { city: "Glen Ridge", state: "NJ" },
  {
    city: "Glen Rock",
    state: "NJ",
  },
  { city: "Glenarden", state: "MD" },
  { city: "Glencoe", state: "IL" },
  {
    city: "Glendale",
    state: "CA",
  },
  { city: "Glendale", state: "AZ" },
  { city: "Glendale", state: "WI" },
  {
    city: "Glendale Heights",
    state: "IL",
  },
  { city: "Glendora", state: "CA" },
  { city: "Glenn Dale", state: "MD" },
  {
    city: "Glenn Heights",
    state: "TX",
  },
  { city: "Glenolden", state: "PA" },
  { city: "Glenpool", state: "OK" },
  {
    city: "Glens Falls",
    state: "NY",
  },
  { city: "Glens Falls North", state: "NY" },
  { city: "Glenside", state: "PA" },
  {
    city: "Glenvar Heights",
    state: "FL",
  },
  { city: "Glenview", state: "IL" },
  { city: "Glenville", state: "NY" },
  {
    city: "Glenwood",
    state: "IL",
  },
  { city: "Glenwood Springs", state: "CO" },
  { city: "Globe", state: "AZ" },
  {
    city: "Glocester",
    state: "RI",
  },
  { city: "Gloucester", state: "MA" },
  { city: "Gloucester City", state: "NJ" },
  {
    city: "Gloucester Point",
    state: "VA",
  },
  { city: "Gloversville", state: "NY" },
  { city: "Godfrey", state: "IL" },
  {
    city: "Goffstown",
    state: "NH",
  },
  { city: "Gold Camp", state: "AZ" },
  { city: "Gold River", state: "CA" },
  {
    city: "Golden",
    state: "CO",
  },
  { city: "Golden Gate", state: "FL" },
  { city: "Golden Glades", state: "FL" },
  {
    city: "Golden Hills",
    state: "CA",
  },
  { city: "Golden Lakes", state: "FL" },
  { city: "Golden Valley", state: "MN" },
  {
    city: "Goldenrod",
    state: "FL",
  },
  { city: "Goldsboro", state: "NC" },
  { city: "Goleta", state: "CA" },
  {
    city: "Gonzales",
    state: "CA",
  },
  { city: "Gonzales", state: "LA" },
  { city: "Gonzales", state: "TX" },
  {
    city: "Gonzalez",
    state: "FL",
  },
  { city: "Goodings Grove", state: "IL" },
  { city: "Goodlettsville", state: "TN" },
  {
    city: "Goodyear",
    state: "AZ",
  },
  { city: "Goose Creek", state: "SC" },
  { city: "Gorham", state: "ME" },
  {
    city: "Goshen",
    state: "IN",
  },
  { city: "Goshen", state: "NY" },
  { city: "Goulds", state: "FL" },
  {
    city: "Gouverneur",
    state: "NY",
  },
  { city: "Grafton", state: "MA" },
  { city: "Grafton", state: "WI" },
  {
    city: "Graham",
    state: "WA",
  },
  { city: "Graham", state: "NC" },
  { city: "Graham", state: "TX" },
  {
    city: "Granby",
    state: "NY",
  },
  { city: "Granby", state: "CT" },
  { city: "Granby", state: "MA" },
  {
    city: "Grand Blanc",
    state: "MI",
  },
  { city: "Grand Chute", state: "WI" },
  { city: "Grand Forks", state: "ND" },
  {
    city: "Grand Haven",
    state: "MI",
  },
  { city: "Grand Island", state: "NE" },
  { city: "Grand Island", state: "NY" },
  {
    city: "Grand Junction",
    state: "CO",
  },
  { city: "Grand Ledge", state: "MI" },
  { city: "Grand Prairie", state: "TX" },
  {
    city: "Grand Rapids",
    state: "WI",
  },
  { city: "Grand Rapids", state: "MI" },
  { city: "Grand Rapids", state: "MN" },
  {
    city: "Grand Terrace",
    state: "CA",
  },
  { city: "Grandview", state: "MO" },
  { city: "Grandview", state: "WA" },
  {
    city: "Grandview Heights",
    state: "OH",
  },
  { city: "Grandville", state: "MI" },
  { city: "Granger", state: "IN" },
  {
    city: "Granite Bay",
    state: "CA",
  },
  { city: "Granite City", state: "IL" },
  { city: "Grants", state: "NM" },
  {
    city: "Grants Pass",
    state: "OR",
  },
  { city: "Grantsville", state: "UT" },
  { city: "Granville", state: "NY" },
  {
    city: "Grapevine",
    state: "TX",
  },
  { city: "Grass Valley", state: "CA" },
  { city: "Gray", state: "ME" },
  {
    city: "Grayslake",
    state: "IL",
  },
  { city: "Great Barrington", state: "MA" },
  { city: "Great Bend", state: "KS" },
  {
    city: "Great Falls",
    state: "MT",
  },
  { city: "Great Falls", state: "VA" },
  { city: "Great Neck", state: "NY" },
  {
    city: "Great Neck Plaza",
    state: "NY",
  },
  { city: "Greater Carrollwood", state: "FL" },
  {
    city: "Greater Landover",
    state: "MD",
  },
  { city: "Greater Northdale", state: "FL" },
  {
    city: "Greater Sun Center",
    state: "FL",
  },
  { city: "Greater Upper Marlboro", state: "MD" },
  { city: "Greatwood", state: "TX" },
  {
    city: "Greece",
    state: "NY",
  },
  { city: "Greeley", state: "CO" },
  { city: "Green", state: "OH" },
  {
    city: "Green",
    state: "OR",
  },
  { city: "Green Bay", state: "WI" },
  { city: "Green Haven", state: "MD" },
  {
    city: "Green Hill",
    state: "TN",
  },
  { city: "Green River", state: "WY" },
  { city: "Green Valley", state: "MD" },
  {
    city: "Green Valley",
    state: "AZ",
  },
  { city: "Greenacres", state: "FL" },
  { city: "Greenbelt", state: "MD" },
  {
    city: "Greenburgh",
    state: "NY",
  },
  { city: "Greencastle", state: "IN" },
  { city: "Greendale", state: "WI" },
  {
    city: "Greeneville",
    state: "TN",
  },
  { city: "Greenfield", state: "WI" },
  { city: "Greenfield", state: "NY" },
  {
    city: "Greenfield",
    state: "IN",
  },
  { city: "Greenfield", state: "MA" },
  { city: "Greenfield", state: "CA" },
  {
    city: "Greenlawn",
    state: "NY",
  },
  { city: "Greensboro", state: "NC" },
  { city: "Greensburg", state: "PA" },
  {
    city: "Greensburg",
    state: "IN",
  },
  { city: "Greentree", state: "NJ" },
  { city: "Greenville", state: "MS" },
  {
    city: "Greenville",
    state: "MI",
  },
  { city: "Greenville", state: "AL" },
  { city: "Greenville", state: "IL" },
  {
    city: "Greenville",
    state: "PA",
  },
  { city: "Greenville", state: "RI" },
  { city: "Greenville", state: "TX" },
  {
    city: "Greenville",
    state: "SC",
  },
  { city: "Greenville", state: "NC" },
  { city: "Greenville", state: "OH" },
  {
    city: "Greenville",
    state: "NY",
  },
  { city: "Greenville", state: "WI" },
  { city: "Greenwich", state: "CT" },
  {
    city: "Greenwood",
    state: "IN",
  },
  { city: "Greenwood", state: "MS" },
  { city: "Greenwood", state: "AR" },
  {
    city: "Greenwood",
    state: "SC",
  },
  { city: "Greenwood Village", state: "CO" },
  { city: "Greer", state: "SC" },
  {
    city: "Grenada",
    state: "MS",
  },
  { city: "Gresham", state: "OR" },
  { city: "Gresham Park", state: "GA" },
  {
    city: "Gretna",
    state: "LA",
  },
  { city: "Griffin", state: "GA" },
  { city: "Griffith", state: "IN" },
  {
    city: "Grinnell",
    state: "IA",
  },
  { city: "Griswold", state: "CT" },
  { city: "Groesbeck", state: "OH" },
  {
    city: "Grosse Ile",
    state: "MI",
  },
  { city: "Grosse Pointe Farms", state: "MI" },
  {
    city: "Grosse Pointe Park",
    state: "MI",
  },
  { city: "Grosse Pointe Woods", state: "MI" },
  { city: "Groton", state: "MA" },
  {
    city: "Groton",
    state: "CT",
  },
  { city: "Grove City", state: "OH" },
  { city: "Grove City", state: "PA" },
  {
    city: "Groveland",
    state: "MA",
  },
  { city: "Grover Beach", state: "CA" },
  { city: "Groves", state: "TX" },
  {
    city: "Groveton",
    state: "VA",
  },
  { city: "Grovetown", state: "GA" },
  { city: "Guilderland", state: "NY" },
  {
    city: "Guilford",
    state: "CT",
  },
  { city: "Gulf Gate Estates", state: "FL" },
  { city: "Gulfport", state: "FL" },
  {
    city: "Gulfport",
    state: "MS",
  },
  { city: "Gunbarrel", state: "CO" },
  { city: "Guntersville", state: "AL" },
  {
    city: "Gurnee",
    state: "IL",
  },
  { city: "Guthrie", state: "OK" },
  { city: "Guttenberg", state: "NJ" },
  {
    city: "Guymon",
    state: "OK",
  },
  { city: "Hacienda Heights", state: "CA" },
  { city: "Hackensack", state: "NJ" },
  {
    city: "Hackettstown",
    state: "NJ",
  },
  { city: "Haddam", state: "CT" },
  { city: "Haddon Heights", state: "NJ" },
  {
    city: "Haddonfield",
    state: "NJ",
  },
  { city: "Hagerstown", state: "MD" },
  { city: "Haiku-Pauwela", state: "HI" },
  {
    city: "Hailey",
    state: "ID",
  },
  { city: "Haines City", state: "FL" },
  { city: "Halawa", state: "HI" },
  {
    city: "Haledon",
    state: "NJ",
  },
  { city: "Hales Corners", state: "WI" },
  { city: "Half Moon", state: "NC" },
  {
    city: "Half Moon Bay",
    state: "CA",
  },
  { city: "Halfmoon", state: "NY" },
  { city: "Halfway", state: "MD" },
  {
    city: "Halifax",
    state: "MA",
  },
  { city: "Hallandale", state: "FL" },
  { city: "Haltom City", state: "TX" },
  {
    city: "Ham Lake",
    state: "MN",
  },
  { city: "Hamburg", state: "NY" },
  { city: "Hamden", state: "CT" },
  {
    city: "Hamilton",
    state: "AL",
  },
  { city: "Hamilton", state: "MA" },
  { city: "Hamilton", state: "OH" },
  {
    city: "Hamlet",
    state: "NC",
  },
  { city: "Hamlin", state: "NY" },
  { city: "Hammond", state: "LA" },
  {
    city: "Hammond",
    state: "IN",
  },
  { city: "Hammonton", state: "NJ" },
  { city: "Hampden", state: "ME" },
  {
    city: "Hampstead",
    state: "NH",
  },
  { city: "Hampton", state: "NH" },
  { city: "Hampton", state: "VA" },
  {
    city: "Hampton Bays",
    state: "NY",
  },
  { city: "Hampton Township", state: "PA" },
  {
    city: "Hamptons at Boca Raton",
    state: "FL",
  },
  { city: "Hamtramck", state: "MI" },
  { city: "Hanahan", state: "SC" },
  {
    city: "Hanford",
    state: "CA",
  },
  { city: "Hannibal", state: "MO" },
  { city: "Hanover", state: "NH" },
  {
    city: "Hanover",
    state: "MA",
  },
  { city: "Hanover", state: "PA" },
  { city: "Hanover", state: "NY" },
  {
    city: "Hanover Park",
    state: "IL",
  },
  { city: "Hanson", state: "MA" },
  { city: "Hapeville", state: "GA" },
  {
    city: "Harahan",
    state: "LA",
  },
  { city: "Harker Heights", state: "TX" },
  { city: "Harleysville", state: "PA" },
  {
    city: "Harlingen",
    state: "TX",
  },
  { city: "Harper Woods", state: "MI" },
  { city: "Harriman", state: "TN" },
  {
    city: "Harrisburg",
    state: "PA",
  },
  { city: "Harrisburg", state: "IL" },
  { city: "Harrison", state: "AR" },
  {
    city: "Harrison",
    state: "MI",
  },
  { city: "Harrison", state: "NJ" },
  { city: "Harrison", state: "NY" },
  {
    city: "Harrison",
    state: "OH",
  },
  { city: "Harrison", state: "TN" },
  { city: "Harrison Township", state: "PA" },
  {
    city: "Harrisonburg",
    state: "VA",
  },
  { city: "Harrisonville", state: "MO" },
  { city: "Harrodsburg", state: "KY" },
  {
    city: "Hartford",
    state: "CT",
  },
  { city: "Hartford", state: "WI" },
  { city: "Hartford", state: "VT" },
  {
    city: "Hartford City",
    state: "IN",
  },
  { city: "Hartland", state: "WI" },
  { city: "Hartsdale", state: "NY" },
  {
    city: "Hartselle",
    state: "AL",
  },
  { city: "Hartsville", state: "SC" },
  { city: "Harvard", state: "IL" },
  {
    city: "Harvey",
    state: "IL",
  },
  { city: "Harvey", state: "LA" },
  { city: "Harwich", state: "MA" },
  {
    city: "Harwood Heights",
    state: "IL",
  },
  { city: "Hasbrouck Heights", state: "NJ" },
  { city: "Haslett", state: "MI" },
  {
    city: "Hastings",
    state: "MI",
  },
  { city: "Hastings", state: "NE" },
  { city: "Hastings", state: "MN" },
  {
    city: "Hastings",
    state: "NY",
  },
  { city: "Hastings-on-Hudson", state: "NY" },
  { city: "Hatboro", state: "PA" },
  {
    city: "Hattiesburg",
    state: "MS",
  },
  { city: "Hauppauge", state: "NY" },
  { city: "Havelock", state: "NC" },
  {
    city: "Haverhill",
    state: "MA",
  },
  { city: "Haverstraw", state: "NY" },
  { city: "Havre", state: "MT" },
  {
    city: "Havre de Grace",
    state: "MD",
  },
  { city: "Hawaiian Gardens", state: "CA" },
  {
    city: "Hawaiian Paradise Park",
    state: "HI",
  },
  { city: "Hawthorn Woods", state: "IL" },
  { city: "Hawthorne", state: "CA" },
  {
    city: "Hawthorne",
    state: "NJ",
  },
  { city: "Hayden", state: "ID" },
  { city: "Hayesville", state: "OR" },
  {
    city: "Hays",
    state: "KS",
  },
  { city: "Haysville", state: "KS" },
  { city: "Hayward", state: "CA" },
  {
    city: "Hazel Crest",
    state: "IL",
  },
  { city: "Hazel Dell North", state: "WA" },
  { city: "Hazel Dell South", state: "WA" },
  {
    city: "Hazel Park",
    state: "MI",
  },
  { city: "Hazelwood", state: "MO" },
  { city: "Hazleton", state: "PA" },
  {
    city: "Healdsburg",
    state: "CA",
  },
  { city: "Heath", state: "OH" },
  { city: "Heber", state: "UT" },
  {
    city: "Heber Springs",
    state: "AR",
  },
  { city: "Hebron", state: "CT" },
  { city: "Helena", state: "AR" },
  {
    city: "Helena",
    state: "AL",
  },
  { city: "Helena", state: "MT" },
  {
    city: "Helena Valley Southeast",
    state: "MT",
  },
  { city: "Helena Valley West Central", state: "MT" },
  { city: "Hemet", state: "CA" },
  {
    city: "Hempstead",
    state: "NY",
  },
  { city: "Henderson", state: "NC" },
  { city: "Henderson", state: "TX" },
  {
    city: "Henderson",
    state: "KY",
  },
  { city: "Henderson", state: "NV" },
  { city: "Hendersonville", state: "TN" },
  {
    city: "Hendersonville",
    state: "NC",
  },
  { city: "Henrietta", state: "NY" },
  { city: "Henryetta", state: "OK" },
  {
    city: "Hercules",
    state: "CA",
  },
  { city: "Hereford", state: "TX" },
  { city: "Herkimer", state: "NY" },
  {
    city: "Hermantown",
    state: "MN",
  },
  { city: "Hermiston", state: "OR" },
  { city: "Hermitage", state: "PA" },
  {
    city: "Hermosa Beach",
    state: "CA",
  },
  { city: "Hernando", state: "FL" },
  { city: "Hernando", state: "MS" },
  {
    city: "Herndon",
    state: "VA",
  },
  { city: "Herrin", state: "IL" },
  { city: "Hershey", state: "PA" },
  {
    city: "Hesperia",
    state: "CA",
  },
  { city: "Hewitt", state: "TX" },
  { city: "Hewlett", state: "NY" },
  {
    city: "Hialeah",
    state: "FL",
  },
  { city: "Hialeah Gardens", state: "FL" },
  { city: "Hiawatha", state: "IA" },
  {
    city: "Hibbing",
    state: "MN",
  },
  { city: "Hickory", state: "NC" },
  { city: "Hickory Hills", state: "IL" },
  {
    city: "Hicksville",
    state: "NY",
  },
  { city: "Hidalgo", state: "TX" },
  { city: "High Point", state: "NC" },
  {
    city: "Highland",
    state: "UT",
  },
  { city: "Highland", state: "IL" },
  { city: "Highland", state: "IN" },
  {
    city: "Highland",
    state: "CA",
  },
  { city: "Highland Heights", state: "KY" },
  {
    city: "Highland Heights",
    state: "OH",
  },
  { city: "Highland Park", state: "TX" },
  { city: "Highland Park", state: "IL" },
  {
    city: "Highland Park",
    state: "NJ",
  },
  { city: "Highland Park", state: "MI" },
  {
    city: "Highland Springs",
    state: "VA",
  },
  { city: "Highland Village", state: "TX" },
  { city: "Highlands", state: "TX" },
  {
    city: "Highlands",
    state: "NY",
  },
  { city: "Highlands Ranch", state: "CO" },
  { city: "Highview", state: "KY" },
  {
    city: "Hillcrest",
    state: "NY",
  },
  { city: "Hillcrest Heights", state: "MD" },
  { city: "Hilliard", state: "OH" },
  {
    city: "Hillsboro",
    state: "OH",
  },
  { city: "Hillsboro", state: "OR" },
  { city: "Hillsboro", state: "TX" },
  {
    city: "Hillsborough",
    state: "CA",
  },
  { city: "Hillsdale", state: "MI" },
  { city: "Hillsdale", state: "NJ" },
  {
    city: "Hillside",
    state: "NJ",
  },
  { city: "Hillside", state: "IL" },
  { city: "Hillview", state: "KY" },
  {
    city: "Hilo",
    state: "HI",
  },
  { city: "Hilton Head Island", state: "SC" },
  { city: "Hinesville", state: "GA" },
  {
    city: "Hingham",
    state: "MA",
  },
  { city: "Hinsdale", state: "IL" },
  { city: "Hitchcock", state: "TX" },
  {
    city: "Hobart",
    state: "WA",
  },
  { city: "Hobart", state: "IN" },
  { city: "Hobbs", state: "NM" },
  {
    city: "Hobe Sound",
    state: "FL",
  },
  { city: "Hoboken", state: "NJ" },
  { city: "Hockessin", state: "DE" },
  {
    city: "Hoffman Estates",
    state: "IL",
  },
  { city: "Holbrook", state: "MA" },
  { city: "Holbrook", state: "NY" },
  {
    city: "Holden",
    state: "MA",
  },
  { city: "Holiday", state: "FL" },
  { city: "Holiday City-Berkeley", state: "NJ" },
  {
    city: "Holladay",
    state: "UT",
  },
  { city: "Holland", state: "MI" },
  { city: "Hollins", state: "VA" },
  {
    city: "Hollis",
    state: "NH",
  },
  { city: "Hollister", state: "CA" },
  { city: "Holliston", state: "MA" },
  {
    city: "Holly",
    state: "MI",
  },
  { city: "Holly Hill", state: "FL" },
  { city: "Holly Springs", state: "MS" },
  {
    city: "Holly Springs",
    state: "NC",
  },
  { city: "Hollywood", state: "FL" },
  { city: "Holmen", state: "WI" },
  {
    city: "Holt",
    state: "MI",
  },
  { city: "Holtsville", state: "NY" },
  { city: "Holualoa", state: "HI" },
  {
    city: "Holyoke",
    state: "MA",
  },
  { city: "Home Gardens", state: "CA" },
  { city: "Homeacre-Lyndora", state: "PA" },
  {
    city: "Homeland Park",
    state: "SC",
  },
  { city: "Homer", state: "NY" },
  { city: "Homestead", state: "FL" },
  {
    city: "Homestead Meadows South",
    state: "TX",
  },
  { city: "Homewood", state: "AL" },
  { city: "Homewood", state: "IL" },
  {
    city: "Homosassa Springs",
    state: "FL",
  },
  { city: "Hondo", state: "TX" },
  { city: "Honolulu", state: "HI" },
  {
    city: "Hooksett",
    state: "NH",
  },
  { city: "Hoosick", state: "NY" },
  { city: "Hoover", state: "AL" },
  {
    city: "Hopatcong",
    state: "NJ",
  },
  { city: "Hope", state: "AR" },
  { city: "Hope Mills", state: "NC" },
  {
    city: "Hopewell",
    state: "VA",
  },
  { city: "Hopkins", state: "MN" },
  { city: "Hopkinsville", state: "KY" },
  {
    city: "Hopkinton",
    state: "MA",
  },
  { city: "Hopkinton", state: "RI" },
  { city: "Hoquiam", state: "WA" },
  {
    city: "Horn Lake",
    state: "MS",
  },
  { city: "Hornell", state: "NY" },
  { city: "Horseheads", state: "NY" },
  {
    city: "Horsham",
    state: "PA",
  },
  { city: "Hot Springs", state: "AR" },
  { city: "Hot Springs Village", state: "AR" },
  {
    city: "Houghton",
    state: "MI",
  },
  { city: "Houlton", state: "ME" },
  { city: "Houma", state: "LA" },
  {
    city: "Houston",
    state: "TX",
  },
  { city: "Howard", state: "WI" },
  { city: "Howell", state: "MI" },
  {
    city: "Howland Center",
    state: "OH",
  },
  { city: "Hubbard", state: "OH" },
  { city: "Huber Heights", state: "OH" },
  {
    city: "Hudson",
    state: "OH",
  },
  { city: "Hudson", state: "NY" },
  { city: "Hudson", state: "WI" },
  {
    city: "Hudson",
    state: "MA",
  },
  { city: "Hudson", state: "NH" },
  { city: "Hudson", state: "FL" },
  {
    city: "Hudson Falls",
    state: "NY",
  },
  { city: "Hudsonville", state: "MI" },
  { city: "Hueytown", state: "AL" },
  {
    city: "Hugo",
    state: "MN",
  },
  { city: "Hull", state: "MA" },
  { city: "Humble", state: "TX" },
  {
    city: "Humboldt",
    state: "TN",
  },
  { city: "Hunters Creek", state: "FL" },
  { city: "Huntersville", state: "NC" },
  {
    city: "Huntingdon",
    state: "PA",
  },
  { city: "Huntington", state: "NY" },
  { city: "Huntington", state: "VA" },
  {
    city: "Huntington",
    state: "WV",
  },
  { city: "Huntington", state: "IN" },
  { city: "Huntington Beach", state: "CA" },
  {
    city: "Huntington Park",
    state: "CA",
  },
  { city: "Huntington Station", state: "NY" },
  {
    city: "Huntington Woods",
    state: "MI",
  },
  { city: "Huntsville", state: "AL" },
  { city: "Huntsville", state: "TX" },
  {
    city: "Hurley",
    state: "NY",
  },
  { city: "Huron", state: "OH" },
  { city: "Huron", state: "SD" },
  {
    city: "Huron",
    state: "CA",
  },
  { city: "Hurricane", state: "UT" },
  { city: "Hurst", state: "TX" },
  {
    city: "Hutchinson",
    state: "KS",
  },
  { city: "Hutchinson", state: "MN" },
  { city: "Hyattsville", state: "MD" },
  {
    city: "Hybla Valley",
    state: "VA",
  },
  { city: "Hyde Park", state: "NY" },
  { city: "Hyrum", state: "UT" },
  {
    city: "Idabel",
    state: "OK",
  },
  { city: "Idaho Falls", state: "ID" },
  { city: "Idylwood", state: "VA" },
  {
    city: "Ilion",
    state: "NY",
  },
  { city: "Immokalee", state: "FL" },
  { city: "Imperial", state: "CA" },
  {
    city: "Imperial Beach",
    state: "CA",
  },
  { city: "Incline Village-Crystal Bay", state: "NV" },
  {
    city: "Independence",
    state: "OR",
  },
  { city: "Independence", state: "MO" },
  { city: "Independence", state: "OH" },
  {
    city: "Independence",
    state: "IA",
  },
  { city: "Independence", state: "KS" },
  {
    city: "Independence",
    state: "KY",
  },
  { city: "Indian Harbour Beach", state: "FL" },
  { city: "Indian Trail", state: "NC" },
  {
    city: "Indiana",
    state: "PA",
  },
  { city: "Indianapolis", state: "IN" },
  { city: "Indianola", state: "MS" },
  {
    city: "Indianola",
    state: "IA",
  },
  { city: "Indio", state: "CA" },
  { city: "Ingleside", state: "TX" },
  {
    city: "Inglewood",
    state: "CA",
  },
  { city: "Inglewood-Finn Hill", state: "WA" },
  { city: "Inkster", state: "MI" },
  {
    city: "Interlaken",
    state: "CA",
  },
  { city: "International Falls", state: "MN" },
  {
    city: "Inver Grove Heights",
    state: "MN",
  },
  { city: "Inverness", state: "FL" },
  { city: "Inverness", state: "IL" },
  {
    city: "Inwood",
    state: "FL",
  },
  { city: "Inwood", state: "NY" },
  { city: "Iola", state: "KS" },
  {
    city: "Iona",
    state: "FL",
  },
  { city: "Ione", state: "CA" },
  { city: "Ionia", state: "MI" },
  {
    city: "Iowa City",
    state: "IA",
  },
  { city: "Iowa Park", state: "TX" },
  { city: "Ipswich", state: "MA" },
  {
    city: "Irmo",
    state: "SC",
  },
  { city: "Iron Mountain", state: "MI" },
  { city: "Irondale", state: "AL" },
  {
    city: "Irondale",
    state: "GA",
  },
  { city: "Irondequoit", state: "NY" },
  { city: "Ironton", state: "OH" },
  {
    city: "Ironwood",
    state: "MI",
  },
  { city: "Irvine", state: "CA" },
  { city: "Irving", state: "TX" },
  {
    city: "Irvington",
    state: "NJ",
  },
  { city: "Irvington", state: "NY" },
  { city: "Iselin", state: "NJ" },
  {
    city: "Ishpeming",
    state: "MI",
  },
  { city: "Isla Vista", state: "CA" },
  { city: "Islamorada", state: "FL" },
  {
    city: "Island Lake",
    state: "IL",
  },
  { city: "Islip", state: "NY" },
  { city: "Issaquah", state: "WA" },
  {
    city: "Itasca",
    state: "IL",
  },
  { city: "Ithaca", state: "NY" },
  { city: "Ives Estates", state: "FL" },
  {
    city: "Jacinto City",
    state: "TX",
  },
  { city: "Jackson", state: "TN" },
  { city: "Jackson", state: "OH" },
  {
    city: "Jackson",
    state: "WY",
  },
  { city: "Jackson", state: "MI" },
  { city: "Jackson", state: "MS" },
  {
    city: "Jackson",
    state: "MO",
  },
  { city: "Jacksonville", state: "AL" },
  { city: "Jacksonville", state: "AR" },
  {
    city: "Jacksonville",
    state: "FL",
  },
  { city: "Jacksonville", state: "IL" },
  { city: "Jacksonville", state: "NC" },
  {
    city: "Jacksonville",
    state: "TX",
  },
  { city: "Jacksonville Beach", state: "FL" },
  { city: "Jamesburg", state: "NJ" },
  {
    city: "Jamestown",
    state: "ND",
  },
  { city: "Jamestown", state: "NY" },
  { city: "Janesville", state: "WI" },
  {
    city: "Jasmine Estates",
    state: "FL",
  },
  { city: "Jasper", state: "AL" },
  { city: "Jasper", state: "IN" },
  {
    city: "Jasper",
    state: "TX",
  },
  { city: "Jeannette", state: "PA" },
  { city: "Jefferson", state: "WI" },
  {
    city: "Jefferson",
    state: "VA",
  },
  { city: "Jefferson", state: "LA" },
  { city: "Jefferson City", state: "MO" },
  {
    city: "Jefferson City",
    state: "TN",
  },
  { city: "Jefferson Hills", state: "PA" },
  {
    city: "Jefferson Valley-Yorktown",
    state: "NY",
  },
  { city: "Jeffersontown", state: "KY" },
  { city: "Jeffersonville", state: "IN" },
  {
    city: "Jenison",
    state: "MI",
  },
  { city: "Jenks", state: "OK" },
  { city: "Jennings", state: "LA" },
  {
    city: "Jennings",
    state: "MO",
  },
  { city: "Jennings Lodge", state: "OR" },
  { city: "Jensen Beach", state: "FL" },
  {
    city: "Jericho",
    state: "NY",
  },
  { city: "Jerome", state: "ID" },
  { city: "Jersey City", state: "NJ" },
  {
    city: "Jersey Village",
    state: "TX",
  },
  { city: "Jerseyville", state: "IL" },
  { city: "Jessup", state: "MD" },
  {
    city: "Jesup",
    state: "GA",
  },
  { city: "Johnson City", state: "NY" },
  { city: "Johnson City", state: "TN" },
  {
    city: "Johnston",
    state: "RI",
  },
  { city: "Johnston", state: "IA" },
  { city: "Johnstown", state: "NY" },
  {
    city: "Johnstown",
    state: "PA",
  },
  { city: "Joliet", state: "IL" },
  { city: "Jollyville", state: "TX" },
  {
    city: "Jonesboro",
    state: "AR",
  },
  { city: "Joplin", state: "MO" },
  { city: "Joppatowne", state: "MD" },
  {
    city: "Junction City",
    state: "KS",
  },
  { city: "Juneau and", state: "AK" },
  { city: "Jupiter", state: "FL" },
  {
    city: "Justice",
    state: "IL",
  },
  { city: "Kahului", state: "HI" },
  { city: "Kailua", state: "HI" },
  {
    city: "Kalamazoo",
    state: "MI",
  },
  { city: "Kalaoa", state: "HI" },
  { city: "Kalispell", state: "MT" },
  {
    city: "Kaneohe",
    state: "HI",
  },
  { city: "Kaneohe Station", state: "HI" },
  { city: "Kankakee", state: "IL" },
  {
    city: "Kannapolis",
    state: "NC",
  },
  { city: "Kansas City", state: "MO" },
  { city: "Kansas City", state: "KS" },
  {
    city: "Kapaa",
    state: "HI",
  },
  { city: "Katy", state: "TX" },
  { city: "Kaufman", state: "TX" },
  {
    city: "Kaukauna",
    state: "WI",
  },
  { city: "Kaysville", state: "UT" },
  { city: "Keansburg", state: "NJ" },
  {
    city: "Kearney",
    state: "NE",
  },
  { city: "Kearns", state: "UT" },
  { city: "Kearny", state: "NJ" },
  {
    city: "Keene",
    state: "NH",
  },
  { city: "Keizer", state: "OR" },
  { city: "Keller", state: "TX" },
  {
    city: "Kelso",
    state: "WA",
  },
  { city: "Kemp Mill", state: "MD" },
  { city: "Ken Caryl", state: "CO" },
  {
    city: "Kenai",
    state: "AK",
  },
  { city: "Kendale Lakes", state: "FL" },
  { city: "Kendall", state: "FL" },
  {
    city: "Kendall Park",
    state: "NJ",
  },
  { city: "Kendall West", state: "FL" },
  { city: "Kendallville", state: "IN" },
  {
    city: "Kenilworth",
    state: "NJ",
  },
  { city: "Kenmore", state: "NY" },
  { city: "Kenmore", state: "WA" },
  {
    city: "Kennebunk",
    state: "ME",
  },
  { city: "Kennedy Township", state: "PA" },
  { city: "Kenner", state: "LA" },
  {
    city: "Kennesaw",
    state: "GA",
  },
  { city: "Kennett", state: "MO" },
  { city: "Kennewick", state: "WA" },
  {
    city: "Kenosha",
    state: "WI",
  },
  { city: "Kensington", state: "CT" },
  { city: "Kent", state: "WA" },
  {
    city: "Kent",
    state: "OH",
  },
  { city: "Kent", state: "NY" },
  { city: "Kentfield", state: "CA" },
  {
    city: "Kenton",
    state: "OH",
  },
  { city: "Kentwood", state: "MI" },
  { city: "Kenwood", state: "OH" },
  {
    city: "Keokuk",
    state: "IA",
  },
  { city: "Kerman", state: "CA" },
  { city: "Kernersville", state: "NC" },
  {
    city: "Kerrville",
    state: "TX",
  },
  { city: "Ketchikan", state: "AK" },
  { city: "Kettering", state: "MD" },
  {
    city: "Kettering",
    state: "OH",
  },
  { city: "Kewanee", state: "IL" },
  { city: "Key Biscayne", state: "FL" },
  {
    city: "Key Largo",
    state: "FL",
  },
  { city: "Key West", state: "FL" },
  { city: "Keyport", state: "NJ" },
  {
    city: "Keystone",
    state: "FL",
  },
  { city: "Kihei", state: "HI" },
  { city: "Kilgore", state: "TX" },
  {
    city: "Killeen",
    state: "TX",
  },
  { city: "Killingly", state: "CT" },
  { city: "Killingworth", state: "CT" },
  {
    city: "Kimberly",
    state: "WI",
  },
  { city: "Kinderhook", state: "NY" },
  { city: "King City", state: "CA" },
  {
    city: "King of Prussia",
    state: "PA",
  },
  { city: "Kingman", state: "AZ" },
  { city: "Kings Grant", state: "NC" },
  {
    city: "Kings Mountain",
    state: "NC",
  },
  { city: "Kings Park", state: "NY" },
  { city: "Kings Point", state: "FL" },
  {
    city: "Kingsburg",
    state: "CA",
  },
  { city: "Kingsbury", state: "NY" },
  { city: "Kingsgate", state: "WA" },
  {
    city: "Kingsland",
    state: "GA",
  },
  { city: "Kingsport", state: "TN" },
  { city: "Kingston", state: "PA" },
  {
    city: "Kingston",
    state: "NY",
  },
  { city: "Kingston", state: "MA" },
  { city: "Kingsville", state: "TX" },
  {
    city: "Kinnelon",
    state: "NJ",
  },
  { city: "Kinston", state: "NC" },
  { city: "Kirby", state: "TX" },
  {
    city: "Kirkland",
    state: "WA",
  },
  { city: "Kirkland", state: "NY" },
  { city: "Kirksville", state: "MO" },
  {
    city: "Kirkwood",
    state: "MO",
  },
  { city: "Kirtland", state: "NM" },
  { city: "Kirtland", state: "OH" },
  {
    city: "Kiryas Joel",
    state: "NY",
  },
  { city: "Kissimmee", state: "FL" },
  { city: "Kittery", state: "ME" },
  {
    city: "Klamath Falls",
    state: "OR",
  },
  { city: "Knik-Fairview", state: "AK" },
  { city: "Knoxville", state: "IA" },
  {
    city: "Knoxville",
    state: "TN",
  },
  { city: "Kodiak", state: "AK" },
  { city: "Kokomo", state: "IN" },
  {
    city: "Kosciusko",
    state: "MS",
  },
  { city: "Kulpsville", state: "PA" },
  {
    city: "La Canada Flintridge",
    state: "CA",
  },
  { city: "La Crescenta-Montrose", state: "CA" },
  { city: "La Crosse", state: "WI" },
  {
    city: "La Fayette",
    state: "GA",
  },
  { city: "La Feria", state: "TX" },
  { city: "La Follette", state: "TN" },
  {
    city: "La Grande",
    state: "OR",
  },
  { city: "La Grange", state: "NY" },
  { city: "La Grange", state: "IL" },
  {
    city: "La Grange Park",
    state: "IL",
  },
  { city: "La Habra", state: "CA" },
  { city: "La Homa", state: "TX" },
  {
    city: "La Junta",
    state: "CO",
  },
  { city: "La Marque", state: "TX" },
  { city: "La Mesa", state: "CA" },
  {
    city: "La Mirada",
    state: "CA",
  },
  { city: "La Palma", state: "CA" },
  { city: "La Plata", state: "MD" },
  {
    city: "La Porte",
    state: "IN",
  },
  { city: "La Porte", state: "TX" },
  { city: "La Presa", state: "CA" },
  {
    city: "La Puente",
    state: "CA",
  },
  { city: "La Quinta", state: "CA" },
  { city: "La Riviera", state: "CA" },
  {
    city: "La Salle",
    state: "IL",
  },
  { city: "La Vergne", state: "TN" },
  { city: "La Verne", state: "CA" },
  {
    city: "La Vista",
    state: "NE",
  },
  { city: "Lacey", state: "WA" },
  { city: "Lackawanna", state: "NY" },
  {
    city: "Lackland AFB",
    state: "TX",
  },
  { city: "Lacombe", state: "LA" },
  { city: "Laconia", state: "NH" },
  {
    city: "Ladera Heights",
    state: "CA",
  },
  { city: "Ladson", state: "SC" },
  { city: "Ladue", state: "MO" },
  {
    city: "Lady Lake",
    state: "FL",
  },
  { city: "Lafayette", state: "CO" },
  { city: "Lafayette", state: "LA" },
  {
    city: "Lafayette",
    state: "IN",
  },
  { city: "Lafayette", state: "CA" },
  { city: "LaGrange", state: "GA" },
  {
    city: "Laguna",
    state: "CA",
  },
  { city: "Laguna Beach", state: "CA" },
  { city: "Laguna Hills", state: "CA" },
  {
    city: "Laguna Niguel",
    state: "CA",
  },
  { city: "Laguna West-Lakeside", state: "CA" },
  { city: "Laguna Woods", state: "CA" },
  {
    city: "Lahaina",
    state: "HI",
  },
  { city: "Lake Arbor", state: "MD" },
  { city: "Lake Arrowhead", state: "CA" },
  {
    city: "Lake Barcroft",
    state: "VA",
  },
  { city: "Lake Bluff", state: "IL" },
  { city: "Lake Butter", state: "FL" },
  {
    city: "Lake Carmel",
    state: "NY",
  },
  { city: "Lake Charles", state: "LA" },
  { city: "Lake City", state: "FL" },
  {
    city: "Lake City",
    state: "SC",
  },
  { city: "Lake Dallas", state: "TX" },
  { city: "Lake Elmo", state: "MN" },
  {
    city: "Lake Elsinore",
    state: "CA",
  },
  { city: "Lake Forest", state: "CA" },
  { city: "Lake Forest", state: "IL" },
  {
    city: "Lake Forest Park",
    state: "WA",
  },
  { city: "Lake Geneva", state: "WI" },
  { city: "Lake Grove", state: "NY" },
  {
    city: "Lake Havasu City",
    state: "AZ",
  },
  { city: "Lake in the Hills", state: "IL" },
  { city: "Lake Jackson", state: "TX" },
  {
    city: "Lake Lorraine",
    state: "FL",
  },
  { city: "Lake Los Angeles", state: "CA" },
  { city: "Lake Lucerne", state: "FL" },
  {
    city: "Lake Magdalene",
    state: "FL",
  },
  { city: "Lake Mary", state: "FL" },
  { city: "Lake Mohawk", state: "NJ" },
  {
    city: "Lake Monticello",
    state: "VA",
  },
  { city: "Lake Morton-Berrydale", state: "WA" },
  { city: "Lake Oswego", state: "OR" },
  {
    city: "Lake Park",
    state: "FL",
  },
  { city: "Lake Ridge", state: "VA" },
  { city: "Lake Ronkonkoma", state: "NY" },
  {
    city: "Lake Shore",
    state: "MD",
  },
  { city: "Lake Shore", state: "WA" },
  { city: "Lake St. Louis", state: "MO" },
  {
    city: "Lake Station",
    state: "IN",
  },
  { city: "Lake Stevens", state: "WA" },
  { city: "Lake Wales", state: "FL" },
  {
    city: "Lake Worth",
    state: "FL",
  },
  { city: "Lake Worth Corridor", state: "FL" },
  { city: "Lake Zurich", state: "IL" },
  {
    city: "Lakeland",
    state: "FL",
  },
  { city: "Lakeland", state: "TN" },
  { city: "Lakeland Highlands", state: "FL" },
  {
    city: "Lakeland North",
    state: "WA",
  },
  { city: "Lakeland South", state: "WA" },
  { city: "Lakes", state: "AK" },
  {
    city: "Lakes by the Bay",
    state: "FL",
  },
  { city: "Lakes of the Four Seasons", state: "IN" },
  { city: "Lakeside", state: "FL" },
  {
    city: "Lakeside",
    state: "CA",
  },
  { city: "Lakeside", state: "VA" },
  { city: "Lakeville", state: "MN" },
  {
    city: "Lakeville",
    state: "MA",
  },
  { city: "Lakeway", state: "TX" },
  { city: "Lakewood", state: "OH" },
  {
    city: "Lakewood",
    state: "NJ",
  },
  { city: "Lakewood", state: "CA" },
  { city: "Lakewood", state: "CO" },
  {
    city: "Lakewood",
    state: "WA",
  },
  { city: "Lakewood Park", state: "FL" },
  { city: "Lamar", state: "CO" },
  {
    city: "Lambertville",
    state: "MI",
  },
  { city: "Lamesa", state: "TX" },
  { city: "Lamont", state: "CA" },
  {
    city: "Lampasas",
    state: "TX",
  },
  { city: "Lancaster", state: "TX" },
  { city: "Lancaster", state: "SC" },
  {
    city: "Lancaster",
    state: "PA",
  },
  { city: "Lancaster", state: "OH" },
  { city: "Lancaster", state: "NY" },
  {
    city: "Lancaster",
    state: "MA",
  },
  { city: "Lancaster", state: "CA" },
  { city: "Land O’ Lakes", state: "FL" },
  {
    city: "Landen",
    state: "OH",
  },
  { city: "Lander", state: "WY" },
  { city: "Lanett", state: "AL" },
  {
    city: "Langley Park",
    state: "MD",
  },
  { city: "Lanham-Seabrook", state: "MD" },
  { city: "Lansdale", state: "PA" },
  {
    city: "Lansdowne",
    state: "PA",
  },
  { city: "Lansdowne-Baltimore Highlands", state: "MD" },
  {
    city: "Lansing",
    state: "KS",
  },
  { city: "Lansing", state: "IL" },
  { city: "Lansing", state: "NY" },
  {
    city: "Lansing",
    state: "MI",
  },
  { city: "Lantana", state: "FL" },
  { city: "Lapeer", state: "MI" },
  {
    city: "Laplace",
    state: "LA",
  },
  { city: "Laramie", state: "WY" },
  { city: "Larchmont", state: "NY" },
  {
    city: "Laredo",
    state: "TX",
  },
  { city: "Largo", state: "MD" },
  { city: "Largo", state: "FL" },
  {
    city: "Larkfield-Wikiup",
    state: "CA",
  },
  { city: "Larkspur", state: "CA" },
  { city: "Larose", state: "LA" },
  {
    city: "Las Cruces",
    state: "NM",
  },
  { city: "Las Vegas", state: "NM" },
  { city: "Las Vegas", state: "NV" },
  {
    city: "Lathrop",
    state: "CA",
  },
  { city: "Latrobe", state: "PA" },
  { city: "Lauderdale Lakes", state: "FL" },
  {
    city: "Lauderhill",
    state: "FL",
  },
  { city: "Laughlin", state: "NV" },
  { city: "Laurel", state: "MS" },
  {
    city: "Laurel",
    state: "MT",
  },
  { city: "Laurel", state: "FL" },
  { city: "Laurel", state: "MD" },
  {
    city: "Laurel",
    state: "VA",
  },
  { city: "Laurel Bay", state: "SC" },
  { city: "Laurence Harbor", state: "NJ" },
  {
    city: "Laurens",
    state: "SC",
  },
  { city: "Laurinburg", state: "NC" },
  { city: "Lawndale", state: "CA" },
  {
    city: "Lawrence",
    state: "KS",
  },
  { city: "Lawrence", state: "IN" },
  { city: "Lawrence", state: "NY" },
  {
    city: "Lawrence",
    state: "MA",
  },
  { city: "Lawrenceburg", state: "TN" },
  { city: "Lawrenceburg", state: "KY" },
  {
    city: "Lawrenceville",
    state: "GA",
  },
  { city: "Lawton", state: "OK" },
  { city: "Layton", state: "UT" },
  {
    city: "Le Mars",
    state: "IA",
  },
  { city: "Le Ray", state: "NY" },
  { city: "Le Roy", state: "NY" },
  {
    city: "Lea Hill",
    state: "WA",
  },
  { city: "Leacock-Leola-Bareville", state: "PA" },
  { city: "League City", state: "TX" },
  {
    city: "Leander",
    state: "TX",
  },
  { city: "Leavenworth", state: "KS" },
  { city: "Leawood", state: "KS" },
  {
    city: "Lebanon",
    state: "IN",
  },
  { city: "Lebanon", state: "CT" },
  { city: "Lebanon", state: "PA" },
  {
    city: "Lebanon",
    state: "TN",
  },
  { city: "Lebanon", state: "OR" },
  { city: "Lebanon", state: "OH" },
  {
    city: "Lebanon",
    state: "NH",
  },
  { city: "Lebanon", state: "MO" },
  { city: "Ledyard", state: "CT" },
  {
    city: "Lee",
    state: "NY",
  },
  { city: "Lee’s Summit", state: "MO" },
  { city: "Leeds", state: "AL" },
  {
    city: "Leesburg",
    state: "FL",
  },
  { city: "Leesburg", state: "VA" },
  { city: "Leesville", state: "LA" },
  {
    city: "Lehi",
    state: "UT",
  },
  { city: "Lehigh Acres", state: "FL" },
  { city: "Leicester", state: "MA" },
  {
    city: "Leisure City",
    state: "FL",
  },
  { city: "Leisure Village West-Pine Lake Park", state: "NJ" },
  {
    city: "Leitchfield",
    state: "KY",
  },
  { city: "Lemay", state: "MO" },
  {
    city: "Lemmon Valley-Golden Valley",
    state: "NV",
  },
  { city: "Lemon Grove", state: "CA" },
  { city: "Lemont", state: "IL" },
  {
    city: "Lemoore",
    state: "CA",
  },
  { city: "Lenexa", state: "KS" },
  { city: "Lennox", state: "CA" },
  {
    city: "Lenoir",
    state: "NC",
  },
  { city: "Lenoir City", state: "TN" },
  { city: "Lenox", state: "NY" },
  {
    city: "Leominster",
    state: "MA",
  },
  { city: "Leon Valley", state: "TX" },
  { city: "Leonia", state: "NJ" },
  {
    city: "Levelland",
    state: "TX",
  },
  { city: "Levittown", state: "PA" },
  { city: "Levittown", state: "NY" },
  {
    city: "Lewisboro",
    state: "NY",
  },
  { city: "Lewisburg", state: "TN" },
  { city: "Lewiston", state: "ME" },
  {
    city: "Lewiston",
    state: "NY",
  },
  { city: "Lewiston", state: "ID" },
  { city: "Lewistown", state: "PA" },
  {
    city: "Lewisville",
    state: "NC",
  },
  { city: "Lewisville", state: "TX" },
  { city: "Lexington", state: "VA" },
  {
    city: "Lexington",
    state: "NC",
  },
  { city: "Lexington", state: "SC" },
  { city: "Lexington", state: "TN" },
  {
    city: "Lexington",
    state: "MA",
  },
  { city: "Lexington", state: "NE" },
  { city: "Lexington Park", state: "MD" },
  {
    city: "Lexington-Fayette",
    state: "KY",
  },
  { city: "Liberal", state: "KS" },
  { city: "Liberty", state: "MO" },
  {
    city: "Liberty",
    state: "NY",
  },
  { city: "Liberty", state: "TX" },
  { city: "Libertyville", state: "IL" },
  {
    city: "Lighthouse Point",
    state: "FL",
  },
  { city: "Lilburn", state: "GA" },
  { city: "Lima", state: "OH" },
  {
    city: "Lincoln",
    state: "RI",
  },
  { city: "Lincoln", state: "NE" },
  { city: "Lincoln", state: "MA" },
  {
    city: "Lincoln",
    state: "IL",
  },
  { city: "Lincoln", state: "CA" },
  { city: "Lincoln City", state: "OR" },
  {
    city: "Lincoln Park",
    state: "MI",
  },
  { city: "Lincoln Park", state: "NJ" },
  { city: "Lincoln Village", state: "OH" },
  {
    city: "Lincolnia",
    state: "VA",
  },
  { city: "Lincolnshire", state: "IL" },
  { city: "Lincolnton", state: "NC" },
  {
    city: "Lincolnwood",
    state: "IL",
  },
  { city: "Lincroft", state: "NJ" },
  { city: "Linda", state: "CA" },
  {
    city: "Linden",
    state: "NJ",
  },
  { city: "Lindenhurst", state: "NY" },
  { city: "Lindenhurst", state: "IL" },
  {
    city: "Lindenwold",
    state: "NJ",
  },
  { city: "Lindon", state: "UT" },
  { city: "Lindsay", state: "CA" },
  {
    city: "Linganore-Bartonsville",
    state: "MD",
  },
  { city: "Linglestown", state: "PA" },
  { city: "Lino Lakes", state: "MN" },
  {
    city: "Linthicum",
    state: "MD",
  },
  { city: "Linton Hall", state: "VA" },
  { city: "Linwood", state: "NJ" },
  {
    city: "Lionville-Marchwood",
    state: "PA",
  },
  { city: "Lisbon", state: "ME" },
  { city: "Lisbon", state: "WI" },
  {
    city: "Lisle",
    state: "IL",
  },
  { city: "Litchfield", state: "IL" },
  { city: "Litchfield", state: "CT" },
  {
    city: "Litchfield",
    state: "MN",
  },
  { city: "Litchfield", state: "NH" },
  { city: "Lititz", state: "PA" },
  {
    city: "Little Canada",
    state: "MN",
  },
  { city: "Little Chute", state: "WI" },
  {
    city: "Little Cottonwood Creek Valley",
    state: "UT",
  },
  { city: "Little Falls", state: "MN" },
  { city: "Little Falls", state: "NJ" },
  {
    city: "Little Ferry",
    state: "NJ",
  },
  { city: "Little River", state: "SC" },
  { city: "Little Rock", state: "AR" },
  {
    city: "Little Silver",
    state: "NJ",
  },
  { city: "Littlefield", state: "TX" },
  { city: "Littleton", state: "MA" },
  {
    city: "Littleton",
    state: "CO",
  },
  { city: "Live Oak", state: "FL" },
  { city: "Live Oak", state: "CA" },
  {
    city: "Live Oak",
    state: "TX",
  },
  { city: "Livermore", state: "CA" },
  { city: "Livingston", state: "CA" },
  {
    city: "Livingston",
    state: "MT",
  },
  { city: "Livingston", state: "NJ" },
  { city: "Livonia", state: "NY" },
  {
    city: "Livonia",
    state: "MI",
  },
  { city: "Lloyd", state: "NY" },
  { city: "Lochearn", state: "MD" },
  {
    city: "Lock Haven",
    state: "PA",
  },
  { city: "Lockhart", state: "FL" },
  { city: "Lockhart", state: "TX" },
  {
    city: "Lockport",
    state: "IL",
  },
  { city: "Lockport", state: "NY" },
  { city: "Lodi", state: "NJ" },
  {
    city: "Lodi",
    state: "CA",
  },
  { city: "Logan", state: "OH" },
  { city: "Logan", state: "UT" },
  {
    city: "Logansport",
    state: "IN",
  },
  { city: "Loma Linda", state: "CA" },
  { city: "Lombard", state: "IL" },
  {
    city: "Lomita",
    state: "CA",
  },
  { city: "Lompoc", state: "CA" },
  { city: "London", state: "OH" },
  {
    city: "Londonderry",
    state: "NH",
  },
  { city: "Londontowne", state: "MD" },
  { city: "Long Beach", state: "CA" },
  {
    city: "Long Beach",
    state: "MS",
  },
  { city: "Long Beach", state: "NY" },
  { city: "Long Branch", state: "NJ" },
  {
    city: "Long Grove",
    state: "IL",
  },
  { city: "Longboat Key", state: "FL" },
  { city: "Longmeadow", state: "MA" },
  {
    city: "Longmont",
    state: "CO",
  },
  { city: "Longview", state: "WA" },
  { city: "Longview", state: "TX" },
  {
    city: "Longwood",
    state: "FL",
  },
  { city: "Loomis", state: "CA" },
  { city: "Lorain", state: "OH" },
  {
    city: "Lorton",
    state: "VA",
  },
  { city: "Los Alamitos", state: "CA" },
  { city: "Los Alamos", state: "NM" },
  {
    city: "Los Altos",
    state: "CA",
  },
  { city: "Los Altos Hills", state: "CA" },
  { city: "Los Angeles", state: "CA" },
  {
    city: "Los Banos",
    state: "CA",
  },
  { city: "Los Gatos", state: "CA" },
  { city: "Los Lunas", state: "NM" },
  {
    city: "Louisville",
    state: "MS",
  },
  { city: "Louisville", state: "OH" },
  { city: "Louisville", state: "CO" },
  {
    city: "Louisville",
    state: "KY",
  },
  { city: "Loveland", state: "CO" },
  { city: "Loveland", state: "OH" },
  {
    city: "Loves Park",
    state: "IL",
  },
  { city: "Lovington", state: "NM" },
  { city: "Lowell", state: "MA" },
  {
    city: "Lowell",
    state: "IN",
  },
  { city: "Lower Allen", state: "PA" },
  { city: "Lower Burrell", state: "PA" },
  {
    city: "Lubbock",
    state: "TX",
  },
  { city: "Lucas Valley-Marinwood", state: "CA" },
  { city: "Ludington", state: "MI" },
  {
    city: "Ludlow",
    state: "MA",
  },
  { city: "Lufkin", state: "TX" },
  { city: "Lugoff", state: "SC" },
  {
    city: "Luling",
    state: "LA",
  },
  { city: "Lumberton", state: "NC" },
  { city: "Lumberton", state: "TX" },
  {
    city: "Lunenburg",
    state: "MA",
  },
  { city: "Lutherville-Timonium", state: "MD" },
  { city: "Lutz", state: "FL" },
  {
    city: "Lynbrook",
    state: "NY",
  },
  { city: "Lynchburg", state: "VA" },
  { city: "Lynden", state: "WA" },
  {
    city: "Lyndhurst",
    state: "OH",
  },
  { city: "Lyndhurst", state: "NJ" },
  { city: "Lyndon", state: "KY" },
  {
    city: "Lynn",
    state: "MA",
  },
  { city: "Lynn Haven", state: "FL" },
  { city: "Lynnfield", state: "MA" },
  {
    city: "Lynnwood",
    state: "WA",
  },
  { city: "Lynwood", state: "IL" },
  { city: "Lynwood", state: "CA" },
  {
    city: "Lyons",
    state: "IL",
  },
  { city: "Lysander", state: "NY" },
  { city: "Mableton", state: "GA" },
  {
    city: "Macedon",
    state: "NY",
  },
  { city: "Macedonia", state: "OH" },
  { city: "Machesney Park", state: "IL" },
  {
    city: "Macomb",
    state: "IL",
  },
  { city: "Macon", state: "GA" },
  { city: "Madeira", state: "OH" },
  {
    city: "Madera",
    state: "CA",
  },
  { city: "Madera Acres", state: "CA" },
  { city: "Madison", state: "AL" },
  {
    city: "Madison",
    state: "IN",
  },
  { city: "Madison", state: "CT" },
  { city: "Madison", state: "NJ" },
  {
    city: "Madison",
    state: "MS",
  },
  { city: "Madison", state: "SD" },
  { city: "Madison", state: "WI" },
  {
    city: "Madison Heights",
    state: "VA",
  },
  { city: "Madison Heights", state: "MI" },
  { city: "Madison Park", state: "NJ" },
  {
    city: "Madisonville",
    state: "KY",
  },
  { city: "Magalia", state: "CA" },
  { city: "Magna", state: "UT" },
  {
    city: "Magnolia",
    state: "AR",
  },
  { city: "Mahopac", state: "NY" },
  { city: "Mahtomedi", state: "MN" },
  {
    city: "Maitland",
    state: "FL",
  },
  { city: "Makaha", state: "HI" },
  { city: "Makakilo City", state: "HI" },
  {
    city: "Makawao",
    state: "HI",
  },
  { city: "Malden", state: "MA" },
  { city: "Malibu", state: "CA" },
  {
    city: "Malone",
    state: "NY",
  },
  { city: "Malta", state: "NY" },
  { city: "Maltby", state: "WA" },
  {
    city: "Malvern",
    state: "AR",
  },
  { city: "Malverne", state: "NY" },
  { city: "Mamakating", state: "NY" },
  {
    city: "Mamaroneck",
    state: "NY",
  },
  { city: "Mammoth Lakes", state: "CA" },
  { city: "Manasquan", state: "NJ" },
  {
    city: "Manassas",
    state: "VA",
  },
  { city: "Manassas Park", state: "VA" },
  { city: "Manchester", state: "TN" },
  {
    city: "Manchester",
    state: "NH",
  },
  { city: "Manchester", state: "NY" },
  { city: "Manchester", state: "MO" },
  {
    city: "Manchester",
    state: "CT",
  },
  { city: "Mandan", state: "ND" },
  { city: "Mandeville", state: "LA" },
  {
    city: "Mango",
    state: "FL",
  },
  { city: "Manhasset", state: "NY" },
  { city: "Manhattan", state: "KS" },
  {
    city: "Manhattan Beach",
    state: "CA",
  },
  { city: "Manistee", state: "MI" },
  { city: "Manitowoc", state: "WI" },
  {
    city: "Mankato",
    state: "MN",
  },
  { city: "Manlius", state: "NY" },
  { city: "Manorhaven", state: "NY" },
  {
    city: "Manorville",
    state: "NY",
  },
  { city: "Mansfield", state: "MA" },
  { city: "Mansfield", state: "CT" },
  {
    city: "Mansfield",
    state: "TX",
  },
  { city: "Mansfield", state: "OH" },
  { city: "Mansfield Center", state: "MA" },
  {
    city: "Manteca",
    state: "CA",
  },
  { city: "Manteno", state: "IL" },
  { city: "Mantua", state: "VA" },
  {
    city: "Manville",
    state: "NJ",
  },
  { city: "Maple Glen", state: "PA" },
  { city: "Maple Grove", state: "MN" },
  {
    city: "Maple Heights",
    state: "OH",
  },
  { city: "Maple Valley", state: "WA" },
  { city: "Maplewood", state: "MN" },
  {
    city: "Maplewood",
    state: "MO",
  },
  { city: "Maplewood", state: "NJ" },
  { city: "Maquoketa", state: "IA" },
  {
    city: "Marana",
    state: "AZ",
  },
  { city: "Marathon", state: "FL" },
  { city: "Marblehead", state: "MA" },
  {
    city: "Marcellus",
    state: "NY",
  },
  { city: "Marco Island", state: "FL" },
  { city: "Marcy", state: "NY" },
  {
    city: "Marengo",
    state: "IL",
  },
  { city: "Margate", state: "FL" },
  { city: "Margate City", state: "NJ" },
  {
    city: "Marianna",
    state: "FL",
  },
  { city: "Marietta", state: "GA" },
  { city: "Marietta", state: "OH" },
  {
    city: "Marina",
    state: "CA",
  },
  { city: "Marina del Rey", state: "CA" },
  { city: "Marinette", state: "WI" },
  {
    city: "Marion",
    state: "VA",
  },
  { city: "Marion", state: "OH" },
  { city: "Marion", state: "SC" },
  {
    city: "Marion",
    state: "AR",
  },
  { city: "Marion", state: "IN" },
  { city: "Marion", state: "IA" },
  {
    city: "Marion",
    state: "IL",
  },
  { city: "Markham", state: "IL" },
  { city: "Marlborough", state: "NY" },
  {
    city: "Marlborough",
    state: "MA",
  },
  { city: "Marlin", state: "TX" },
  { city: "Marlow Heights", state: "MD" },
  {
    city: "Marlton",
    state: "MD",
  },
  { city: "Marlton", state: "NJ" },
  { city: "Marquette", state: "MI" },
  {
    city: "Marrero",
    state: "LA",
  },
  { city: "Marshall", state: "MI" },
  { city: "Marshall", state: "MN" },
  {
    city: "Marshall",
    state: "MO",
  },
  { city: "Marshall", state: "TX" },
  { city: "Marshalltown", state: "IA" },
  {
    city: "Marshfield",
    state: "MA",
  },
  { city: "Marshfield", state: "WI" },
  { city: "Martha Lake", state: "WA" },
  {
    city: "Martin",
    state: "TN",
  },
  { city: "Martinez", state: "GA" },
  { city: "Martinez", state: "CA" },
  {
    city: "Martins Ferry",
    state: "OH",
  },
  { city: "Martinsburg", state: "WV" },
  { city: "Martinsville", state: "VA" },
  {
    city: "Martinsville",
    state: "IN",
  },
  { city: "Maryland City", state: "MD" },
  { city: "Maryland Heights", state: "MO" },
  {
    city: "Marysville",
    state: "MI",
  },
  { city: "Marysville", state: "CA" },
  { city: "Marysville", state: "WA" },
  {
    city: "Marysville",
    state: "OH",
  },
  { city: "Maryville", state: "TN" },
  { city: "Maryville", state: "MO" },
  {
    city: "Mashpee",
    state: "MA",
  },
  { city: "Mason", state: "MI" },
  { city: "Mason", state: "OH" },
  {
    city: "Mason City",
    state: "IA",
  },
  { city: "Masonboro", state: "NC" },
  { city: "Massapequa", state: "NY" },
  {
    city: "Massapequa Park",
    state: "NY",
  },
  { city: "Massena", state: "NY" },
  { city: "Massillon", state: "OH" },
  {
    city: "Mastic",
    state: "NY",
  },
  { city: "Mastic Beach", state: "NY" },
  { city: "Matawan", state: "NJ" },
  {
    city: "Mattapoisett",
    state: "MA",
  },
  { city: "Matteson", state: "IL" },
  { city: "Matthews", state: "NC" },
  {
    city: "Mattoon",
    state: "IL",
  },
  { city: "Mattydale", state: "NY" },
  { city: "Mauldin", state: "SC" },
  {
    city: "Maumee",
    state: "OH",
  },
  { city: "Maumelle", state: "AR" },
  { city: "Mayfield", state: "KY" },
  {
    city: "Mayfield",
    state: "NY",
  },
  { city: "Mayfield Heights", state: "OH" },
  { city: "Maynard", state: "MA" },
  {
    city: "Mays Chapel",
    state: "MD",
  },
  { city: "Maysville", state: "KY" },
  { city: "Maywood", state: "IL" },
  {
    city: "Maywood",
    state: "CA",
  },
  { city: "Maywood", state: "NJ" },
  { city: "McAlester", state: "OK" },
  {
    city: "McAllen",
    state: "TX",
  },
  { city: "McCandless Township", state: "PA" },
  { city: "McComb", state: "MS" },
  {
    city: "McCook",
    state: "NE",
  },
  { city: "McDonough", state: "GA" },
  { city: "McFarland", state: "CA" },
  {
    city: "McFarland",
    state: "WI",
  },
  { city: "McGregor", state: "FL" },
  { city: "McGuire AFB", state: "NJ" },
  {
    city: "McHenry",
    state: "IL",
  },
  { city: "McKees Rocks", state: "PA" },
  { city: "McKeesport", state: "PA" },
  {
    city: "McKinleyville",
    state: "CA",
  },
  { city: "McKinney", state: "TX" },
  { city: "McLean", state: "VA" },
  {
    city: "McMinnville",
    state: "TN",
  },
  { city: "McMinnville", state: "OR" },
  { city: "McPherson", state: "KS" },
  {
    city: "Meadow Woods",
    state: "FL",
  },
  { city: "Meadville", state: "PA" },
  { city: "Mebane", state: "NC" },
  {
    city: "Mechanicsburg",
    state: "PA",
  },
  { city: "Mechanicstown", state: "NY" },
  { city: "Mechanicsville", state: "VA" },
  {
    city: "Medfield",
    state: "MA",
  },
  { city: "Medford", state: "MA" },
  { city: "Medford", state: "NY" },
  {
    city: "Medford",
    state: "OR",
  },
  { city: "Medina", state: "OH" },
  { city: "Medina", state: "NY" },
  {
    city: "Medulla",
    state: "FL",
  },
  { city: "Medway", state: "MA" },
  { city: "Mehlville", state: "MO" },
  {
    city: "Melbourne",
    state: "FL",
  },
  { city: "Melrose", state: "MA" },
  { city: "Melrose Park", state: "FL" },
  {
    city: "Melrose Park",
    state: "IL",
  },
  { city: "Melville", state: "NY" },
  { city: "Melvindale", state: "MI" },
  {
    city: "Memphis",
    state: "FL",
  },
  { city: "Memphis", state: "TN" },
  { city: "Menasha", state: "WI" },
  {
    city: "Mendon",
    state: "NY",
  },
  { city: "Mendota", state: "CA" },
  { city: "Mendota", state: "IL" },
  {
    city: "Mendota Heights",
    state: "MN",
  },
  { city: "Menlo Park", state: "CA" },
  { city: "Menominee", state: "MI" },
  {
    city: "Menomonee Falls",
    state: "WI",
  },
  { city: "Menomonie", state: "WI" },
  { city: "Mentone", state: "CA" },
  {
    city: "Mentor",
    state: "OH",
  },
  { city: "Mentor-on-the-Lake", state: "OH" },
  { city: "Mequon", state: "WI" },
  {
    city: "Meraux",
    state: "LA",
  },
  { city: "Merced", state: "CA" },
  { city: "Mercedes", state: "TX" },
  {
    city: "Mercer Island",
    state: "WA",
  },
  { city: "Mercerville-Hamilton Square", state: "NJ" },
  { city: "Meriden", state: "CT" },
  {
    city: "Meridian",
    state: "ID",
  },
  { city: "Meridian", state: "MS" },
  { city: "Merriam", state: "KS" },
  {
    city: "Merrick",
    state: "NY",
  },
  { city: "Merrifield", state: "VA" },
  { city: "Merrill", state: "WI" },
  {
    city: "Merrillville",
    state: "IN",
  },
  { city: "Merrimac", state: "MA" },
  { city: "Merrimack", state: "NH" },
  {
    city: "Merritt Island",
    state: "FL",
  },
  { city: "Merrydale", state: "LA" },
  { city: "Merton", state: "WI" },
  {
    city: "Mesa",
    state: "AZ",
  },
  { city: "Mesquite", state: "NV" },
  { city: "Mesquite", state: "TX" },
  {
    city: "Metairie",
    state: "LA",
  },
  { city: "Methuen", state: "MA" },
  { city: "Metropolis", state: "IL" },
  {
    city: "Metuchen",
    state: "NJ",
  },
  { city: "Mexia", state: "TX" },
  { city: "Mexico", state: "MO" },
  {
    city: "Miami",
    state: "FL",
  },
  { city: "Miami", state: "OK" },
  { city: "Miami Beach", state: "FL" },
  {
    city: "Miami Lakes",
    state: "FL",
  },
  { city: "Miami Shores", state: "FL" },
  { city: "Miami Springs", state: "FL" },
  {
    city: "Miamisburg",
    state: "OH",
  },
  { city: "Micco", state: "FL" },
  { city: "Michigan City", state: "IN" },
  {
    city: "Middle Island",
    state: "NY",
  },
  { city: "Middle River", state: "MD" },
  { city: "Middle Valley", state: "TN" },
  {
    city: "Middleborough",
    state: "MA",
  },
  { city: "Middleborough Center", state: "MA" },
  {
    city: "Middleburg",
    state: "FL",
  },
  { city: "Middleburg Heights", state: "OH" },
  { city: "Middlebury", state: "VT" },
  {
    city: "Middlebury",
    state: "CT",
  },
  { city: "Middlesborough", state: "KY" },
  { city: "Middlesex", state: "NJ" },
  {
    city: "Middleton",
    state: "MA",
  },
  { city: "Middleton", state: "WI" },
  { city: "Middletown", state: "OH" },
  {
    city: "Middletown",
    state: "PA",
  },
  { city: "Middletown", state: "RI" },
  { city: "Middletown", state: "NY" },
  {
    city: "Middletown",
    state: "CT",
  },
  { city: "Middletown", state: "DE" },
  { city: "Midland", state: "MI" },
  {
    city: "Midland",
    state: "TX",
  },
  { city: "Midland", state: "WA" },
  { city: "Midland Park", state: "NJ" },
  {
    city: "Midlothian",
    state: "IL",
  },
  { city: "Midlothian", state: "TX" },
  { city: "Midvale", state: "UT" },
  {
    city: "Midwest City",
    state: "OK",
  },
  { city: "Milan", state: "TN" },
  { city: "Miles City", state: "MT" },
  {
    city: "Milford",
    state: "NH",
  },
  { city: "Milford", state: "MI" },
  { city: "Milford", state: "MA" },
  {
    city: "Milford",
    state: "DE",
  },
  { city: "Milford", state: "CT" },
  { city: "Milford", state: "OH" },
  {
    city: "Milford Mill",
    state: "MD",
  },
  { city: "Mililani Town", state: "HI" },
  { city: "Mill Creek", state: "WA" },
  {
    city: "Mill Plain",
    state: "WA",
  },
  { city: "Mill Valley", state: "CA" },
  { city: "Millbrae", state: "CA" },
  {
    city: "Millbrook",
    state: "AL",
  },
  { city: "Millburn", state: "NJ" },
  { city: "Millbury", state: "MA" },
  {
    city: "Millcreek",
    state: "UT",
  },
  { city: "Milledgeville", state: "GA" },
  { city: "Miller Place", state: "NY" },
  {
    city: "Millersville",
    state: "PA",
  },
  { city: "Millington", state: "TN" },
  { city: "Millis", state: "MA" },
  {
    city: "Milltown",
    state: "NJ",
  },
  { city: "Millville", state: "NJ" },
  { city: "Milo", state: "NY" },
  {
    city: "Milpitas",
    state: "CA",
  },
  { city: "Milton", state: "FL" },
  { city: "Milton", state: "NY" },
  {
    city: "Milton",
    state: "MA",
  },
  { city: "Milton", state: "PA" },
  { city: "Milton", state: "VT" },
  {
    city: "Milton-Freewater",
    state: "OR",
  },
  { city: "Milwaukee", state: "WI" },
  { city: "Milwaukie", state: "OR" },
  {
    city: "Mims",
    state: "FL",
  },
  { city: "Minden", state: "LA" },
  { city: "Mineola", state: "NY" },
  {
    city: "Mineral Wells",
    state: "TX",
  },
  { city: "Minneapolis", state: "MN" },
  { city: "Minnehaha", state: "WA" },
  {
    city: "Minnetonka",
    state: "MN",
  },
  { city: "Minot", state: "ND" },
  { city: "Minot AFB", state: "ND" },
  {
    city: "Mint Hill",
    state: "NC",
  },
  { city: "Mira Loma", state: "CA" },
  { city: "Mira Monte", state: "CA" },
  {
    city: "Miramar",
    state: "FL",
  },
  { city: "Mishawaka", state: "IN" },
  { city: "Mission", state: "KS" },
  {
    city: "Mission",
    state: "TX",
  },
  { city: "Mission Bend", state: "TX" },
  { city: "Mission Viejo", state: "CA" },
  {
    city: "Missoula",
    state: "MT",
  },
  { city: "Missouri City", state: "TX" },
  { city: "Mitchell", state: "SD" },
  {
    city: "Mitchellville",
    state: "MD",
  },
  { city: "Moberly", state: "MO" },
  { city: "Mobile", state: "AL" },
  {
    city: "Modesto",
    state: "CA",
  },
  { city: "Mohave Valley", state: "AZ" },
  { city: "Mokena", state: "IL" },
  {
    city: "Moline",
    state: "IL",
  },
  { city: "Monaca", state: "PA" },
  { city: "Monahans", state: "TX" },
  {
    city: "Monessen",
    state: "PA",
  },
  { city: "Monett", state: "MO" },
  { city: "Monmouth", state: "IL" },
  {
    city: "Monmouth",
    state: "OR",
  },
  { city: "Monona", state: "WI" },
  { city: "Monroe", state: "WI" },
  {
    city: "Monroe",
    state: "WA",
  },
  { city: "Monroe", state: "OH" },
  { city: "Monroe", state: "CT" },
  {
    city: "Monroe",
    state: "GA",
  },
  { city: "Monroe", state: "MI" },
  { city: "Monroe", state: "LA" },
  {
    city: "Monroe",
    state: "NC",
  },
  { city: "Monroe", state: "NY" },
  { city: "Monroeville", state: "AL" },
  {
    city: "Monrovia",
    state: "CA",
  },
  { city: "Monsey", state: "NY" },
  { city: "Monson", state: "MA" },
  {
    city: "Montague",
    state: "MA",
  },
  { city: "Montclair", state: "NJ" },
  { city: "Montclair", state: "CA" },
  {
    city: "Montclair",
    state: "VA",
  },
  { city: "Montebello", state: "CA" },
  { city: "Montecito", state: "CA" },
  {
    city: "Monterey",
    state: "CA",
  },
  { city: "Monterey Park", state: "CA" },
  { city: "Montgomery", state: "AL" },
  {
    city: "Montgomery",
    state: "NY",
  },
  { city: "Montgomery", state: "OH" },
  {
    city: "Montgomery Village",
    state: "MD",
  },
  { city: "Montgomeryville", state: "PA" },
  { city: "Monticello", state: "MN" },
  {
    city: "Monticello",
    state: "NY",
  },
  { city: "Monticello", state: "AR" },
  { city: "Montpelier", state: "VT" },
  {
    city: "Montrose",
    state: "VA",
  },
  { city: "Montrose", state: "CO" },
  { city: "Montvale", state: "NJ" },
  {
    city: "Montville",
    state: "CT",
  },
  { city: "Moody", state: "AL" },
  { city: "Moore", state: "OK" },
  {
    city: "Moorestown-Lenola",
    state: "NJ",
  },
  { city: "Mooresville", state: "NC" },
  { city: "Mooresville", state: "IN" },
  {
    city: "Moorhead",
    state: "MN",
  },
  { city: "Moorpark", state: "CA" },
  { city: "Moraga", state: "CA" },
  {
    city: "Moraine",
    state: "OH",
  },
  { city: "Moreau", state: "NY" },
  { city: "Morehead City", state: "NC" },
  {
    city: "Moreno Valley",
    state: "CA",
  },
  { city: "Morgan City", state: "LA" },
  { city: "Morgan Hill", state: "CA" },
  {
    city: "Morganton",
    state: "NC",
  },
  { city: "Morgantown", state: "WV" },
  { city: "Morganville", state: "NJ" },
  {
    city: "Morrilton",
    state: "AR",
  },
  { city: "Morris", state: "IL" },
  { city: "Morristown", state: "NJ" },
  {
    city: "Morristown",
    state: "TN",
  },
  { city: "Morrisville", state: "PA" },
  { city: "Morro Bay", state: "CA" },
  {
    city: "Morton",
    state: "IL",
  },
  { city: "Morton Grove", state: "IL" },
  { city: "Moscow", state: "ID" },
  {
    city: "Moses Lake",
    state: "WA",
  },
  { city: "Moss Bluff", state: "LA" },
  { city: "Moss Point", state: "MS" },
  {
    city: "Moultrie",
    state: "GA",
  },
  { city: "Mound", state: "MN" },
  { city: "Mounds View", state: "MN" },
  {
    city: "Moundsville",
    state: "WV",
  },
  { city: "Mount Airy", state: "MD" },
  { city: "Mount Airy", state: "NC" },
  {
    city: "Mount Carmel",
    state: "IL",
  },
  { city: "Mount Carmel", state: "PA" },
  { city: "Mount Clemens", state: "MI" },
  {
    city: "Mount Dora",
    state: "FL",
  },
  { city: "Mount Healthy", state: "OH" },
  { city: "Mount Holly", state: "NC" },
  {
    city: "Mount Hope",
    state: "NY",
  },
  { city: "Mount Ivy", state: "NY" },
  { city: "Mount Joy", state: "PA" },
  {
    city: "Mount Juliet",
    state: "TN",
  },
  { city: "Mount Kisco", state: "NY" },
  { city: "Mount Lebanon", state: "PA" },
  {
    city: "Mount Olympus",
    state: "UT",
  },
  { city: "Mount Pleasant", state: "TX" },
  { city: "Mount Pleasant", state: "WI" },
  {
    city: "Mount Pleasant",
    state: "SC",
  },
  { city: "Mount Pleasant", state: "NY" },
  { city: "Mount Pleasant", state: "MI" },
  {
    city: "Mount Pleasant",
    state: "IA",
  },
  { city: "Mount Prospect", state: "IL" },
  { city: "Mount Rainier", state: "MD" },
  {
    city: "Mount Sinai",
    state: "NY",
  },
  { city: "Mount Vernon", state: "NY" },
  { city: "Mount Vernon", state: "IL" },
  {
    city: "Mount Vernon",
    state: "IN",
  },
  { city: "Mount Vernon", state: "OH" },
  { city: "Mount Vernon", state: "WA" },
  {
    city: "Mount Vernon",
    state: "VA",
  },
  { city: "Mount Washington", state: "KY" },
  {
    city: "Mountain Brook",
    state: "AL",
  },
  { city: "Mountain Home", state: "AR" },
  {
    city: "Mountain Home",
    state: "ID",
  },
  { city: "Mountain Home AFB", state: "ID" },
  { city: "Mountain Park", state: "GA" },
  {
    city: "Mountain Top",
    state: "PA",
  },
  { city: "Mountain View", state: "CA" },
  { city: "Mountainside", state: "NJ" },
  {
    city: "Mountlake Terrace",
    state: "WA",
  },
  { city: "Mukilteo", state: "WA" },
  { city: "Mukwonago", state: "WI" },
  {
    city: "Muncie",
    state: "IN",
  },
  { city: "Mundelein", state: "IL" },
  {
    city: "Munhall",
    state: "PA",
  },
  { city: "Municipality of Monroeville", state: "PA" },
  {
    city: "Municipality of Murrysville",
    state: "PA",
  },
  { city: "Munster", state: "IN" },
  { city: "Murfreesboro", state: "TN" },
  {
    city: "Murphy",
    state: "MO",
  },
  { city: "Murphysboro", state: "IL" },
  { city: "Murray", state: "KY" },
  {
    city: "Murray",
    state: "NY",
  },
  { city: "Murray", state: "UT" },
  { city: "Murraysville", state: "NC" },
  {
    city: "Murrieta",
    state: "CA",
  },
  { city: "Muscatine", state: "IA" },
  { city: "Muscle Shoals", state: "AL" },
  {
    city: "Muscoy",
    state: "CA",
  },
  { city: "Muskego", state: "WI" },
  { city: "Muskegon", state: "MI" },
  {
    city: "Muskegon Heights",
    state: "MI",
  },
  { city: "Muskogee", state: "OK" },
  { city: "Mustang", state: "OK" },
  {
    city: "Myrtle Beach",
    state: "SC",
  },
  { city: "Myrtle Grove", state: "NC" },
  { city: "Myrtle Grove", state: "FL" },
  {
    city: "Mystic Island",
    state: "NJ",
  },
  { city: "Nacogdoches", state: "TX" },
  { city: "Nampa", state: "ID" },
  {
    city: "Nanakuli",
    state: "HI",
  },
  { city: "Nanticoke", state: "PA" },
  { city: "Nantucket", state: "MA" },
  {
    city: "Nanuet",
    state: "NY",
  },
  { city: "Napa", state: "CA" },
  { city: "Naperville", state: "IL" },
  {
    city: "Napili-Honokowai",
    state: "HI",
  },
  { city: "Naples", state: "FL" },
  { city: "Naples Park", state: "FL" },
  {
    city: "Napoleon",
    state: "OH",
  },
  { city: "Nappanee", state: "IN" },
  { city: "Narragansett", state: "RI" },
  {
    city: "Nashua",
    state: "NH",
  },
  { city: "Nashville-Davidson", state: "TN" },
  { city: "Natchez", state: "MS" },
  {
    city: "Natchitoches",
    state: "LA",
  },
  { city: "Natick", state: "MA" },
  { city: "National City", state: "CA" },
  {
    city: "Naugatuck",
    state: "CT",
  },
  { city: "Navasota", state: "TX" },
  { city: "Nazareth", state: "PA" },
  {
    city: "Nebraska City",
    state: "NE",
  },
  { city: "Nederland", state: "TX" },
  { city: "Needham", state: "MA" },
  {
    city: "Neenah",
    state: "WI",
  },
  { city: "Nellis AFB", state: "NV" },
  { city: "Neosho", state: "MO" },
  {
    city: "Neptune Beach",
    state: "FL",
  },
  { city: "Nesconset", state: "NY" },
  { city: "Nether Providence Township", state: "PA" },
  {
    city: "Nevada",
    state: "MO",
  },
  { city: "Nevada", state: "IA" },
  { city: "New Albany", state: "IN" },
  {
    city: "New Albany",
    state: "MS",
  },
  { city: "New Baltimore", state: "MI" },
  { city: "New Bedford", state: "MA" },
  {
    city: "New Berlin",
    state: "WI",
  },
  { city: "New Bern", state: "NC" },
  { city: "New Braunfels", state: "TX" },
  {
    city: "New Brighton",
    state: "PA",
  },
  { city: "New Brighton", state: "MN" },
  { city: "New Britain", state: "CT" },
  {
    city: "New Brunswick",
    state: "NJ",
  },
  { city: "New Canaan", state: "CT" },
  { city: "New Carrollton", state: "MD" },
  {
    city: "New Cassel",
    state: "NY",
  },
  { city: "New Castle", state: "NY" },
  { city: "New Castle", state: "PA" },
  {
    city: "New Castle",
    state: "IN",
  },
  { city: "New City", state: "NY" },
  { city: "New Cumberland", state: "PA" },
  {
    city: "New Fairfield",
    state: "CT",
  },
  { city: "New Hartford", state: "CT" },
  { city: "New Hartford", state: "NY" },
  {
    city: "New Haven",
    state: "CT",
  },
  { city: "New Haven", state: "IN" },
  { city: "New Hope", state: "MN" },
  {
    city: "New Hyde Park",
    state: "NY",
  },
  { city: "New Iberia", state: "LA" },
  { city: "New Kensington", state: "PA" },
  {
    city: "New Kingman-Butler",
    state: "AZ",
  },
  { city: "New Lenox", state: "IL" },
  { city: "New London", state: "CT" },
  {
    city: "New London",
    state: "WI",
  },
  { city: "New Milford", state: "CT" },
  { city: "New Milford", state: "NJ" },
  {
    city: "New Orleans",
    state: "LA",
  },
  { city: "New Paltz", state: "NY" },
  { city: "New Philadelphia", state: "OH" },
  {
    city: "New Port Richey",
    state: "FL",
  },
  { city: "New Port Richey East", state: "FL" },
  {
    city: "New Providence",
    state: "NJ",
  },
  { city: "New Richmond", state: "WI" },
  { city: "New River", state: "AZ" },
  {
    city: "New Rochelle",
    state: "NY",
  },
  { city: "New Scotland", state: "NY" },
  { city: "New Smyrna Beach", state: "FL" },
  {
    city: "New Territory",
    state: "TX",
  },
  { city: "New Ulm", state: "MN" },
  { city: "New Windsor", state: "NY" },
  {
    city: "New York",
    state: "NY",
  },
  { city: "Newark", state: "NJ" },
  { city: "Newark", state: "NY" },
  {
    city: "Newark",
    state: "OH",
  },
  { city: "Newark", state: "DE" },
  { city: "Newark", state: "CA" },
  {
    city: "Newberg",
    state: "OR",
  },
  { city: "Newberry", state: "SC" },
  { city: "Newburg", state: "KY" },
  {
    city: "Newburgh",
    state: "NY",
  },
  { city: "Newbury", state: "MA" },
  { city: "Newburyport", state: "MA" },
  {
    city: "Newcastle",
    state: "WA",
  },
  { city: "Newfane", state: "NY" },
  { city: "Newington", state: "VA" },
  {
    city: "Newington",
    state: "CT",
  },
  { city: "Newman", state: "CA" },
  { city: "Newmarket", state: "NH" },
  {
    city: "Newnan",
    state: "GA",
  },
  { city: "Newport", state: "AR" },
  { city: "Newport", state: "KY" },
  {
    city: "Newport",
    state: "NH",
  },
  { city: "Newport", state: "TN" },
  { city: "Newport", state: "RI" },
  {
    city: "Newport",
    state: "OR",
  },
  { city: "Newport Beach", state: "CA" },
  { city: "Newport East", state: "RI" },
  {
    city: "Newport News",
    state: "VA",
  },
  { city: "Newstead", state: "NY" },
  { city: "Newton", state: "NC" },
  {
    city: "Newton",
    state: "KS",
  },
  { city: "Newton", state: "IA" },
  { city: "Newton", state: "MA" },
  {
    city: "Newton",
    state: "NJ",
  },
  { city: "Newtown", state: "CT" },
  { city: "Niagara", state: "NY" },
  {
    city: "Niagara Falls",
    state: "NY",
  },
  { city: "Niceville", state: "FL" },
  { city: "Nicholasville", state: "KY" },
  {
    city: "Niles",
    state: "MI",
  },
  { city: "Niles", state: "IL" },
  { city: "Niles", state: "OH" },
  {
    city: "Nipomo",
    state: "CA",
  },
  { city: "Niskayuna", state: "NY" },
  { city: "Nitro", state: "WV" },
  {
    city: "Nixa",
    state: "MO",
  },
  { city: "Noblesville", state: "IN" },
  { city: "Nogales", state: "AZ" },
  {
    city: "Norco",
    state: "CA",
  },
  { city: "Norcross", state: "GA" },
  { city: "Norfolk", state: "MA" },
  {
    city: "Norfolk",
    state: "NE",
  },
  { city: "Norfolk", state: "VA" },
  { city: "Norland", state: "FL" },
  {
    city: "Normal",
    state: "IL",
  },
  { city: "Norman", state: "OK" },
  { city: "Normandy Park", state: "WA" },
  {
    city: "Norridge",
    state: "IL",
  },
  { city: "Norristown", state: "PA" },
  { city: "North Adams", state: "MA" },
  {
    city: "North Amherst",
    state: "MA",
  },
  { city: "North Amityville", state: "NY" },
  {
    city: "North Andover",
    state: "MA",
  },
  { city: "North Andrews Gardens", state: "FL" },
  {
    city: "North Arlington",
    state: "NJ",
  },
  { city: "North Atlanta", state: "GA" },
  {
    city: "North Attleborough",
    state: "MA",
  },
  { city: "North Attleborough Center", state: "MA" },
  {
    city: "North Auburn",
    state: "CA",
  },
  { city: "North Augusta", state: "SC" },
  { city: "North Aurora", state: "IL" },
  {
    city: "North Babylon",
    state: "NY",
  },
  { city: "North Bay Shore", state: "NY" },
  {
    city: "North Bay Village",
    state: "FL",
  },
  { city: "North Bellmore", state: "NY" },
  { city: "North Bellport", state: "NY" },
  {
    city: "North Bend",
    state: "OR",
  },
  { city: "North Bethesda", state: "MD" },
  { city: "North Braddock", state: "PA" },
  {
    city: "North Branch",
    state: "MN",
  },
  { city: "North Branford", state: "CT" },
  {
    city: "North Brunswick Township",
    state: "NJ",
  },
  { city: "North Caldwell", state: "NJ" },
  { city: "North Canton", state: "OH" },
  {
    city: "North Castle",
    state: "NY",
  },
  { city: "North Charleston", state: "SC" },
  {
    city: "North Chicago",
    state: "IL",
  },
  { city: "North College Hill", state: "OH" },
  { city: "North Creek", state: "WA" },
  {
    city: "North Decatur",
    state: "GA",
  },
  { city: "North Druid Hills", state: "GA" },
  { city: "North Elba", state: "NY" },
  {
    city: "North Fair Oaks",
    state: "CA",
  },
  { city: "North Fort Myers", state: "FL" },
  {
    city: "North Greenbush",
    state: "NY",
  },
  { city: "North Haledon", state: "NJ" },
  { city: "North Haven", state: "CT" },
  {
    city: "North Hempstead",
    state: "NY",
  },
  { city: "North Highlands", state: "CA" },
  {
    city: "North Kensington",
    state: "MD",
  },
  { city: "North Kingstown", state: "RI" },
  {
    city: "North Las Vegas",
    state: "NV",
  },
  { city: "North Lauderdale", state: "FL" },
  {
    city: "North Laurel",
    state: "MD",
  },
  { city: "North Lindenhurst", state: "NY" },
  {
    city: "North Little Rock",
    state: "AR",
  },
  { city: "North Logan", state: "UT" },
  { city: "North Madison", state: "OH" },
  {
    city: "North Manchester",
    state: "IN",
  },
  { city: "North Mankato", state: "MN" },
  {
    city: "North Marysville",
    state: "WA",
  },
  { city: "North Massapequa", state: "NY" },
  { city: "North Merrick", state: "NY" },
  {
    city: "North Miami",
    state: "FL",
  },
  { city: "North Miami Beach", state: "FL" },
  {
    city: "North Myrtle Beach",
    state: "SC",
  },
  { city: "North New Hyde Park", state: "NY" },
  {
    city: "North Ogden",
    state: "UT",
  },
  { city: "North Olmsted", state: "OH" },
  {
    city: "North Palm Beach",
    state: "FL",
  },
  { city: "North Patchogue", state: "NY" },
  {
    city: "North Plainfield",
    state: "NJ",
  },
  { city: "North Platte", state: "NE" },
  { city: "North Port", state: "FL" },
  {
    city: "North Potomac",
    state: "MD",
  },
  { city: "North Providence", state: "RI" },
  {
    city: "North Reading",
    state: "MA",
  },
  { city: "North Richland Hills", state: "TX" },
  {
    city: "North Ridgeville",
    state: "OH",
  },
  { city: "North Riverside", state: "IL" },
  {
    city: "North Royalton",
    state: "OH",
  },
  { city: "North Salt Lake", state: "UT" },
  {
    city: "North Sarasota",
    state: "FL",
  },
  { city: "North Smithfield", state: "RI" },
  {
    city: "North Springfield",
    state: "VA",
  },
  { city: "North St. Paul", state: "MN" },
  { city: "North Star", state: "DE" },
  {
    city: "North Syracuse",
    state: "NY",
  },
  { city: "North Tonawanda", state: "NY" },
  {
    city: "North Valley",
    state: "NM",
  },
  { city: "North Valley Stream", state: "NY" },
  {
    city: "North Vernon",
    state: "IN",
  },
  { city: "North Versailles", state: "PA" },
  { city: "North Wantagh", state: "NY" },
  {
    city: "Northampton",
    state: "PA",
  },
  { city: "Northampton", state: "MA" },
  { city: "Northborough", state: "MA" },
  {
    city: "Northbridge",
    state: "MA",
  },
  { city: "Northbrook", state: "IL" },
  { city: "Northbrook", state: "OH" },
  {
    city: "Northfield",
    state: "MN",
  },
  { city: "Northfield", state: "NJ" },
  { city: "Northgate", state: "OH" },
  {
    city: "Northglenn",
    state: "CO",
  },
  { city: "Northlake", state: "IL" },
  { city: "Northport", state: "AL" },
  {
    city: "Northport",
    state: "NY",
  },
  { city: "Northridge", state: "OH" },
  { city: "Northview", state: "MI" },
  {
    city: "Northville",
    state: "MI",
  },
  { city: "Northwest Harborcreek", state: "PA" },
  { city: "Norton", state: "OH" },
  {
    city: "Norton",
    state: "MA",
  },
  { city: "Norton Shores", state: "MI" },
  { city: "Norwalk", state: "IA" },
  {
    city: "Norwalk",
    state: "CT",
  },
  { city: "Norwalk", state: "CA" },
  { city: "Norwalk", state: "OH" },
  {
    city: "Norway",
    state: "WI",
  },
  { city: "Norwell", state: "MA" },
  { city: "Norwich", state: "NY" },
  {
    city: "Norwich",
    state: "CT",
  },
  { city: "Norwood", state: "MA" },
  { city: "Norwood", state: "OH" },
  {
    city: "Novato",
    state: "CA",
  },
  { city: "Novi", state: "MI" },
  { city: "Nutley", state: "NJ" },
  {
    city: "Nyack",
    state: "NY",
  },
  { city: "O’Fallon", state: "IL" },
  { city: "O’Fallon", state: "MO" },
  {
    city: "O’Hara Township",
    state: "PA",
  },
  { city: "Oak Brook", state: "IL" },
  { city: "Oak Creek", state: "WI" },
  {
    city: "Oak Forest",
    state: "IL",
  },
  { city: "Oak Grove", state: "KY" },
  { city: "Oak Grove", state: "SC" },
  {
    city: "Oak Grove",
    state: "OR",
  },
  { city: "Oak Grove", state: "MN" },
  { city: "Oak Harbor", state: "WA" },
  {
    city: "Oak Hill",
    state: "WV",
  },
  { city: "Oak Hills", state: "OR" },
  { city: "Oak Hills Place", state: "LA" },
  {
    city: "Oak Island",
    state: "NC",
  },
  { city: "Oak Lawn", state: "IL" },
  { city: "Oak Park", state: "IL" },
  {
    city: "Oak Park",
    state: "MI",
  },
  { city: "Oak Ridge", state: "TN" },
  { city: "Oak Ridge", state: "FL" },
  {
    city: "Oakbrook",
    state: "KY",
  },
  { city: "Oakdale", state: "LA" },
  { city: "Oakdale", state: "CA" },
  {
    city: "Oakdale",
    state: "MN",
  },
  { city: "Oakdale", state: "NY" },
  { city: "Oakland", state: "NJ" },
  {
    city: "Oakland",
    state: "CA",
  },
  { city: "Oakland Park", state: "FL" },
  { city: "Oakley", state: "CA" },
  {
    city: "Oakmont",
    state: "PA",
  },
  { city: "Oakton", state: "VA" },
  { city: "Oakville", state: "MO" },
  {
    city: "Oakville",
    state: "CT",
  },
  { city: "Oakwood", state: "OH" },
  { city: "Oatfield", state: "OR" },
  {
    city: "Oberlin",
    state: "OH",
  },
  { city: "Ocala", state: "FL" },
  { city: "Ocean Acres", state: "NJ" },
  {
    city: "Ocean City",
    state: "NJ",
  },
  { city: "Ocean City", state: "MD" },
  { city: "Ocean Pines", state: "MD" },
  {
    city: "Ocean Springs",
    state: "MS",
  },
  { city: "Oceano", state: "CA" },
  { city: "Oceanside", state: "CA" },
  {
    city: "Oceanside",
    state: "NY",
  },
  { city: "Ocoee", state: "FL" },
  { city: "Oconomowoc", state: "WI" },
  {
    city: "Odenton",
    state: "MD",
  },
  { city: "Odessa", state: "TX" },
  { city: "Oelwein", state: "IA" },
  {
    city: "Offutt AFB",
    state: "NE",
  },
  { city: "Ogden", state: "NY" },
  { city: "Ogden", state: "UT" },
  {
    city: "Ogdensburg",
    state: "NY",
  },
  { city: "Oil City", state: "PA" },
  { city: "Oildale", state: "CA" },
  {
    city: "Ojai",
    state: "CA",
  },
  { city: "Ojus", state: "FL" },
  { city: "Okemos", state: "MI" },
  {
    city: "Oklahoma City",
    state: "OK",
  },
  { city: "Okmulgee", state: "OK" },
  { city: "Okolona", state: "KY" },
  {
    city: "Olathe",
    state: "KS",
  },
  { city: "Old Bridge", state: "NJ" },
  { city: "Old Forge", state: "PA" },
  {
    city: "Old Lyme",
    state: "CT",
  },
  { city: "Old Orchard Beach", state: "ME" },
  { city: "Old Saybrook", state: "CT" },
  {
    city: "Old Town",
    state: "ME",
  },
  { city: "Oldsmar", state: "FL" },
  { city: "Olean", state: "NY" },
  {
    city: "Olive Branch",
    state: "MS",
  },
  { city: "Olivehurst", state: "CA" },
  { city: "Olivette", state: "MO" },
  {
    city: "Olmsted Falls",
    state: "OH",
  },
  { city: "Olney", state: "MD" },
  { city: "Olney", state: "IL" },
  {
    city: "Olympia",
    state: "WA",
  },
  { city: "Olympia Heights", state: "FL" },
  { city: "Omaha", state: "NE" },
  {
    city: "Onalaska",
    state: "WI",
  },
  { city: "Oneida", state: "NY" },
  { city: "Oneonta", state: "NY" },
  {
    city: "Onondaga",
    state: "NY",
  },
  { city: "Ontario", state: "NY" },
  { city: "Ontario", state: "OR" },
  {
    city: "Ontario",
    state: "CA",
  },
  { city: "Opa-locka", state: "FL" },
  { city: "Opa-locka North", state: "FL" },
  {
    city: "Opal Cliffs",
    state: "CA",
  },
  { city: "Opelika", state: "AL" },
  { city: "Opelousas", state: "LA" },
  {
    city: "Opp",
    state: "AL",
  },
  { city: "Opportunity", state: "WA" },
  { city: "Oquirrh", state: "UT" },
  {
    city: "Oradell",
    state: "NJ",
  },
  { city: "Orange", state: "NJ" },
  { city: "Orange", state: "MA" },
  {
    city: "Orange",
    state: "TX",
  },
  { city: "Orange", state: "CT" },
  { city: "Orange", state: "CA" },
  {
    city: "Orange City",
    state: "FL",
  },
  { city: "Orange Cove", state: "CA" },
  { city: "Orange Lake", state: "NY" },
  {
    city: "Orange Park",
    state: "FL",
  },
  { city: "Orangeburg", state: "SC" },
  { city: "Orangetown", state: "NY" },
  {
    city: "Orangevale",
    state: "CA",
  },
  { city: "Orchard Mesa", state: "CO" },
  { city: "Orchard Park", state: "NY" },
  {
    city: "Orchards",
    state: "WA",
  },
  { city: "Orcutt", state: "CA" },
  { city: "Oregon", state: "OH" },
  {
    city: "Oregon",
    state: "WI",
  },
  { city: "Oregon City", state: "OR" },
  { city: "Orem", state: "UT" },
  {
    city: "Orinda",
    state: "CA",
  },
  { city: "Orland", state: "CA" },
  { city: "Orland Hills", state: "IL" },
  {
    city: "Orland Park",
    state: "IL",
  },
  { city: "Orlando", state: "FL" },
  { city: "Orleans", state: "MA" },
  {
    city: "Orlovista",
    state: "FL",
  },
  { city: "Ormond Beach", state: "FL" },
  { city: "Ormond-By-The-Sea", state: "FL" },
  {
    city: "Oro Valley",
    state: "AZ",
  },
  { city: "Orono", state: "ME" },
  { city: "Orono", state: "MN" },
  {
    city: "Orosi",
    state: "CA",
  },
  { city: "Oroville", state: "CA" },
  { city: "Oroville East", state: "CA" },
  {
    city: "Orrville",
    state: "OH",
  },
  { city: "Osceola", state: "AR" },
  { city: "Oshkosh", state: "WI" },
  {
    city: "Oskaloosa",
    state: "IA",
  },
  { city: "Ossining", state: "NY" },
  { city: "Oswego", state: "NY" },
  {
    city: "Oswego",
    state: "IL",
  },
  { city: "Otis Orchards-East Farms", state: "WA" },
  { city: "Otsego", state: "MN" },
  {
    city: "Ottawa",
    state: "IL",
  },
  { city: "Ottawa", state: "KS" },
  { city: "Ottumwa", state: "IA" },
  {
    city: "Overland",
    state: "MO",
  },
  { city: "Overland Park", state: "KS" },
  { city: "Overlea", state: "MD" },
  {
    city: "Oviedo",
    state: "FL",
  },
  { city: "Owasso", state: "OK" },
  { city: "Owatonna", state: "MN" },
  {
    city: "Owego",
    state: "NY",
  },
  { city: "Owensboro", state: "KY" },
  { city: "Owings Mills", state: "MD" },
  {
    city: "Owosso",
    state: "MI",
  },
  { city: "Oxford", state: "MA" },
  { city: "Oxford", state: "MS" },
  {
    city: "Oxford",
    state: "NC",
  },
  { city: "Oxford", state: "OH" },
  { city: "Oxford", state: "CT" },
  {
    city: "Oxford",
    state: "AL",
  },
  { city: "Oxnard", state: "CA" },
  { city: "Oxon Hill-Glassmanor", state: "MD" },
  {
    city: "Oyster Bay",
    state: "NY",
  },
  { city: "Ozark", state: "MO" },
  { city: "Ozark", state: "AL" },
  {
    city: "Pace",
    state: "FL",
  },
  { city: "Pacific Grove", state: "CA" },
  { city: "Pacifica", state: "CA" },
  {
    city: "Paducah",
    state: "KY",
  },
  { city: "Page", state: "AZ" },
  { city: "Pahrump", state: "NV" },
  {
    city: "Paine Field-Lake Stickney",
    state: "WA",
  },
  { city: "Painesville", state: "OH" },
  { city: "Palatine", state: "IL" },
  {
    city: "Palatka",
    state: "FL",
  },
  { city: "Palestine", state: "TX" },
  { city: "Palisades Park", state: "NJ" },
  {
    city: "Palm Bay",
    state: "FL",
  },
  { city: "Palm Beach", state: "FL" },
  { city: "Palm Beach Gardens", state: "FL" },
  {
    city: "Palm City",
    state: "FL",
  },
  { city: "Palm Coast", state: "FL" },
  { city: "Palm Desert", state: "CA" },
  {
    city: "Palm Harbor",
    state: "FL",
  },
  { city: "Palm River-Clair Mel", state: "FL" },
  {
    city: "Palm Springs",
    state: "FL",
  },
  { city: "Palm Springs", state: "CA" },
  { city: "Palm Valley", state: "FL" },
  {
    city: "Palmdale",
    state: "CA",
  },
  { city: "Palmer", state: "MA" },
  { city: "Palmetto", state: "FL" },
  {
    city: "Palmetto Estates",
    state: "FL",
  },
  { city: "Palmview South", state: "TX" },
  { city: "Palmyra", state: "PA" },
  {
    city: "Palmyra",
    state: "NJ",
  },
  { city: "Palmyra", state: "NY" },
  { city: "Palo Alto", state: "CA" },
  {
    city: "Palos Heights",
    state: "IL",
  },
  { city: "Palos Hills", state: "IL" },
  { city: "Palos Verdes Estates", state: "CA" },
  {
    city: "Pampa",
    state: "TX",
  },
  { city: "Panama City", state: "FL" },
  { city: "Panama City Beach", state: "FL" },
  {
    city: "Panthersville",
    state: "GA",
  },
  { city: "Papillion", state: "NE" },
  { city: "Paradise", state: "NV" },
  {
    city: "Paradise",
    state: "CA",
  },
  { city: "Paradise Valley", state: "AZ" },
  { city: "Paragould", state: "AR" },
  {
    city: "Paramount",
    state: "CA",
  },
  { city: "Paramus", state: "NJ" },
  { city: "Paris", state: "TX" },
  {
    city: "Paris",
    state: "TN",
  },
  { city: "Paris", state: "IL" },
  { city: "Paris", state: "KY" },
  {
    city: "Park City",
    state: "IL",
  },
  { city: "Park City", state: "UT" },
  { city: "Park Forest", state: "IL" },
  {
    city: "Park Forest Village",
    state: "PA",
  },
  { city: "Park Hills", state: "MO" },
  { city: "Park Ridge", state: "NJ" },
  {
    city: "Park Ridge",
    state: "IL",
  },
  { city: "Parker", state: "CO" },
  { city: "Parker", state: "SC" },
  {
    city: "Parkersburg",
    state: "WV",
  },
  { city: "Parkland", state: "WA" },
  { city: "Parkland", state: "FL" },
  {
    city: "Parkville",
    state: "MD",
  },
  { city: "Parkville", state: "PA" },
  { city: "Parkway-South Sacramento", state: "CA" },
  {
    city: "Parkwood",
    state: "WA",
  },
  { city: "Parlier", state: "CA" },
  { city: "Parma", state: "NY" },
  {
    city: "Parma",
    state: "OH",
  },
  { city: "Parma Heights", state: "OH" },
  { city: "Parole", state: "MD" },
  {
    city: "Parsons",
    state: "KS",
  },
  { city: "Pasadena", state: "MD" },
  { city: "Pasadena", state: "CA" },
  {
    city: "Pasadena",
    state: "TX",
  },
  { city: "Pascagoula", state: "MS" },
  { city: "Pasco", state: "WA" },
  {
    city: "Pass Christian",
    state: "MS",
  },
  { city: "Passaic", state: "NJ" },
  { city: "Pataskala", state: "OH" },
  {
    city: "Patchogue",
    state: "NY",
  },
  { city: "Paterson", state: "NJ" },
  { city: "Patterson", state: "NY" },
  {
    city: "Patterson",
    state: "CA",
  },
  { city: "Pauls Valley", state: "OK" },
  { city: "Paulsboro", state: "NJ" },
  {
    city: "Pawling",
    state: "NY",
  },
  { city: "Pawtucket", state: "RI" },
  { city: "Payette", state: "ID" },
  {
    city: "Payson",
    state: "AZ",
  },
  { city: "Payson", state: "UT" },
  { city: "Pea Ridge", state: "WV" },
  {
    city: "Peabody",
    state: "MA",
  },
  { city: "Peachtree City", state: "GA" },
  { city: "Pearl", state: "MS" },
  {
    city: "Pearl City",
    state: "HI",
  },
  { city: "Pearl River", state: "NY" },
  { city: "Pearland", state: "TX" },
  {
    city: "Pearsall",
    state: "TX",
  },
  { city: "Pecan Grove", state: "TX" },
  { city: "Pecos", state: "TX" },
  {
    city: "Pedley",
    state: "CA",
  },
  { city: "Peekskill", state: "NY" },
  { city: "Pekin", state: "IL" },
  {
    city: "Pelham",
    state: "AL",
  },
  { city: "Pelham", state: "NY" },
  { city: "Pelham", state: "NH" },
  {
    city: "Pell City",
    state: "AL",
  },
  { city: "Pella", state: "IA" },
  { city: "Pembroke", state: "MA" },
  {
    city: "Pembroke",
    state: "NH",
  },
  { city: "Pembroke Park", state: "FL" },
  { city: "Pembroke Pines", state: "FL" },
  {
    city: "Pendleton",
    state: "NY",
  },
  { city: "Pendleton", state: "OR" },
  { city: "Penfield", state: "NY" },
  {
    city: "Penn Hills",
    state: "PA",
  },
  { city: "Pennsauken", state: "NJ" },
  { city: "Pennsville", state: "NJ" },
  {
    city: "Pensacola",
    state: "FL",
  },
  { city: "Peoria", state: "AZ" },
  { city: "Peoria", state: "IL" },
  {
    city: "Peoria Heights",
    state: "IL",
  },
  { city: "Pepper Pike", state: "OH" },
  { city: "Pepperell", state: "MA" },
  {
    city: "Perinton",
    state: "NY",
  },
  { city: "Perkasie", state: "PA" },
  { city: "Perris", state: "CA" },
  {
    city: "Perry",
    state: "FL",
  },
  { city: "Perry", state: "IA" },
  { city: "Perry", state: "GA" },
  {
    city: "Perry",
    state: "NY",
  },
  { city: "Perry Hall", state: "MD" },
  { city: "Perry Heights", state: "OH" },
  {
    city: "Perrysburg",
    state: "OH",
  },
  { city: "Perryton", state: "TX" },
  { city: "Perryville", state: "MO" },
  {
    city: "Perth Amboy",
    state: "NJ",
  },
  { city: "Peru", state: "NY" },
  { city: "Peru", state: "IL" },
  {
    city: "Peru",
    state: "IN",
  },
  { city: "Petal", state: "MS" },
  { city: "Petaluma", state: "CA" },
  {
    city: "Petersburg",
    state: "VA",
  },
  { city: "Petoskey", state: "MI" },
  { city: "Pewaukee", state: "WI" },
  {
    city: "Pflugerville",
    state: "TX",
  },
  { city: "Pharr", state: "TX" },
  { city: "Phelps", state: "NY" },
  {
    city: "Phenix City",
    state: "AL",
  },
  { city: "Philadelphia", state: "MS" },
  { city: "Philadelphia", state: "PA" },
  {
    city: "Philipstown",
    state: "NY",
  },
  { city: "Phillipsburg", state: "NJ" },
  { city: "Phoenix", state: "AZ" },
  {
    city: "Phoenixville",
    state: "PA",
  },
  { city: "Picayune", state: "MS" },
  {
    city: "Pickerington",
    state: "OH",
  },
  { city: "Picnic Point-North Lynnwood", state: "WA" },
  {
    city: "Pico Rivera",
    state: "CA",
  },
  { city: "Picture Rocks", state: "AZ" },
  { city: "Piedmont", state: "CA" },
  {
    city: "Pierre",
    state: "SD",
  },
  { city: "Pike Creek", state: "DE" },
  { city: "Pikesville", state: "MD" },
  {
    city: "Pikeville",
    state: "KY",
  },
  { city: "Pimmit Hills", state: "VA" },
  { city: "Pine Bluff", state: "AR" },
  {
    city: "Pine Castle",
    state: "FL",
  },
  { city: "Pine Hill", state: "NJ" },
  { city: "Pine Hills", state: "FL" },
  {
    city: "Pinecrest",
    state: "FL",
  },
  { city: "Pinehurst", state: "MA" },
  { city: "Pinehurst", state: "NC" },
  {
    city: "Pinellas Park",
    state: "FL",
  },
  { city: "Pineville", state: "LA" },
  { city: "Pinewood", state: "FL" },
  {
    city: "Piney Green",
    state: "NC",
  },
  { city: "Pinole", state: "CA" },
  { city: "Piqua", state: "OH" },
  {
    city: "Pismo Beach",
    state: "CA",
  },
  { city: "Pitman", state: "NJ" },
  { city: "Pittsburg", state: "CA" },
  {
    city: "Pittsburg",
    state: "KS",
  },
  { city: "Pittsburgh", state: "PA" },
  { city: "Pittsfield", state: "MA" },
  {
    city: "Pittsford",
    state: "NY",
  },
  { city: "Pittston", state: "PA" },
  { city: "Placentia", state: "CA" },
  {
    city: "Placerville",
    state: "CA",
  },
  { city: "Plainedge", state: "NY" },
  { city: "Plainfield", state: "NJ" },
  {
    city: "Plainfield",
    state: "CT",
  },
  { city: "Plainfield", state: "IN" },
  { city: "Plainfield", state: "IL" },
  {
    city: "Plainview",
    state: "NY",
  },
  { city: "Plainview", state: "TX" },
  { city: "Plainville", state: "MA" },
  {
    city: "Plainville",
    state: "CT",
  },
  { city: "Plaistow", state: "NH" },
  { city: "Plano", state: "TX" },
  {
    city: "Plant City",
    state: "FL",
  },
  { city: "Plantation", state: "FL" },
  { city: "Plaquemine", state: "LA" },
  {
    city: "Plattekill",
    state: "NY",
  },
  { city: "Platteville", state: "WI" },
  { city: "Plattsburgh", state: "NY" },
  {
    city: "Plattsmouth",
    state: "NE",
  },
  { city: "Pleasant Grove", state: "UT" },
  { city: "Pleasant Grove", state: "AL" },
  {
    city: "Pleasant Hill",
    state: "CA",
  },
  { city: "Pleasant Hills", state: "PA" },
  {
    city: "Pleasant Prairie",
    state: "WI",
  },
  { city: "Pleasant Valley", state: "NY" },
  { city: "Pleasanton", state: "TX" },
  {
    city: "Pleasanton",
    state: "CA",
  },
  { city: "Pleasantville", state: "NY" },
  {
    city: "Pleasantville",
    state: "NJ",
  },
  { city: "Pleasure Ridge Park", state: "KY" },
  { city: "Plover", state: "WI" },
  {
    city: "Plum",
    state: "PA",
  },
  { city: "Plymouth", state: "PA" },
  { city: "Plymouth", state: "MA" },
  {
    city: "Plymouth",
    state: "MI",
  },
  { city: "Plymouth", state: "MN" },
  { city: "Plymouth", state: "IN" },
  {
    city: "Plymouth",
    state: "CT",
  },
  { city: "Plymouth", state: "WI" },
  { city: "Plymouth Township", state: "MI" },
  {
    city: "Pocahontas",
    state: "AR",
  },
  { city: "Pocatello", state: "ID" },
  { city: "Poinciana", state: "FL" },
  {
    city: "Point Pleasant",
    state: "NJ",
  },
  { city: "Pomfret", state: "NY" },
  { city: "Pomona", state: "CA" },
  {
    city: "Pompano Beach",
    state: "FL",
  },
  { city: "Pompano Beach Highlands", state: "FL" },
  { city: "Pompey", state: "NY" },
  {
    city: "Pompton Lakes",
    state: "NJ",
  },
  { city: "Ponca City", state: "OK" },
  { city: "Pontiac", state: "IL" },
  {
    city: "Pontiac",
    state: "MI",
  },
  { city: "Pooler", state: "GA" },
  { city: "Poplar Bluff", state: "MO" },
  {
    city: "Poquoson",
    state: "VA",
  },
  { city: "Port Angeles", state: "WA" },
  { city: "Port Arthur", state: "TX" },
  {
    city: "Port Charlotte",
    state: "FL",
  },
  { city: "Port Chester", state: "NY" },
  { city: "Port Clinton", state: "OH" },
  {
    city: "Port Hueneme",
    state: "CA",
  },
  { city: "Port Huron", state: "MI" },
  {
    city: "Port Jefferson",
    state: "NY",
  },
  { city: "Port Jefferson Station", state: "NY" },
  {
    city: "Port Jervis",
    state: "NY",
  },
  { city: "Port Lavaca", state: "TX" },
  { city: "Port Neches", state: "TX" },
  {
    city: "Port Orange",
    state: "FL",
  },
  { city: "Port Orchard", state: "WA" },
  { city: "Port Salerno", state: "FL" },
  {
    city: "Port St. John",
    state: "FL",
  },
  { city: "Port St. Lucie", state: "FL" },
  { city: "Port Townsend", state: "WA" },
  {
    city: "Port Washington",
    state: "NY",
  },
  { city: "Port Washington", state: "WI" },
  { city: "Portage", state: "WI" },
  {
    city: "Portage",
    state: "IN",
  },
  { city: "Portage", state: "MI" },
  { city: "Portage Lakes", state: "OH" },
  {
    city: "Portales",
    state: "NM",
  },
  { city: "Porter", state: "NY" },
  { city: "Porterville", state: "CA" },
  {
    city: "Portland",
    state: "CT",
  },
  { city: "Portland", state: "ME" },
  { city: "Portland", state: "IN" },
  {
    city: "Portland",
    state: "TN",
  },
  { city: "Portland", state: "TX" },
  { city: "Portland", state: "OR" },
  {
    city: "Portola Hills",
    state: "CA",
  },
  { city: "Portsmouth", state: "RI" },
  { city: "Portsmouth", state: "OH" },
  {
    city: "Portsmouth",
    state: "NH",
  },
  { city: "Portsmouth", state: "VA" },
  { city: "Post Falls", state: "ID" },
  {
    city: "Poteau",
    state: "OK",
  },
  { city: "Potomac", state: "MD" },
  { city: "Potsdam", state: "NY" },
  {
    city: "Pottstown",
    state: "PA",
  },
  { city: "Pottsville", state: "PA" },
  { city: "Poughkeepsie", state: "NY" },
  {
    city: "Poulsbo",
    state: "WA",
  },
  { city: "Poway", state: "CA" },
  { city: "Powder Springs", state: "GA" },
  {
    city: "Powell",
    state: "OH",
  },
  { city: "Prairie du Chien", state: "WI" },
  {
    city: "Prairie Ridge",
    state: "WA",
  },
  { city: "Prairie Village", state: "KS" },
  { city: "Pratt", state: "KS" },
  {
    city: "Prattville",
    state: "AL",
  },
  { city: "Prescott", state: "AZ" },
  { city: "Prescott Valley", state: "AZ" },
  {
    city: "Presque Isle",
    state: "ME",
  },
  { city: "Price", state: "UT" },
  { city: "Prichard", state: "AL" },
  {
    city: "Prien",
    state: "LA",
  },
  { city: "Princeton", state: "KY" },
  { city: "Princeton", state: "FL" },
  {
    city: "Princeton",
    state: "IN",
  },
  { city: "Princeton", state: "IL" },
  { city: "Princeton", state: "NJ" },
  {
    city: "Princeton",
    state: "WV",
  },
  { city: "Princeton Meadows", state: "NJ" },
  { city: "Prineville", state: "OR" },
  {
    city: "Prior Lake",
    state: "MN",
  },
  { city: "Progress", state: "PA" },
  { city: "Prospect", state: "CT" },
  {
    city: "Prospect Heights",
    state: "IL",
  },
  { city: "Prospect Park", state: "PA" },
  { city: "Providence", state: "RI" },
  {
    city: "Provo",
    state: "UT",
  },
  { city: "Prunedale", state: "CA" },
  { city: "Pryor Creek", state: "OK" },
  {
    city: "Pueblo",
    state: "CO",
  },
  { city: "Pueblo West", state: "CO" },
  { city: "Pukalani", state: "HI" },
  {
    city: "Pulaski",
    state: "TN",
  },
  { city: "Pulaski", state: "VA" },
  { city: "Pullman", state: "WA" },
  {
    city: "Punta Gorda",
    state: "FL",
  },
  { city: "Punxsutawney", state: "PA" },
  { city: "Putnam", state: "CT" },
  {
    city: "Putnam District",
    state: "CT",
  },
  { city: "Putnam Valley", state: "NY" },
  { city: "Puyallup", state: "WA" },
  {
    city: "Quakertown",
    state: "PA",
  },
  { city: "Quantico Station", state: "VA" },
  { city: "Quartz Hill", state: "CA" },
  {
    city: "Queensbury",
    state: "NY",
  },
  { city: "Quincy", state: "FL" },
  { city: "Quincy", state: "IL" },
  {
    city: "Quincy",
    state: "MA",
  },
  { city: "Raceland", state: "LA" },
  { city: "Racine", state: "WI" },
  {
    city: "Radcliff",
    state: "KY",
  },
  { city: "Radford", state: "VA" },
  { city: "Radnor Township", state: "PA" },
  {
    city: "Rahway",
    state: "NJ",
  },
  { city: "Rainbow City", state: "AL" },
  { city: "Raleigh", state: "NC" },
  {
    city: "Ralston",
    state: "NE",
  },
  { city: "Ramapo", state: "NY" },
  { city: "Ramblewood", state: "NJ" },
  {
    city: "Ramona",
    state: "CA",
  },
  { city: "Ramsey", state: "NJ" },
  { city: "Ramsey", state: "MN" },
  {
    city: "Rancho Cordova",
    state: "CA",
  },
  { city: "Rancho Cucamonga", state: "CA" },
  {
    city: "Rancho Mirage",
    state: "CA",
  },
  { city: "Rancho Palos Verdes", state: "CA" },
  {
    city: "Rancho San Diego",
    state: "CA",
  },
  { city: "Rancho Santa Margarita", state: "CA" },
  { city: "Randallstown", state: "MD" },
  {
    city: "Randolph",
    state: "MA",
  },
  { city: "Rantoul", state: "IL" },
  { city: "Rapid City", state: "SD" },
  {
    city: "Rapid Valley",
    state: "SD",
  },
  { city: "Raritan", state: "NJ" },
  { city: "Raton", state: "NM" },
  {
    city: "Ravenna",
    state: "OH",
  },
  { city: "Rawlins", state: "WY" },
  { city: "Raymond", state: "NH" },
  {
    city: "Raymondville",
    state: "TX",
  },
  { city: "Raymore", state: "MO" },
  { city: "Rayne", state: "LA" },
  {
    city: "Raynham",
    state: "MA",
  },
  { city: "Raytown", state: "MO" },
  { city: "Reading", state: "MA" },
  {
    city: "Reading",
    state: "PA",
  },
  { city: "Reading", state: "OH" },
  { city: "Red Bank", state: "SC" },
  {
    city: "Red Bank",
    state: "TN",
  },
  { city: "Red Bank", state: "NJ" },
  { city: "Red Bluff", state: "CA" },
  {
    city: "Red Hill",
    state: "SC",
  },
  { city: "Red Hook", state: "NY" },
  { city: "Red Lion", state: "PA" },
  {
    city: "Red Oak",
    state: "IA",
  },
  { city: "Red Wing", state: "MN" },
  { city: "Redan", state: "GA" },
  {
    city: "Redding",
    state: "CT",
  },
  { city: "Redding", state: "CA" },
  { city: "Redford", state: "MI" },
  {
    city: "Redland",
    state: "MD",
  },
  { city: "Redlands", state: "CA" },
  { city: "Redlands", state: "CO" },
  {
    city: "Redmond",
    state: "OR",
  },
  { city: "Redmond", state: "WA" },
  { city: "Redondo Beach", state: "CA" },
  {
    city: "Redwood City",
    state: "CA",
  },
  { city: "Reedley", state: "CA" },
  { city: "Reedsburg", state: "WI" },
  {
    city: "Rehoboth",
    state: "MA",
  },
  { city: "Reidsville", state: "NC" },
  { city: "Reisterstown", state: "MD" },
  {
    city: "Rendon",
    state: "TX",
  },
  { city: "Reno", state: "NV" },
  { city: "Rensselaer", state: "NY" },
  {
    city: "Renton",
    state: "WA",
  },
  { city: "Republic", state: "MO" },
  { city: "Reserve", state: "LA" },
  {
    city: "Reston",
    state: "VA",
  },
  { city: "Revere", state: "MA" },
  { city: "Rexburg", state: "ID" },
  {
    city: "Reynoldsburg",
    state: "OH",
  },
  { city: "Rhinebeck", state: "NY" },
  { city: "Rhinelander", state: "WI" },
  {
    city: "Rialto",
    state: "CA",
  },
  { city: "Rib Mountain", state: "WI" },
  { city: "Rice Lake", state: "WI" },
  {
    city: "Richardson",
    state: "TX",
  },
  { city: "Richboro", state: "PA" },
  { city: "Richfield", state: "UT" },
  {
    city: "Richfield",
    state: "WI",
  },
  { city: "Richfield", state: "MN" },
  { city: "Richland", state: "WA" },
  {
    city: "Richland",
    state: "MS",
  },
  { city: "Richland Hills", state: "TX" },
  { city: "Richmond", state: "TX" },
  {
    city: "Richmond",
    state: "RI",
  },
  { city: "Richmond", state: "VA" },
  { city: "Richmond", state: "MO" },
  {
    city: "Richmond",
    state: "KY",
  },
  { city: "Richmond", state: "IN" },
  { city: "Richmond", state: "CA" },
  {
    city: "Richmond Heights",
    state: "FL",
  },
  { city: "Richmond Heights", state: "MO" },
  {
    city: "Richmond Heights",
    state: "OH",
  },
  { city: "Richmond Hill", state: "GA" },
  { city: "Richmond West", state: "FL" },
  {
    city: "Richton Park",
    state: "IL",
  },
  { city: "Ridge", state: "NY" },
  { city: "Ridgecrest", state: "CA" },
  {
    city: "Ridgefield",
    state: "CT",
  },
  { city: "Ridgefield", state: "NJ" },
  { city: "Ridgefield Park", state: "NJ" },
  {
    city: "Ridgeland",
    state: "MS",
  },
  { city: "Ridgeway", state: "NY" },
  { city: "Ridgewood", state: "NJ" },
  {
    city: "Ridley Park",
    state: "PA",
  },
  { city: "Rifle", state: "CO" },
  { city: "Ringwood", state: "NJ" },
  {
    city: "Rio del Mar",
    state: "CA",
  },
  { city: "Rio Grande City", state: "TX" },
  { city: "Rio Linda", state: "CA" },
  {
    city: "Rio Rancho",
    state: "NM",
  },
  { city: "Ripley", state: "TN" },
  { city: "Ripon", state: "WI" },
  {
    city: "Ripon",
    state: "CA",
  },
  { city: "Rittman", state: "OH" },
  { city: "River Edge", state: "NJ" },
  {
    city: "River Falls",
    state: "WI",
  },
  { city: "River Forest", state: "IL" },
  { city: "River Grove", state: "IL" },
  {
    city: "River Oaks",
    state: "TX",
  },
  { city: "River Ridge", state: "LA" },
  { city: "River Rouge", state: "MI" },
  {
    city: "River Vale",
    state: "NJ",
  },
  { city: "Riverbank", state: "CA" },
  { city: "Riverdale", state: "IL" },
  {
    city: "Riverdale",
    state: "GA",
  },
  { city: "Riverdale", state: "UT" },
  { city: "Riverdale Park", state: "MD" },
  {
    city: "Riverhead",
    state: "NY",
  },
  { city: "Riverside", state: "OH" },
  { city: "Riverside", state: "MD" },
  {
    city: "Riverside",
    state: "IL",
  },
  { city: "Riverside", state: "CA" },
  { city: "Riverton", state: "UT" },
  {
    city: "Riverton",
    state: "WY",
  },
  { city: "Riverton-Boulevard Park", state: "WA" },
  { city: "Riverview", state: "FL" },
  {
    city: "Riverview",
    state: "MI",
  },
  { city: "Riviera Beach", state: "MD" },
  { city: "Riviera Beach", state: "FL" },
  {
    city: "Roanoke",
    state: "AL",
  },
  { city: "Roanoke", state: "VA" },
  { city: "Roanoke Rapids", state: "NC" },
  {
    city: "Robbins",
    state: "IL",
  },
  { city: "Robbinsdale", state: "MN" },
  { city: "Robinson", state: "IL" },
  {
    city: "Robinson",
    state: "TX",
  },
  { city: "Robinson Township", state: "PA" },
  { city: "Robstown", state: "TX" },
  {
    city: "Rochelle",
    state: "IL",
  },
  { city: "Rochester", state: "MN" },
  { city: "Rochester", state: "MI" },
  {
    city: "Rochester",
    state: "IN",
  },
  { city: "Rochester", state: "NY" },
  { city: "Rochester", state: "NH" },
  {
    city: "Rochester Hills",
    state: "MI",
  },
  { city: "Rock Falls", state: "IL" },
  { city: "Rock Hill", state: "SC" },
  {
    city: "Rock Island",
    state: "IL",
  },
  { city: "Rock Springs", state: "WY" },
  { city: "Rockaway", state: "NJ" },
  {
    city: "Rockcreek",
    state: "OR",
  },
  { city: "Rockford", state: "IL" },
  { city: "Rockingham", state: "NC" },
  {
    city: "Rockland",
    state: "MA",
  },
  { city: "Rockland", state: "ME" },
  { city: "Rockledge", state: "FL" },
  {
    city: "Rocklin",
    state: "CA",
  },
  { city: "Rockport", state: "MA" },
  { city: "Rockport", state: "TX" },
  {
    city: "Rockville",
    state: "MD",
  },
  { city: "Rockville", state: "CT" },
  { city: "Rockville Centre", state: "NY" },
  {
    city: "Rockwall",
    state: "TX",
  },
  { city: "Rocky Hill", state: "CT" },
  { city: "Rocky Mount", state: "NC" },
  {
    city: "Rocky Point",
    state: "NY",
  },
  { city: "Rocky River", state: "OH" },
  { city: "Rodeo", state: "CA" },
  {
    city: "Roeland Park",
    state: "KS",
  },
  { city: "Rogers", state: "AR" },
  { city: "Rohnert Park", state: "CA" },
  {
    city: "Rolla",
    state: "MO",
  },
  { city: "Rolling Hills Estates", state: "CA" },
  { city: "Rolling Meadows", state: "IL" },
  {
    city: "Roma",
    state: "TX",
  },
  { city: "Rome", state: "NY" },
  { city: "Rome", state: "GA" },
  {
    city: "Romeoville",
    state: "IL",
  },
  { city: "Romulus", state: "MI" },
  { city: "Ronkonkoma", state: "NY" },
  {
    city: "Roosevelt",
    state: "NY",
  },
  { city: "Rosamond", state: "CA" },
  { city: "Rosaryville", state: "MD" },
  {
    city: "Roscoe",
    state: "IL",
  },
  { city: "Rose Hill", state: "VA" },
  { city: "Roseburg", state: "OR" },
  {
    city: "Rosedale",
    state: "MD",
  },
  { city: "Rosedale", state: "CA" },
  { city: "Roseland", state: "CA" },
  {
    city: "Roselle",
    state: "IL",
  },
  { city: "Roselle", state: "NJ" },
  { city: "Roselle Park", state: "NJ" },
  {
    city: "Rosemead",
    state: "CA",
  },
  { city: "Rosemont", state: "CA" },
  { city: "Rosemount", state: "MN" },
  {
    city: "Rosenberg",
    state: "TX",
  },
  { city: "Rosendale", state: "NY" },
  { city: "Roseville", state: "MN" },
  {
    city: "Roseville",
    state: "MI",
  },
  { city: "Roseville", state: "CA" },
  { city: "Roslyn Heights", state: "NY" },
  {
    city: "Ross Township",
    state: "PA",
  },
  { city: "Rossford", state: "OH" },
  { city: "Rossmoor", state: "CA" },
  {
    city: "Rossmoor",
    state: "MD",
  },
  { city: "Rossville", state: "MD" },
  { city: "Roswell", state: "GA" },
  {
    city: "Roswell",
    state: "NM",
  },
  { city: "Rotonda", state: "FL" },
  { city: "Rotterdam", state: "NY" },
  {
    city: "Round Lake Beach",
    state: "IL",
  },
  { city: "Round Lake Park", state: "IL" },
  { city: "Round Rock", state: "TX" },
  {
    city: "Rowland Heights",
    state: "CA",
  },
  { city: "Rowlett", state: "TX" },
  { city: "Roxboro", state: "NC" },
  {
    city: "Roy",
    state: "UT",
  },
  { city: "Royal Oak", state: "MI" },
  { city: "Royal Palm Beach", state: "FL" },
  {
    city: "Royalton",
    state: "NY",
  },
  { city: "Rubidoux", state: "CA" },
  { city: "Ruidoso", state: "NM" },
  {
    city: "Rumford",
    state: "ME",
  },
  { city: "Rumson", state: "NJ" },
  { city: "Runnemede", state: "NJ" },
  {
    city: "Ruskin",
    state: "FL",
  },
  { city: "Russellville", state: "AR" },
  { city: "Russellville", state: "AL" },
  {
    city: "Russellville",
    state: "KY",
  },
  { city: "Ruston", state: "LA" },
  { city: "Rutherford", state: "NJ" },
  {
    city: "Rutland",
    state: "MA",
  },
  { city: "Rutland", state: "VT" },
  { city: "Rye", state: "NY" },
  {
    city: "Rye Brook",
    state: "NY",
  },
  { city: "Sachse", state: "TX" },
  { city: "Saco", state: "ME" },
  {
    city: "Sacramento",
    state: "CA",
  },
  { city: "Saddle Brook", state: "NJ" },
  { city: "Safety Harbor", state: "FL" },
  {
    city: "Safford",
    state: "AZ",
  },
  { city: "Saginaw", state: "MI" },
  { city: "Saginaw", state: "TX" },
  {
    city: "Saginaw Township North",
    state: "MI",
  },
  { city: "Saginaw Township South", state: "MI" },
  { city: "Saks", state: "AL" },
  {
    city: "Salamanca",
    state: "NY",
  },
  { city: "Salem", state: "NH" },
  { city: "Salem", state: "OH" },
  {
    city: "Salem",
    state: "OR",
  },
  { city: "Salem", state: "VA" },
  { city: "Salem", state: "WI" },
  {
    city: "Salem",
    state: "MA",
  },
  { city: "Salem", state: "IL" },
  { city: "Salem", state: "IN" },
  {
    city: "Salida",
    state: "CA",
  },
  { city: "Salina", state: "KS" },
  { city: "Salina", state: "NY" },
  {
    city: "Salinas",
    state: "CA",
  },
  { city: "Saline", state: "MI" },
  { city: "Salisbury", state: "MA" },
  {
    city: "Salisbury",
    state: "MD",
  },
  { city: "Salisbury", state: "NY" },
  { city: "Salisbury", state: "NC" },
  {
    city: "Sallisaw",
    state: "OK",
  },
  { city: "Salmon Creek", state: "WA" },
  { city: "Salt Lake City", state: "UT" },
  {
    city: "Sammamish",
    state: "WA",
  },
  { city: "San Angelo", state: "TX" },
  { city: "San Anselmo", state: "CA" },
  {
    city: "San Antonio",
    state: "TX",
  },
  { city: "San Benito", state: "TX" },
  { city: "San Bernardino", state: "CA" },
  {
    city: "San Bruno",
    state: "CA",
  },
  { city: "San Buenaventura", state: "CA" },
  { city: "San Carlos", state: "CA" },
  {
    city: "San Carlos Park",
    state: "FL",
  },
  { city: "San Clemente", state: "CA" },
  {
    city: "San Diego",
    state: "CA",
  },
  { city: "San Diego Country Estates", state: "CA" },
  {
    city: "San Dimas",
    state: "CA",
  },
  { city: "San Elizario", state: "TX" },
  { city: "San Fernando", state: "CA" },
  {
    city: "San Francisco",
    state: "CA",
  },
  { city: "San Gabriel", state: "CA" },
  { city: "San Jacinto", state: "CA" },
  {
    city: "San Jose",
    state: "CA",
  },
  { city: "San Juan", state: "TX" },
  { city: "San Juan Capistrano", state: "CA" },
  {
    city: "San Leandro",
    state: "CA",
  },
  { city: "San Lorenzo", state: "CA" },
  { city: "San Luis", state: "AZ" },
  {
    city: "San Luis Obispo",
    state: "CA",
  },
  { city: "San Marcos", state: "CA" },
  { city: "San Marcos", state: "TX" },
  {
    city: "San Marino",
    state: "CA",
  },
  { city: "San Mateo", state: "CA" },
  { city: "San Pablo", state: "CA" },
  {
    city: "San Rafael",
    state: "CA",
  },
  { city: "San Ramon", state: "CA" },
  { city: "Sanatoga", state: "PA" },
  {
    city: "Sand Lake",
    state: "NY",
  },
  { city: "Sand Springs", state: "OK" },
  { city: "Sandalfoot Cove", state: "FL" },
  {
    city: "Sandersville",
    state: "GA",
  },
  { city: "Sandpoint", state: "ID" },
  { city: "Sandusky", state: "OH" },
  {
    city: "Sandusky South",
    state: "OH",
  },
  { city: "Sandwich", state: "IL" },
  { city: "Sandwich", state: "MA" },
  {
    city: "Sandy",
    state: "UT",
  },
  { city: "Sandy Springs", state: "GA" },
  { city: "Sanford", state: "FL" },
  {
    city: "Sanford",
    state: "ME",
  },
  { city: "Sanford", state: "NC" },
  { city: "Sanger", state: "CA" },
  {
    city: "Sanibel",
    state: "FL",
  },
  { city: "Sans Souci", state: "SC" },
  { city: "Santa Ana", state: "CA" },
  {
    city: "Santa Barbara",
    state: "CA",
  },
  { city: "Santa Clara", state: "CA" },
  { city: "Santa Clarita", state: "CA" },
  {
    city: "Santa Cruz",
    state: "CA",
  },
  { city: "Santa Fe", state: "NM" },
  { city: "Santa Fe", state: "TX" },
  {
    city: "Santa Fe Springs",
    state: "CA",
  },
  { city: "Santa Maria", state: "CA" },
  { city: "Santa Monica", state: "CA" },
  {
    city: "Santa Paula",
    state: "CA",
  },
  { city: "Santa Rosa", state: "CA" },
  { city: "Santee", state: "CA" },
  {
    city: "Sappington",
    state: "MO",
  },
  { city: "Sapulpa", state: "OK" },
  { city: "Saraland", state: "AL" },
  {
    city: "Sarasota",
    state: "FL",
  },
  { city: "Sarasota Springs", state: "FL" },
  { city: "Saratoga", state: "CA" },
  {
    city: "Saratoga Springs",
    state: "NY",
  },
  { city: "Sartell", state: "MN" },
  { city: "Satellite Beach", state: "FL" },
  {
    city: "Saugerties",
    state: "NY",
  },
  { city: "Saugus", state: "MA" },
  { city: "Sauk Rapids", state: "MN" },
  {
    city: "Sauk Village",
    state: "IL",
  },
  { city: "Sault Ste. Marie", state: "MI" },
  { city: "Sausalito", state: "CA" },
  {
    city: "Savage",
    state: "MN",
  },
  { city: "Savage-Guilford", state: "MD" },
  { city: "Savannah", state: "GA" },
  {
    city: "Savannah",
    state: "TN",
  },
  { city: "Sayreville", state: "NJ" },
  { city: "Sayville", state: "NY" },
  {
    city: "Scarborough",
    state: "ME",
  },
  { city: "Scarsdale", state: "NY" },
  { city: "Schaghticoke", state: "NY" },
  {
    city: "Schaumburg",
    state: "IL",
  },
  { city: "Schenectady", state: "NY" },
  { city: "Schererville", state: "IN" },
  {
    city: "Schertz",
    state: "TX",
  },
  { city: "Schiller Park", state: "IL" },
  { city: "Schodack", state: "NY" },
  {
    city: "Schofield Barracks",
    state: "HI",
  },
  { city: "Schroeppel", state: "NY" },
  { city: "Scituate", state: "RI" },
  {
    city: "Scituate",
    state: "MA",
  },
  { city: "Scotch Plains", state: "NJ" },
  { city: "Scotchtown", state: "NY" },
  {
    city: "Scotia",
    state: "NY",
  },
  { city: "Scott", state: "LA" },
  { city: "Scott Lake", state: "FL" },
  {
    city: "Scott Township",
    state: "PA",
  },
  { city: "Scottdale", state: "GA" },
  { city: "Scotts Valley", state: "CA" },
  {
    city: "Scottsbluff",
    state: "NE",
  },
  { city: "Scottsboro", state: "AL" },
  { city: "Scottsburg", state: "IN" },
  {
    city: "Scottsdale",
    state: "AZ",
  },
  { city: "Scranton", state: "PA" },
  { city: "Scriba", state: "NY" },
  {
    city: "Seabrook",
    state: "NH",
  },
  { city: "Seabrook", state: "TX" },
  { city: "Seaford", state: "NY" },
  {
    city: "Seaford",
    state: "DE",
  },
  { city: "Seagoville", state: "TX" },
  { city: "Seal Beach", state: "CA" },
  {
    city: "Searcy",
    state: "AR",
  },
  { city: "Seaside", state: "CA" },
  { city: "SeaTac", state: "WA" },
  {
    city: "Seattle",
    state: "WA",
  },
  { city: "Seattle Hill-Silver Firs", state: "WA" },
  {
    city: "Sebastian",
    state: "FL",
  },
  { city: "Sebastopol", state: "CA" },
  { city: "Sebring", state: "FL" },
  {
    city: "Secaucus",
    state: "NJ",
  },
  { city: "Security-Widefield", state: "CO" },
  { city: "Sedalia", state: "MO" },
  {
    city: "Sedona",
    state: "AZ",
  },
  { city: "Sedro-Woolley", state: "WA" },
  { city: "Seekonk", state: "MA" },
  {
    city: "Seguin",
    state: "TX",
  },
  { city: "Selah", state: "WA" },
  { city: "Selden", state: "NY" },
  {
    city: "Sellersburg",
    state: "IN",
  },
  { city: "Selma", state: "AL" },
  { city: "Selma", state: "CA" },
  {
    city: "Seminole",
    state: "FL",
  },
  { city: "Seminole", state: "OK" },
  { city: "Senatobia", state: "MS" },
  {
    city: "Seneca",
    state: "SC",
  },
  { city: "Seneca Falls", state: "NY" },
  {
    city: "Setauket-East Setauket",
    state: "NY",
  },
  { city: "Seven Corners", state: "VA" },
  { city: "Seven Hills", state: "OH" },
  {
    city: "Seven Oaks",
    state: "SC",
  },
  { city: "Severn", state: "MD" },
  { city: "Severna Park", state: "MD" },
  {
    city: "Sevierville",
    state: "TN",
  },
  { city: "Seward", state: "NE" },
  { city: "Seymour", state: "TN" },
  {
    city: "Seymour",
    state: "IN",
  },
  { city: "Seymour", state: "CT" },
  { city: "Shady Hills", state: "FL" },
  {
    city: "Shafter",
    state: "CA",
  },
  { city: "Shaker Heights", state: "OH" },
  { city: "Shakopee", state: "MN" },
  {
    city: "Shaler Township",
    state: "PA",
  },
  { city: "Shamokin", state: "PA" },
  { city: "Sharon", state: "PA" },
  {
    city: "Sharon",
    state: "MA",
  },
  { city: "Sharonville", state: "OH" },
  { city: "Shasta Lake", state: "CA" },
  {
    city: "Shawangunk",
    state: "NY",
  },
  { city: "Shawano", state: "WI" },
  { city: "Shawnee", state: "OK" },
  {
    city: "Shawnee",
    state: "KS",
  },
  { city: "Sheboygan", state: "WI" },
  { city: "Sheboygan Falls", state: "WI" },
  {
    city: "Sheffield",
    state: "AL",
  },
  { city: "Sheffield Lake", state: "OH" },
  { city: "Shelburne", state: "VT" },
  {
    city: "Shelby",
    state: "OH",
  },
  { city: "Shelby", state: "NC" },
  { city: "Shelby", state: "MI" },
  {
    city: "Shelbyville",
    state: "KY",
  },
  { city: "Shelbyville", state: "IN" },
  { city: "Shelbyville", state: "TN" },
  {
    city: "Shelton",
    state: "WA",
  },
  { city: "Shelton", state: "CT" },
  { city: "Shenandoah", state: "LA" },
  {
    city: "Shepherdsville",
    state: "KY",
  },
  { city: "Sheridan", state: "WY" },
  { city: "Sherman", state: "TX" },
  {
    city: "Sherrelwood",
    state: "CO",
  },
  { city: "Sherwood", state: "AR" },
  { city: "Sherwood", state: "OR" },
  {
    city: "Shields",
    state: "MI",
  },
  { city: "Shiloh", state: "IL" },
  { city: "Shiloh", state: "PA" },
  {
    city: "Shiloh",
    state: "OH",
  },
  { city: "Shiprock", state: "NM" },
  { city: "Shirley", state: "NY" },
  {
    city: "Shirley",
    state: "MA",
  },
  { city: "Shively", state: "KY" },
  { city: "Shoreline", state: "WA" },
  {
    city: "Shoreview",
    state: "MN",
  },
  { city: "Shorewood", state: "MN" },
  { city: "Shorewood", state: "IL" },
  {
    city: "Shorewood",
    state: "WI",
  },
  { city: "Show Low", state: "AZ" },
  { city: "Shreveport", state: "LA" },
  {
    city: "Shrewsbury",
    state: "MO",
  },
  { city: "Shrewsbury", state: "MA" },
  { city: "Sidney", state: "NY" },
  {
    city: "Sidney",
    state: "NE",
  },
  { city: "Sidney", state: "OH" },
  { city: "Sierra Madre", state: "CA" },
  {
    city: "Sierra Vista",
    state: "AZ",
  },
  { city: "Sierra Vista Southeast", state: "AZ" },
  {
    city: "Siesta Key",
    state: "FL",
  },
  { city: "Signal Hill", state: "CA" },
  { city: "Signal Mountain", state: "TN" },
  {
    city: "Sikeston",
    state: "MO",
  },
  { city: "Siler City", state: "NC" },
  { city: "Siloam Springs", state: "AR" },
  {
    city: "Silsbee",
    state: "TX",
  },
  { city: "Silver City", state: "NM" },
  {
    city: "Silver Spring",
    state: "MD",
  },
  { city: "Silver Springs Shores", state: "FL" },
  { city: "Silverdale", state: "WA" },
  {
    city: "Silverton",
    state: "OR",
  },
  { city: "Silvis", state: "IL" },
  { city: "Simi Valley", state: "CA" },
  {
    city: "Simpsonville",
    state: "SC",
  },
  { city: "Simsbury", state: "CT" },
  { city: "Sioux Center", state: "IA" },
  {
    city: "Sioux City",
    state: "IA",
  },
  { city: "Sioux Falls", state: "SD" },
  { city: "Sitka and", state: "AK" },
  {
    city: "Skaneateles",
    state: "NY",
  },
  { city: "Skidaway Island", state: "GA" },
  { city: "Skokie", state: "IL" },
  {
    city: "Skowhegan",
    state: "ME",
  },
  { city: "Slaton", state: "TX" },
  { city: "Sleepy Hollow", state: "NY" },
  {
    city: "Slidell",
    state: "LA",
  },
  { city: "Smithfield", state: "NC" },
  { city: "Smithfield", state: "RI" },
  {
    city: "Smithfield",
    state: "UT",
  },
  { city: "Smithfield", state: "VA" },
  { city: "Smiths", state: "AL" },
  {
    city: "Smithtown",
    state: "NY",
  },
  { city: "Smyrna", state: "TN" },
  { city: "Smyrna", state: "GA" },
  {
    city: "Snellville",
    state: "GA",
  },
  { city: "Snohomish", state: "WA" },
  { city: "Snyder", state: "TX" },
  {
    city: "Socastee",
    state: "SC",
  },
  { city: "Socorro", state: "NM" },
  { city: "Socorro", state: "TX" },
  {
    city: "Soddy-Daisy",
    state: "TN",
  },
  { city: "Sodus", state: "NY" },
  { city: "Solana Beach", state: "CA" },
  {
    city: "Soledad",
    state: "CA",
  },
  { city: "Solon", state: "OH" },
  { city: "Solvay", state: "NY" },
  {
    city: "Somers",
    state: "NY",
  },
  { city: "Somers", state: "WI" },
  { city: "Somers", state: "CT" },
  {
    city: "Somers Point",
    state: "NJ",
  },
  { city: "Somerset", state: "NJ" },
  { city: "Somerset", state: "PA" },
  {
    city: "Somerset",
    state: "KY",
  },
  { city: "Somerset", state: "MA" },
  { city: "Somersworth", state: "NH" },
  {
    city: "Somerton",
    state: "AZ",
  },
  { city: "Somerville", state: "MA" },
  { city: "Somerville", state: "NJ" },
  {
    city: "Sonoma",
    state: "CA",
  },
  { city: "Souderton", state: "PA" },
  { city: "Sound Beach", state: "NY" },
  {
    city: "South Amboy",
    state: "NJ",
  },
  { city: "South Bend", state: "IN" },
  { city: "South Berwick", state: "ME" },
  {
    city: "South Boston",
    state: "VA",
  },
  { city: "South Bradenton", state: "FL" },
  {
    city: "South Burlington",
    state: "VT",
  },
  { city: "South Charleston", state: "WV" },
  {
    city: "South Cleveland",
    state: "TN",
  },
  { city: "South Daytona", state: "FL" },
  { city: "South El Monte", state: "CA" },
  {
    city: "South Elgin",
    state: "IL",
  },
  { city: "South Euclid", state: "OH" },
  { city: "South Farmingdale", state: "NY" },
  {
    city: "South Gate",
    state: "MD",
  },
  { city: "South Gate", state: "CA" },
  { city: "South Hadley", state: "MA" },
  {
    city: "South Highpoint",
    state: "FL",
  },
  { city: "South Hill", state: "NY" },
  { city: "South Hill", state: "WA" },
  {
    city: "South Holland",
    state: "IL",
  },
  { city: "South Houston", state: "TX" },
  { city: "South Huntington", state: "NY" },
  {
    city: "South Jordan",
    state: "UT",
  },
  { city: "South Kensington", state: "MD" },
  {
    city: "South Kingstown",
    state: "RI",
  },
  { city: "South Lake Tahoe", state: "CA" },
  { city: "South Laurel", state: "MD" },
  {
    city: "South Lockport",
    state: "NY",
  },
  { city: "South Lyon", state: "MI" },
  { city: "South Miami", state: "FL" },
  {
    city: "South Miami Heights",
    state: "FL",
  },
  { city: "South Milwaukee", state: "WI" },
  { city: "South Monroe", state: "MI" },
  {
    city: "South Ogden",
    state: "UT",
  },
  { city: "South Orange", state: "NJ" },
  {
    city: "South Oroville",
    state: "CA",
  },
  { city: "South Park Township", state: "PA" },
  {
    city: "South Pasadena",
    state: "CA",
  },
  { city: "South Patrick Shores", state: "FL" },
  {
    city: "South Plainfield",
    state: "NJ",
  },
  { city: "South Portland", state: "ME" },
  { city: "South River", state: "NJ" },
  {
    city: "South Salt Lake",
    state: "UT",
  },
  { city: "South San Francisco", state: "CA" },
  {
    city: "South San Gabriel",
    state: "CA",
  },
  { city: "South San Jose Hills", state: "CA" },
  {
    city: "South Sioux City",
    state: "NE",
  },
  { city: "South St. Paul", state: "MN" },
  { city: "South Valley", state: "NM" },
  {
    city: "South Venice",
    state: "FL",
  },
  { city: "South Whittier", state: "CA" },
  {
    city: "South Williamsport",
    state: "PA",
  },
  { city: "South Windsor", state: "CT" },
  { city: "South Yarmouth", state: "MA" },
  {
    city: "South Yuba City",
    state: "CA",
  },
  { city: "Southampton", state: "NY" },
  { city: "Southaven", state: "MS" },
  {
    city: "Southborough",
    state: "MA",
  },
  { city: "Southbridge", state: "MA" },
  { city: "Southbury", state: "CT" },
  {
    city: "Southeast",
    state: "NY",
  },
  { city: "Southeast Arcadia", state: "FL" },
  { city: "Southern Pines", state: "NC" },
  {
    city: "Southfield",
    state: "MI",
  },
  { city: "Southgate", state: "MI" },
  { city: "Southgate", state: "FL" },
  {
    city: "Southglenn",
    state: "CO",
  },
  { city: "Southington", state: "CT" },
  { city: "Southlake", state: "TX" },
  {
    city: "Southold",
    state: "NY",
  },
  { city: "Southport", state: "NY" },
  { city: "Southside", state: "AL" },
  {
    city: "Southwick",
    state: "MA",
  },
  { city: "Southwood Acres", state: "CT" },
  { city: "Spanaway", state: "WA" },
  {
    city: "Spanish Fork",
    state: "UT",
  },
  { city: "Spanish Lake", state: "MO" },
  { city: "Spanish Springs", state: "NV" },
  {
    city: "Sparks",
    state: "NV",
  },
  { city: "Sparta", state: "WI" },
  { city: "Spartanburg", state: "SC" },
  {
    city: "Spearfish",
    state: "SD",
  },
  { city: "Speedway", state: "IN" },
  { city: "Spencer", state: "IA" },
  {
    city: "Spencer",
    state: "MA",
  },
  { city: "Spokane", state: "WA" },
  { city: "Spotswood", state: "NJ" },
  {
    city: "Spring",
    state: "TX",
  },
  { city: "Spring Creek", state: "NV" },
  { city: "Spring Hill", state: "TN" },
  {
    city: "Spring Hill",
    state: "FL",
  },
  { city: "Spring Lake", state: "NC" },
  { city: "Spring Lake Park", state: "MN" },
  {
    city: "Spring Valley",
    state: "CA",
  },
  { city: "Spring Valley", state: "NV" },
  { city: "Spring Valley", state: "NY" },
  {
    city: "Springboro",
    state: "OH",
  },
  { city: "Springdale", state: "OH" },
  { city: "Springdale", state: "NJ" },
  {
    city: "Springdale",
    state: "AR",
  },
  { city: "Springfield", state: "FL" },
  { city: "Springfield", state: "MO" },
  {
    city: "Springfield",
    state: "MA",
  },
  { city: "Springfield", state: "IL" },
  { city: "Springfield", state: "NJ" },
  {
    city: "Springfield",
    state: "OH",
  },
  { city: "Springfield", state: "OR" },
  { city: "Springfield", state: "PA" },
  {
    city: "Springfield",
    state: "VT",
  },
  { city: "Springfield", state: "TN" },
  { city: "Springfield", state: "VA" },
  {
    city: "Springville",
    state: "UT",
  },
  { city: "St. Albans", state: "VT" },
  { city: "St. Albans", state: "WV" },
  {
    city: "St. Andrews",
    state: "SC",
  },
  { city: "St. Ann", state: "MO" },
  { city: "St. Anthony", state: "MN" },
  {
    city: "St. Augustine",
    state: "FL",
  },
  { city: "St. Charles", state: "MO" },
  { city: "St. Charles", state: "IL" },
  {
    city: "St. Charles",
    state: "MD",
  },
  { city: "St. Clair Shores", state: "MI" },
  { city: "St. Cloud", state: "MN" },
  {
    city: "St. Cloud",
    state: "FL",
  },
  { city: "St. Dennis", state: "KY" },
  { city: "St. Francis", state: "WI" },
  {
    city: "St. George",
    state: "UT",
  },
  { city: "St. Helens", state: "OR" },
  { city: "St. James", state: "NY" },
  {
    city: "St. John",
    state: "IN",
  },
  { city: "St. John", state: "MO" },
  { city: "St. Johns", state: "MI" },
  {
    city: "St. Johnsbury",
    state: "VT",
  },
  { city: "St. Joseph", state: "MI" },
  { city: "St. Joseph", state: "MO" },
  {
    city: "St. Louis",
    state: "MO",
  },
  { city: "St. Louis Park", state: "MN" },
  { city: "St. Martin", state: "MS" },
  {
    city: "St. Martinville",
    state: "LA",
  },
  { city: "St. Marys", state: "GA" },
  { city: "St. Marys", state: "PA" },
  {
    city: "St. Marys",
    state: "OH",
  },
  { city: "St. Matthews", state: "KY" },
  { city: "St. Michael", state: "MN" },
  {
    city: "St. Paul",
    state: "MN",
  },
  { city: "St. Pete Beach", state: "FL" },
  { city: "St. Peter", state: "MN" },
  {
    city: "St. Peters",
    state: "MO",
  },
  { city: "St. Petersburg", state: "FL" },
  { city: "St. Rose", state: "LA" },
  {
    city: "St. Simons",
    state: "GA",
  },
  { city: "St. Stephens", state: "NC" },
  { city: "Stafford", state: "TX" },
  {
    city: "Stafford",
    state: "CT",
  },
  { city: "Stamford", state: "CT" },
  { city: "Standish", state: "ME" },
  {
    city: "Stanford",
    state: "CA",
  },
  { city: "Stanton", state: "CA" },
  { city: "Starkville", state: "MS" },
  {
    city: "State College",
    state: "PA",
  },
  { city: "Statesboro", state: "GA" },
  { city: "Statesville", state: "NC" },
  {
    city: "Staunton",
    state: "VA",
  },
  { city: "Stayton", state: "OR" },
  { city: "Steamboat Springs", state: "CO" },
  {
    city: "Steger",
    state: "IL",
  },
  { city: "Steilacoom", state: "WA" },
  { city: "Stephenville", state: "TX" },
  {
    city: "Sterling",
    state: "IL",
  },
  { city: "Sterling", state: "MA" },
  { city: "Sterling", state: "CO" },
  {
    city: "Sterling Heights",
    state: "MI",
  },
  { city: "Steubenville", state: "OH" },
  { city: "Stevens Point", state: "WI" },
  {
    city: "Stickney",
    state: "IL",
  },
  { city: "Stillwater", state: "MN" },
  { city: "Stillwater", state: "NY" },
  {
    city: "Stillwater",
    state: "OK",
  },
  { city: "Stockbridge", state: "GA" },
  { city: "Stockton", state: "CA" },
  {
    city: "Stone Mountain",
    state: "GA",
  },
  { city: "Stonegate", state: "CO" },
  { city: "Stoneham", state: "MA" },
  {
    city: "Stonington",
    state: "CT",
  },
  { city: "Stony Brook", state: "NY" },
  { city: "Stony Point", state: "NY" },
  {
    city: "Storm Lake",
    state: "IA",
  },
  { city: "Storrs", state: "CT" },
  { city: "Stoughton", state: "MA" },
  {
    city: "Stoughton",
    state: "WI",
  },
  { city: "Stow", state: "OH" },
  { city: "Stowe Township", state: "PA" },
  {
    city: "Stratford",
    state: "NJ",
  },
  { city: "Stratford", state: "CT" },
  { city: "Stratham", state: "NH" },
  {
    city: "Strathmore",
    state: "NJ",
  },
  { city: "Stratmoor", state: "CO" },
  { city: "Streamwood", state: "IL" },
  {
    city: "Streator",
    state: "IL",
  },
  { city: "Streetsboro", state: "OH" },
  { city: "Strongsville", state: "OH" },
  {
    city: "Struthers",
    state: "OH",
  },
  { city: "Stuart", state: "FL" },
  { city: "Stuarts Draft", state: "VA" },
  {
    city: "Sturbridge",
    state: "MA",
  },
  { city: "Sturgeon Bay", state: "WI" },
  { city: "Sturgis", state: "SD" },
  {
    city: "Sturgis",
    state: "MI",
  },
  { city: "Stuttgart", state: "AR" },
  { city: "Suamico", state: "WI" },
  {
    city: "Succasunna-Kenvil",
    state: "NJ",
  },
  { city: "Sudbury", state: "MA" },
  { city: "Sudley", state: "VA" },
  {
    city: "Suffern",
    state: "NY",
  },
  { city: "Suffield", state: "CT" },
  { city: "Suffolk", state: "VA" },
  {
    city: "Sugar Hill",
    state: "GA",
  },
  { city: "Sugar Land", state: "TX" },
  { city: "Sugarmill Woods", state: "FL" },
  {
    city: "Suisun City",
    state: "CA",
  },
  { city: "Suitland-Silver Hill", state: "MD" },
  { city: "Sullivan", state: "MO" },
  {
    city: "Sullivan",
    state: "NY",
  },
  { city: "Sulphur", state: "LA" },
  { city: "Sulphur Springs", state: "TX" },
  {
    city: "Summerfield",
    state: "NC",
  },
  { city: "Summerville", state: "SC" },
  { city: "Summit", state: "NJ" },
  {
    city: "Summit",
    state: "WA",
  },
  { city: "Summit", state: "IL" },
  { city: "Summit Park", state: "UT" },
  {
    city: "Sumner",
    state: "WA",
  },
  { city: "Sumter", state: "SC" },
  { city: "Sun City", state: "CA" },
  {
    city: "Sun City",
    state: "AZ",
  },
  { city: "Sun City West", state: "AZ" },
  { city: "Sun Lakes", state: "AZ" },
  {
    city: "Sun Prairie",
    state: "WI",
  },
  { city: "Sun Valley", state: "NV" },
  { city: "Sunbury", state: "PA" },
  {
    city: "Sunland Park",
    state: "NM",
  },
  { city: "Sunny Isles Beach", state: "FL" },
  { city: "Sunnyside", state: "OR" },
  {
    city: "Sunnyside",
    state: "WA",
  },
  { city: "Sunnyvale", state: "CA" },
  { city: "Sunrise", state: "FL" },
  {
    city: "Sunrise Manor",
    state: "NV",
  },
  { city: "Sunset", state: "FL" },
  { city: "Sunset Hills", state: "MO" },
  {
    city: "Superior",
    state: "CO",
  },
  { city: "Superior", state: "WI" },
  { city: "Surprise", state: "AZ" },
  {
    city: "Susanville",
    state: "CA",
  },
  { city: "Sussex", state: "WI" },
  { city: "Sutherlin", state: "OR" },
  {
    city: "Sutton",
    state: "MA",
  },
  { city: "Suwanee", state: "GA" },
  { city: "Swainsboro", state: "GA" },
  {
    city: "Swampscott",
    state: "MA",
  },
  { city: "Swansea", state: "IL" },
  { city: "Swansea", state: "MA" },
  {
    city: "Swanton",
    state: "VT",
  },
  { city: "Swanzey", state: "NH" },
  { city: "Swarthmore", state: "PA" },
  {
    city: "Sweden",
    state: "NY",
  },
  { city: "Sweet Home", state: "OR" },
  { city: "Sweetwater", state: "TX" },
  {
    city: "Sweetwater",
    state: "FL",
  },
  { city: "Swissvale", state: "PA" },
  { city: "Sycamore", state: "IL" },
  {
    city: "Sylacauga",
    state: "AL",
  },
  { city: "Sylvania", state: "OH" },
  { city: "Syosset", state: "NY" },
  {
    city: "Syracuse",
    state: "NY",
  },
  { city: "Syracuse", state: "UT" },
  { city: "Tacoma", state: "WA" },
  {
    city: "Taft",
    state: "CA",
  },
  { city: "Tahlequah", state: "OK" },
  { city: "Takoma Park", state: "MD" },
  {
    city: "Talladega",
    state: "AL",
  },
  { city: "Tallahassee", state: "FL" },
  { city: "Tallmadge", state: "OH" },
  {
    city: "Tallulah",
    state: "LA",
  },
  { city: "Tamalpais-Homestead Valley", state: "CA" },
  { city: "Tamaqua", state: "PA" },
  {
    city: "Tamarac",
    state: "FL",
  },
  { city: "Tamiami", state: "FL" },
  { city: "Tampa", state: "FL" },
  {
    city: "Tanque Verde",
    state: "AZ",
  },
  { city: "Tappan", state: "NY" },
  { city: "Tarboro", state: "NC" },
  {
    city: "Tarpon Springs",
    state: "FL",
  },
  { city: "Tarrant", state: "AL" },
  { city: "Tarrytown", state: "NY" },
  {
    city: "Taunton",
    state: "MA",
  },
  { city: "Tavares", state: "FL" },
  { city: "Taylor", state: "MI" },
  {
    city: "Taylor",
    state: "PA",
  },
  { city: "Taylor", state: "TX" },
  { city: "Taylor Mill", state: "KY" },
  {
    city: "Taylors",
    state: "SC",
  },
  { city: "Taylorsville", state: "UT" },
  { city: "Taylorville", state: "IL" },
  {
    city: "Teaneck",
    state: "NJ",
  },
  { city: "Teays Valley", state: "WV" },
  { city: "Tecumseh", state: "MI" },
  {
    city: "Tecumseh",
    state: "OK",
  },
  { city: "Tehachapi", state: "CA" },
  { city: "Tell City", state: "IN" },
  {
    city: "Temecula",
    state: "CA",
  },
  { city: "Tempe", state: "AZ" },
  { city: "Temperance", state: "MI" },
  {
    city: "Temple",
    state: "TX",
  },
  { city: "Temple City", state: "CA" },
  { city: "Temple Hills", state: "MD" },
  {
    city: "Temple Terrace",
    state: "FL",
  },
  { city: "Templeton", state: "MA" },
  { city: "Tenafly", state: "NJ" },
  {
    city: "Terrace Heights",
    state: "WA",
  },
  { city: "Terre Haute", state: "IN" },
  { city: "Terrell", state: "TX" },
  {
    city: "Terrytown",
    state: "LA",
  },
  { city: "Terryville", state: "NY" },
  { city: "Tewksbury", state: "MA" },
  {
    city: "Texarkana",
    state: "TX",
  },
  { city: "Texarkana", state: "AR" },
  { city: "Texas City", state: "TX" },
  {
    city: "The Colony",
    state: "TX",
  },
  { city: "The Crossings", state: "FL" },
  { city: "The Hammocks", state: "FL" },
  {
    city: "The Pinery",
    state: "CO",
  },
  { city: "The Village", state: "OK" },
  { city: "The Villages", state: "FL" },
  {
    city: "The Woodlands",
    state: "TX",
  },
  { city: "Theodore", state: "AL" },
  { city: "Thermalito", state: "CA" },
  {
    city: "Thibodaux",
    state: "LA",
  },
  { city: "Thief River Falls", state: "MN" },
  { city: "Thomaston", state: "GA" },
  {
    city: "Thomaston",
    state: "CT",
  },
  { city: "Thomasville", state: "GA" },
  { city: "Thomasville", state: "NC" },
  {
    city: "Thompson",
    state: "NY",
  },
  { city: "Thompson", state: "CT" },
  { city: "Thompsonville", state: "CT" },
  {
    city: "Thomson",
    state: "GA",
  },
  { city: "Thonotosassa", state: "FL" },
  { city: "Thornton", state: "CO" },
  {
    city: "Thousand Oaks",
    state: "CA",
  },
  { city: "Three Lakes", state: "FL" },
  { city: "Three Rivers", state: "MI" },
  {
    city: "Tiburon",
    state: "CA",
  },
  { city: "Tiffin", state: "OH" },
  { city: "Tifton", state: "GA" },
  {
    city: "Tigard",
    state: "OR",
  },
  { city: "Tillmans Corner", state: "AL" },
  { city: "Timberlake", state: "VA" },
  {
    city: "Timberlane",
    state: "LA",
  },
  { city: "Tinley Park", state: "IL" },
  { city: "Tinton Falls", state: "NJ" },
  {
    city: "Tipp City",
    state: "OH",
  },
  { city: "Titusville", state: "PA" },
  { city: "Titusville", state: "FL" },
  {
    city: "Tiverton",
    state: "RI",
  },
  { city: "Toccoa", state: "GA" },
  { city: "Toledo", state: "OH" },
  {
    city: "Tolland",
    state: "CT",
  },
  { city: "Tomah", state: "WI" },
  { city: "Tomball", state: "TX" },
  {
    city: "Toms River",
    state: "NJ",
  },
  { city: "Tonawanda", state: "NY" },
  { city: "Tooele", state: "UT" },
  {
    city: "Topeka",
    state: "KS",
  },
  { city: "Toppenish", state: "WA" },
  { city: "Topsfield", state: "MA" },
  {
    city: "Topsham",
    state: "ME",
  },
  { city: "Torrance", state: "CA" },
  { city: "Torrington", state: "CT" },
  {
    city: "Totowa",
    state: "NJ",
  },
  { city: "Town 'n' Country", state: "FL" },
  { city: "Town and Country", state: "MO" },
  {
    city: "Townsend",
    state: "MA",
  },
  { city: "Towson", state: "MD" },
  { city: "Tracy", state: "CA" },
  {
    city: "Traverse City",
    state: "MI",
  },
  { city: "Travilah", state: "MD" },
  { city: "Treasure Island", state: "FL" },
  {
    city: "Trenton",
    state: "MI",
  },
  { city: "Trenton", state: "MO" },
  { city: "Trenton", state: "NJ" },
  {
    city: "Trenton",
    state: "OH",
  },
  { city: "Trinidad", state: "CO" },
  { city: "Trinity", state: "NC" },
  {
    city: "Trooper",
    state: "PA",
  },
  { city: "Trophy Club", state: "TX" },
  { city: "Trotwood", state: "OH" },
  {
    city: "Troutdale",
    state: "OR",
  },
  { city: "Troy", state: "OH" },
  { city: "Troy", state: "NY" },
  {
    city: "Troy",
    state: "MO",
  },
  { city: "Troy", state: "MI" },
  { city: "Troy", state: "AL" },
  {
    city: "Troy",
    state: "IL",
  },
  { city: "Truckee", state: "CA" },
  { city: "Trumann", state: "AR" },
  {
    city: "Trumbull",
    state: "CT",
  },
  { city: "Trussville", state: "AL" },
  { city: "Truth or Consequences", state: "NM" },
  {
    city: "Tualatin",
    state: "OR",
  },
  { city: "Tuba City", state: "AZ" },
  { city: "Tuckahoe", state: "NY" },
  {
    city: "Tuckahoe",
    state: "VA",
  },
  { city: "Tucker", state: "GA" },
  { city: "Tucson", state: "AZ" },
  {
    city: "Tucson Estates",
    state: "AZ",
  },
  { city: "Tukwila", state: "WA" },
  { city: "Tulare", state: "CA" },
  {
    city: "Tullahoma",
    state: "TN",
  },
  { city: "Tulsa", state: "OK" },
  { city: "Tumwater", state: "WA" },
  {
    city: "Tupelo",
    state: "MS",
  },
  { city: "Turlock", state: "CA" },
  { city: "Turtle Creek", state: "PA" },
  {
    city: "Tuscaloosa",
    state: "AL",
  },
  { city: "Tuscumbia", state: "AL" },
  { city: "Tuskegee", state: "AL" },
  {
    city: "Tustin",
    state: "CA",
  },
  { city: "Tustin Foothills", state: "CA" },
  {
    city: "Twentynine Palms",
    state: "CA",
  },
  { city: "Twentynine Palms Base", state: "CA" },
  { city: "Twin Falls", state: "ID" },
  {
    city: "Twin Lakes",
    state: "CO",
  },
  { city: "Twin Rivers", state: "NJ" },
  { city: "Twinsburg", state: "OH" },
  {
    city: "Two Rivers",
    state: "WI",
  },
  { city: "Tyler", state: "TX" },
  { city: "Tyngsborough", state: "MA" },
  {
    city: "Tysons Corner",
    state: "VA",
  },
  { city: "Ukiah", state: "CA" },
  { city: "Ulster", state: "NY" },
  {
    city: "Union",
    state: "SC",
  },
  { city: "Union", state: "NY" },
  { city: "Union", state: "MO" },
  {
    city: "Union",
    state: "NJ",
  },
  { city: "Union Beach", state: "NJ" },
  { city: "Union City", state: "NJ" },
  {
    city: "Union City",
    state: "CA",
  },
  { city: "Union City", state: "GA" },
  {
    city: "Union City",
    state: "TN",
  },
  { city: "Union Hill-Novelty Hill", state: "WA" },
  { city: "Union Park", state: "FL" },
  {
    city: "Uniondale",
    state: "NY",
  },
  { city: "Uniontown", state: "PA" },
  { city: "Universal City", state: "TX" },
  {
    city: "University",
    state: "FL",
  },
  { city: "University City", state: "MO" },
  {
    city: "University Heights",
    state: "OH",
  },
  { city: "University Park", state: "TX" },
  {
    city: "University Park",
    state: "FL",
  },
  { city: "University Park", state: "IL" },
  { city: "University Place", state: "WA" },
  {
    city: "Upland",
    state: "CA",
  },
  { city: "Upper Arlington", state: "OH" },
  {
    city: "Upper Grand Lagoon",
    state: "FL",
  },
  { city: "Upper Providence Township", state: "PA" },
  {
    city: "Upper Saddle River",
    state: "NJ",
  },
  { city: "Upper Sandusky", state: "OH" },
  { city: "Upper St. Clair", state: "PA" },
  {
    city: "Urbana",
    state: "OH",
  },
  { city: "Urbana", state: "IL" },
  { city: "Urbandale", state: "IA" },
  {
    city: "Utica",
    state: "NY",
  },
  { city: "Uvalde", state: "TX" },
  { city: "Uxbridge", state: "MA" },
  {
    city: "Vacaville",
    state: "CA",
  },
  { city: "Vadnais Heights", state: "MN" },
  { city: "Valdosta", state: "GA" },
  {
    city: "Valinda",
    state: "CA",
  },
  { city: "Valle Vista", state: "CA" },
  { city: "Vallejo", state: "CA" },
  {
    city: "Valley",
    state: "AL",
  },
  { city: "Valley Center", state: "CA" },
  { city: "Valley City", state: "ND" },
  {
    city: "Valley Cottage",
    state: "NY",
  },
  { city: "Valley Falls", state: "RI" },
  { city: "Valley Park", state: "MO" },
  {
    city: "Valley Station",
    state: "KY",
  },
  { city: "Valley Stream", state: "NY" },
  { city: "Valparaiso", state: "IN" },
  {
    city: "Valparaiso",
    state: "FL",
  },
  { city: "Valrico", state: "FL" },
  { city: "Van Buren", state: "AR" },
  {
    city: "Van Buren",
    state: "NY",
  },
  { city: "Van Wert", state: "OH" },
  { city: "Vancouver", state: "WA" },
  {
    city: "Vandalia",
    state: "OH",
  },
  { city: "Vandalia", state: "IL" },
  { city: "Vandenberg AFB", state: "CA" },
  {
    city: "Vashon",
    state: "WA",
  },
  { city: "Venice", state: "FL" },
  { city: "Venice Gardens", state: "FL" },
  {
    city: "Ventnor City",
    state: "NJ",
  },
  { city: "Veradale", state: "WA" },
  { city: "Vermilion", state: "OH" },
  {
    city: "Vermillion",
    state: "SD",
  },
  { city: "Vernal", state: "UT" },
  { city: "Vernon", state: "WI" },
  {
    city: "Vernon",
    state: "TX",
  },
  { city: "Vernon", state: "CT" },
  { city: "Vernon Hills", state: "IL" },
  {
    city: "Vero Beach",
    state: "FL",
  },
  { city: "Vero Beach South", state: "FL" },
  { city: "Verona", state: "NJ" },
  {
    city: "Verona",
    state: "NY",
  },
  { city: "Verona", state: "WI" },
  { city: "Versailles", state: "KY" },
  {
    city: "Vestal",
    state: "NY",
  },
  { city: "Vestavia Hills", state: "AL" },
  { city: "Vicksburg", state: "MS" },
  {
    city: "Victor",
    state: "NY",
  },
  { city: "Victoria", state: "TX" },
  { city: "Victorville", state: "CA" },
  {
    city: "Vidalia",
    state: "GA",
  },
  { city: "Vidor", state: "TX" },
  { city: "Vienna", state: "VA" },
  {
    city: "Vienna",
    state: "WV",
  },
  { city: "View Park-Windsor Hills", state: "CA" },
  {
    city: "Villa Hills",
    state: "KY",
  },
  { city: "Villa Park", state: "IL" },
  {
    city: "Village Green-Green Ridge",
    state: "PA",
  },
  { city: "Village Park", state: "HI" },
  { city: "Village St. George", state: "LA" },
  {
    city: "Villas",
    state: "FL",
  },
  { city: "Villas", state: "NJ" },
  { city: "Ville Platte", state: "LA" },
  {
    city: "Vincennes",
    state: "IN",
  },
  { city: "Vincent", state: "CA" },
  { city: "Vineland", state: "NJ" },
  {
    city: "Vineyard",
    state: "CA",
  },
  { city: "Vinings", state: "GA" },
  { city: "Vinita", state: "OK" },
  {
    city: "Vinton",
    state: "VA",
  },
  { city: "Violet", state: "LA" },
  { city: "Virginia", state: "MN" },
  {
    city: "Virginia Beach",
    state: "VA",
  },
  { city: "Visalia", state: "CA" },
  { city: "Vista", state: "CA" },
  {
    city: "Volney",
    state: "NY",
  },
  { city: "Wabash", state: "IN" },
  { city: "Waco", state: "TX" },
  {
    city: "Waconia",
    state: "MN",
  },
  { city: "Wade Hampton", state: "SC" },
  { city: "Wading River", state: "NY" },
  {
    city: "Wadsworth",
    state: "OH",
  },
  { city: "Waggaman", state: "LA" },
  { city: "Wagoner", state: "OK" },
  {
    city: "Wahiawa",
    state: "HI",
  },
  { city: "Wahpeton", state: "ND" },
  { city: "Waianae", state: "HI" },
  {
    city: "Waihee-Waiehu",
    state: "HI",
  },
  { city: "Wailuku", state: "HI" },
  { city: "Waimalu", state: "HI" },
  {
    city: "Waimea",
    state: "HI",
  },
  { city: "Waipahu", state: "HI" },
  { city: "Waipio", state: "HI" },
  {
    city: "Waite Park",
    state: "MN",
  },
  { city: "Wake Forest", state: "NC" },
  { city: "Wakefield", state: "MA" },
  {
    city: "Wakefield-Peacedale",
    state: "RI",
  },
  { city: "Walden", state: "NY" },
  { city: "Waldorf", state: "MD" },
  {
    city: "Waldwick",
    state: "NJ",
  },
  { city: "Walker", state: "MI" },
  { city: "Walker Mill", state: "MD" },
  {
    city: "Walla Walla",
    state: "WA",
  },
  { city: "Walled Lake", state: "MI" },
  { city: "Waller", state: "WA" },
  {
    city: "Wallingford",
    state: "CT",
  },
  { city: "Wallingford Center", state: "CT" },
  { city: "Wallington", state: "NJ" },
  {
    city: "Wallkill",
    state: "NY",
  },
  { city: "Walnut", state: "CA" },
  { city: "Walnut Creek", state: "CA" },
  {
    city: "Walnut Grove",
    state: "WA",
  },
  { city: "Walnut Park", state: "CA" },
  { city: "Walpole", state: "MA" },
  {
    city: "Waltham",
    state: "MA",
  },
  { city: "Walworth", state: "NY" },
  { city: "Wanaque", state: "NJ" },
  {
    city: "Wantagh",
    state: "NY",
  },
  { city: "Wapakoneta", state: "OH" },
  { city: "Wappinger", state: "NY" },
  {
    city: "Ware",
    state: "MA",
  },
  { city: "Wareham", state: "MA" },
  { city: "Warner Robins", state: "GA" },
  {
    city: "Warr Acres",
    state: "OK",
  },
  { city: "Warren", state: "PA" },
  { city: "Warren", state: "RI" },
  {
    city: "Warren",
    state: "AR",
  },
  { city: "Warren", state: "MI" },
  { city: "Warren", state: "OH" },
  {
    city: "Warrensburg",
    state: "MO",
  },
  { city: "Warrensville Heights", state: "OH" },
  { city: "Warrenton", state: "VA" },
  {
    city: "Warrenville",
    state: "IL",
  },
  { city: "Warrington", state: "FL" },
  { city: "Warsaw", state: "IN" },
  {
    city: "Warwick",
    state: "NY",
  },
  { city: "Warwick", state: "RI" },
  { city: "Wasco", state: "CA" },
  {
    city: "Waseca",
    state: "MN",
  },
  { city: "Washington", state: "MO" },
  { city: "Washington", state: "NJ" },
  {
    city: "Washington",
    state: "OH",
  },
  { city: "Washington", state: "NC" },
  { city: "Washington", state: "IN" },
  {
    city: "Washington",
    state: "IA",
  },
  { city: "Washington", state: "IL" },
  { city: "Washington", state: "PA" },
  {
    city: "Washington",
    state: "UT",
  },
  { city: "Washington", state: "WI" },
  { city: "Washington", state: "WA" },
  {
    city: "Washington Terrace",
    state: "UT",
  },
  { city: "Washington Township", state: "NJ" },
  { city: "Washougal", state: "WA" },
  {
    city: "Watauga",
    state: "TX",
  },
  { city: "Waterboro", state: "ME" },
  { city: "Waterbury", state: "CT" },
  {
    city: "Waterford",
    state: "CT",
  },
  { city: "Waterford", state: "CA" },
  { city: "Waterford", state: "MI" },
  {
    city: "Waterford",
    state: "NY",
  },
  { city: "Waterloo", state: "NY" },
  { city: "Waterloo", state: "IL" },
  {
    city: "Waterloo",
    state: "IA",
  },
  { city: "Watertown", state: "CT" },
  { city: "Watertown", state: "NY" },
  {
    city: "Watertown",
    state: "MA",
  },
  { city: "Watertown", state: "SD" },
  { city: "Watertown", state: "WI" },
  {
    city: "Waterville",
    state: "ME",
  },
  { city: "Watervliet", state: "NY" },
  { city: "Watsonville", state: "CA" },
  {
    city: "Wauconda",
    state: "IL",
  },
  { city: "Waukegan", state: "IL" },
  { city: "Waukesha", state: "WI" },
  {
    city: "Waunakee",
    state: "WI",
  },
  { city: "Waupun", state: "WI" },
  { city: "Wausau", state: "WI" },
  {
    city: "Wauseon",
    state: "OH",
  },
  { city: "Wauwatosa", state: "WI" },
  { city: "Waveland", state: "MS" },
  {
    city: "Waverly",
    state: "MI",
  },
  { city: "Waverly", state: "IA" },
  { city: "Wawarsing", state: "NY" },
  {
    city: "Wawayanda",
    state: "NY",
  },
  { city: "Waxahachie", state: "TX" },
  { city: "Waycross", state: "GA" },
  {
    city: "Wayland",
    state: "MA",
  },
  { city: "Wayne", state: "MI" },
  { city: "Wayne", state: "NJ" },
  {
    city: "Waynesboro",
    state: "VA",
  },
  { city: "Waynesboro", state: "PA" },
  { city: "Waynesville", state: "NC" },
  {
    city: "Weare",
    state: "NH",
  },
  { city: "Weatherford", state: "OK" },
  { city: "Weatherford", state: "TX" },
  {
    city: "Webb City",
    state: "MO",
  },
  { city: "Webster", state: "MA" },
  { city: "Webster", state: "NY" },
  {
    city: "Webster",
    state: "TX",
  },
  { city: "Webster City", state: "IA" },
  { city: "Webster Groves", state: "MO" },
  {
    city: "Weddington",
    state: "NC",
  },
  { city: "Weigelstown", state: "PA" },
  { city: "Weirton", state: "WV" },
  {
    city: "Wekiwa Springs",
    state: "FL",
  },
  { city: "Welby", state: "CO" },
  { city: "Welcome", state: "SC" },
  {
    city: "Wellesley",
    state: "MA",
  },
  { city: "Wellington", state: "FL" },
  { city: "Wellington", state: "KS" },
  {
    city: "Wells",
    state: "ME",
  },
  { city: "Wells Branch", state: "TX" },
  { city: "Wellston", state: "OH" },
  {
    city: "Wellsville",
    state: "NY",
  },
  { city: "Wenatchee", state: "WA" },
  { city: "Wentzville", state: "MO" },
  {
    city: "Weslaco",
    state: "TX",
  },
  { city: "West Allis", state: "WI" },
  { city: "West and East Lealman", state: "FL" },
  {
    city: "West Athens",
    state: "CA",
  },
  { city: "West Babylon", state: "NY" },
  {
    city: "West Bend",
    state: "WI",
  },
  { city: "West Bloomfield Township", state: "MI" },
  {
    city: "West Boylston",
    state: "MA",
  },
  { city: "West Bridgewater", state: "MA" },
  {
    city: "West Caldwell",
    state: "NJ",
  },
  { city: "West Carrollton City", state: "OH" },
  {
    city: "West Carson",
    state: "CA",
  },
  { city: "West Chester", state: "PA" },
  { city: "West Chicago", state: "IL" },
  {
    city: "West Columbia",
    state: "SC",
  },
  { city: "West Covina", state: "CA" },
  { city: "West Des Moines", state: "IA" },
  {
    city: "West Fargo",
    state: "ND",
  },
  { city: "West Frankfort", state: "IL" },
  { city: "West Freehold", state: "NJ" },
  {
    city: "West Gate",
    state: "VA",
  },
  { city: "West Glens Falls", state: "NY" },
  { city: "West Goshen", state: "PA" },
  {
    city: "West Hartford",
    state: "CT",
  },
  { city: "West Hattiesburg", state: "MS" },
  {
    city: "West Haven",
    state: "CT",
  },
  { city: "West Haven-Sylvan", state: "OR" },
  {
    city: "West Haverstraw",
    state: "NY",
  },
  { city: "West Helena", state: "AR" },
  { city: "West Hempstead", state: "NY" },
  {
    city: "West Hollywood",
    state: "CA",
  },
  { city: "West Islip", state: "NY" },
  { city: "West Jordan", state: "UT" },
  {
    city: "West Lafayette",
    state: "IN",
  },
  { city: "West Lake Stevens", state: "WA" },
  {
    city: "West Linn",
    state: "OR",
  },
  { city: "West Little River", state: "FL" },
  {
    city: "West Livingston",
    state: "TX",
  },
  { city: "West Long Branch", state: "NJ" },
  { city: "West Melbourne", state: "FL" },
  {
    city: "West Memphis",
    state: "AR",
  },
  { city: "West Mifflin", state: "PA" },
  { city: "West Milford", state: "NJ" },
  {
    city: "West Modesto",
    state: "CA",
  },
  { city: "West Monroe", state: "LA" },
  { city: "West New York", state: "NJ" },
  {
    city: "West Norriton",
    state: "PA",
  },
  { city: "West Odessa", state: "TX" },
  { city: "West Orange", state: "NJ" },
  {
    city: "West Palm Beach",
    state: "FL",
  },
  { city: "West Paterson", state: "NJ" },
  { city: "West Pensacola", state: "FL" },
  {
    city: "West Perrine",
    state: "FL",
  },
  { city: "West Plains", state: "MO" },
  { city: "West Point", state: "NY" },
  {
    city: "West Point",
    state: "MS",
  },
  { city: "West Point", state: "UT" },
  { city: "West Puente Valley", state: "CA" },
  {
    city: "West Richland",
    state: "WA",
  },
  { city: "West Sacramento", state: "CA" },
  { city: "West Seneca", state: "NY" },
  {
    city: "West Slope",
    state: "OR",
  },
  { city: "West Springfield", state: "VA" },
  {
    city: "West Springfield",
    state: "MA",
  },
  { city: "West St. Paul", state: "MN" },
  {
    city: "West University Place",
    state: "TX",
  },
  { city: "West Valley", state: "WA" },
  {
    city: "West Valley City",
    state: "UT",
  },
  { city: "West Vero Corridor", state: "FL" },
  { city: "West View", state: "PA" },
  {
    city: "West Warwick",
    state: "RI",
  },
  { city: "West Whittier-Los Nietos", state: "CA" },
  {
    city: "West Yarmouth",
    state: "MA",
  },
  { city: "Westborough", state: "MA" },
  { city: "Westbrook", state: "CT" },
  {
    city: "Westbrook",
    state: "ME",
  },
  { city: "Westbury", state: "NY" },
  { city: "Westchase", state: "FL" },
  {
    city: "Westchester",
    state: "FL",
  },
  { city: "Westchester", state: "IL" },
  { city: "Westerly", state: "RI" },
  {
    city: "Western Springs",
    state: "IL",
  },
  { city: "Westerville", state: "OH" },
  { city: "Westfield", state: "MA" },
  {
    city: "Westfield",
    state: "NJ",
  },
  { city: "Westfield", state: "IN" },
  { city: "Westford", state: "MA" },
  {
    city: "Westgate-Belvedere Homes",
    state: "FL",
  },
  { city: "Westlake", state: "OH" },
  { city: "Westlake Village", state: "CA" },
  {
    city: "Westland",
    state: "MI",
  },
  { city: "Westmere", state: "NY" },
  { city: "Westminster", state: "MA" },
  {
    city: "Westminster",
    state: "CA",
  },
  { city: "Westminster", state: "CO" },
  { city: "Westminster", state: "MD" },
  {
    city: "Westmont",
    state: "IL",
  },
  { city: "Westmont", state: "CA" },
  { city: "Westmoreland", state: "NY" },
  {
    city: "Weston",
    state: "CT",
  },
  { city: "Weston", state: "FL" },
  { city: "Weston", state: "MA" },
  {
    city: "Weston",
    state: "WI",
  },
  { city: "Westport", state: "MA" },
  { city: "Westport", state: "CT" },
  {
    city: "Westview",
    state: "FL",
  },
  { city: "Westwego", state: "LA" },
  { city: "Westwood", state: "MA" },
  {
    city: "Westwood",
    state: "MI",
  },
  { city: "Westwood", state: "NJ" },
  { city: "Westwood Lakes", state: "FL" },
  {
    city: "Wethersfield",
    state: "CT",
  },
  { city: "Weymouth", state: "MA" },
  { city: "Wharton", state: "NJ" },
  {
    city: "Wharton",
    state: "TX",
  },
  { city: "Wheat Ridge", state: "CO" },
  { city: "Wheatfield", state: "NY" },
  {
    city: "Wheaton",
    state: "IL",
  },
  { city: "Wheaton-Glenmont", state: "MD" },
  { city: "Wheelersburg", state: "OH" },
  {
    city: "Wheeling",
    state: "IL",
  },
  { city: "Wheeling", state: "WV" },
  { city: "White Bear Lake", state: "MN" },
  {
    city: "White Center",
    state: "WA",
  },
  { city: "White Horse", state: "NJ" },
  { city: "White House", state: "TN" },
  {
    city: "White Marsh",
    state: "MD",
  },
  { city: "White Meadow Lake", state: "NJ" },
  { city: "White Oak", state: "OH" },
  {
    city: "White Oak",
    state: "MD",
  },
  { city: "White Oak", state: "PA" },
  { city: "White Plains", state: "NY" },
  {
    city: "White Rock",
    state: "NM",
  },
  { city: "White Settlement", state: "TX" },
  { city: "Whitefish Bay", state: "WI" },
  {
    city: "Whitehall",
    state: "PA",
  },
  { city: "Whitehall", state: "OH" },
  { city: "Whitestown", state: "NY" },
  {
    city: "Whitewater",
    state: "WI",
  },
  { city: "Whitinsville", state: "MA" },
  { city: "Whitman", state: "MA" },
  {
    city: "Whitmore Lake",
    state: "MI",
  },
  { city: "Whitney", state: "NV" },
  { city: "Whittier", state: "CA" },
  {
    city: "Wichita",
    state: "KS",
  },
  { city: "Wichita Falls", state: "TX" },
  { city: "Wickliffe", state: "OH" },
  {
    city: "Wilbraham",
    state: "MA",
  },
  { city: "Wildomar", state: "CA" },
  { city: "Wildwood", state: "MO" },
  {
    city: "Wilkes-Barre",
    state: "PA",
  },
  { city: "Wilkins Township", state: "PA" },
  { city: "Wilkinsburg", state: "PA" },
  {
    city: "Willard",
    state: "OH",
  },
  { city: "Williamsburg", state: "FL" },
  { city: "Williamsburg", state: "VA" },
  {
    city: "Williamson",
    state: "NY",
  },
  { city: "Williamsport", state: "PA" },
  { city: "Williamstown", state: "NJ" },
  {
    city: "Williamstown",
    state: "MA",
  },
  { city: "Willimantic", state: "CT" },
  { city: "Williston", state: "ND" },
  {
    city: "Williston",
    state: "VT",
  },
  { city: "Williston Park", state: "NY" },
  { city: "Willmar", state: "MN" },
  {
    city: "Willoughby",
    state: "OH",
  },
  { city: "Willoughby Hills", state: "OH" },
  { city: "Willow Grove", state: "PA" },
  {
    city: "Willow Street",
    state: "PA",
  },
  { city: "Willowbrook", state: "CA" },
  { city: "Willowbrook", state: "IL" },
  {
    city: "Willowick",
    state: "OH",
  },
  { city: "Willows", state: "CA" },
  { city: "Wilmette", state: "IL" },
  {
    city: "Wilmington",
    state: "MA",
  },
  { city: "Wilmington", state: "DE" },
  { city: "Wilmington", state: "OH" },
  {
    city: "Wilmington",
    state: "NC",
  },
  { city: "Wilmington Island", state: "GA" },
  { city: "Wilmington Manor", state: "DE" },
  {
    city: "Wilna",
    state: "NY",
  },
  { city: "Wilson", state: "NC" },
  { city: "Wilson", state: "PA" },
  {
    city: "Wilsonville",
    state: "OR",
  },
  { city: "Wilton", state: "NY" },
  { city: "Wilton", state: "CT" },
  {
    city: "Wilton Manors",
    state: "FL",
  },
  { city: "Winchendon", state: "MA" },
  { city: "Winchester", state: "MA" },
  {
    city: "Winchester",
    state: "NV",
  },
  { city: "Winchester", state: "CT" },
  { city: "Winchester", state: "KY" },
  {
    city: "Winchester",
    state: "TN",
  },
  { city: "Winchester", state: "VA" },
  { city: "Windemere", state: "TX" },
  {
    city: "Winder",
    state: "GA",
  },
  { city: "Windham", state: "ME" },
  { city: "Windham", state: "CT" },
  {
    city: "Windham",
    state: "NH",
  },
  { city: "Windsor", state: "NY" },
  { city: "Windsor", state: "CT" },
  {
    city: "Windsor",
    state: "CO",
  },
  { city: "Windsor", state: "CA" },
  { city: "Windsor Locks", state: "CT" },
  {
    city: "Winfield",
    state: "KS",
  },
  { city: "Winfield", state: "IL" },
  { city: "Winnemucca", state: "NV" },
  {
    city: "Winnetka",
    state: "IL",
  },
  { city: "Winona", state: "MN" },
  { city: "Winooski", state: "VT" },
  {
    city: "Winslow",
    state: "ME",
  },
  { city: "Winslow", state: "AZ" },
  { city: "Winsted", state: "CT" },
  {
    city: "Winston",
    state: "FL",
  },
  { city: "Winston-Salem", state: "NC" },
  { city: "Winter Garden", state: "FL" },
  {
    city: "Winter Gardens",
    state: "CA",
  },
  { city: "Winter Haven", state: "FL" },
  { city: "Winter Park", state: "FL" },
  {
    city: "Winter Springs",
    state: "FL",
  },
  { city: "Winters", state: "CA" },
  { city: "Winthrop", state: "ME" },
  {
    city: "Winthrop",
    state: "MA",
  },
  { city: "Winthrop Harbor", state: "IL" },
  { city: "Winton", state: "CA" },
  {
    city: "Wisconsin Rapids",
    state: "WI",
  },
  { city: "Wixom", state: "MI" },
  { city: "Woburn", state: "MA" },
  {
    city: "Wolcott",
    state: "CT",
  },
  { city: "Wolf Trap", state: "VA" },
  { city: "Wolfeboro", state: "NH" },
  {
    city: "Wonder Lake",
    state: "IL",
  },
  { city: "Wood Dale", state: "IL" },
  { city: "Wood River", state: "IL" },
  {
    city: "Wood-Ridge",
    state: "NJ",
  },
  { city: "Woodbourne-Hyde Park", state: "OH" },
  { city: "Woodbridge", state: "NJ" },
  {
    city: "Woodbridge",
    state: "CT",
  },
  { city: "Woodbridge", state: "VA" },
  { city: "Woodburn", state: "OR" },
  {
    city: "Woodbury",
    state: "CT",
  },
  { city: "Woodbury", state: "NJ" },
  { city: "Woodbury", state: "MN" },
  {
    city: "Woodbury",
    state: "NY",
  },
  { city: "Woodcrest", state: "CA" },
  { city: "Woodfield", state: "SC" },
  {
    city: "Woodhaven",
    state: "MI",
  },
  { city: "Woodinville", state: "WA" },
  { city: "Woodlake", state: "CA" },
  {
    city: "Woodland",
    state: "CA",
  },
  { city: "Woodland Park", state: "CO" },
  { city: "Woodlawn", state: "MD" },
  {
    city: "Woodlyn",
    state: "PA",
  },
  { city: "Woodmere", state: "LA" },
  { city: "Woodmere", state: "NY" },
  {
    city: "Woodmoor",
    state: "CO",
  },
  { city: "Woodmore", state: "MD" },
  { city: "Woodridge", state: "IL" },
  {
    city: "Woods Cross",
    state: "UT",
  },
  { city: "Woodstock", state: "GA" },
  { city: "Woodstock", state: "IL" },
  {
    city: "Woodstock",
    state: "CT",
  },
  { city: "Woodstock", state: "NY" },
  { city: "Woodward", state: "OK" },
  {
    city: "Woodway",
    state: "TX",
  },
  { city: "Woonsocket", state: "RI" },
  { city: "Wooster", state: "OH" },
  {
    city: "Worcester",
    state: "MA",
  },
  { city: "Worth", state: "IL" },
  { city: "Worthington", state: "MN" },
  {
    city: "Worthington",
    state: "OH",
  },
  { city: "Wrentham", state: "MA" },
  { city: "Wright", state: "FL" },
  {
    city: "Wright-Patterson AFB",
    state: "OH",
  },
  { city: "Wyandanch", state: "NY" },
  { city: "Wyandotte", state: "MI" },
  {
    city: "Wyckoff",
    state: "NJ",
  },
  { city: "Wylie", state: "TX" },
  { city: "Wyndham", state: "VA" },
  {
    city: "Wynne",
    state: "AR",
  },
  { city: "Wyoming", state: "OH" },
  { city: "Wyoming", state: "MI" },
  {
    city: "Wyomissing",
    state: "PA",
  },
  { city: "Wytheville", state: "VA" },
  { city: "Xenia", state: "OH" },
  {
    city: "Yakima",
    state: "WA",
  },
  { city: "Yankton", state: "SD" },
  { city: "Yardville-Groveville", state: "NJ" },
  {
    city: "Yarmouth",
    state: "ME",
  },
  { city: "Yarmouth", state: "MA" },
  { city: "Yazoo City", state: "MS" },
  {
    city: "Yeadon",
    state: "PA",
  },
  { city: "Yeehaw Junction", state: "FL" },
  { city: "Yonkers", state: "NY" },
  {
    city: "Yorba Linda",
    state: "CA",
  },
  { city: "York", state: "NE" },
  { city: "York", state: "ME" },
  {
    city: "York",
    state: "PA",
  },
  { city: "York", state: "SC" },
  { city: "Yorketown", state: "NJ" },
  {
    city: "Yorkshire",
    state: "VA",
  },
  { city: "Yorktown", state: "NY" },
  { city: "Yorktown Heights", state: "NY" },
  {
    city: "Yorkville",
    state: "IL",
  },
  { city: "Youngstown", state: "OH" },
  { city: "Ypsilanti", state: "MI" },
  {
    city: "Yreka",
    state: "CA",
  },
  { city: "Yuba City", state: "CA" },
  { city: "Yucaipa", state: "CA" },
  {
    city: "Yucca Valley",
    state: "CA",
  },
  { city: "Yukon", state: "OK" },
  { city: "Yulee", state: "FL" },
  {
    city: "Yuma",
    state: "AZ",
  },
  { city: "Zachary", state: "LA" },
  { city: "Zanesville", state: "OH" },
  {
    city: "Zephyrhills",
    state: "FL",
  },
  { city: "Zion", state: "IL" },
  { city: "Zionsville", state: "IN" },
  { city: "Zuni Pueblo", state: "NM" },
];

export default US_CITIES;
