import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";

import "./styles.scss";
import colors from "../../styles/colors";

import { Icon } from "../../ui-kit";
import Skeleton from "../skeleton";

const MIME_TYPES = {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
  "application/msword": "doc",
  "application/pdf": "pdf",
};

/**
 * @component Dropzone
 * @example
 * <Dropzone />
 */
const Dropzone = (props) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: props.accept,
    multiple: props.multiple,
    onDrop: (acceptedFiles) => {
      props.onChange(
        acceptedFiles.map((f) =>
          Object.assign(f, {
            preview: URL.createObjectURL(f),
          }),
        ),
      );
    },
  });

  useEffect(
    () => () => {
      URL.revokeObjectURL(props.files?.preview);
    },
    [props.files],
  );

  return (
    <>
      <div disabled={props.disabled} className="uploading-files">
        {props.skelet ? (
          <div className="dropzone">
            <Skeleton className="circle" width="fit-content">
              <Icon name="files" width={64} height={64} />
            </Skeleton>
            {props.title && (
              <Skeleton className="description" width="fit-content">
                <p>****************************************************************</p>
              </Skeleton>
            )}
          </div>
        ) : (
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <Icon name="files" width={64} height={64} />
            {props.title && <p dangerouslySetInnerHTML={{ __html: props.title }} />}
          </div>
        )}
      </div>
      {!props.skelet && props.files?.length > 0 && (
        <aside>
          {props.files.map((el, i) => (
            <div>
              <figure
                className={MIME_TYPES[el.type]}
                style={
                  Object.keys(MIME_TYPES).every((element) => element !== el.type)
                    ? { backgroundImage: `url(${el.preview})` }
                    : null
                }
              >
                <div className="hidden-section">
                  <button type="button" onClick={() => props.onChange(props.files.filter((_el, index) => index !== i))}>
                    <Icon
                      name="cross"
                      width={24}
                      height={24}
                      color={colors.color_gray._2}
                      className={{
                        display: "block",
                        "margin-left": "auto",
                        "margin-right": "auto",
                      }}
                    />
                  </button>
                </div>
              </figure>
              <p>{el.name}</p>
            </div>
          ))}
        </aside>
      )}
    </>
  );
};

/**
 * Properties
 */

Dropzone.propTypes = {
  title: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  files: PropTypes.array,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  skelet: PropTypes.bool,
};
Dropzone.defaultProps = {
  title: null,
  multiple: true,
  onChange: () => {},
  files: [],
  accept: "image/*",
  disabled: false,
  skelet: false,
};

export default Dropzone;
