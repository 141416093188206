import { DataExporter } from "../../utils/DataExporter";

export const surveyResultsDataExporter = new DataExporter(
  {
    language: "language",
    createdAt: "createdAt",
    result: "result",
  },
  {
    language: "language",
    createdAt: "createdAt",
    result: (row) =>
      Object.entries(row.result)
        .map(([question, answer]) => `${question}:${answer}`)
        .join(", "),
  },
);
