import { api, debug } from "../index";
import { HTTP_STATUS, URL } from "../config";
import { uid } from "uid";

export default class NotificationService {
  static #API_ENDPOINTS = {
    list: "notification/list",
    notification: "notification",
    notificationSendOneUser: "notification/send",
  };

  static async getNotificationList({ page, size = 20, notificationType }) {
    try {
      const request = await api.get(
        `${URL}${
          this.#API_ENDPOINTS.list
        }?pagination=page:${page};size:${size}&filter=type==${notificationType};lastSentBy==admin&sort=createdAt:desc`,
        {
          headers: {
            "content-type": "application/json",
          },
        },
      );

      debug.success("Successful notification request", request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed GET request by path: ${URL}${
          this.#API_ENDPOINTS.list
        }?pagination=page:${page};size:${size}&filter=type==${notificationType};lastSentBy==admin`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async sendNotificationForUsers(data) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.notification}`, {
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          ...data,
          slug: uid(),
        }),
      });

      debug.success("New notification created successfully", request);

      if (request.status !== HTTP_STATUS.CREATED) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed POST request by path: ${URL}${this.#API_ENDPOINTS.notification}`, error);
      throw await error.response?.json();
    }
  }

  static async sendNotificationForOneUser(content, typeOfNotification, notificationText, userId) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.notificationSendOneUser}`, {
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          description: notificationText,
          type: typeOfNotification,
          slug: uid(),
          data: content,
        }),
      });

      debug.success("Sending a notification to the user was successful", request);

      return request.json();
    } catch (error) {
      debug.error(`Failed POST request by path: ${URL}${this.#API_ENDPOINTS.notificationSendOneUser}`, error);
      throw await error.response?.json();
    }
  }

  static async delete(idOfNotification) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.notification}?_id=${idOfNotification}`, {
        headers: {
          "content-type": "application/json",
        },
      });

      debug.success("Result of deleting notification", request);

      if (request.status !== HTTP_STATUS.DELETED) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request;
    } catch (error) {
      debug.error(
        `Failed DELETE request by path(removing a notification): ${URL}${
          this.#API_ENDPOINTS.notification
        }?uid=${idOfNotification}`,
        error,
      );
      throw await error.response?.json();
    }
  }
}
