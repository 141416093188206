const initialState = {
  loading: false,
  failure: null,
  authorized: false,
  data: null,
};

const LOGIN_LOADING = "LOGIN_LOADING";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILURE = "LOGIN_FAILURE";
const LOG_OUT = "LOG_OUT";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_LOADING:
      return {
        loading: true,
        failure: null,
        authorized: false,
        data: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        failure: null,
        authorized: true,
        data: action.payload,
      };
    case LOG_OUT:
      return {
        authorized: false,
      };
    case LOGIN_FAILURE:
      return {
        loading: false,
        failure: action.payload,
        authorized: false,
        data: null,
      };
    default:
      return state;
  }
};

export default reducer;

export const loginLoading = () => ({
  type: LOGIN_LOADING,
});

export const loginFailure = (payload) => ({
  type: LOGIN_FAILURE,
  payload,
});

export const loginSuccess = (payload) => {
  return {
    type: LOGIN_SUCCESS,
    payload,
  };
};

export const logOut = () => ({
  type: LOG_OUT,
});
