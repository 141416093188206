import { useCallback, useEffect, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

import UserService from "../../services/user";
import { Toast } from "../../ui-kit";
import { useQuery } from "../../utils/hooks";
import useAlerts from "../../utils/useAlerts";
import UserView from "./user-view";

const User = () => {
  const [user, setUser] = useState({});
  const [userStats, setUserStats] = useState({});

  const [page, setPage] = useState("userData");
  const [showModal, setShowModal] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  const {
    onGetAlertReports,
    onGetAlerts,
    onDeleteAlert,
    alerts,
    reports,
    alertPage,
    isLastPage,
    isReportsLoading,
    selectedAlert,
    setSelectedAlert,
    isBottomLoading,
    isAlertLoading,
    setIsAlertLoading,
  } = useAlerts();

  const userId = useQuery().get("id");

  const changePage = (notificationType) => {
    setPage(notificationType);
  };

  const getUser = async () => {
    try {
      const result = await UserService.getUserById(userId);
      setUser(result);
    } catch (error) {
      Toast.error("fail");
    }
  };

  const changeUserStatus = useCallback(async () => {
    setButtonLoading(true);
    try {
      const status = user.status === "active" ? "suspended" : "active";
      const updatedUser = await UserService.updateUser(user._id, { status });
      setUser(updatedUser);
      Toast.success("Success");
    } catch (error) {
      Toast.error("Fail");
    } finally {
      setButtonLoading(false);
    }
  }, [user._id, user.status]);

  const getUserStats = async () => {
    try {
      const result = await UserService.getUserStats(userId);
      setUserStats(result);
    } catch (error) {
      Toast.error("fail");
    }
  };

  const getAlerts = async () => {
    await onGetAlerts({
      page: 1,
      size: 10,
      type: "user",
      uid: userId,
    });
  };

  const getNextPage = async () => {
    await onGetAlerts(
      {
        page: alertPage + 1,
        size: 10,
        type: "user",
        uid: userId,
      },
      true,
    );
  };

  const deleteAlert = async (notification) => {
    const deleteCallBack = () => {
      setSelectedAlert("");
      setShowModal("");
    };
    await onDeleteAlert(notification, deleteCallBack);
  };

  const getAlertReports = async () => {
    await onGetAlertReports();
  };

  const getAllData = async () => {
    setIsAlertLoading(true);
    try {
      await Promise.all([getUser(), getAlerts(), getUserStats()]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsAlertLoading(false);
    }
  };

  useBottomScrollListener(() => {
    if (!isBottomLoading && !isLastPage && page === "alerts") {
      getNextPage();
    }
  });

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    showModal === "reports" && getAlertReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <UserView
      user={user}
      userStats={userStats}
      alerts={alerts}
      reports={reports}
      page={page}
      changePage={changePage}
      showModal={showModal}
      setShowModal={setShowModal}
      selectedAlert={selectedAlert}
      setSelectedAlert={setSelectedAlert}
      deleteAlert={deleteAlert}
      reportsLoading={isReportsLoading}
      loading={isAlertLoading}
      buttonLoading={buttonLoading}
      changeUserStatus={changeUserStatus}
    />
  );
};

export default User;
