import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
import { Icon } from "../";
import colors from "../../styles/colors";

/**
 * @component Input
 * @example
 * <Input
 *  label="Phone *"
 *  size="large"
 *  value=""
 *  onChange={() => {}}
 *  inputType="primary"
 * />
 */
const Input = (props) => {
  const renderClassName = () => {
    let str = "input-container";
    if (props.inputType) {
      str += ` ${inputConfigList[props.inputType].basicClass}`;
    }
    if (props.size) {
      str += ` ${sizes[props.size]}`;
    }
    return str;
  };
  return (
    <div className={renderClassName()} title={props.title}>
      <input
        className={props.type === "password" ? "password" : ""}
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value}
        type={props.type}
        maxLength={props.maxLength}
        placeholder={props.placeholder}
        max={props.max}
        min={props.min}
        name={props.name}
        pattern={props.pattern}
        required={props.required}
        disabled={props.disabled}
        readOnly={props.readOnly}
        onBlur={(e) => props.onBlur(e.target.value)}
        autoComplete={props.autocomplete}
        onFocus={props.onFocus}
      />
      <label>{props.label}</label>
      {props.isValid && (
        <div className="is-valid">
          <Icon name="check" width={14} height={14} color={colors.color_green._0} />
        </div>
      )}
      {props.inputType === "danger" && (
        <div className="is-danger">
          <Icon name="danger" width={20} height={20} color={colors.color_red._0} />
        </div>
      )}
      {props.rightIcon && (
        <div className="right-icon" onClick={props.rightIconHandleChange}>
          <Icon
            name={props.rightIcon}
            width={props.iconWidth}
            height={props.iconHeight}
            color={inputConfigList[props.inputType].iconColor}
          />
        </div>
      )}
    </div>
  );
};

/**
 * Styles
 */

const inputConfigList = {
  default: {
    basicClass: "default",
    iconColor: colors.color_gray._4,
  },
  primary: {
    basicClass: "primary",
    iconColor: colors.color_black._1,
  },
  secondary: {
    basicClass: "secondary",
    iconColor: colors.color_green._0,
  },
  danger: {
    basicClass: "danger",
    iconColor: colors.color_gray._4,
  },
};

/**
 * SIZES
 */

const sizes = {
  small: "small",
  medium: "medium",
  large: "large",
};

/**
 * Properties
 */

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  pattern: PropTypes.instanceOf(RegExp),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autocomplete: PropTypes.string,
  value: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  inputType: PropTypes.oneOf(["default", "primary", "secondary", "danger"]),
  isValid: PropTypes.bool,
  readOnly: PropTypes.bool,
  rightIcon: PropTypes.string,
  rightIconHandleChange: PropTypes.func,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
  iconColor: PropTypes.string,
  onBlur: PropTypes.func,
  title: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
};
Input.defaultProps = {
  rightIcon: null,
  iconColor: "#111",
  iconHeight: 20,
  iconWidth: 20,
  label: "",
  type: "text",
  maxLength: 256,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  rightIconHandleChange: () => {},
  placeholder: " ",
  max: null,
  min: null,
  name: null,
  pattern: null,
  required: false,
  disabled: false,
  autocomplete: "off",
  value: "",
  size: "large",
  inputType: "default",
  isValid: false,
  readOnly: false,
  title: null,
};

export default Input;
