import React, { useState } from "react";
import { useSelector } from "react-redux";

import SelectionControlsView from "./selection-controls-view";

const SelectionControlsContainer = () => {
  const [clientName1, setClientName1] = useState(true);
  const [clientName2, setClientName2] = useState(false);
  const [clientName3, setClientName3] = useState(true);

  const [gender, setGender] = useState(0);

  const [testValue1, setTestValue1] = useState(false);
  const [testValue2, setTestValue2] = useState(true);
  const [testValue3, setTestValue3] = useState(false);

  const selectedLanguage = useSelector((store) => store.localization.currentLanguage);

  return (
    <SelectionControlsView
      gender={gender}
      testValue1={testValue1}
      testValue2={testValue2}
      testValue3={testValue3}
      clientName1={clientName1}
      clientName2={clientName2}
      clientName3={clientName3}
      selectedLanguage={selectedLanguage}
      setGender={setGender}
      setTestValue1={setTestValue1}
      setTestValue2={setTestValue2}
      setTestValue3={setTestValue3}
      setClientName1={setClientName1}
      setClientName2={setClientName2}
      setClientName3={setClientName3}
    />
  );
};

export default SelectionControlsContainer;
