import { FirebaseImage } from "../../components";
import PromotionModal from "../../components/modals/promotions-modal";
import Skelet from "./skelet";
import { Button, Confirm, Icon, Table, Tooltip } from "../../ui-kit";

import { getBeautyEngDateString } from "../../utils/datetime";
import { translateStatus } from "../../utils/translate-status";

import STRINGS from "../../localization";
import "./styles.scss";
import { Typography } from "@mui/material";
import { SectionHeader } from "../../components/section-header/SectionHeader";
import React from "react";

const PromotionsView = (props) => {
  return (
    <>
      <PromotionModal
        onClose={() => props.setShowModal("")}
        open={props.showModal === "edit"}
        title={STRINGS.screens.promotions.editPromotion}
        selectedPromotion={props.selectedPromotion}
        saveChanges={props.editPromotion}
        blockedPositions={props.blockedPositions}
      />
      <PromotionModal
        onClose={() => props.setShowModal("")}
        open={props.showModal === "add"}
        title={STRINGS.screens.promotions.addPromotion}
        saveChanges={props.addPromotion}
        blockedPositions={props.blockedPositions}
      />
      <Confirm
        onClose={() => props.setShowModal("")}
        title="Do you really want to delete the Promotion?"
        visible={props.showModal === "delete"}
        subtitle="The deletion will be irreversible."
        buttons={[
          { label: "Yes, I am sure", onClick: () => props.deletePromotion() },
          {
            label: "No, cancel",
            outline: true,
            onClick: () => props.setShowModal(""),
          },
        ]}
      />
      <SectionHeader>
        <Typography variant="h1">{STRINGS.screens.promotions.promotions}</Typography>
        <Button
          icon={{ name: "plus", side: "left" }}
          label={STRINGS.screens.promotions.addPromotion}
          onClick={() => props.setShowModal("add")}
          type="primary"
          size="small"
        />
      </SectionHeader>
      <div>
        <div className="promotions_table">
          {props.loading ? (
            <Skelet />
          ) : (
            <Table
              className={"promotions_paper"}
              head={() => (
                <tr className="promotions_table_heading">
                  <th className="name">{STRINGS.screens.promotions.name}</th>
                  <th>{STRINGS.screens.promotions.picture}</th>
                  <th>{STRINGS.screens.promotions.startDate}</th>
                  <th>{STRINGS.screens.promotions.endDate}</th>
                  <th className="status">{STRINGS.screens.promotions.status}</th>
                  <th>{STRINGS.screens.promotions.pos}</th>
                  <th className="edit" />
                </tr>
              )}
            >
              <>
                {props.promotions?.map((item) => (
                  <tr
                    key={item._id}
                    className="promotions_table_body"
                    onClick={async (event) => {
                      event.stopPropagation();
                      props.setSelectedPromotion(item);
                      props.setShowModal("edit");
                    }}
                  >
                    <td className="name">{item.name}</td>
                    <td>
                      <FirebaseImage url={item.photo.en} className="photo" />
                    </td>
                    <td>{getBeautyEngDateString(new Date(item?.startDate))}</td>
                    <td>{getBeautyEngDateString(new Date(item?.endDate))}</td>
                    <td>
                      <Button
                        loading={props.buttonLoading === item._id}
                        className="outline"
                        size="medium"
                        label={translateStatus(item?.status).status}
                        type={translateStatus(item?.status).button}
                        typeButton="submit"
                        onClick={(e) => {
                          e.stopPropagation();
                          props.changeStatus(item);
                        }}
                      />
                    </td>
                    <td>{item.frequency}</td>
                    <td className="promotions_table_edit">
                      <div className="promotions_table_edit_wrapper">
                        <Tooltip title={STRINGS.screens.promotions.edit}>
                          <div
                            className="promotions_table_edit_iconWrapper"
                            onClick={(event) => {
                              event.stopPropagation();
                              props.setSelectedPromotion(item);
                              props.setShowModal("edit");
                            }}
                          >
                            <Icon name="pen" width={24} height={24} />
                          </div>
                        </Tooltip>
                        <Tooltip title={STRINGS.screens.promotions.delete}>
                          <div
                            className="promotions_table_edit_iconWrapper"
                            onClick={(event) => {
                              event.stopPropagation();
                              props.setSelectedPromotion(item._id);
                              props.setShowModal("delete");
                            }}
                          >
                            <Icon name="trash-outline" width={24} height={24} />
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
                {props.promotions?.length === 0 && (
                  <tr className="empty">
                    <td colSpan={7}>No promotions created</td>
                  </tr>
                )}
              </>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

export default PromotionsView;
