import React from "react";
import "./styles.scss";
import { Skeleton, Paper } from "../../ui-kit";

const Skelet = (props) => {
  return (
    <div className="container-for-dashboard">
      {new Array(4).fill("").map((_, i) => (
        <div style={{ padding: 20 }} key={i}>
          <Skeleton variant="rect">
            <Paper className="paper-skeleton-for-dashboard"></Paper>
          </Skeleton>
        </div>
      ))}
    </div>
  );
};
export default Skelet;
