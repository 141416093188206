import { api, debug } from "../index";
import { HTTP_STATUS, URL } from "../config";

export default class PromotionsService {
  static #API_ENDPOINTS = {
    promotion: "promotion",
    promotions: "promotion/list",
  };

  static async getPromotion(uid) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.promotion}?_id=${uid}`, {
        headers: {
          "content-type": "application/json",
        },
      });

      debug.success("Authentication request result", request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      const requestData = await request.json();

      if (requestData) {
        return requestData;
      }

      return Promise.reject("No Data");
    } catch (globalError) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.promotion}?_id=${uid}`, globalError);
      throw await globalError.response?.json();
    }
  }

  static async createPromotion(data) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.promotion}`, {
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      });

      debug.success("Authentication request result", request);

      /* if (request.status !== HTTP_STATUS.CREATED) {
        return Promise.reject(`Incorrect status ${request.status}`);
      } */

      const requestData = await request.json();

      if (requestData) {
        return requestData;
      }

      return Promise.reject("No Data");
    } catch (globalError) {
      debug.error(`Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.promotion}`, globalError);
      throw await globalError.response?.json();
    }
  }

  static async editPromotion(uid, data) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.promotion}?_id=${uid}`, {
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      });

      debug.success("Authentication request result", request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      const requestData = await request.json();

      if (requestData) {
        return requestData;
      }

      return Promise.reject("No Data");
    } catch (globalError) {
      debug.error(`Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.promotion}?_id=${uid}`, globalError);
      throw await globalError.response?.json();
    }
  }

  static async deletePromotion(uid) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.promotion}?_id=${uid}`, {
        headers: {
          "content-type": "application/json",
        },
      });

      debug.success("Authentication request result", request);

      if (request.status !== HTTP_STATUS.DELETED) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }
    } catch (globalError) {
      debug.error(`Failed with DELETE request by path: ${URL}${this.#API_ENDPOINTS.promotion}?_id=${uid}`, globalError);
      throw await globalError.response?.json();
    }
  }

  static async getPromotions({ page = 1, size = 10, filter = "" }) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.promotions}?pagination=page:${page};size:${size}${
          filter
            ? `&filter=$
        {filter}`
            : ""
        }`,
        {
          headers: {
            "content-type": "application/json",
          },
        },
      );

      debug.success("Authentication request result", request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      const requestData = await request.json();

      if (requestData) {
        return requestData;
      }

      return Promise.reject("No Data");
    } catch (globalError) {
      debug.error(
        `Failed with GET request by path:${URL}${this.#API_ENDPOINTS.promotions}?pagination=page:${page};
        size:${size}${filter ? `&filter=${filter}` : ""}`,
        globalError,
      );
      throw await globalError.response?.json();
    }
  }
}
