import React from "react";
import "./styles.scss";
import { Skeleton, Paper } from "../../ui-kit";
import { NOTIFICATIONS_TYPES } from "../../constants/notificationTypes";

const Skelet = (props) => {
  return (
    <div className="container-for-notifications">
      {new Array(20).fill(null).map((_, i) => (
        <Skeleton variant="rect" key={i}>
          <Paper
            className={
              props.type === NOTIFICATIONS_TYPES.SMS
                ? "paper-skeleton-for-notification-sms"
                : "paper-skeleton-for-notification-other"
            }
          ></Paper>
        </Skeleton>
      ))}
    </div>
  );
};
export default Skelet;
