import STRINGS from "../../localization";
import { Confirm, Icon, Paper, Table, Tooltip } from "../../ui-kit";
import { translateStatus } from "../../utils/translate-status";
import "./styles.scss";
import { LabelAndSort } from "../../components/label-and-sort/LabelAndSort";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DownloadButton } from "../../components/download/DownloadButton";
import { usersDataExporter } from "./usersDataExporter";
import { Box, Button, debounce, IconButton, InputAdornment, Typography } from "@mui/material";
import { LocationFilter } from "../../components/location-filter/LocationFilter";
import { SectionHeader } from "../../components/section-header/SectionHeader";
import { Close } from "@mui/icons-material";
import MuiTextField from "@mui/material/TextField";
import { getAuth } from "firebase/auth";

const statuses = [
  { label: "All users", value: "" },
  { label: "Active", value: "active" },
  { label: "Suspended", value: "suspended" },
];

const TextField = ({ onChange, ...props }) => {
  const ref = useRef();
  const [changed, setChanged] = useState(false);
  const [value, setValue] = useState("");

  const resetValue = useCallback(() => {
    setValue("");
    setChanged(true);
  }, []);

  const onChangeCallback = useCallback((e) => {
    setValue(e.target.value);
    setChanged(true);
  }, []);

  useEffect(() => {
    if (onChange && changed) {
      onChange({ target: ref.current });
    }
  }, [onChange, value, changed]);

  return (
    <MuiTextField
      value={value}
      color="secondary"
      sx={{ width: 200 }}
      size="small"
      variant="outlined"
      {...props}
      onChange={onChangeCallback}
      InputProps={{
        endAdornment: (
          <InputAdornment position={"end"}>
            <IconButton sx={{ visibility: value ? "visible" : "hidden" }} onClick={resetValue} size={"small"}>
              <Close fontSize={"small"} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputProps={{ ref }}
    />
  );
};

/**
 * @component UsersFilters
 * @example
 * <UsersFilters />
 */
const UsersFilters = ({ onChange, loading }) => {
  const [filter, setFilter] = useState();

  const setFilterDebounced = useMemo(() => debounce(setFilter, 700), []);

  useEffect(() => {
    if (filter) {
      const updatedFilters = [];

      if (filter.cities?.length) {
        updatedFilters.push(`address.city_contains_any_${filter.cities}`);
      }
      if (filter.states?.length) {
        updatedFilters.push(`address.state_contains_any_${filter.states}`);
      }
      for (const key of ["status", "nickname", "firstname", "lastname", "email", "phone"]) {
        if (key in filter && filter[key]) {
          updatedFilters.push(`${key}==${filter[key]}`);
        }
      }
      onChange(updatedFilters);
    }
  }, [filter, onChange]);

  const onFilterInputChange = useCallback(
    ({ target }) => {
      const key = target.name;
      const value = target.value;
      if (target.type === "button") {
        setFilter((prev) => ({ ...prev, [key]: value }));
      } else {
        setFilterDebounced((prev) => ({ ...prev, [key]: value }));
      }
    },
    [setFilterDebounced],
  );

  const updateCities = useCallback((cities) => setFilter((prev) => ({ ...(prev ?? {}), cities })), []);
  const updateStates = useCallback((states) => setFilter((prev) => ({ ...(prev ?? {}), states })), []);

  return (
    <fieldset disabled={loading}>
      <Box
        justifyContent={"space-between"}
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        flexWrap={"wrap"}
        gap={2}
      >
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          gap={2}
        >
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Typography variant="body2" color="textSecondary">
              Status
            </Typography>
            <Box display={"flex"} flexDirection={"row"} gap={1}>
              {statuses.map(({ value, label }) => {
                const isSelected = (filter?.status ?? "") === value;
                const color = isSelected ? "primary" : "secondary";
                const variant = isSelected ? "contained" : "outlined";
                return (
                  <Button
                    disabled={loading}
                    name={"status"}
                    value={value}
                    key={value}
                    color={color}
                    variant={variant}
                    children={label}
                    size="small"
                    onClick={onFilterInputChange}
                  />
                );
              })}
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Typography variant="body2" color="textSecondary">
              Location
            </Typography>
            <LocationFilter size={"small"} onChangeStates={updateStates} onChangeCities={updateCities} />
          </Box>
        </Box>

        <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"} gap={2} justifyContent={"space-between"}>
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Typography variant="body2" color="textSecondary">
              Username
            </Typography>
            <TextField name="nickname" type={"text"} onChange={onFilterInputChange} />
          </Box>
          <Box display={"flex"} flexDirection="column" gap={1}>
            <Typography variant="body2" color="textSecondary">
              First Name
            </Typography>
            <TextField name={"firstname"} type={"text"} inputMode={"search"} onChange={onFilterInputChange} />
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Typography variant="body2" color="textSecondary">
              Last Name
            </Typography>
            <TextField name={"lastname"} type={"text"} onChange={onFilterInputChange} />
          </Box>
        </Box>

        <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"} gap={2} justifyContent={"space-between"}>
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Typography variant="body2" color="textSecondary">
              Phone
            </Typography>
            <TextField name={"phone"} type={"tel"} onChange={onFilterInputChange} />
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Typography variant="body2" color="textSecondary">
              Email
            </Typography>
            <TextField name={"email"} type={"email"} onChange={onFilterInputChange} />
          </Box>
        </Box>
      </Box>
    </fieldset>
  );
};

const UsersView = ({ setColumnSort, setFilter, changeUserStatus, deleteUser, loading, ...props }) => {
  const [userIdToDelete, setUserIdToDelete] = useState();
  const currentUserFbUid = getAuth().currentUser?.uid;

  const onChangeUserStatus = useCallback(
    (e) => {
      const userId = e.currentTarget.getAttribute("data-user-id");
      const status = e.currentTarget.getAttribute("data-user-status");
      changeUserStatus(userId, status === "active" ? "suspended" : "active");
      e.stopPropagation();
    },
    [changeUserStatus],
  );

  const onDeleteUserClicked = useCallback((e) => {
    const userId = e.currentTarget.getAttribute("data-user-id");
    setUserIdToDelete(userId);
    e.stopPropagation();
  }, []);

  const onDeleteUserConfirmed = useCallback(() => {
    setUserIdToDelete(null);
    deleteUser(userIdToDelete);
  }, [deleteUser, userIdToDelete]);

  const onDeleteUserCanceled = useCallback(() => setUserIdToDelete(null), []);

  const head = useMemo(
    () => (
      <tr className="users_table_heading">
        <th className="username">{STRINGS.screens.users.username}</th>
        <th>{STRINGS.screens.users.mobile}</th>
        <th>{STRINGS.screens.users.email}</th>
        <th>
          <LabelAndSort label={STRINGS.screens.users.city} onChange={(mode) => setColumnSort("address.city", mode)} />
        </th>
        <th>
          <LabelAndSort label={STRINGS.screens.users.state} onChange={(mode) => setColumnSort("address.state", mode)} />
        </th>
        <th>
          <LabelAndSort
            label={STRINGS.screens.users.creationDate}
            onChange={(mode) => setColumnSort("createdAt", mode)}
          />
        </th>
        <th>{STRINGS.screens.users.status}</th>
      </tr>
    ),
    [setColumnSort],
  );

  const filtersPanel = useMemo(() => <UsersFilters loading={loading} onChange={setFilter} />, [setFilter, loading]);

  return (
    <div className="users-page">
      <SectionHeader>
        <Typography variant={"h1"}>{STRINGS.components.sidebar.users}</Typography>
        <DownloadButton
          label={"Download"}
          onClick={(format) => usersDataExporter.export(props.users, `users-${new Date().toISOString()}`, format)}
          disabled={!props.users.length || loading}
        />
      </SectionHeader>

      <div className="users_table">
        <Paper className={"users-filter"}>
          <Box display={"flex"} flexDirection={"column"} gap={4}>
            {filtersPanel}
          </Box>
        </Paper>
        <div>
          <div className="tables">
            <Table className={"tables_body"} head={() => head} disabledShadow>
              {props.users?.map((item) => (
                <tr
                  key={item._id}
                  style={{ cursor: "pointer" }}
                  className="users_table_body"
                  onClick={(e) => props.onTableClick(e, item?._id)}
                >
                  <td className="username">{item.nickname}</td>
                  <td>{item.phone}</td>
                  <td>{item.email}</td>
                  <td>{item.address?.city}</td>
                  <td>{item.address?.state}</td>
                  <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                  <td>
                    <Button
                      disabled={item.fbUid === currentUserFbUid}
                      data-user-id={item._id}
                      data-user-status={item.status}
                      onClick={onChangeUserStatus}
                      size="small"
                      variant={"outlined"}
                      children={translateStatus(item?.status).status}
                      color={translateStatus(item?.status).button}
                    />
                  </td>
                  <td>
                    <Tooltip title={STRINGS.screens.users.delete}>
                      <IconButton
                        disabled={item.fbUid === currentUserFbUid}
                        data-user-id={item._id}
                        onClick={onDeleteUserClicked}
                        sx={{ "&:hover": { background: "none" } }}
                      >
                        <Icon name="trash-outline" width={24} height={24} />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </Table>
          </div>
        </div>
      </div>
      <Confirm
        onClose={onDeleteUserCanceled}
        title="Do you really want to delete the user?"
        visible={!!userIdToDelete}
        subtitle="The deletion will be irreversible."
        buttons={[
          {
            label: "Yes, I am sure",
            onClick: onDeleteUserConfirmed,
          },
          {
            label: "No, cancel",
            outline: true,
            onClick: onDeleteUserCanceled,
          },
        ]}
      />
    </div>
  );
};

export default UsersView;
