import React from "react";

import "./styles.scss";
import { Paper, Input, Button, TextArea } from "../../../ui-kit";
import { TextField } from "../../../ui-kit";

const TextFieldsView = (props) => {
  return (
    <div id="--text-fields">
      <Paper className="text-fields">
        <h4>Поля ввода</h4>
        <article>
          <span>Текстовы поля предназначены для записи строк в стейт.</span>
          <br />
          <code>{'<Input inputType="default|primary|secondary|danger" />'}</code>
        </article>
        <aside>
          <Input
            inputType="default"
            label="Default"
            value={props.inputTextFields.input_0}
            onChange={(value) => props.setInputTextFields({ ...props.inputTextFields, input_0: value })}
          />
          <Input
            inputType="primary"
            label="Primary"
            value={props.inputTextFields.input_1}
            onChange={(value) => props.setInputTextFields({ ...props.inputTextFields, input_1: value })}
          />
          <Input
            inputType="secondary"
            label="Secondary"
            isValid
            value={props.inputTextFields.input_2}
            onChange={(value) => props.setInputTextFields({ ...props.inputTextFields, input_2: value })}
          />
          <Input
            inputType="danger"
            label="Danger"
            value={props.inputTextFields.input_7}
            onChange={(value) => props.setInputTextFields({ ...props.inputTextFields, input_7: value })}
          />
        </aside>
      </Paper>
      <Paper className="text-fields-sizes">
        <h4>Размеры текстовых полей</h4>
        <article>
          <span>
            Текстовы поля могут иметь различные размеры. Для изменения размеров требуется передать свойство size.
          </span>
          <br />
          <code>{'<Input size="small|medium|large" />'}</code>
        </article>
        <aside>
          <Input
            label="Small"
            size="small"
            value={props.inputTextFields.input_4}
            onChange={(value) => props.setInputTextFields({ ...props.inputTextFields, input_4: value })}
          />
          <Input
            label="Medium"
            size="medium"
            value={props.inputTextFields.input_5}
            onChange={(value) => props.setInputTextFields({ ...props.inputTextFields, input_5: value })}
          />
          <Input
            label="Large"
            size="large"
            value={props.inputTextFields.input_6}
            onChange={(value) => props.setInputTextFields({ ...props.inputTextFields, input_6: value })}
          />
        </aside>
      </Paper>
      <Paper className="text-field-validation">
        <div>
          <h4 className="sb-input-title">Валидация поля</h4>
          <article>
            <span>Для полей доступна валидация.</span>
          </article>
        </div>
        <aside>
          <Input
            label="Имя пользователя"
            value={props.userName.value}
            isValid={props.validation.data.userName?.isValid}
            onChange={(value) => props.setUserName({ ...props.userName, value })}
            /**
             * For display danger input type
             */
            onBlur={() => props.setUserName({ ...props.userName, focus: true })}
            focus={props.userName.focus && !props.validation.data.userName?.isValid}
            inputType={!props.validation.data.userName?.isValid && props.userName.focus ? "danger" : "primary"}
          />
          <Input
            label="Почта"
            value={props.email.value}
            isValid={props.validation.data.email?.isValid}
            onChange={(value) => props.setEmail({ ...props.email, value })}
            /**
             * For display danger input type
             */
            onBlur={() => props.setEmail({ ...props.email, focus: true })}
            focus={props.email.focus && !props.validation.data.email?.isValid}
            inputType={props.email.focus && !props.validation.data.email?.isValid ? "danger" : "primary"}
          />
          <Button label="Продолжить" disabled={!props.validation.isValid} onClick={() => alert("Ok!")} />
        </aside>
      </Paper>
      <Paper className="text-area-box">
        <h4>Текстовое поле</h4>
        <article>
          <span>
            Компонент <code>{"<TextArea />"}</code> предназначен для ввода большого количества символов. Для изменения
            внешнего вида требуется передать свойство textareaType.
          </span>
          <br />
          <code>{'<TextArea textareaType="default|primary|secondary|danger" />'}</code>
        </article>
        <aside>
          <TextArea
            label="Текстовое поле"
            value={props.textAreaField.textarea_0}
            onChange={(value) => props.setTextAreaField({ ...props.textAreaField, textarea_0: value })}
          />
        </aside>
        <article>
          <span>Есть возможность использовать компонент с автоматическим подбором высоты</span>
          <br />
          <code>{"<TextArea autosize />"}</code>
        </article>
        <aside>
          <TextArea
            label="Текстовое поле"
            value={props.textAreaField.textarea_1}
            onChange={(value) => props.setTextAreaField({ ...props.textAreaField, textarea_1: value })}
            autosize
          />
        </aside>
      </Paper>
      <Paper>
        <aside>
          <TextField quantitySimbols={300} title={"Title"} />
        </aside>
      </Paper>
    </div>
  );
};

export default TextFieldsView;
