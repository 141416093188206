import { useEffect, useState } from "react";
import GeofenceAdminConfigService from "../../services/geofenceAdminConfig";
import { Toast } from "../../ui-kit";

import View from "./view";

const Container = () => {
  const [loading, setLoading] = useState(false);
  const [radius, setRadius] = useState({});
  const [area, setArea] = useState({});
  const [selectedRadius, setSelectedRadius] = useState({});
  const [showModal, setShowModal] = useState("");
  const [skeletVisible, setSkeletVisible] = useState(true);

  const getRadius = async () => {
    setSkeletVisible(true);
    try {
      const result = await GeofenceAdminConfigService.getGeofenceAdminConfig();

      if (result) {
        setArea(result.data.find((item) => item.type === "square"));
        setRadius(result.data.find((item) => item.type === "radius"));
      }
    } catch (error) {
      Toast.error("Fail to get radius of geofences");
    }
    setSkeletVisible(false);
  };

  const saveChanges = async (data) => {
    let newData = {};
    if (selectedRadius.type === "radius") {
      const newValues = [...radius.ranges[0]?.values];
      newValues[selectedRadius.index] = Number(data);

      newData = {
        type: "radius",
        ranges: [
          { max: 3000, unitOfMeasure: "meter", values: newValues },
          { max: 3000, unitOfMeasure: "yard", values: newValues },
        ],
      };
    } else if (selectedRadius.type === "max") {
      newData = {
        type: "radius",
        ranges: [
          {
            max: Number(data),
            unitOfMeasure: "meter",
            values: radius.ranges[0]?.values,
          },
          {
            max: Number(data),
            unitOfMeasure: "yard",
            values: radius.ranges[0]?.values,
          },
        ],
      };
    } else if (selectedRadius.type === "area") {
      newData = {
        type: "square",
        ranges: [
          {
            max: Number(data),
            unitOfMeasure: "meter",
            values: null,
          },
          {
            max: Number(data),
            unitOfMeasure: "yard",
            values: null,
          },
        ],
      };
    }

    try {
      await GeofenceAdminConfigService.updateGeofenceAdminConfig(selectedRadius._id, newData);
      setShowModal("");
      await getRadius();
    } catch (error) {
      Toast.error("Fail");
    }
    setSelectedRadius();
  };

  const updateRadius = () => {};

  useEffect(() => {
    getRadius();
  }, []);

  return (
    <View
      loading={loading}
      setLoading={setLoading}
      area={area}
      radius={radius}
      setRadius={setRadius}
      showModal={showModal}
      setShowModal={setShowModal}
      skeletVisible={skeletVisible}
      selectedRadius={selectedRadius}
      setSelectedRadius={setSelectedRadius}
      updateRadius={updateRadius}
      saveChanges={saveChanges}
    />
  );
};

export default Container;
