import { Paper } from "../../ui-kit";
import { Skeleton } from "../../ui-kit";

import "./styles.scss";

const SkeletManagers = () => (
  <Paper>
    <>
      <div className="row">
        <Skeleton width="fit-content">
          <span>********************</span>
        </Skeleton>
        <Skeleton width="fit-content">
          <span>********************</span>
        </Skeleton>
      </div>
      <div className="row">
        <Skeleton width="fit-content">
          <span>********************</span>
        </Skeleton>
        <Skeleton width="fit-content">
          <span>********************</span>
        </Skeleton>
      </div>
      <div className="row">
        <Skeleton width="fit-content">
          <span>********************</span>
        </Skeleton>
        <Skeleton width="fit-content">
          <span>********************</span>
        </Skeleton>
      </div>
      <div className="row">
        <Skeleton width="fit-content">
          <span>********************</span>
        </Skeleton>
      </div>
      <div className="row">
        <Skeleton width="fit-content">
          <span>********************</span>
        </Skeleton>
        <Skeleton width="fit-content">
          <span>********************</span>
        </Skeleton>
      </div>
      <div className="row">
        <Skeleton width="fit-content">
          <span>********************</span>
        </Skeleton>
        <Skeleton width="fit-content">
          <span>********************</span>
        </Skeleton>
      </div>
      <div className="row">
        <Skeleton width="fit-content">
          <span>********************</span>
        </Skeleton>
        <Skeleton width="fit-content">
          <span>********************</span>
        </Skeleton>
      </div>
    </>
  </Paper>
);

export default SkeletManagers;
