import React, { Fragment } from "react";
import { Icon, Skeleton, Table } from "../../ui-kit";

import "./styles.scss";

const Skelet = () => (
  <div className="promotions_table">
    <Table
      head={() => (
        <tr className="promotions_table_heading">
          <th>
            <Skeleton width="fit-content">
              <span>name</span>
            </Skeleton>
          </th>
          <th>
            <Skeleton width="fit-content">
              <span>Picture</span>
            </Skeleton>
          </th>
          <th>
            <Skeleton width="fit-content">
              <span>Start date</span>
            </Skeleton>
          </th>
          <th>
            <Skeleton width="fit-content">
              <span>End date</span>
            </Skeleton>
          </th>
          <th>
            <Skeleton width="fit-content">
              <span>Status</span>
            </Skeleton>
          </th>
          <th>
            <Skeleton width="fit-content">
              <span>Frequency</span>
            </Skeleton>
          </th>
          <th />
        </tr>
      )}
    >
      <>
        {new Array(30).fill(null).map((item, index) => (
          <Fragment key={index.toString()}>
            <tr className="promotions_table_body">
              <td>
                <Skeleton width="fit-content">
                  <span>*********</span>
                </Skeleton>
              </td>
              <td>
                <Skeleton width="fit-content">
                  <span>***********</span>
                </Skeleton>
              </td>
              <td>
                <Skeleton width="fit-content">
                  <span>****************</span>
                </Skeleton>
              </td>
              <td>
                <Skeleton width="fit-content">
                  <span>****************</span>
                </Skeleton>
              </td>
              <td>
                <Skeleton width="fit-content">
                  <span>***********</span>
                </Skeleton>
              </td>
              <td>
                <Skeleton width="fit-content">
                  <span>***********</span>
                </Skeleton>
              </td>
              <td className="promotions_table_edit">
                <div className="promotions_table_edit_wrapper">
                  <Skeleton width="fit-content" className="roles_table_iconWrapper">
                    <div className="roles_table_iconWrapper">
                      <Icon name="pen" width={24} height={24} />
                    </div>
                  </Skeleton>
                  <Skeleton width="fit-content" className="roles_table_iconWrapper">
                    <div className="roles_table_iconWrapper">
                      <Icon name="trash-outline" width={24} height={24} />
                    </div>
                  </Skeleton>
                </div>
              </td>
            </tr>
          </Fragment>
        ))}
      </>
    </Table>
  </div>
);

export default Skelet;
