import { Input } from "../../ui-kit";
import { Skeleton } from "../../ui-kit";

import "./styles.scss";

const SkeletManagers = () => (
  <div className="settings_input_wrapper">
    <Skeleton>
      <Input label="*********" value="****************" />
    </Skeleton>
    <Skeleton>
      <Input label="*********" value="****************" />
    </Skeleton>
  </div>
);

export default SkeletManagers;
