import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import s from "./float-menu.module.scss";

const FloatMenu = ({ renderTrigger, buttons }) => {
  const [visible, setVisible] = useState(false);

  const onMenuClose = (e) => {
    setVisible(false);
  };

  useEffect(() => {
    visible
      ? document.addEventListener("click", onMenuClose)
      : document.removeEventListener("click", onMenuClose);

    return () => {
      document.removeEventListener("click", onMenuClose);
    };
  }, [visible]);

  const onButtonClick = async (e, item) => {
    e.stopPropagation();
    onMenuClose();
    await item.onClick();
  };

  return (
    <div className={s.modal + " float-menu"}>
      <div data-visible={visible} className={s.modalContent}>
        {buttons.map((item, i) => (
          <div
            key={i}
            className={s.modalButton}
            onClick={(e) => onButtonClick(e, item)}
          >
            {item.text}
          </div>
        ))}
      </div>
      {renderTrigger(setVisible)}
    </div>
  );
};

/**
 * Properties
 */

FloatMenu.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  buttons: PropTypes.array,
  renderTrigger: PropTypes.func,
};
FloatMenu.defaultProps = {
  buttons: [],
  visible: false,
  setVisible: () => {},
  renderTrigger: () => <div></div>,
};

export default FloatMenu;
