import React from "react";

import "./styles.scss";

import { Paper } from "../../ui-kit";

import STRINGS from "../../localization";
import Skelet from "./skelet";
import { LocationFilter } from "../../components/location-filter/LocationFilter";
import { DateRangeFilter } from "../../components/date-range-filter/DateRangeFilter";
import { Box, Typography } from "@mui/material";
import { DownloadButton } from "../../components/download/DownloadButton";
import { SectionHeader } from "../../components/section-header/SectionHeader";
import { Link } from "react-router-dom";

const links = {
  numberOfReports: "/alerts/reported",
  numberOfAlertsCoyote: "/alerts/coyote",
  numberOfAlertsLostPets: "/alerts/lost_pets",
  numberOfAlertsFoundPets: "/alerts/found_pets",
  numberOfUsers: "/users",
};
const DashboardView = (props) => {
  return (
    <div className="dashboard-page">
      <SectionHeader>
        <Typography variant={"h1"}>{STRINGS.components.sidebar.dashboard}</Typography>
        <DownloadButton label={"Download"} onClick={props.onDownload} disabled={!props.data?.length} />
      </SectionHeader>
      <Paper>
        <Box display={"flex"} flexDirection={"column"} gap={4}>
          <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} flexWrap={"wrap"} gap={4}>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <Typography variant="body2" color="textSecondary">
                Date
              </Typography>
              <DateRangeFilter
                size={"small"}
                onChangeStartDate={props.setStartDate}
                onChangeEndDate={props.setEndDate}
              />
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <Typography variant="body2" color="textSecondary">
                Location
              </Typography>
              <LocationFilter size={"small"} onChangeCities={props.setCities} onChangeStates={props.setStates} />
            </Box>
          </Box>
        </Box>
      </Paper>
      <Box marginTop={"1rem"} className="container-for-content">
        {props.skeletVisible ? (
          <Skelet />
        ) : (
          props.data.map(([key, value], inx) => (
            <Paper className="container" key={inx}>
              <>
                <h4>{STRINGS.screens.dashboards[key]}</h4>
                <Link style={{ textDecoration: "none" }} to={links[key]}>
                  <h1>{value} </h1>
                </Link>
              </>
            </Paper>
          ))
        )}
      </Box>
    </div>
  );
};

export default DashboardView;
