import { DataExporter } from "../../utils/DataExporter";

export const alertsDataExporter = new DataExporter(
  {
    type: "type",
    geopoint: "geopoint",
    formattedAddress: "formattedAddress",
    createdAt: "createdAt",
    reportsCount: "reportsCount",
  },
  {
    type: "type",
    geopoint: (row) => `${row.geopoint.latitude}, ${row.geopoint.longitude}`,
    formattedAddress: "formattedAddress",
    createdAt: "createdAt",
    reportsCount: "reportsCount",
  },
);
