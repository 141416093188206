import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import AuthorizationView from "./authorization-view";
import LocalizationActions from "../../store/localization/actions";
import { validateObject } from "../../utils/input-functions";
import CurrentUserThunks from "../../store/current-user/thunks";
import AuthService from "../../services/auth";
import isEmail from "validator/es/lib/isEmail";
import { Navigate } from "react-router-dom";

const SCHEME = {
  email: (value) => value.length > 0 && isEmail(value),
  password: (value) => value?.length >= 4,
};

const AuthorizationContainer = () => {
  const [email, setEmail] = useState("");
  const [loginFocus, setLoginFocus] = useState(false);
  const [screen, setScreen] = useState("auth");
  const [error, setError] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordFocus, setPasswordFocus] = useState(false);

  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({
    isValid: false,
    data: {},
  });

  const dispatch = useDispatch();

  const selectedLanguage = useSelector((store) => store.localization.currentLanguage);

  const fieldsToValidate = {
    email,
    password,
  };

  const authorization = useCallback(() => {
    setLoading(true);
    dispatch(CurrentUserThunks.authentication({ email, password }, () => setLoading(false)));
  }, [dispatch, email, password]);

  const resetPasswordRequest = useCallback(async () => {
    setLoading(true);
    try {
      await AuthService.resetPasswordRequest(email);
      setScreen("confirm");
      setError("");
      setLoading(false);
    } catch (error) {
      if (error?.message?.includes("auth/user-not-found")) {
        setError("notFound");
      }
      if (error?.message?.includes("auth/invalid-email")) {
        setError("invalid");
      }
      setLoading(false);
    }
  }, [email]);

  const handleChangeLanguage = useCallback(
    (languageCode) => {
      dispatch(LocalizationActions.setLanguage(languageCode));
    },
    [dispatch],
  );

  useEffect(() => {
    setValidation(validateObject(fieldsToValidate, SCHEME));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password]);

  const user = useSelector((store) => store.currentUser);

  if (user.authorized) {
    return <Navigate to={"/dashboard"} />;
  }

  return (
    <AuthorizationView
      login={email}
      password={password}
      validation={validation}
      authorization={authorization}
      setPassword={setPassword}
      setLogin={setEmail}
      loading={loading}
      selectedLanguage={selectedLanguage}
      loginFocus={loginFocus}
      passwordFocus={passwordFocus}
      setLoginFocus={setLoginFocus}
      setPasswordFocus={setPasswordFocus}
      handleChangeLanguage={handleChangeLanguage}
      screen={screen}
      setScreen={setScreen}
      resetPasswordRequest={resetPasswordRequest}
      error={error}
      setError={setError}
    />
  );
};

export default AuthorizationContainer;
