import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

/**
 * @component Paper
 * @example
 * <Paper
 *  children={() => {}}
 *  className="some-class"
 * />
 */
const Paper = (props) => {
  return (
    <aside
      id={props.id}
      title={props.title}
      className={`paper ${props.className || ""} ${props.disabledShadow ? "shadow-disable" : ""}`}
    >
      {props.children}
    </aside>
  );
};

/**
 * Properties
 */

Paper.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabledShadow: PropTypes.bool,
};
Paper.defaultProps = {
  children: null,
  className: null,
  id: null,
  disabled: false,
  disabledShadow: false,
  title: null,
};

export default Paper;
