import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
import color from "../../styles/colors";

import { Icon } from "../../ui-kit";

import STRINGS from "../../localization";

/**
 * @component HeaderInput
 * @example
 * <HeaderInput
 *  value="Найти"
 *  onChange={() => {}}
 *  search={() => {}}
 * />
 */
const HeaderInput = (props) => {
  const renderClassName = () => {
    let str = "text-field-box";
    if (props.value.length > 0) {
      str += " filled";
    }
    return str;
  };
  return (
    <>
      <div id="--header-search">
        <div className={renderClassName()}>
          <Icon name="search" width={26} height={26} color={color.color_gray._4} />
          <input
            onKeyPress={(e) => {
              if (e.nativeEvent.keyCode === 13) {
                props.search(props.value);
              }
            }}
            value={props.value}
            placeholder={STRINGS.components.header.search}
            onChange={(e) => props.onChange(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

/**
 * Properties
 */

HeaderInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  search: PropTypes.func,
};
HeaderInput.defaultProps = {
  value: "",
  onChange: () => {},
  search: () => {},
};

export default HeaderInput;
