import React from "react";
import STRINGS from "../../../localization";

import { Paper, InputDate, TimePicker } from "../../../ui-kit";
import { getBeautyDateString } from "../../../utils/datetime";
import "./styles.scss";

const DatePickerView = (props) => {
  return (
    <div id="--date-pickers">
      <Paper className="date-pickers-types">
        <h4>Поле выбора даты</h4>
        <article>
          <span>
            Компонент <code>{"<InputDate />"}</code>
            предназначен для выбора даты.
          </span>
          <br />
          <code>{'<InputDate inputType="default|primary|secondary|disabled" />'}</code>
        </article>
        <aside>
          <InputDate
            inputType="default"
            label="Default"
            onChange={(date) => props.setDate({ ...props.date, value_0: date })}
            inputValue={props.date.value_0 ? getBeautyDateString(props.date.value_0) : STRINGS.components.date.date}
            value={props.date.value_0}
          />
          <InputDate
            inputType="primary"
            label="Primary"
            onChange={(date) => props.setDate({ ...props.date, value_1: date })}
            inputValue={props.date.value_1 ? getBeautyDateString(props.date.value_1) : STRINGS.components.date.date}
            value={props.date.value_1}
          />
          <InputDate
            inputType="secondary"
            label="Secondary"
            onChange={(date) => props.setDate({ ...props.date, value_2: date })}
            inputValue={props.date.value_2 ? getBeautyDateString(props.date.value_2) : STRINGS.components.date.date}
            value={props.date.value_2}
          />
        </aside>
      </Paper>
      <Paper className="time-pickers-types">
        <h4>Поле выбора даты</h4>
        <article>
          <span>
            Компонент <code>{"<TimePicker />"}</code>
            предназначен для выбора времени.
          </span>
          <br />
          <code>{'<TimePicker inputType="default|primary|secondary|disabled" />'}</code>
        </article>
        <aside>
          <TimePicker
            inputType="default"
            label="Default"
            onChange={(date) => props.setDate({ ...props.date, value_0: date })}
            inputValue={props.date.value_0 ? getBeautyDateString(props.date.value_0) : STRINGS.components.date.date}
            value={props.date.value_0}
          />
          <TimePicker
            inputType="primary"
            label="Primary"
            onChange={(date) => props.setDate({ ...props.date, value_1: date })}
            inputValue={props.date.value_1 ? getBeautyDateString(props.date.value_1) : STRINGS.components.date.date}
            value={props.date.value_1}
          />
          <TimePicker
            inputType="secondary"
            label="Secondary"
            onChange={(date) => props.setDate({ ...props.date, value_2: date })}
            inputValue={props.date.value_2 ? getBeautyDateString(props.date.value_2) : STRINGS.components.date.date}
            value={props.date.value_2}
          />
        </aside>
      </Paper>
      <Paper className="date-pickers-size">
        <h4>Размеры компонета поля выбора даты</h4>
        <article>
          <span>
            Поля выбора даты могут иметь различные размеры. Для изменения размеров требуется передать свойство size.
          </span>
          <br />
          <code>{'<InputDate size="small|medium|large" />'}</code>
        </article>
        <aside>
          <InputDate
            inputType="default"
            label="Small"
            size="small"
            onChange={(date) => props.setDate({ ...props.date, value_4: date })}
            inputValue={props.date.value_4 ? getBeautyDateString(props.date.value_4) : STRINGS.components.date.date}
            value={props.date.value_4}
          />
          <InputDate
            inputType="default"
            label="Medium"
            onChange={(date) => props.setDate({ ...props.date, value_5: date })}
            inputValue={props.date.value_5 ? getBeautyDateString(props.date.value_5) : STRINGS.components.date.date}
            value={props.date.value_5}
          />
          <InputDate
            inputType="default"
            label="Large"
            size="large"
            onChange={(date) => props.setDate({ ...props.date, value_6: date })}
            inputValue={props.date.value_6 ? getBeautyDateString(props.date.value_6) : STRINGS.components.date.date}
            value={props.date.value_6}
          />
        </aside>
      </Paper>
    </div>
  );
};

export default DatePickerView;
