import React, { useMemo, useState } from "react";

import PropTypes from "prop-types";
import { Box, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

import { Button, TextField } from "../";

import STRINGS from "../../localization";

/**
 * @component Confirm
 * @example
 * <Confirm
 *  visible={false}
 *  buttons={[
 *    {
 *      label: 'Cancel',
 *      onClick: () => {},
 *    }
 *  ]}
 * />
 */
const ConfirmWithInput = (props) => {
  const [notification, setNotification] = useState("");

  const reasons = useMemo(
    () => ({
      spam: "Reason: spam",
      inappropriate: "Reason: inappropriate photo",
    }),
    []
  );

  const onClose = () => {
    setNotification("");
    props.onClose();
  };

  const fillFieldSpam = () => {
    setNotification(reasons.spam);
  };

  const fillFieldBadPhoto = () => {
    setNotification(reasons.inappropriate);
  };

  return (
    <Dialog fullWidth={props.fullWidth} maxWidth="xs" open={props.visible} onClose={onClose}>
      <DialogTitle margin={1}>{props.title}</DialogTitle>
      <DialogContent>
        <Box display={"flex"} flexDirection={"row"} gap={1}>
          <Chip
            variant={notification === reasons.inappropriate ? "filled" : "outlined"}
            label={"Inappropriate photo"}
            onClick={fillFieldBadPhoto}
          />

          <Chip
            variant={notification === reasons.spam ? "filled" : "outlined"}
            onClick={fillFieldSpam}
            label={"Spam"}
          />
        </Box>
        <div className="input">
          <TextField
            quantitySimbols={props?.quantitySimbols}
            onChange={(title) => setNotification(title)}
            value={notification}
            uncontrolled
          />
        </div>
        <DialogContentText>{props.subtitle}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", margin: 1 }}>
        <Button
          label={STRINGS.components.modals.confirm}
          onClick={() => props.onOkClick({ title: notification })}
          loading={props.loading}
          disabled={!notification.replaceAll(" ", "").length}
        />
        <Button outline label={STRINGS.components.modals.cancel} onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};

/**
 * Properties
 */

ConfirmWithInput.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClose: PropTypes.func,
  onOkClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  quantitySimbols: PropTypes,
  loading: PropTypes.bool,
};
ConfirmWithInput.defaultProps = {
  buttons: [],
  visible: false,
  title: null,
  subtitle: null,
  onClose: () => {},
  onOkClick: () => {},
  fullWidth: false,
  loading: false,
};

export default ConfirmWithInput;
