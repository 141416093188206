import React, { useState } from "react";

import DropzoneView from "./dropzone-view";

const DropzoneContainer = () => {
  const [files, setFiles] = useState();
  return <DropzoneView files={files} setFiles={setFiles} />;
};

export default DropzoneContainer;
