import { api, debug } from "../index";
import { HTTP_STATUS, URL } from "../config";

export default class AdminSettingsService {
  static #API_ENDPOINTS = {
    setting: "adminSetting",
    settings: "adminSetting/list",
  };

  static async getSetting(uid) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.setting}?_id=${uid}`, {
        headers: {
          "content-type": "application/json",
        },
      });

      debug.success("Authentication request result", request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      const requestData = await request.json();

      if (requestData) {
        return requestData;
      }

      return Promise.reject("No Data");
    } catch (globalError) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.setting}?_id=${uid}`, globalError);
      throw await globalError.response?.json();
    }
  }

  static async createSetting(data) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.setting}`, {
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      });

      debug.success("Authentication request result", request);

      if (request.status !== HTTP_STATUS.CREATED) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      const requestData = await request.json();

      if (requestData) {
        return requestData;
      }

      return Promise.reject("No Data");
    } catch (globalError) {
      debug.error(`Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.setting}`, globalError);
      throw await globalError.response?.json();
    }
  }

  static async editAdminSetting(uid, data) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.setting}?_id=${uid}`, {
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      });

      debug.success("Authentication request result", request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      const requestData = await request.json();

      if (requestData) {
        return requestData;
      }

      return Promise.reject("No Data");
    } catch (globalError) {
      debug.error(`Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.setting}?_id=${uid}`, globalError);
      throw await globalError.response?.json();
    }
  }

  static async getSettings({ page = 1, size = 10, filter = "" }) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.settings}?pagination=page:${page};size:${size}${
          filter ? `&filter=${filter}` : ""
        }`,
        {
          headers: {
            "content-type": "application/json",
          },
        },
      );

      debug.success("Authentication request result", request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      const requestData = await request.json();

      if (requestData) {
        return requestData;
      }

      return Promise.reject("No Data");
    } catch (globalError) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.settings}?pagination=page:${page};size:${size}${
          filter ? `&filter=${filter}` : ""
        }`,
        globalError,
      );
      throw await globalError.response?.json();
    }
  }
}
