import STRINGS from "../../localization";
import { Button, Form, Input, Paper } from "../../ui-kit";
import "./styles.scss";
import { SectionHeader } from "../../components/section-header/SectionHeader";
import { Typography } from "@mui/material";
import React from "react";

const ChangePasswordView = (props) => {
  return (
    <div>
      <SectionHeader>
        <Typography variant={"h1"}>{STRINGS.screens.settings.changePassword}</Typography>
      </SectionHeader>
      <div>
        <Paper className="paper settings" key={alert.id}>
          <Form className="modal settings_modal" onSubmit={props.onPressSaveHandle}>
            <div className="settings_input_wrapper">
              <Input
                label={STRINGS.screens.settings.oldPassword}
                value={props.currentPassword.value}
                onChange={props.handleChangeCurrentPassword}
                inputType={
                  props.currentPassword.focus && !props.validation.data.currentPassword?.isValid ? "danger" : "default"
                }
                onBlur={() =>
                  props.setCurrentPassword({
                    ...props.currentPassword,
                    focus: true,
                  })
                }
                type={props.currentPassword.isPassword ? "password" : "default"}
                rightIcon={props.currentPassword.isPassword ? "eye" : "eyeOff"}
                rightIconHandleChange={() =>
                  props.setCurrentPassword({
                    ...props.currentPassword,
                    isPassword: !props.currentPassword.isPassword,
                  })
                }
              />
            </div>
            <div className="settings_input_wrapper">
              <Input
                label={STRINGS.screens.settings.newPassword}
                value={props.newPassword.value}
                onChange={props.handleChangeNewPassword}
                inputType={
                  props.newPassword.focus && !props.validation.data.newPassword?.isValid ? "danger" : "default"
                }
                onBlur={() => props.setNewPassword({ ...props.newPassword, focus: true })}
                type={props.newPassword.isPassword ? "password" : "default"}
                rightIcon={props.newPassword.isPassword ? "eye" : "eyeOff"}
                rightIconHandleChange={() =>
                  props.setNewPassword({
                    ...props.newPassword,
                    isPassword: !props.newPassword.isPassword,
                  })
                }
              />
            </div>
            <div className="settings_input_wrapper">
              <Input
                label={STRINGS.screens.settings.confirmPassword}
                value={props.confirmPassword.value}
                onChange={(value) => props.setConfirmPassword({ ...props.confirmPassword, value })}
                inputType={
                  props.confirmPassword.focus && props.confirmPassword.value !== props.newPassword.value
                    ? "danger"
                    : "default"
                }
                onBlur={() =>
                  props.setConfirmPassword({
                    ...props.confirmPassword,
                    focus: true,
                  })
                }
                type={props.confirmPassword.isPassword ? "password" : "default"}
                rightIcon={props.confirmPassword.isPassword ? "eye" : "eyeOff"}
                rightIconHandleChange={() =>
                  props.setConfirmPassword({
                    ...props.confirmPassword,
                    isPassword: !props.confirmPassword.isPassword,
                  })
                }
              />
            </div>
            {((props.currentPassword.focus && !props.validation.data.currentPassword?.isValid) ||
              (props.newPassword.focus && !props.validation.data.newPassword?.isValid)) && (
              <div className="setting_alert">
                Password must contain at least 8 characters, minimum 1 letter, minimum 1 number or symbol
              </div>
            )}
            {props.errorText && <div className="setting_alert">{props.errorText}</div>}
            <Button
              className="button"
              label={STRINGS.components.buttons.save}
              size="medium"
              typeButton="submit"
              loading={props.loading}
              disabled={!props.validation.isValid || props.confirmPassword.value !== props.newPassword.value}
            />
          </Form>
        </Paper>
      </div>
    </div>
  );
};

export default ChangePasswordView;
