import { DataExporter } from "../../utils/DataExporter";

export const dashboardDataExporter = new DataExporter(
  {
    metric: "0",
    value: "1",
  },
  {
    metric: "0",
    value: "1",
  },
);
