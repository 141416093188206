import React, { useCallback, useEffect, useState } from "react";

import NotificationsView from "./notifications-view";
import NotificationService from "../../services/notification";
import { Toast } from "../../ui-kit";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { NOTIFICATIONS_TYPES } from "../../constants/notificationTypes";

const Notifications = () => {
  const [notificationType, setNotificationType] = useState(NOTIFICATIONS_TYPES.PUSH);
  const [skeletVisible, setSkeletVisible] = useState(true);
  const [notifications, setNotifications] = useState({
    last: false,
    data: [],
  });
  const [page, setPage] = useState(1);
  const [notificationForEdit, setNotificationForEdit] = useState("");
  const [deleteModalVisible, setDeleteModalVisible] = useState(null);
  const [drawer, setDrawer] = useState(false);

  const getListOfNotifications = useCallback(async () => {
    setPage(1);
    setSkeletVisible(true);
    const size = notificationType === NOTIFICATIONS_TYPES.SMS ? 20 : 15;
    try {
      const result = await NotificationService.getNotificationList({
        page: 1,
        size,
        notificationType,
      });
      setNotifications(result);
      setSkeletVisible(false);
    } catch (err) {
      console.error(err);
      Toast.error("Failed to get page the list of notifications");
    }
  }, [notificationType]);

  const nextNotificationsList = async () => {
    setPage(page + 1);
    try {
      const result = await NotificationService.getNotificationList({
        page,
        size: 10,
        notificationType,
      });
      setNotifications({
        last: result.last,
        data: [...notifications.data, ...result.data],
      });
    } catch (err) {
      console.error(err);
      Toast.error("Failed to get next page the list of notifications");
    }
  };

  const changeNotificationType = (notificationType) => {
    setNotifications({ last: false, data: [] });
    setNotificationType(notificationType);
  };

  const editnotification = (id) => {
    setNotificationForEdit(notifications.data.filter((notification) => notification._id === id));
    setDrawer(!drawer);
  };

  const createNotificationStateDrawer = () => {
    setNotificationForEdit("");
    setDrawer(!drawer);
  };

  const createNotification = async (title = "", description, subject = "") => {
    let bodyForRequest = {};
    if (notificationType === NOTIFICATIONS_TYPES.PUSH) {
      bodyForRequest = {
        title: {
          en: title,
          es: title,
        },
        description: {
          en: description,
          es: description,
        },
        type: NOTIFICATIONS_TYPES.PUSH,
      };
    } else if (notificationType === NOTIFICATIONS_TYPES.SMS) {
      bodyForRequest = {
        description: {
          en: description,
          es: description,
        },
        type: NOTIFICATIONS_TYPES.SMS,
      };
    } else if (notificationType === NOTIFICATIONS_TYPES.EMAIL) {
      bodyForRequest = {
        title: {
          en: title,
          es: title,
        },
        description: {
          en: description,
          es: description,
        },
        subject: {
          en: subject,
          es: subject,
        },
        type: NOTIFICATIONS_TYPES.EMAIL,
      };
    }
    try {
      await NotificationService.sendNotificationForUsers(bodyForRequest);
      getListOfNotifications();
      Toast.success("Notification sent successfully");
    } catch (err) {
      console.error(err);
      Toast.error("Failed to post notification");
    }
  };

  const opendeleteNotificationModal = (id) => {
    setDeleteModalVisible(id);
  };

  const deleteNotification = async (id) => {
    try {
      await NotificationService.delete(id);
      setDeleteModalVisible(null);
      getListOfNotifications();
      Toast.success("Notification delete successfully");
    } catch (err) {
      console.error(err);
      Toast.error("Failed to delete notification");
    }
  };

  const closedeleteNotificationModal = () => {
    setDeleteModalVisible(null);
  };

  useBottomScrollListener(() => {
    if (!notifications.last) {
      nextNotificationsList();
    }
  });

  useEffect(() => {
    getListOfNotifications();
  }, [getListOfNotifications, notificationType]);

  return (
    <NotificationsView
      notificationType={notificationType}
      deleteModalVisible={deleteModalVisible}
      drawer={drawer}
      notificationForEdit={notificationForEdit[0]}
      notifications={notifications.data}
      skeletVisible={skeletVisible}
      createNotification={createNotification}
      deleteNotification={deleteNotification}
      opendeleteNotificationModal={opendeleteNotificationModal}
      setDeleteModalVisible={setDeleteModalVisible}
      changeNotificationType={changeNotificationType}
      closedeleteNotificationModal={closedeleteNotificationModal}
      createNotificationStateDrawer={createNotificationStateDrawer}
      editnotification={editnotification}
    />
  );
};

export default Notifications;
