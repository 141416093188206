import { toast } from "react-toastify";

/**
 * @component Toast
 * @example
 * Toast.success('Image success loaded');
 */
class Toast {
  static info(value) {
    return toast.info(value);
  }

  static success(value) {
    return toast.success(value);
  }

  static error(value) {
    toast.error(value);
  }
}

export default Toast;
