import React from "react";

import { Icon } from "../../ui-kit";
import { Table, Skeleton } from "../../ui-kit";

import "./styles.scss";

const SkeletManagers = () => (
  <div className="summary_table">
    <Table
      head={() => (
        <>
          <tr className="radius_table_heading">
            <th>
              <Skeleton width="fit-content">
                <h4>******************</h4>
              </Skeleton>
            </th>
            <th />
          </tr>
        </>
      )}
    >
      <>
        <tr className="radius_table_body">
          <td>
            <Skeleton width="fit-content">
              <span>******************</span>
            </Skeleton>
          </td>
          <td className="radius_table_edit">
            <div className="radius_table_edit_wrapper">
              <div className="radius_table_iconWrapper">
                <Skeleton width="fit-content">
                  <Icon name="pen" width={24} height={24} />
                </Skeleton>
              </div>
            </div>
          </td>
        </tr>
      </>
    </Table>
  </div>
);

export default SkeletManagers;
