import React from "react";

import "./styles.scss";

import { Paper, Button, SquareButton } from "../../../ui-kit";

const ButtonsView = () => {
  return (
    <div id="--buttons">
      <Paper className="buttons-types">
        <h4>Кнопки с заливкой</h4>
        <article>
          <span>
            Кнопки с заливкой имеют залитое пространство внутри и содержат текст. Для использования достаточно
            использовать свойство type.
          </span>
          <br />
          <code>{'<Button type="default|primary|secondary|danger" />'}</code>
        </article>
        <aside className="row">
          <Button type="default" label="Default" />
          <Button type="primary" label="Primary" />
          <Button type="secondary" label="Secondary" />
          <Button type="danger" label="Danger" />
        </aside>
        <article>
          <code>{'<SquareButton type="default|primary|secondary|danger" />'}</code>
        </article>
        <aside className="row">
          <SquareButton type="default" icon="plus" />
          <SquareButton type="primary" icon="plus" />
          <SquareButton type="secondary" icon="plus" />
          <SquareButton type="danger" icon="plus" />
        </aside>
      </Paper>
      <Paper className="buttons-outline">
        <h4>Outlined кнопки</h4>
        <article>
          <span>Outlined кнопки не имеют заливки и обладают выделенными границами.</span>
          <br />
          <code>{"<Button outline />"}</code>
        </article>
        <aside className="row">
          <Button type="default" label="Default" outline />
          <Button type="primary" label="Primary" outline />
          <Button type="secondary" label="Secondary" outline />
          <Button type="danger" label="Danger" outline />
        </aside>
        <article>
          <code>{"<SquareButton outline />"}</code>
        </article>
        <aside className="row">
          <SquareButton type="default" icon="plus" outline />
          <SquareButton type="primary" icon="plus" outline />
          <SquareButton type="secondary" icon="plus" outline />
          <SquareButton type="danger" icon="plus" outline />
        </aside>
      </Paper>
      <Paper className="buttons-sizes">
        <h4>Размеры кнопок</h4>
        <article>
          <span>Для изменеия размеров кнопки достаточно передать свойсто size.</span>
          <br />
          <code>{'<Button size="small|medium|large" />'}</code>
        </article>
        <aside className="sizes align-center">
          <Button size="small" label="Small" />
          <Button label="Medium" />
          <Button size="large" label="Large" />
        </aside>
        <aside className="sizes align-center last">
          <Button outline size="small" label="Small" />
          <Button outline label="Medium" />
          <Button outline size="large" label="Large" />
        </aside>
        <article>
          <code>{'<SquareButton size="small|medium|large" />'}</code>
        </article>
        <aside className="sizes align-center">
          <SquareButton icon="plus" size="small" />
          <SquareButton icon="plus" />
          <SquareButton icon="plus" size="large" />
        </aside>
        <aside className="sizes align-center last">
          <SquareButton icon="plus" outline size="small" />
          <SquareButton icon="plus" outline />
          <SquareButton icon="plus" outline size="large" />
        </aside>
      </Paper>
      <Paper className="buttons-types">
        <h4>Заблокированные кнопки и кнопки с загрузкой</h4>
        <article>
          <span>В кнопки возможна передача свойства disabled, для блокирования нажатия на кнопку.</span>
          <br />
          <code>{"<Button disabled />"}</code>
        </article>
        <aside className="row">
          <Button type="default" label="Default" disabled />
          <Button type="default" outline label="Default" disabled />
        </aside>
        <article>
          <code>{"<SquareButton disabled />"}</code>
        </article>
        <aside className="row">
          <SquareButton type="default" icon="plus" disabled />
          <SquareButton type="default" outline icon="plus" disabled />
        </aside>
        <article>
          <span>Кнопки могут иметь загрузочный вид, для этого достаточно передать свойство loading.</span>
          <br />
          <code>{"<Button loading />"}</code>
        </article>
        <aside className="row">
          <Button label="Primary" loading />
          <Button label="Primary" outline loading />
        </aside>
      </Paper>
      <Paper className="buttons-icons">
        <h4>Кнопки содержащие иконки</h4>
        <article>
          <span>
            В компонент Button есть возможность внедрить иконку. Для того чтобы отобразить иконку достаточно передать
            объект icon с свойством name. Так же в этом объекте доступны свойства width, height, side(left или right).
          </span>
          <br />
          <code>{'<Button icon={{ name: "cloud-upload", side: "left" }} />'}</code>
        </article>
        <aside className="row">
          <Button type="default" icon={{ name: "cloud-upload" }} label="Default" />
          <Button icon={{ name: "cloud-upload" }} type="primary" label="Primary" />
          <Button icon={{ name: "cloud-upload" }} type="secondary" label="Secondary" />
          <Button icon={{ name: "cloud-upload" }} type="danger" label="Danger" />
          <Button icon={{ name: "cloud-upload" }} type="default" label="Default" disabled />
        </aside>
        <aside className="row">
          <Button type="default" icon={{ name: "cloud-upload", side: "right" }} label="Default" outline />
          <Button icon={{ name: "cloud-upload", side: "right" }} type="primary" label="Primary" outline />
          <Button icon={{ name: "cloud-upload", side: "right" }} type="secondary" label="Secondary" outline />
          <Button icon={{ name: "cloud-upload", side: "right" }} type="danger" label="Danger" outline />
          <Button icon={{ name: "cloud-upload", side: "right" }} type="default" label="Default" outline disabled />
        </aside>
      </Paper>
    </div>
  );
};

export default ButtonsView;
