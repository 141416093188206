import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import Loader from "../../../ui-kit/loader.js";
import { Button, Form, Input, Drawer, TextArea } from "../../../ui-kit";
import STRINGS from "../../../localization";

import "./styles.scss";
import { validateObject } from "../../../utils/input-functions.js";

const SCHEME = {
  question: (data) => !!data,
  answer: (data) => !!data,
};

const QuestionModal = (props) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [validation, setValidation] = useState({ isValid: false, data: {} });

  const clearForm = () => {
    setQuestion("");
    setAnswer("");
  };

  useEffect(() => {
    setValidation(
      validateObject(
        {
          question,
          answer,
        },
        SCHEME,
      ),
    );
  }, [question, answer]);

  useEffect(() => {
    if (props.open && props.selectedQuestion) {
      setQuestion(props.selectedQuestion?.question.en ? props.selectedQuestion?.question.en : "");
      setAnswer(props.selectedQuestion?.answer.en ? props.selectedQuestion.answer.en : "");
    } else {
      clearForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  return (
    <Drawer
      title={props.title}
      visible={props.open}
      onClose={() => {
        props.onClose();
      }}
    >
      <Form className="modal" onSubmit={() => props.saveChanges({ question, answer })}>
        <div className="header">
          <span>{props.title}</span>
        </div>
        {props.loading ? (
          <Loader color="black" width={50} height={50} class="center" />
        ) : (
          <div className="body">
            <Input label={STRINGS.screens.faq.question} value={question} onChange={setQuestion} type="default" />
            <TextArea
              label={STRINGS.screens.faq.answer}
              value={answer}
              onChange={setAnswer}
              textareaType="default"
              autosize={false}
            />
          </div>
        )}
        <div className="footer">
          <Button
            className="button"
            label={STRINGS.components.buttons.cancel}
            size="medium"
            type="default"
            onClick={props.onClose}
            loading={props.loading}
            typeButton="button"
          />
          <Button
            className="button"
            label={STRINGS.components.buttons.save}
            size="medium"
            typeButton="submit"
            loading={props.loading}
            disabled={!validation.isValid}
          />
        </div>
      </Form>
    </Drawer>
  );
};

QuestionModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  saveChanges: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
};
QuestionModal.defaultProps = {
  isOpen: false,
  close: () => {},
  saveChanges: () => {},
  title: "",
  onChange: () => {},
};

export default QuestionModal;
