import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import localization from "./localization";
//import user from './user';
import currentUser from "./current-user";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const appReducer = combineReducers({
  localization,
  currentUser,
});

const initialState = appReducer({}, {});

const RESET_REDUX = "RESET_REDUX";

const rootReducer = (state, action) => {
  if (action.type === RESET_REDUX) {
    state = {
      ...initialState,
      localization: state.localization,
      authorized: false,
    };
  }

  return appReducer(state, action);
};

export const resetRedux = () => ({
  type: RESET_REDUX,
});

const persistConfig = {
  key: "root",
  storage,
};

const pReducer = persistReducer(persistConfig, rootReducer);
const configureStore = () => {
  return createStore(pReducer, composeWithDevTools(applyMiddleware(thunk)));
};

export const store = configureStore();
export const persistor = persistStore(store);
