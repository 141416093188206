import React from "react";

import "./styles.scss";

import icons from "../../../ui-kit/icons/icons";
import { Paper, Toast } from "../../../ui-kit";

import convertPxToRem from "../../../styles/rem";
import colors from "../../../styles/colors";

const IconsView = () => {
  const arrayOfIcons = Object.values(icons)
    .map((e, i) => ({ ...e, name: Object.keys(icons)[i] }))
    .filter((el) => !el.hide);

  return (
    <div id="--icons-root">
      {arrayOfIcons.map((element) => (
        <Paper title={element.name} className="icon-container">
          {element.code({
            width: convertPxToRem(28),
            height: convertPxToRem(28),
            color: colors.color_black._0,
          })}
          <input
            value={element.name}
            readOnly
            onClick={(e) => {
              navigator.clipboard.writeText(e.target.value);
              Toast.info("Название иконки скопировано в буфер обмена!");
            }}
          />
        </Paper>
      ))}
    </div>
  );
};

export default IconsView;
