import { Icon, Paper } from "../../ui-kit";
import { Skeleton } from "../../ui-kit";

import "./styles.scss";

const SkeletManagers = () => (
  <div id="faq-container">
    {new Array(5).fill(null).map((question, index) => (
      <Paper key={`question${index}`} className="paper">
        <Skeleton className="drag">
          <button className="drag">
            <Icon name="drag-handle" width={28} height={28} />
          </button>
        </Skeleton>
        <Skeleton className="questions-button edit">
          <button className="edit">
            <Icon
              name="pen"
              width={24}
              height={24}
              className={{
                display: "block",
                "margin-left": "auto",
                "margin-right": "auto",
              }}
            />
          </button>
        </Skeleton>
        <Skeleton className="questions-button delete">
          <button className="delete">
            <Icon
              name="cross"
              width={24}
              height={24}
              className={{
                display: "block",
                "margin-left": "auto",
                "margin-right": "auto",
              }}
            />
          </button>
        </Skeleton>
        <div className="content-all">
          <Skeleton width="fit-content">
            <h4 className="title">**************************************** ****************************************</h4>
          </Skeleton>
          <Skeleton width="fit-content" className="skeleton-text">
            <p className="test">
              **************************************** ****************************************
              **************************************** ****************************************
              **************************************** ****************************************
              **************************************** ****************************************
              **************************************** ****************************************
            </p>
          </Skeleton>
        </div>
      </Paper>
    ))}
  </div>
);

export default SkeletManagers;
