import React, { useEffect, useState } from "react";
import AdminSettingsService from "../../services/adminSettings";
import { Toast } from "../../ui-kit";

import FaqView from "./questions-view";

const Faq = () => {
  const [questions, setQuestions] = useState([]);
  const [questionForEdit, setQuestionForEdit] = useState("");
  const [showModal, setShowModal] = useState("");
  const [loading, setLoading] = useState(false);
  const [uid, setUid] = useState();

  const addQuestion = async (data) => {
    setLoading(true);
    const question = {
      question: {
        en: data.question,
        es: data.question,
      },
      answer: {
        en: data.answer,
        es: data.answer,
      },
    };
    try {
      if (uid) {
        await AdminSettingsService.editAdminSetting(uid, { faq: [...questions, question] });
      } else {
        await AdminSettingsService.createSetting({ faq: [question] });
      }
      await getQuestions();
      Toast.success("Success");
    } catch (error) {
      Toast.error("Fail");
    }
    setLoading(false);
    setShowModal("");
  };

  const editQuestion = async (data) => {
    try {
      const copy = [...questions];

      copy[questionForEdit.index] = {
        question: {
          en: data.question,
          es: data.question,
        },
        answer: {
          en: data.answer,
          es: data.answer,
        },
      };

      if (uid) {
        await AdminSettingsService.editAdminSetting(uid, { faq: copy });
      }

      setQuestions(copy);
      Toast.success("Success");
    } catch (error) {
      Toast.error("Fail");
    }
    setShowModal("");
    setQuestionForEdit("");
  };

  const deleteQuestion = async () => {
    setShowModal("");
    try {
      const copy = questions.filter((question, index) => index !== questionForEdit);
      if (uid) {
        await AdminSettingsService.editAdminSetting(uid, { faq: copy });
      }
      setQuestions(copy);
      Toast.success("Success");
    } catch (error) {
      Toast.error("Fail");
    }
    setQuestionForEdit("");
  };

  const getQuestions = async () => {
    setLoading(true);
    try {
      const result = await AdminSettingsService.getSettings({ page: 1, size: 10 });
      const data = result.data[0];
      if (data?._id) {
        setQuestions(data?.faq ? data?.faq : []);
        setUid(data?._id);
      }
    } catch (error) {
      Toast.error("Fail");
    }
    setLoading(false);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const changeOrder = async (d) => {
    setLoading(true);
    try {
      if (uid) {
        await AdminSettingsService.editAdminSetting(uid, { faq: d });
      }
      setQuestions(d);
      Toast.success("Success");
    } catch (error) {
      Toast.error("Fail");
    }
    setLoading(false);
  };

  const getItemStyle = (draggableStyle) => {
    const { transform } = draggableStyle;
    let activeTransform = {};
    if (transform) {
      activeTransform = {
        transform: `translate(0, ${transform.substring(transform.indexOf(",") + 1, transform.indexOf(")"))})`,
      };
    }
    return {
      userSelect: "none",
      ...draggableStyle,
      ...activeTransform,
    };
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(questions, source.index, destination.index);
      changeOrder(items);
    }
  };

  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <FaqView
      questions={questions}
      showModal={showModal}
      setShowModal={setShowModal}
      editQuestion={editQuestion}
      questionForEdit={questionForEdit}
      setQuestionForEdit={setQuestionForEdit}
      deleteQuestion={deleteQuestion}
      loading={loading}
      addQuestion={addQuestion}
      getItemStyle={getItemStyle}
      onDragEnd={onDragEnd}
    />
  );
};

export default Faq;
