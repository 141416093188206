import React, { Fragment } from "react";

import "./styles.scss";

import { Paper, Button, Icon, Drawer, Confirm } from "../../ui-kit";

import STRINGS from "../../localization";
import colors from "../../styles/colors";
import { AddNotification } from "../../components";
import Skelet from "./skelet";
import { NOTIFICATIONS_TYPES } from "../../constants/notificationTypes";
import { SectionHeader } from "../../components/section-header/SectionHeader";
import { Typography } from "@mui/material";

const NotificationsView = (props) => {
  // 'sms' or 'push' or 'email'
  const renderContent = () =>
    props.notifications?.map((notification) => (
      <Fragment key={notification._id}>
        <Paper className="paper">
          <>
            <button className="edit" onClick={() => props.editnotification(notification._id)}>
              <Icon name="pen" width={24} height={24} color={colors.color_basic._0} />
            </button>
            <button className="delete" onClick={() => props.opendeleteNotificationModal(notification._id)}>
              <Icon name="cross" width={24} height={24} color={colors.color_basic._0} />
            </button>
            <div>
              {props.notificationType !== NOTIFICATIONS_TYPES.SMS ? (
                <>
                  <h4>{STRINGS.screens.notifications.title}</h4>
                  <span>{notification.title?.en}</span>
                </>
              ) : null}
              {props.notificationType === NOTIFICATIONS_TYPES.EMAIL ? (
                <>
                  <h4>{STRINGS.screens.notifications.subject}</h4>
                  <span className="style-for-span">{notification.subject?.en}</span>
                </>
              ) : null}
              <h4>{STRINGS.screens.notifications.description}</h4>
              <span>{notification.description?.en}</span>
            </div>
          </>
        </Paper>
        <Confirm
          onClose={props.opendeleteNotificationModal}
          title={STRINGS.screens.notifications.questionAboutDeletingNotification}
          visible={props.deleteModalVisible === notification._id}
          subtitle={STRINGS.screens.notifications.irreversible}
          buttons={[
            {
              label: STRINGS.components.modals.confirm,
              onClick: () => props.deleteNotification(notification._id),
            },
            {
              label: STRINGS.components.modals.cancel,
              outline: true,
              onClick: () => props.closedeleteNotificationModal(),
            },
          ]}
        />
      </Fragment>
    ));

  const renderTypeTitle = (type, typeName = "push") => (
    <h1
      onClick={() => props.changeNotificationType(type)}
      className={props.notificationType === type ? "active-bottom" : "not-active-bottom"}
    >
      {STRINGS.screens.notifications.typeOfNotifications[typeName]}
    </h1>
  );

  return (
    <>
      <SectionHeader>
        <Typography variant="h1">{STRINGS.components.sidebar.notifications}</Typography>
        <Button
          icon={{ name: "plus", side: "left" }}
          label={STRINGS.components.buttons.create}
          onClick={() => props.createNotificationStateDrawer()}
          type="primary"
          size="small"
        />
      </SectionHeader>
      <div className="container-for-header">
        {renderTypeTitle(NOTIFICATIONS_TYPES.PUSH)}
        {renderTypeTitle(NOTIFICATIONS_TYPES.SMS, "sms")}
        {renderTypeTitle(NOTIFICATIONS_TYPES.EMAIL, "email")}
      </div>
      {props.skeletVisible ? (
        <Skelet type={props.notificationType} />
      ) : (
        <div className="container-for-notifications">{renderContent()}</div>
      )}
      <Drawer anchor="right" visible={props.drawer} onClose={props.createNotificationStateDrawer}>
        <div className="drawer notification-drawer">
          <h1>
            {props.notificationType === NOTIFICATIONS_TYPES.PUSH
              ? STRINGS.screens.notifications.typeOfNotifications.push
              : null}
            {props.notificationType === NOTIFICATIONS_TYPES.SMS
              ? STRINGS.screens.notifications.typeOfNotifications.sms
              : null}
            {props.notificationType === NOTIFICATIONS_TYPES.EMAIL
              ? STRINGS.screens.notifications.typeOfNotifications.email
              : null}
          </h1>
          <AddNotification
            notificationType={props.notificationType}
            createNotification
            cancelNotificationCreation={props.createNotificationStateDrawer}
            title={props.notificationForEdit?.title?.en}
            description={props.notificationForEdit?.description?.en}
            subject={props.notificationForEdit?.subject?.en}
            onSave={(title = "", description, subject = "") => props.createNotification(title, description, subject)}
          />
        </div>
      </Drawer>
    </>
  );
};

export default NotificationsView;
