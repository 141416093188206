import React, { useState } from "react";

import SkeletView from "./skelet-view";

const SkeletContainer = () => {
  const [enable, setEnable] = useState(true);
  return <SkeletView setEnable={setEnable} enable={enable} />;
};

export default SkeletContainer;
