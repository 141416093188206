import React from "react";

import colors from "../../styles/colors";

const icons = {
  logo: {
    hide: true,
    code: ({ width, height, color }) => (
      <svg width={width} height={height} viewBox="0 0 277 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.26684 23.7235H13.8045V57.8656H3.26684V23.7235ZM2.42383 12.9751C2.42383 11.2891 3.00925 9.86066 4.18011 8.68981C5.39779 7.47212 6.84965 6.86328 8.53567 6.86328C10.2217 6.86328 11.6501 7.47212 12.821 8.68981C14.0387 9.86066 14.6475 11.2891 14.6475 12.9751C14.6475 14.6611 14.0387 16.113 12.821 17.3307C11.6501 18.5015 10.2217 19.087 8.53567 19.087C6.84965 19.087 5.39779 18.5015 4.18011 17.3307C3.00925 16.113 2.42383 14.6611 2.42383 12.9751Z"
          fill="#110C1A"
        />
        <path
          d="M21.3752 23.7235H31.4913V28.3601H31.6318C31.9597 27.7044 32.428 27.0488 33.0368 26.3931C33.6457 25.7374 34.3716 25.152 35.2146 24.6368C36.0576 24.1216 37.0177 23.7001 38.0949 23.3723C39.1721 23.0444 40.343 22.8805 41.6075 22.8805C44.277 22.8805 46.4314 23.302 48.0706 24.145C49.7098 24.9412 50.9743 26.0652 51.8641 27.5171C52.8008 28.969 53.4331 30.6784 53.7609 32.6454C54.0888 34.6125 54.2527 36.7434 54.2527 39.0383V57.8656H43.715V41.1458C43.715 40.1623 43.6682 39.1554 43.5745 38.125C43.5277 37.0478 43.3169 36.0643 42.9422 35.1745C42.6144 34.2846 42.0758 33.5587 41.3265 32.9967C40.624 32.4347 39.5936 32.1537 38.2354 32.1537C36.8772 32.1537 35.7766 32.4113 34.9336 32.9264C34.0906 33.3948 33.4349 34.0504 32.9666 34.8935C32.5451 35.6896 32.2641 36.6029 32.1236 37.6333C31.9831 38.6636 31.9128 39.7408 31.9128 40.8648V57.8656H21.3752V23.7235Z"
          fill="#110C1A"
        />
        <path
          d="M79.8479 33.7694C78.3024 31.8493 76.2651 30.8892 73.7361 30.8892C72.8462 30.8892 71.9798 31.0999 71.1368 31.5214C70.2938 31.9429 69.8723 32.6688 69.8723 33.6992C69.8723 34.5422 70.2938 35.1745 71.1368 35.596C72.0266 35.9706 73.1272 36.3219 74.4386 36.6497C75.7968 36.9307 77.2252 37.2586 78.7239 37.6333C80.2694 37.9611 81.6979 38.4997 83.0092 39.249C84.3674 39.9984 85.468 41.0287 86.311 42.3401C87.2009 43.6046 87.6458 45.314 87.6458 47.4684C87.6458 49.6696 87.154 51.4961 86.1705 52.948C85.2338 54.353 84.0161 55.5005 82.5174 56.3903C81.0188 57.2333 79.3327 57.8187 77.4594 58.1466C75.586 58.5212 73.7361 58.7086 71.9095 58.7086C69.521 58.7086 67.109 58.3807 64.6737 57.7251C62.2383 57.0226 60.1776 55.8049 58.4916 54.072L64.8844 46.9767C65.8679 48.1943 66.9451 49.131 68.116 49.7867C69.3337 50.3955 70.7387 50.7 72.331 50.7C73.5487 50.7 74.6493 50.536 75.6328 50.2082C76.6164 49.8335 77.1081 49.1779 77.1081 48.2412C77.1081 47.3513 76.6632 46.6956 75.7733 46.2741C74.9303 45.8058 73.8297 45.4311 72.4715 45.1501C71.1602 44.8223 69.7318 44.4944 68.1862 44.1666C66.6875 43.7919 65.2591 43.2533 63.9009 42.5508C62.5896 41.8483 61.489 40.8648 60.5991 39.6003C59.7561 38.2889 59.3346 36.5561 59.3346 34.4017C59.3346 32.3878 59.7327 30.655 60.5289 29.2031C61.3719 27.7513 62.4491 26.557 63.7604 25.6203C65.1186 24.6836 66.6641 24.0045 68.397 23.583C70.1298 23.1147 71.8861 22.8805 73.6658 22.8805C75.9139 22.8805 78.1853 23.2084 80.4802 23.864C82.775 24.5197 84.7186 25.6906 86.311 27.3766L79.8479 33.7694Z"
          fill="#110C1A"
        />
        <path
          d="M116.301 32.1537H107.027V43.5343C107.027 44.471 107.074 45.3375 107.168 46.1336C107.262 46.883 107.472 47.5387 107.8 48.1007C108.128 48.6627 108.62 49.1076 109.275 49.4354C109.978 49.7164 110.891 49.8569 112.015 49.8569C112.577 49.8569 113.303 49.8101 114.193 49.7164C115.13 49.5759 115.832 49.2949 116.301 48.8734V57.6548C115.13 58.0763 113.912 58.3573 112.647 58.4978C111.383 58.6383 110.142 58.7086 108.924 58.7086C107.144 58.7086 105.505 58.5212 104.007 58.1466C102.508 57.7719 101.197 57.1865 100.073 56.3903C98.9485 55.5473 98.0587 54.4701 97.403 53.1587C96.7942 51.8474 96.4897 50.255 96.4897 48.3817V32.1537H89.7456V23.7235H96.4897V13.6074H107.027V23.7235H116.301V32.1537Z"
          fill="#110C1A"
        />
        <path d="M163.334 6.86355H173.872V57.8656H163.334V6.86355Z" fill="#110C1A" />
        <path
          d="M212.969 51.9645C211.283 54.1188 209.152 55.7815 206.576 56.9523C204.001 58.1232 201.331 58.7086 198.568 58.7086C195.945 58.7086 193.463 58.2871 191.122 57.4441C188.827 56.601 186.813 55.4068 185.08 53.8613C183.394 52.2689 182.059 50.3721 181.076 48.1709C180.092 45.9697 179.6 43.5109 179.6 40.7946C179.6 38.0782 180.092 35.6194 181.076 33.4182C182.059 31.217 183.394 29.3436 185.08 27.7981C186.813 26.2057 188.827 24.9881 191.122 24.145C193.463 23.302 195.945 22.8805 198.568 22.8805C201.003 22.8805 203.204 23.302 205.172 24.145C207.185 24.9881 208.871 26.2057 210.23 27.7981C211.635 29.3436 212.712 31.217 213.461 33.4182C214.21 35.6194 214.585 38.0782 214.585 40.7946V44.0964H190.138C190.56 46.1102 191.473 47.726 192.878 48.9437C194.283 50.1145 196.016 50.7 198.076 50.7C199.809 50.7 201.261 50.3253 202.432 49.5759C203.649 48.7798 204.703 47.7728 205.593 46.5551L212.969 51.9645ZM204.047 36.9307C204.094 35.1511 203.509 33.6289 202.291 32.3644C201.074 31.0999 199.505 30.4676 197.585 30.4676C196.414 30.4676 195.383 30.655 194.494 31.0297C193.604 31.4043 192.831 31.8961 192.175 32.5049C191.566 33.0669 191.075 33.746 190.7 34.5422C190.372 35.2916 190.185 36.0877 190.138 36.9307H204.047Z"
          fill="#110C1A"
        />
        <path
          d="M220.844 23.7235H231.382V29.2031H231.522C232.646 27.0956 233.981 25.5267 235.526 24.4963C237.072 23.4191 239.015 22.8805 241.357 22.8805C241.966 22.8805 242.575 22.9039 243.184 22.9508C243.793 22.9976 244.355 23.0913 244.87 23.2318V32.8562C244.12 32.622 243.371 32.4581 242.622 32.3644C241.919 32.2239 241.17 32.1537 240.374 32.1537C238.36 32.1537 236.767 32.4347 235.597 32.9967C234.426 33.5587 233.512 34.3549 232.857 35.3852C232.248 36.3687 231.85 37.563 231.663 38.968C231.475 40.373 231.382 41.9186 231.382 43.6046V57.8656H220.844V23.7235Z"
          fill="#110C1A"
        />
        <path
          d="M273.493 32.1537H264.22V43.5343C264.22 44.471 264.267 45.3375 264.361 46.1336C264.455 46.883 264.665 47.5387 264.993 48.1007C265.321 48.6627 265.813 49.1076 266.468 49.4354C267.171 49.7164 268.084 49.8569 269.208 49.8569C269.77 49.8569 270.496 49.8101 271.386 49.7164C272.323 49.5759 273.025 49.2949 273.493 48.8734V57.6548C272.323 58.0763 271.105 58.3573 269.84 58.4978C268.576 58.6383 267.335 58.7086 266.117 58.7086C264.337 58.7086 262.698 58.5212 261.2 58.1466C259.701 57.7719 258.389 57.1865 257.265 56.3903C256.141 55.5473 255.252 54.4701 254.596 53.1587C253.987 51.8474 253.683 50.255 253.683 48.3817V32.1537H246.939V23.7235H253.683V13.6074H264.22V23.7235H273.493V32.1537Z"
          fill="#110C1A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.203 39.648C120.203 49.7538 128.396 57.9463 138.501 57.9463H156.8V39.648C156.8 29.5421 148.607 21.3496 138.501 21.3496C128.396 21.3496 120.203 29.5421 120.203 39.648ZM128.59 39.648C128.59 45.122 133.027 49.5596 138.501 49.5596H148.413V39.648C148.413 34.1739 143.975 29.7363 138.501 29.7363C133.027 29.7363 128.59 34.1739 128.59 39.648ZM138.501 43.8409C140.817 43.8409 142.694 41.9635 142.694 39.6475C142.694 37.3316 140.817 35.4542 138.501 35.4542C136.185 35.4542 134.308 37.3316 134.308 39.6475C134.308 41.9635 136.185 43.8409 138.501 43.8409Z"
          fill="#EE0045"
        />
      </svg>
    ),
  },
  dashboard: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill={color}>
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4zm2 2H5V5h14v14zm0-16H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
      </svg>
    ),
  },
  users: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill={color}>
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M9 13.75c-2.34 0-7 1.17-7 3.5V19h14v-1.75c0-2.33-4.66-3.5-7-3.5zM4.34 17c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25H4.34zM9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5 5.5 6.57 5.5 8.5 7.07 12 9 12zm0-5c.83 0 1.5.67 1.5 1.5S9.83 10 9 10s-1.5-.67-1.5-1.5S8.17 7 9 7zm7.04 6.81c1.16.84 1.96 1.96 1.96 3.44V19h4v-1.75c0-2.02-3.5-3.17-5.96-3.44zM15 12c1.93 0 3.5-1.57 3.5-3.5S16.93 5 15 5c-.54 0-1.04.13-1.5.35.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35z" />
      </svg>
    ),
  },
  radius: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill={color}>
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
      </svg>
    ),
  },
  family: {
    code: ({ width, height, color }) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 24 24"
        viewBox="0 0 24 24"
        fill={color}
        width={width}
        height={height}
      >
        <g>
          <rect fill="none" width={width} height={height} />
          <path d="M16,4c0-1.11,0.89-2,2-2s2,0.89,2,2s-0.89,2-2,2S16,5.11,16,4z M20,22v-6h2.5l-2.54-7.63C19.68,7.55,18.92,7,18.06,7h-0.12 c-0.86,0-1.63,0.55-1.9,1.37l-0.86,2.58C16.26,11.55,17,12.68,17,14v8H20z M12.5,11.5c0.83,0,1.5-0.67,1.5-1.5s-0.67-1.5-1.5-1.5 S11,9.17,11,10S11.67,11.5,12.5,11.5z M5.5,6c1.11,0,2-0.89,2-2s-0.89-2-2-2s-2,0.89-2,2S4.39,6,5.5,6z M7.5,22v-7H9V9 c0-1.1-0.9-2-2-2H4C2.9,7,2,7.9,2,9v6h1.5v7H7.5z M14,22v-4h1v-4c0-0.82-0.68-1.5-1.5-1.5h-2c-0.82,0-1.5,0.68-1.5,1.5v4h1v4H14z" />
        </g>
      </svg>
    ),
  },
  pets: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" fill={"none"} width={width} height={height}>
        <g>
          <rect fill="none" width={width} height={height} />
          <path
            d="M 7.49824 2.76913 C 6.34563 3.79216 5.5 5 5.5 6.8 C 5.5 9 7.39832 10.9088 8.99943 13.0416 M 7.49824 2.76913 C 8.59691 1.74789 10.1111 1 12 1 C 13.8889 1 15.5175 1.85427 16.5018 2.76913 M 7.49824 2.76913 L 12 9 M 16.5018 2.76913 C 17.7646 3.97277 18.3333 5 18.3333 6.8 C 18.3333 8.15911 16.5507 10.593 14.7703 12.8785 M 16.5018 2.76913 C 14.9544 4.81493 13.503 6.92497 12 9 M 12 16.5 L 16.6667 23 H 22 L 14.7703 12.8785 M 12 16.5 C 10.1972 19.1189 7.33333 23 7.33333 23 H 2 C 4.32941 19.6739 6.65019 16.3472 8.99943 13.0416 M 12 16.5 C 12.6826 15.5084 13.7261 14.219 14.7703 12.8785 M 8.99943 13.0416 C 9.97087 11.6746 11.0162 10.3582 12 9"
            stroke="#110C1A"
            strokeWidth="1.6"
            strokewinecap="round"
            strokewinejoin="round"
          />
        </g>
      </svg>
    ),
  },
  location: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 24" fill={"none"} width={width} height={height}>
        <g>
          <rect fill="none" width={width} height={height} />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M10.0129 1H10H9.98714C5.23 1 1.00111 4.322 1 9.38322C0.998893 14.4444 8.71425 23 10 23C11.2858 23 18.9994 14.4444 19 9.38322C19.0006 4.322 14.77 1 10.0129 1Z"
            stroke="#110C1A"
            strokeWidth="1.6"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M10.002 12.5C11.3827 12.5 12.502 11.3807 12.502 10C12.502 8.61929 11.3827 7.5 10.002 7.5C8.62124 7.5 7.50195 8.61929 7.50195 10C7.50195 11.3807 8.62124 12.5 10.002 12.5Z"
            stroke="#110C1A"
            strokeWidth="1.6"
            strokeMiterlimit="10"
            strokewinecap="round"
            strokewinejoin="round"
          />
        </g>
      </svg>
    ),
  },
  surveyResults: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={"none"} width={width} height={height}>
        <g>
          <rect fill="none" width={width} height={height} />
          <path
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.7365 2.7619C11.9075 2.7619 9.0785 2.76189 6.24949 2.7619C5.14493 2.76191 4.24951 3.65734 4.24951 4.7619V19.1149C4.24951 20.2195 5.14494 21.1149 6.24951 21.1149C10.1002 21.1149 13.9508 21.1149 17.8015 21.1149C18.9061 21.1149 19.8015 20.2195 19.8015 19.1149V8.0379L14.7365 2.7619Z"
            stroke="#110C1A"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M14.4736 2.7502V5.6592C14.4736 7.0792 15.6226 8.2312 17.0416 8.2342C18.3586 8.2372 19.7056 8.2382 19.7966 8.2322"
            stroke="#110C1A"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M14.2837 15.5578H8.88672"
            stroke="#110C1A"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M12.2422 10.6056H8.88623"
            stroke="#110C1A"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    ),
  },
  vector: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={"none"} width={width} height={height}>
        <g>
          <rect fill="none" width={width} height={height} />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M12.0093 1H11.9999H11.9905C8.51251 1 5.42073 3.64954 5.41992 7.68623C5.41911 11.7229 11.0599 18.5466 11.9999 18.5466C12.94 18.5466 18.5794 11.7229 18.5799 7.68623C18.5803 3.64954 15.4873 1 12.0093 1Z"
            stroke="#110C1A"
            strokeWidth="1.6"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M16.9348 12.6185C20.5127 13.5215 22.9664 15.3758 22.9664 17.5166C22.9664 20.545 18.0565 22.9999 11.9998 22.9999C5.94313 22.9999 1.0332 20.545 1.0332 17.5166C1.0332 16.282 1.84932 15.1426 3.22653 14.2261C3.56033 14.0039 3.92709 13.7949 4.32319 13.6007"
            stroke="#110C1A"
            strokeWidth="1.6"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M11.9995 10.0265C13.2108 10.0265 14.1928 9.04456 14.1928 7.83322C14.1928 6.62188 13.2108 5.63989 11.9995 5.63989C10.7881 5.63989 9.80615 6.62188 9.80615 7.83322C9.80615 9.04456 10.7881 10.0265 11.9995 10.0265Z"
            stroke="#110C1A"
            strokeWidth="1.6"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    ),
  },
  layer: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill={color}>
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z" />
      </svg>
    ),
  },
  "arrow-down": {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width={width} height={height}>
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
      </svg>
    ),
  },
  search: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill={color}>
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
      </svg>
    ),
  },
  burger: {
    code: ({ width, height, color }) => (
      <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" fill={color} width={width} height={height}>
        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
      </svg>
    ),
  },
  logout: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill={color}>
        <g>
          <path d="M0,0h24v24H0V0z" fill="none" />
        </g>
        <g>
          <path d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z" />
        </g>
      </svg>
    ),
  },
  house: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill={color}>
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z" />
      </svg>
    ),
  },
  plus: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600" width={width} height={height} fill={color}>
        <path d="M492,236H276V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v216H20c-11.046,0-20,8.954-20,20s8.954,20,20,20h216    v216c0,11.046,8.954,20,20,20s20-8.954,20-20V276h216c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z" />
      </svg>
    ),
  },
  minus: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={width} height={height} fill={color}>
        <path d="M492,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,236,492,236z" />
      </svg>
    ),
  },
  check: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" fill={color} />
      </svg>
    ),
  },
  background: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"
          fill={color}
        />
      </svg>
    ),
  },
  calendar: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"
          fill={color}
        />
      </svg>
    ),
  },
  "cloud-upload": {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0V0z" style={{ fill: "none" }} />
        <path
          d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zM8 13h2.55v3h2.9v-3H16l-4-4z"
          fill={color}
        />
      </svg>
    ),
  },
  case: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} fill={color}>
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M14 6V4h-4v2h4zM4 8v11h16V8H4zm16-2c1.11 0 2 .89 2 2v11c0 1.11-.89 2-2 2H4c-1.11 0-2-.89-2-2l.01-11c0-1.11.88-2 1.99-2h4V4c0-1.11.89-2 2-2h4c1.11 0 2 .89 2 2v2h4z" />
      </svg>
    ),
  },
  pen: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} fill={color}>
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
      </svg>
    ),
  },
  lock: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <g fill="none">
          <path d="M0 0h24v24H0V0z" />
          <path d="M0 0h24v24H0V0z" opacity=".87" />
        </g>
        <path
          d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"
          fill={color}
        />
      </svg>
    ),
  },
  "crossed-image": {
    code: ({ width, height, color }) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 24 24"
        width={width}
        height={height}
        viewBox="0 0 24 24"
      >
        <g>
          <rect fill="none" width={width} height={height} />
        </g>
        <g>
          <g>
            <path d="M19,5v11.17l2,2V5c0-1.1-0.9-2-2-2H5.83l2,2H19z" fill={color} />
            <path
              d="M2.81,2.81L1.39,4.22L3,5.83V19c0,1.1,0.9,2,2,2h13.17l1.61,1.61l1.41-1.41L2.81,2.81z M5,19V7.83l7.07,7.07L11.25,16 L9,13l-3,4h8.17l2,2H5z"
              fill={color}
            />
          </g>
        </g>
      </svg>
    ),
  },
  "arrow-bottom": {
    code: ({ width, height, color }) => (
      <svg viewBox="0 0 24 24" width={width} height={height} aria-hidden="true">
        <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
      </svg>
    ),
  },
  visibility: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z"
          fill={color}
        />
      </svg>
    ),
  },

  "crossed-visibility": {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0V0zm0 0h24v24H0V0zm0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none" />
        <path
          d="M12 6c3.79 0 7.17 2.13 8.82 5.5-.59 1.22-1.42 2.27-2.41 3.12l1.41 1.41c1.39-1.23 2.49-2.77 3.18-4.53C21.27 7.11 17 4 12 4c-1.27 0-2.49.2-3.64.57l1.65 1.65C10.66 6.09 11.32 6 12 6zm-1.07 1.14L13 9.21c.57.25 1.03.71 1.28 1.28l2.07 2.07c.08-.34.14-.7.14-1.07C16.5 9.01 14.48 7 12 7c-.37 0-.72.05-1.07.14zM2.01 3.87l2.68 2.68C3.06 7.83 1.77 9.53 1 11.5 2.73 15.89 7 19 12 19c1.52 0 2.98-.29 4.32-.82l3.42 3.42 1.41-1.41L3.42 2.45 2.01 3.87zm7.5 7.5l2.61 2.61c-.04.01-.08.02-.12.02-1.38 0-2.5-1.12-2.5-2.5 0-.05.01-.08.01-.13zm-3.4-3.4l1.75 1.75c-.23.55-.36 1.15-.36 1.78 0 2.48 2.02 4.5 4.5 4.5.63 0 1.23-.13 1.77-.36l.98.98c-.88.24-1.8.38-2.75.38-3.79 0-7.17-2.13-8.82-5.5.7-1.43 1.72-2.61 2.93-3.53z"
          fill={color}
        />
      </svg>
    ),
  },

  warning: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
          fill={color}
        />
      </svg>
    ),
  },

  info: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
          fill={color}
        />
      </svg>
    ),
  },

  "visibility-filled": {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
          fill={color}
        />
      </svg>
    ),
  },

  "check-filled": {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
          fill={color}
        />
      </svg>
    ),
  },

  block: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"
          fill={color}
        />
      </svg>
    ),
  },

  "crossed-visibility-filled": {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z" fill="none" />
        <path
          d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"
          fill={color}
        />
      </svg>
    ),
  },

  "user-search": {
    code: ({ width, height, color }) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 24 24"
        width={width}
        height={height}
        viewBox="0 0 24 24"
      >
        <g>
          <rect fill="none" height="24" width="24" />
        </g>
        <g>
          <g>
            <circle cx="10" cy="8" r="4" fill={color} />
            <path d="M10.35,14.01C7.62,13.91,2,15.27,2,18v2h9.54C9.07,17.24,10.31,14.11,10.35,14.01z" fill={color} />
            <path
              d="M19.43,18.02C19.79,17.43,20,16.74,20,16c0-2.21-1.79-4-4-4s-4,1.79-4,4c0,2.21,1.79,4,4,4c0.74,0,1.43-0.22,2.02-0.57 L20.59,22L22,20.59L19.43,18.02z M16,18c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C18,17.1,17.1,18,16,18z"
              fill={color}
            />
          </g>
        </g>
      </svg>
    ),
  },

  question: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z"
          fill={color ? color : "currentColor"}
        />
      </svg>
    ),
  },

  help: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
          fill={color}
        />
      </svg>
    ),
  },

  mail: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill={color}>
        <path d="M4 20Q3.175 20 2.588 19.413Q2 18.825 2 18V6Q2 5.175 2.588 4.588Q3.175 4 4 4H20Q20.825 4 21.413 4.588Q22 5.175 22 6V18Q22 18.825 21.413 19.413Q20.825 20 20 20ZM12 13 4 8V18Q4 18 4 18Q4 18 4 18H20Q20 18 20 18Q20 18 20 18V8ZM12 11 20 6H4ZM4 8V6V8V18Q4 18 4 18Q4 18 4 18Q4 18 4 18Q4 18 4 18Z" />
      </svg>
    ),
  },
  "phone-call": {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill={color}>
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M20 15.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zM19 12h2c0-4.97-4.03-9-9-9v2c3.87 0 7 3.13 7 7zm-4 0h2c0-2.76-2.24-5-5-5v2c1.66 0 3 1.34 3 3z" />
      </svg>
    ),
  },

  "trash-outline": {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill={color}>
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z" />
      </svg>
    ),
  },

  filter: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill={color}>
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
      </svg>
    ),
  },

  cross: {
    code: ({ width, height, color, className }) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        // display={className?.display}
        // margin-left={className?.['margin-left']}
        // margn-right={className?.['margn-right']}
        display="block"
        margin-left="auto"
        margin-right="auto"
        viewBox="0 0 24 24"
        fill={color}
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
      </svg>
    ),
  },
  files: {
    hide: true,
    code: ({ width, height }) => (
      <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 468.293 468.293">
        <path
          fill={colors.color_basic._0}
          d="M444.054,165.462H24.239V73.264c0-6.952,5.636-12.588,12.588-12.588h104.238  c3.487,0,6.817,1.446,9.197,3.994l29.149,31.196c2.38,2.548,5.711,3.994,9.197,3.994h242.857c6.952,0,12.588,5.636,12.588,12.588  V165.462z"
        />
        <rect x="92.491" y="118.347" fill="#E1E6E9" width="283.286" height="84.574" />
        <rect x="63.913" y="137.079" fill="#EBF0F3" width="340.48" height="84.574" />
        <path
          fill={colors.color_basic._1}
          d="M418.905,407.616H49.388c-6.281,0-11.601-4.63-12.467-10.851L0.123,170.132  c-1.054-7.569,4.825-14.324,12.467-14.324h443.113c7.642,0,13.522,6.755,12.467,14.324l-36.798,226.633  C430.505,402.986,425.186,407.616,418.905,407.616z"
        />
      </svg>
    ),
  },
  schedule: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 24 24" width={width} fill={color}>
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
        <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
      </svg>
    ),
  },
  folder: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 24 24" width={width} fill={color}>
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M9.17 6l2 2H20v10H4V6h5.17M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z" />
      </svg>
    ),
  },
  gear: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 24 24" width={width} fill={color}>
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
      </svg>
    ),
  },
  eye: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 24 24" width={width} fill={color}>
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z" />
      </svg>
    ),
  },
  eyeOff: {
    code: ({ width, height, color }) => (
      <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 24 24" width={width} fill={color}>
        <path d="m16.1 13.3-1.45-1.45q.225-1.175-.675-2.2-.9-1.025-2.325-.8L10.2 7.4q.425-.2.862-.3Q11.5 7 12 7q1.875 0 3.188 1.312Q16.5 9.625 16.5 11.5q0 .5-.1.938-.1.437-.3.862Zm3.2 3.15-1.45-1.4q.95-.725 1.688-1.588.737-.862 1.262-1.962-1.25-2.525-3.588-4.013Q14.875 6 12 6q-.725 0-1.425.1-.7.1-1.375.3L7.65 4.85q1.025-.425 2.1-.638Q10.825 4 12 4q3.775 0 6.725 2.087Q21.675 8.175 23 11.5q-.575 1.475-1.512 2.738Q20.55 15.5 19.3 16.45Zm.5 6.15-4.2-4.15q-.875.275-1.762.413Q12.95 19 12 19q-3.775 0-6.725-2.087Q2.325 14.825 1 11.5q.525-1.325 1.325-2.463Q3.125 7.9 4.15 7L1.4 4.2l1.4-1.4 18.4 18.4ZM5.55 8.4q-.725.65-1.325 1.425T3.2 11.5q1.25 2.525 3.587 4.012Q9.125 17 12 17q.5 0 .975-.062.475-.063.975-.138l-.9-.95q-.275.075-.525.112Q12.275 16 12 16q-1.875 0-3.188-1.312Q7.5 13.375 7.5 11.5q0-.275.037-.525.038-.25.113-.525Zm7.975 2.325ZM9.75 12.6Z" />
      </svg>
    ),
  },
  ribbon: {
    code: ({ width, height, color }) => (
      <svg width={width} height={height} viewBox="0 0 22 24" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.49824 2.76913C5.34563 3.79216 4.5 5 4.5 6.8C4.5 9 6.39832 10.9088 7.99943 13.0416M6.49824 2.76913C7.59691 1.74789 9.11107 1 11 1C12.8889 1 14.5175 1.85427 15.5018 2.76913M6.49824 2.76913L11 9M15.5018 2.76913C16.7646 3.97277 17.3333 5 17.3333 6.8C17.3333 8.15911 15.5507 10.593 13.7703 12.8785M15.5018 2.76913C13.9544 4.81493 12.503 6.92497 11 9M11 16.5L15.6667 23H21L13.7703 12.8785M11 16.5C9.19716 19.1189 6.33333 23 6.33333 23H1C3.32941 19.6739 5.65019 16.3472 7.99943 13.0416M11 16.5C11.6826 15.5084 12.7261 14.219 13.7703 12.8785M7.99943 13.0416C8.97087 11.6746 10.0162 10.3582 11 9"
          stroke="#000000"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </svg>
    ),
  },
  "drag-handle": {
    code: ({ width, height, color }) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 24 24"
        height={height}
        viewBox="0 0 24 24"
        width={width}
        fill={color}
      >
        <g>
          <rect fill="none" height={height} width={width} />
        </g>
        <g>
          <g>
            <g>
              <path d="M20,9H4v2h16V9z M4,15h16v-2H4V15z" />
            </g>
          </g>
        </g>
      </svg>
    ),
  },
};

export default icons;
