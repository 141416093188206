import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import Loader from "../../../ui-kit/loader.js";
import { Button, CustomDropzone, Drawer, Form, Input, InputDate, Select } from "../../../ui-kit";
import { getBeautyEngDateString } from "../../../utils/datetime";
import STRINGS from "../../../localization";

import "./styles.scss";
import { storage } from "../../../services/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { validateObject } from "../../../utils/input-functions.js";

const SCHEME = {
  name: (data) => !!data,
  startDate: (data) => !!data,
  position: (data) => !!data,
  file: (data) => !!data,
};

const PromotionModal = (props) => {
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [position, setPosition] = useState();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({ isValid: false, data: {} });

  const positionOptions = [
    { key: 1, label: 1 },
    { key: 2, label: 2 },
    { key: 3, label: 3 },
  ];

  const clearForm = () => {
    setName("");
    setStartDate(new Date());
    setEndDate(new Date());
    setPosition();
    setFile(null);
  };

  const handleSave = async () => {
    setLoading(true);

    try {
      let data = {};
      if (props.selectedPromotion) {
        if (name !== props.selectedPromotion.name) {
          data.name = name;
        }
        if (startDate !== props.selectedPromotion.startDate) {
          data.startDate = startDate.toISOString();
        }
        if (endDate !== props.selectedPromotion.endDate) {
          data.endDate = endDate ? endDate.toISOString() : "";
        }
        if (position !== props.selectedPromotion.frequency) {
          data.frequency = Number(position);
        }
        if (position !== props.selectedPromotion.frequency) {
          data.frequency = Number(position);
        }
        if (!file?.gsLink || file?.gsLink !== props.selectedPromotion?.photo?.en) {
          const photo = await storageHandler(false);
          data.photo = { en: photo, es: photo };
        }
      } else {
        data = {
          name,
          startDate: startDate.toISOString(),
          endDate: endDate ? endDate.toISOString() : "",
          frequency: Number(position),
          status: "active",
        };

        if (file) {
          const photo = await storageHandler();
          data.photo = { en: photo, es: photo };
        }
      }

      await props.saveChanges(data);
    } finally {
      setLoading(false);
    }
    props.onClose();
  };

  const storageHandler = async () => {
    const imgName = `${Math.floor(Math.random() * 10000000000000)}.${file.path.split("/").pop()}`;

    const storageRef = ref(storage, `/images/promotions/${imgName}`);
    await uploadBytesResumable(storageRef, file);
    return `gs://${storageRef.bucket}/${storageRef.fullPath}`;
  };

  useEffect(() => {
    setValidation(validateObject({ name, startDate, position, file }, SCHEME));
  }, [name, startDate, position, file]);

  useEffect(() => {
    if (props.open && props.selectedPromotion) {
      setName(props.selectedPromotion?.name || "");
      setStartDate(new Date(props.selectedPromotion?.startDate || ""));
      setEndDate(new Date(props.selectedPromotion?.endDate || ""));
      setPosition(props.selectedPromotion?.frequency);
      setCurrentFile();
    } else if (!props.open) {
      clearForm();
      props.onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const setCurrentFile = async () => {
    const url = props.selectedPromotion?.photo?.en;
    const path = url.split("/").pop();

    try {
      const preview = await getDownloadURL(ref(storage, url));
      setFile({
        path,
        type: "image/png",
        preview,
        gsLink: props.selectedPromotion?.photo?.en,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Drawer
      title={props.title}
      visible={props.open}
      onClose={() => {
        props.onClose();
      }}
    >
      <Form className="modal promotion_modal" onSubmit={handleSave}>
        <div className="header">
          <span>{props.title}</span>
        </div>
        {props.loading ? (
          <Loader color="black" width={50} height={50} class="center" />
        ) : (
          <div className="body">
            <Input
              label={STRINGS.screens.promotions.name}
              value={name}
              onChange={setName}
              type="default"
              maxLength={300}
            />
            <div className="container_fullwidth">
              <InputDate
                label={STRINGS.screens.promotions.startDate}
                value={startDate}
                className="container_fullwidth_half_first"
                inputValue={startDate ? getBeautyEngDateString(startDate) : STRINGS.components.date.date}
                onChange={setStartDate}
                size="large"
                inputType="default"
                disablePast
                maxDate={endDate}
              />
              <InputDate
                label={STRINGS.screens.promotions.endDate}
                value={endDate}
                className="container_fullwidth_half_first"
                inputValue={endDate ? getBeautyEngDateString(endDate) : STRINGS.components.date.date}
                onChange={setEndDate}
                size="large"
                inputType="default"
                disablePast
                minDate={startDate}
              />
            </div>
            <Select
              label={STRINGS.screens.promotions.position}
              selectType="default"
              size="large"
              value={position}
              onChange={setPosition}
              options={positionOptions}
              disabledOptions={props.blockedPositions}
            />
            <div className="picture-block">
              <div className="subtitle">
                <span>{STRINGS.screens.promotions.picture}:</span>
              </div>
              <div className="picture">
                <CustomDropzone maxFiles={1} files={file} onChange={setFile} dropzoneType={"default"} />
              </div>
            </div>
          </div>
        )}
        <div className="footer">
          <Button
            className="button"
            label={STRINGS.components.buttons.cancel}
            size="medium"
            type="default"
            onClick={props.onClose}
            loading={props.loading}
            typeButton="button"
          />
          <Button
            className="button"
            label={STRINGS.components.buttons.save}
            size="medium"
            typeButton="submit"
            loading={props.loading || loading}
            disabled={!validation.isValid}
          />
        </div>
      </Form>
    </Drawer>
  );
};

PromotionModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  saveChanges: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
};
PromotionModal.defaultProps = {
  isOpen: false,
  close: () => {},
  saveChanges: () => {},
  title: "",
  onChange: () => {},
};

export default PromotionModal;
