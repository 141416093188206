import ky from "ky";
import AuthService from "../auth";
import { getAuth, getIdToken } from "firebase/auth";
import { store } from "../../store";
import Thunks from "../../store/current-user/thunks";

/**
 * This one makes api requests on entire application
 */
const api = ky.extend({
  retry: {
    limit: 3,
    methods: ["get", "post", "put", "delete", "patch"],
    statusCodes: [403, 408, 413, 429, 500, 502, 503, 504],
  },
  timeout: 60000,
  hooks: {
    beforeRequest: [
      async (request) => {
        const token = AuthService.getAuthToken();
        if (token) {
          request.headers.set("Authorization", `Bearer ${token}`);
        }
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response.status === 403 || response.status === 401) {
          let refreshed = false;
          try {
            const user = getAuth().currentUser;
            const token = user ? await getIdToken(user, true) : null;
            refreshed = !!token && token !== AuthService.getAuthToken();
            AuthService.setAuthToken({ token, refreshToken: null });
          } catch (e) {
            console.error("Token refresh failed due to", e);
          }

          if (refreshed) {
            request.headers.set("Authorization", `Bearer ${AuthService.getAuthToken()}`);
            return ky(request);
          }

          await store.dispatch(Thunks.logout());
        }
      },
    ],
  },
});

export default api;
