import React from "react";

import "./styles.scss";

import { Paper, Switch, Radio, Checkbox } from "../../../ui-kit";

import STRINGS from "../../../localization";

const SelectionControlsView = (props) => {
  return (
    <div id="--selection-controls">
      <Paper className="switchers">
        <h4>Переключатели</h4>
        <article>
          <span>Включает или выключает состояние отдельного параметра.</span>
          <br />
          <code>{'<Switch checked onChange={() => {}} label="Gilad Gray"/>'}</code>
        </article>
        <aside>
          <Switch checked={props.clientName1} onChange={props.setClientName1} label="Gilad Gray" />
          <Switch checked={props.clientName2} onChange={props.setClientName2} label="Jason Killian" />
          <Switch checked={props.clientName3} onChange={props.setClientName3} label="Antoine Llorca" />
          <Switch checked={true} disabled label={STRINGS.screens.storybook.disabled} />
        </aside>
      </Paper>
      <Paper className="radio-buttons">
        <h4>Радио-кнопки</h4>
        <article>
          <span>Радиокнопки позволяют пользователю выбрать один вариант из набора.</span>
          <br />
          <code>{'<Radio checked onChange={() => {}} label="Male"/>'}</code>
        </article>
        <aside>
          <Radio
            checked={props.gender === 1}
            onChange={() => props.setGender(1)}
            label={STRINGS.screens.storybook.male}
          />
          <Radio
            checked={props.gender === 2}
            onChange={() => props.setGender(2)}
            label={STRINGS.screens.storybook.female}
          />
          <Radio
            checked={props.gender === 3}
            onChange={() => props.setGender(3)}
            label={STRINGS.screens.storybook.other}
          />
          <Radio checked={false} disabled label={STRINGS.screens.storybook.disabled} />
        </aside>
      </Paper>
      <Paper className="checkboxes">
        <h4>Флажки</h4>
        <article>
          <span>Флажки позволяют пользователю выбрать один или несколько элементов из набора.</span>
          <br />
          <code>{'<Checkbox checked onChange={() => {}} label="Apple" />'}</code>
        </article>
        <aside>
          <Checkbox checked={props.testValue1} onChange={props.setTestValue1} label={STRINGS.screens.storybook.apple} />
          <Checkbox checked={props.testValue2} onChange={props.setTestValue2} label={STRINGS.screens.storybook.kiwi} />
          <Checkbox
            checked={props.testValue3}
            onChange={props.setTestValue3}
            label={STRINGS.screens.storybook.banana}
          />
          <Checkbox checked={true} disabled label={STRINGS.screens.storybook.disabled} />
        </aside>
      </Paper>
    </div>
  );
};

export default SelectionControlsView;
