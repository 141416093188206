import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import Loader from "../../../ui-kit/loader";
import { Button, Form, Input, Drawer } from "../../../ui-kit";
import "./styles.scss";
import STRINGS from "../../../localization";
import { validateObject } from "../../../utils/input-functions";

const SCHEME = {
  meters: (value) => !!value,
};

const RadiusModal = (props) => {
  const [meters, setMeters] = useState("");
  const [validation, setValidation] = useState({ isValid: false, data: {} });

  const handleSave = (e) => {
    e.preventDefault();
    props.onSave(meters);
  };

  const onClickCancel = () => {
    props.onClose();
  };

  useEffect(() => {
    if (props.open) {
      setMeters(props.data.meters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  useEffect(() => {
    setValidation(validateObject({ meters }, SCHEME));
  }, [meters]);

  return (
    <Drawer title={props.title} visible={props.open} onClose={() => props.onClose()}>
      <Form className="modal" onSubmit={handleSave}>
        <div className="header">
          <span>Edit radius</span>
        </div>
        {props.loading ? (
          <Loader />
        ) : (
          <div className="body">
            <Input type="number" label="Meters/Yards" value={meters} onChange={(e) => setMeters(e)} />
          </div>
        )}
        <div className="footer">
          <Button
            className="button"
            label={STRINGS.components.buttons.cancel}
            size="medium"
            type="default"
            onClick={onClickCancel}
            loading={props.loading}
          />
          <Button
            className="button"
            label={STRINGS.components.buttons.save}
            size="medium"
            typeButton="submit"
            loading={props.loading}
            disabled={!validation.isValid || Number(meters) === props.data?.meters}
          />
        </div>
      </Form>
    </Drawer>
  );
};

RadiusModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  saveChanges: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
};
RadiusModal.defaultProps = {
  isOpen: false,
  close: () => {},
  saveChanges: () => {},
  title: "",
  onChange: () => {},
};

export default RadiusModal;
