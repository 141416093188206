import { localizationSetLanguages } from "./localization";

import LanguagesService from "../../services/languages";

class LanguagesThunks {
  /**
   *
   * @return {Dispatch}
   */
  static getLanguages() {
    return async (dispatch) => {
      try {
        const response = await LanguagesService.getLanguages();
        dispatch(localizationSetLanguages(response?.data));
      } catch (error) {}
    };
  }
}

export default LanguagesThunks;
