/**
 * HTTP statuses
 */
export const HTTP_STATUS = {
  SUCCESS: 200,
  CREATED: 201,
  DELETED: 204,
};

/**
 * TODO: Paste your server address here
 */

export const URL = process.env.REACT_APP_API_SERVER_URL;
