import React from "react";
import PropTypes from "prop-types";

/**
 * @component Form
 * @example
 * <Form
 *  id="--id"
 *  className="some class"
 *  onSubmit={() = {}}
 * />
 */
const Form = (props) => {
  const action = (e) => {
    e.preventDefault();
    props.onSubmit(e);
  };

  return (
    <form id={props.id} className={props.className} onSubmit={action} autoComplete="off">
      {props.children}
    </form>
  );
};

/**
 * Properties
 */

Form.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  onSubmit: PropTypes.func,
};
Form.defaultProps = {
  className: null,
  id: null,
  onSubmit: () => {},
};

export default Form;
