import STRINGS from "../../localization";
import { Button, Form, Input, Paper } from "../../ui-kit";
import SkeletManagers from "./skelet";
import "./styles.scss";
import { SectionHeader } from "../../components/section-header/SectionHeader";
import { Typography } from "@mui/material";
import React from "react";

const ContactView = (props) => {
  return (
    <>
      <SectionHeader>
        <Typography variant={"h1"}>{STRINGS.screens.contact.contact}</Typography>
      </SectionHeader>
      <div>
        <Paper className="paper settings">
          <Form className="modal settings_modal" onSubmit={props.saveChanges}>
            {props.loading ? (
              <SkeletManagers />
            ) : (
              <div className="settings_input_wrapper">
                <Input
                  label={`${STRINGS.screens.contact.email} 1 *`}
                  value={props.email1.value}
                  onChange={(value) => props.setEmail1({ ...props.email1, value })}
                  inputType={props.email1.focus && !props.validation.data.email1?.isValid ? "danger" : "default"}
                  onBlur={() => props.setEmail1({ ...props.email1, focus: true })}
                  isValid={!!props.validation.data.email1?.isValid}
                />
                <Input
                  label={`${STRINGS.screens.contact.email} 2 *`}
                  value={props.email2.value}
                  onChange={(value) => props.setEmail2({ ...props.email2, value })}
                  inputType={props.email2.focus && !props.validation.data.email2?.isValid ? "danger" : "default"}
                  onBlur={() => props.setEmail2({ ...props.email2, focus: true })}
                  isValid={!!props.email2.value && !!props.validation.data.email2?.isValid}
                />
              </div>
            )}
            <Button
              className="button"
              label={STRINGS.components.buttons.save}
              size="medium"
              typeButton="submit"
              loading={!!props.loading}
              disabled={!props.validation.isValid}
            />
          </Form>
        </Paper>
      </div>
    </>
  );
};

export default ContactView;
