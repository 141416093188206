import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const Checkbox = (props) => {
  return (
    // <div className='checkbox'>
    //   <div>
    //     <input
    //       type='checkbox'
    //       disabled={props.disabled}
    //       onChange={() => props.onChange(!props.checked)}
    //       checked={props.checked}
    //       label={props.label}
    //     />
    //   </div>
    //   {props.label && <span className='label'>{props.label}</span>}
    // </div>

    <div class="checkbox">
      <input class="custom-checkbox" type="checkbox" id="color-1" name="color-1" value="indigo" />
      <label for="color-1">{props.label}</label>
    </div>
  );
};

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
};
Checkbox.defaultProps = {
  disabled: false,
  onChange: () => {},
  checked: false,
  label: null,
};

export default Checkbox;
