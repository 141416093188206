import React, { useState } from "react";

import DropdownView from "./dropdown-view";

const DropdownContainer = () => {
  const [selectValue, setSelectValue] = useState({
    value_0: 1,
    value_1: undefined,
    value_2: undefined,
    value_3: 1,
    value_4: undefined,
    value_5: undefined,
    value_6: 2,
  });
  return <DropdownView selectValue={selectValue} setSelectValue={setSelectValue} />;
};

export default DropdownContainer;
