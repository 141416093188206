import React from "react";

import "./styles.scss";

import { Dropzone, Paper } from "../../../ui-kit";

const DropzoneView = (props) => {
  return (
    <div id="--dropzone">
      <Paper className="dropzone-example">
        <h4>Dropzone (Зона загрузки)</h4>
        <article>
          <span>
            Данный компонент открывает файловую систему устройства по нажатию в границе блока. Так же компонент способен
            обрабатывать файлы при помощи перетягивания в зону загрузки.
            <br />
            <br />
            Компонент предоставляет возможность <code>multiple</code> выбора. Компонент так же способен отображать
            preview картинок, в случае если файл не предостовляет собой изображение, то будет отрисоавана картинка
            соответсвущего типа.
          </span>
          <br />
          <code>{'<Dropzone files={props.files} onChange={props.setFiles} title="" skelet />'}</code>
        </article>
        <aside className="row">
          <Dropzone
            files={props.files}
            onChange={props.setFiles}
            title="Для загрузки изображенияе перетяните в область или нажмите на любою область зоны"
          />
        </aside>
      </Paper>
    </div>
  );
};

export default DropzoneView;
