import { Box as MuiBox, IconButton } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { SortIcon } from "./SortIcon";
import { styled } from "@mui/system";

export enum SortMode {
  none = "none",
  asc = "asc",
  desc = "desc",
}

const SortSequence = Object.values(SortMode);

const Box = styled(MuiBox)({
  root: {
    cursor: "pointer",
    "& .icon": {
      visibility: "hidden",
    },
    "&:hover": {
      "& .icon": {
        visibility: "visible",
      },
    },
  },
});

export const LabelAndSort: React.FC<{ label: string; onChange: any }> = ({ label, onChange }) => {
  const [mode, setMode] = useState<SortMode>();

  const onClick = useCallback(async () => {
    setMode((prev = SortMode.none) => SortSequence[(1 + SortSequence.indexOf(prev)) % SortSequence.length]);
  }, [setMode]);

  useEffect(() => {
    if (mode) {
      onChange(mode);
    }
  }, [mode, onChange]);

  return (
    <Box
      onClick={onClick}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <span>{label}</span>
      <IconButton size={"small"}>
        <SortIcon mode={mode ?? SortMode.none} />
      </IconButton>
    </Box>
  );
};
