import React, { useState } from "react";

import DrawerView from "./drawer-view";

const DrawerContainer = () => {
  const [leftVisible, setLeftVisible] = useState(false);
  const [rightVisible, setRightVisible] = useState(false);

  const handleLeftDrawer = () => {
    setLeftVisible((old) => !old);
  };

  const handleRightDrawer = () => {
    setRightVisible((old) => !old);
  };

  return (
    <DrawerView
      leftVisible={leftVisible}
      rightVisible={rightVisible}
      handleLeftDrawer={handleLeftDrawer}
      handleRightDrawer={handleRightDrawer}
    />
  );
};

export default DrawerContainer;
