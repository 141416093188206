import React, { useState, useEffect, useCallback } from "react";

import { Toast } from "../../ui-kit";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import SurveyResultsView from "./survey-results-view";
import SurveyService from "../../services/survey";
import { surveyResultsDataExporter } from "./surveyResultsDataExporter";

const SurveyResults = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [surveyData, setSurveyData] = useState({ data: [] });
  const [page, setPage] = useState(1);
  const [skeletVisible, setSkeletVisible] = useState(false);

  const formatKeys = useCallback((obj) => {
    if (!obj) {
      return;
    }

    const keyValues = Object.keys(obj).map((key) => {
      const newKey = key.replaceAll("\n", "").replaceAll(" ", "");
      return { [newKey]: obj[key] };
    });

    return Object.assign({}, ...keyValues);
  }, []);

  const filterSurveyResults = useCallback(
    (result, isNext = false) => {
      const data = result.data.map((survey) => ({
        ...survey,
        obj: formatKeys(survey.result),
      }));

      if (isNext) {
        setSurveyData((prev) => ({ ...prev, last: result.last, data: [...prev?.data, ...data] }));
      } else {
        setSurveyData({ data });
      }

      setSurveyData((prev) => (isNext ? { ...prev, last: result.last, data: [...prev?.data, ...data] } : { data }));
    },
    [formatKeys],
  );

  const syncSurveyList = useCallback(
    async (page, start, end) => {
      try {
        setSkeletVisible(true);

        const result = await SurveyService.gettingSurveyInformation({ page, start, end });

        filterSurveyResults(result, page > 1);
      } catch (err) {
        Toast.error("Failed to get the list of survey");
      } finally {
        setSkeletVisible(false);
      }
    },
    [filterSurveyResults],
  );

  useBottomScrollListener(() => {
    !surveyData.last && setPage((prev) => prev + 1);
  });

  useEffect(() => {
    syncSurveyList(page, startDate?.toISOString(), endDate?.toISOString());
  }, [page, startDate, endDate, syncSurveyList]);

  const onDownload = useCallback(
    (format) => surveyResultsDataExporter.export(surveyData.data, `survey-results-${new Date().toISOString()}`, format),
    [surveyData.data],
  );

  return (
    <SurveyResultsView
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      skeletVisible={skeletVisible}
      startDate={startDate}
      endDate={endDate}
      surveyData={surveyData.data}
      onDownload={onDownload}
    />
  );
};

export default SurveyResults;
