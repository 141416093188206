import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
import { Paper } from "../index";

/**
 * @component Table
 * @example
 * <Table />
 */
const Table = (props) => {
  return (
    <Paper className={props.className} additionalClass={`table-container  ${props.secondary ? "secondary" : ""}`}>
      <aside>
        <table>
          <thead>{props.head()}</thead>
          <tbody>{props.children}</tbody>
        </table>
      </aside>
    </Paper>
  );
};

/**
 * Properties
 */

Table.propTypes = {
  head: PropTypes.func,
  children: PropTypes.node,
  secondary: PropTypes.bool,
  className: PropTypes.string,
};
Table.defaultProps = {
  head: () => {},
  children: null,
  secondary: false,
  className: "",
};

export default Table;
