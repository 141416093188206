import React from "react";

import "./styles.scss";

import { Paper, Button, Icon, Confirm, ConfirmWithInput } from "../../ui-kit";

import STRINGS from "../../localization";
import colors from "../../styles/colors";
import Skelet from "./skelet";
import { SectionHeader } from "../../components/section-header/SectionHeader";
import { Typography } from "@mui/material";

const WeRememberYouView = (props) => {
  const renderContent = () =>
    props.postsData.map((post) => (
      <div key={post._id}>
        <Paper className="paper">
          <>
            <div className={`${"content-posts-for-approval"}`}>
              <div className="container-for-photo">
                {!post.photo ? (
                  <Icon name={"pets"} color={colors.color_text.primary} width={100} height={100} />
                ) : (
                  <img className="photo" src={post.photo} alt="animal" />
                )}
              </div>
              <div className="container-for-text">
                <div className="flex">
                  <h4>{STRINGS.screens.weRememberYou.animalName}</h4>
                  <span>{post.animalName}</span>
                </div>
                <div className="flex">
                  <h4 className="text">{STRINGS.screens.weRememberYou.author}</h4>
                  <span>{post.authorId?.nickname}</span>
                </div>
                <div className="flex">
                  <h4>{STRINGS.screens.weRememberYou.dateOfDeath}</h4>
                  <span>{new Date(post.dateOfDeath).toLocaleDateString()}</span>
                </div>
                <div className="flex">
                  <h4>{STRINGS.screens.weRememberYou.placeOfDeath}</h4>
                  <span>
                    {post.placeOfDeath.city} {post.placeOfDeath.country} {post.placeOfDeath.state}
                  </span>
                </div>
              </div>
            </div>
            <button className="delete" onClick={() => props.opendeletePostModal(post._id)}>
              <Icon name="cross" width={24} height={24} color={colors.color_basic._0} />
            </button>
            {props.postType === "forApproval" && (
              <div className="button">
                <Button
                  type="primary"
                  label={STRINGS.screens.weRememberYou.approve}
                  onClick={() => props.openApproveModal(post._id)}
                  className="approve"
                />
              </div>
            )}
          </>
        </Paper>
        <Confirm
          onClose={props.openApproveModal}
          title={STRINGS.screens.weRememberYou.questionAboutPostApproval}
          visible={props.approveModalVisible === post._id}
          buttons={[
            {
              label: STRINGS.components.modals.approve,
              onClick: () => props.approvePost(post._id),
            },
            {
              label: STRINGS.components.modals.cancel,
              outline: true,
              onClick: () => props.closeApprovePostModal(),
            },
          ]}
        />
        <ConfirmWithInput
          onClose={props.opendeletePostModal}
          title={STRINGS.screens.weRememberYou.questionAboutDeletingPost}
          visible={props.deleteModalVisible === post._id}
          onOkClick={(notification) => props.deletePost(post, notification)}
          quantitySimbols={300}
          type={"post"}
          loading={props.loading}
        />
      </div>
    ));

  return (
    <div className="we-remember-you-page">
      <SectionHeader>
        <Typography variant={"h1"}>{STRINGS.components.sidebar.weRememberYou}</Typography>
      </SectionHeader>
      <div className="container-for-header">
        <h1
          onClick={() => props.changePostType("forApproval")} //forApproval or activePosts
          className={props.postType === "forApproval" ? "active-bottom" : "not-active-bottom"}
        >
          {STRINGS.screens.weRememberYou.forApproval}
        </h1>
        <h1
          onClick={() => props.changePostType("activePosts")}
          className={props.postType === "activePosts" ? "active-bottom" : "not-active-bottom"}
        >
          {STRINGS.screens.weRememberYou.activePosts}
        </h1>
      </div>
      {props.skeletVisible ? <Skelet /> : <div className="container">{renderContent()}</div>}
    </div>
  );
};

export default WeRememberYouView;
