export type JsonExportOptions = Record<string, string | ((row: object) => object)>;
export type CsvExportOptions = Record<string, string | ((row: object) => string)>;

export class DataExporter {
  public constructor(
    private readonly jsonExportOptions: JsonExportOptions,
    private readonly csvExportOptions: CsvExportOptions,
  ) {}

  public export(rows: object[], filename: string, format: "csv" | "json") {
    this.downloadFile({
      data: format === "json" ? this.jsonData(rows) : this.csvData(rows),
      fileName: `${filename}.${format}`,
      fileType: `text/${format}`,
    });
  }

  private csvData(rows: object[]): string {
    const keys = Object.keys(this.csvExportOptions);
    const header = keys.join(",");

    const lines = rows
      .map((row) =>
        keys
          .map((key) => {
            let value = "";
            if (typeof this.csvExportOptions[key] === "string") {
              // @ts-ignore
              value = row[this.csvExportOptions[key]];
            }
            if (typeof this.csvExportOptions[key] === "function") {
              // @ts-ignore
              value = this.csvExportOptions[key](row);
            }
            return `"${String(value ?? "").replace(/"/g, '""')}"`;
          })
          .join(","),
      )
      .join("\n");

    return `${header}\n${lines}`;
  }

  private jsonData(rows: object[]): string {
    const keys = Object.keys(this.jsonExportOptions);

    return JSON.stringify(
      rows.map((row) =>
        Object.fromEntries(
          keys.map((key) => {
            let value = "";
            if (typeof this.csvExportOptions[key] === "string") {
              // @ts-ignore
              value = row[this.csvExportOptions[key]];
            }
            if (typeof this.csvExportOptions[key] === "function") {
              // @ts-ignore
              value = this.csvExportOptions[key](row);
            }
            return [key, value];
          }),
        ),
      ),
    );
  }

  private downloadFile = ({ data, fileName, fileType }: { data: string; fileName: string; fileType: string }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
}
