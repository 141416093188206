import STRINGS from "../localization";

/**
 * Argument is N, then returns 0N.
 * Argument is NN, then returns NN.
 * @param {Number} n - number
 */
const addZeroIfOneNumber = (n = 0) => {
  if (n <= 9) {
    return `0${n}`;
  }
  return n;
};

/**
 * Returns string from date like 01/03/2020
 * @param {Date} d
 */
const getBeautyDateString = (d = new Date()) => {
  return `${addZeroIfOneNumber(d.getDate())}/${addZeroIfOneNumber(d.getMonth() + 1)}/${d.getFullYear()}`;
};

/**
 * Returns string from date like 04/18/2020
 * @param {Date} d
 */
const getBeautyEngDateString = (d = new Date()) => {
  return `${addZeroIfOneNumber(d.getMonth() + 1)}/${addZeroIfOneNumber(d.getDate())}/${d.getFullYear()}`;
};

/**
 * Returns string from date like [01/03/2020 | Today | Yesterday], 12:00
 * @param {Date} d
 */
const getBeautyDateTimeString = (d = new Date(), shouldConvertDayPhrase = true) => {
  const now = new Date();
  const hours = d.getHours();
  const minutes = addZeroIfOneNumber(d.getMinutes());
  const dateStr = getBeautyDateString(d);
  const datePhrase =
    d.toDateString() === now.toDateString()
      ? STRINGS.utils.today
      : d.toDateString() === new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()
      ? STRINGS.utils.yesterday
      : dateStr;
  return `${shouldConvertDayPhrase ? datePhrase : dateStr}, ${hours}:${minutes}`;
};

/**
 * Format date to format YYYY-MM-DD
 * @param {Date} d
 */
const formatDate = (d = new Date()) => {
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();
  return `${year}-${addZeroIfOneNumber(month)}-${addZeroIfOneNumber(date)}`;
};

/**
 * Returns string from date like 01/03/2020
 * @param {String} d - date format "2021-03-16 12:50:29"
 */
const getFormateDate = (d) => {
  if (typeof d === "string") {
    const date = d.split(" ")[0].split("-");
    const year = date[0];
    const month = date[1];
    const day = date[2];
    return `${day}/${month}/${year}`;
  }
  return;
};

/**
 * @param {number} daysAgo
 * @return Date
 */
const daysAgo = (daysAgo) => {
  return new Date(Date.now() - daysAgo * 24 * 3600 * 1000);
};

export { daysAgo, getBeautyDateString, getBeautyDateTimeString, formatDate, getFormateDate, getBeautyEngDateString };
