import { useEffect, useState } from "react";
import STRINGS from "../../localization";
import AuthService from "../../services/auth";
import { Toast } from "../../ui-kit";
import { validateObject } from "../../utils/input-functions";
import ChangePasswordView from "./changePassword-view";

const PASSWORD_LENGTH = 8;

const SCHEME = {
  currentPassword: (data) =>
    data.length >= PASSWORD_LENGTH && /[a-zA-Z]/.test(data) && /[0-9!@#$%^&*)(+=._-]/.test(data),
  newPassword: (data) => data.length >= PASSWORD_LENGTH && /[a-zA-Z]/.test(data) && /[0-9!@#$%^&*)(+=._-]/.test(data),
};

const initialState = {
  value: "",
  focus: false,
  isPassword: true,
};

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState(initialState);
  const [newPassword, setNewPassword] = useState(initialState);
  const [confirmPassword, setConfirmPassword] = useState(initialState);
  const [errorText, setErrorText] = useState("");
  const [loader, setLoader] = useState("");

  const [validation, setValidation] = useState({ isValid: false, data: {} });

  const clearStates = () => {
    setCurrentPassword(initialState);
    setNewPassword(initialState);
    setConfirmPassword(initialState);
  };

  const handleChangeCurrentPassword = (value) => {
    setCurrentPassword((prev) => ({ ...prev, value }));
    setErrorText("");
  };

  const handleChangeNewPassword = (value) => {
    setNewPassword((prev) => ({ ...prev, value }));
    setErrorText("");
  };

  const onPressSaveHandle = async () => {
    try {
      setLoader(true);
      const res = await AuthService.reauthenticate(currentPassword.value);
      if (res) {
        setErrorText("");
        await AuthService.changePassword(newPassword.value);
      }
      clearStates();
      setLoader(false);
      Toast.success(STRINGS.screens.settings.success);
    } catch (error) {
      if (error?.message?.includes("auth/wrong-password")) {
        setErrorText("Wrong password");
      }
      setLoader(false);
    }
  };

  useEffect(() => {
    setValidation(
      validateObject(
        {
          currentPassword: currentPassword.value,
          newPassword: newPassword.value,
        },
        SCHEME,
      ),
    );
  }, [currentPassword, newPassword]);

  return (
    <ChangePasswordView
      currentPassword={currentPassword}
      setCurrentPassword={setCurrentPassword}
      handleChangeCurrentPassword={handleChangeCurrentPassword}
      newPassword={newPassword}
      setNewPassword={setNewPassword}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      validation={validation}
      onPressSaveHandle={onPressSaveHandle}
      errorText={errorText}
      setErrorText={setErrorText}
      handleChangeNewPassword={handleChangeNewPassword}
      loader={loader}
    />
  );
};

export default ChangePassword;
