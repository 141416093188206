import { Box } from "@mui/material";
import InputDateTime from "../../ui-kit/input-datetime";
import STRINGS from "../../localization";
import React, { useEffect, useState } from "react";

export const DateRangeFilter: React.FC<{
  onChangeStartDate: (d?: Date) => void;
  onChangeEndDate: (d?: Date) => void;
  initialStartDate?: Date;
  initialEndDate?: Date;
  size?: "small" | "medium" | "large";
}> = ({ onChangeStartDate, onChangeEndDate, initialStartDate, initialEndDate, size }) => {
  const [startDate, setStartDate] = useState<Date | undefined>(initialStartDate);
  const [endDate, setEndDate] = useState<Date | undefined>(initialEndDate);

  useEffect(() => {
    onChangeStartDate(startDate);
  }, [onChangeStartDate, startDate]);

  useEffect(() => {
    onChangeEndDate(endDate);
  }, [onChangeEndDate, endDate]);

  return (
    <Box display={"flex"} flexDirection={"row"} gap={2}>
      <InputDateTime
        size={size}
        label={STRINGS.screens.dashboard.startDate}
        value={startDate}
        inputType="default"
        maxDate={endDate}
        onChange={setStartDate}
        disableFuture
        inputValue={startDate?.toLocaleString()}
      />

      <InputDateTime
        size={size}
        label={STRINGS.screens.dashboard.endDate}
        value={endDate}
        maxDate={new Date()}
        minDate={startDate}
        inputType="default"
        onChange={setEndDate}
        disableFuture
        inputValue={endDate?.toLocaleString()}
      />
    </Box>
  );
};
