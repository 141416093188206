import React, { useCallback, useEffect, useState } from "react";

import WeRememberYouView from "./we-remember-you";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { Toast } from "../../ui-kit";
import PostsService from "../../services/posts";
import NotificationService from "../../services/notification";

const WeRememberYou = () => {
  const [postType, setPostType] = useState("forApproval"); //forApproval or activePosts
  const [deleteModalVisible, setDeleteModalVisible] = useState(null);
  const [approveModalVisible, setApproveModalVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [skeletVisible, setSkeletVisible] = useState(true);
  const [postsData, setPostsData] = useState({
    last: false,
    page: null,
    data: [],
  });
  const [loading, setLoading] = useState(false);

  const gettingPostsList = useCallback(async () => {
    setPage(1);
    const status = postType === "forApproval" ? "pending" : "approved";
    setSkeletVisible(true);
    try {
      const result = await PostsService.gettingPostList({
        page: 1,
        size: 10,
        status,
      });
      setPostsData(result);
      setSkeletVisible(false);
    } catch {
      Toast.error("Failed to get the list of posts");
    }
  }, [postType]);

  const changePostType = useCallback(
    (type) => {
      if (type === "forApproval") {
        setPostType("forApproval");
      } else {
        setPostType("activePosts");
      }
    },
    [setPostType],
  );

  const openApproveModal = useCallback((id) => {
    setApproveModalVisible(id);
  }, []);

  const closeApprovePostModal = useCallback(() => {
    setApproveModalVisible(null);
  }, []);

  const approvePost = useCallback(
    async (id) => {
      setApproveModalVisible(null);
      try {
        await PostsService.postConfirmation(id);
        gettingPostsList();
        Toast.success("Post approved successfully");
      } catch {
        Toast.error("Failed to approve post");
      }
    },
    [gettingPostsList],
  );

  const opendeletePostModal = useCallback((id) => {
    setDeleteModalVisible(id);
  }, []);

  const closedeletePostModal = useCallback(() => {
    setDeleteModalVisible(null);
  }, []);

  const deletePost = useCallback(
    async (post, notification) => {
      setLoading(true);
      try {
        await PostsService.deletePost(post._id);
        Toast.success("Post delete successfully");
      } catch {
        Toast.error("Failed to delete post");
      }
      try {
        await NotificationService.sendNotificationForOneUser(
          post,
          "push",
          {
            en: notification.title,
            es: notification.title,
          },
          post.authorId._id,
        );
        Toast.success("Post removal notification sent successfully");
      } catch {
        Toast.error("Failed post removal notification sent");
      }
      gettingPostsList();
      closedeletePostModal();
      setLoading(false);
    },
    [gettingPostsList, closedeletePostModal],
  );

  const nextPosts = useCallback(async () => {
    const status = postType === "forApproval" ? "pending" : "approved";
    try {
      const result = await PostsService.gettingPostList({
        page: page + 1,
        size: 10,
        status,
      });
      setPage((page) => page + 1);
      setPostsData({
        last: result.last,
        data: [...postsData.data, ...result.data],
      });
    } catch (err) {
      Toast.error("Failed to get next page the list of posts");
    }
  }, [page, postType, postsData.data]);

  useBottomScrollListener(() => {
    if (!postsData.last) {
      nextPosts();
    }
  });

  useEffect(() => {
    gettingPostsList();
  }, [gettingPostsList]);

  return (
    <WeRememberYouView
      postType={postType}
      deleteModalVisible={deleteModalVisible}
      approveModalVisible={approveModalVisible}
      postsData={postsData.data}
      skeletVisible={skeletVisible}
      loading={loading}
      closeApprovePostModal={closeApprovePostModal}
      approvePost={approvePost}
      openApproveModal={openApproveModal}
      changePostType={changePostType}
      opendeletePostModal={opendeletePostModal}
      deletePost={deletePost}
      closedeletePostModal={closedeletePostModal}
    />
  );
};

export default WeRememberYou;
