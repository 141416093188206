import React, { useState, useEffect } from "react";

import "./styles.scss";
import { TextArea } from "..";
/**
 * @component TextField
 * @example
 * <TextField
 * quantitySimbols={300}
 * title={'Title'}
 * onChange={() => {}}
 * value="Some text"
 * />
 */

const TextField = (props) => {
  const [valueInput, setValueInput] = useState(props.value);

  const numberOfChars = valueInput && valueInput.length;

  useEffect(() => {
    props.onChange && props.onChange(valueInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueInput]);

  useEffect(() => {
    if (props.value !== valueInput) {
      setValueInput(props.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <>
      <div className="tracking-input-container">
        <div className="title-container">
          <div>
            <div className="title">{props.title} </div>
          </div>
          <div style={{ display: props.quantitySimbols ? "visible" : "none" }} className="counter-simbols">
            {numberOfChars || 0}/{props.quantitySimbols || ""}
          </div>
        </div>
        <div>
          <TextArea
            autosize
            value={props.uncontrolled ? props.value : valueInput === 0 ? "" : valueInput}
            onChange={setValueInput}
            maxLength={props.quantitySimbols || "100000"}
            disabled={props.disabled}
          />
        </div>
      </div>
    </>
  );
};

export default TextField;
