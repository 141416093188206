import { localizationSetCurrentLanguage } from "./localization";
import { Localization } from "../../localization";

/**
 * Redux actions for localization.
 */
class LocalizationActions {
  /**
   * Localization initialization
   *
   * @return {Dispatch}
   */
  static initial() {
    return (dispatch) => {
      try {
        const currentAsyncStorageLanguage = Localization.getLanguageFromAsyncStorage() || "en";
        dispatch(LocalizationActions.setLanguage(currentAsyncStorageLanguage));
      } catch (err) {
        dispatch(LocalizationActions.setLanguage("en"));
      }
    };
  }

  /**
   * Set language to localization.currentLanguage in redux
   * @param {String} languageCode - language
   */
  static setLanguage(languageCode) {
    return (dispatch) => {
      try {
        Localization.setLanguage(languageCode);
        dispatch(localizationSetCurrentLanguage(languageCode));
      } catch (error) {
        //
      }
    };
  }
}

export default LocalizationActions;
