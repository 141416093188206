import React from "react";
import PropTypes from "prop-types";

import colors from "../styles/colors";

/**
 * @component Loader
 * @example
 * <Loader color="light"/>
 */
import "./styles.scss";

const Loader = (props) => {
  return (
    <div className={`w-loader ${props.class ? props.class : ""}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 100 100">
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke={props.color === "light" ? colors.color_white._0 : colors.color_black._0}
          strokeWidth="10"
          r="35"
          strokeDasharray="164.93361431346415 56.97787143782138"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          />
        </circle>
      </svg>
    </div>
  );
};

/**
 * Properties
 */

Loader.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
  width: PropTypes.number,
  height: PropTypes.number,
};
Loader.defaultProps = {
  color: "light",
  width: 28,
  height: 28,
};

export default Loader;
