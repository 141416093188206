import { Box } from "@mui/material";
import rem from "../../styles/rem";
import React, { PropsWithChildren } from "react";

export const SectionHeader: React.FC<PropsWithChildren> = ({ children }) => (
  <Box
    display={"flex"}
    flexDirection={"row"}
    justifyContent={"space-between"}
    children={children}
    alignItems={"center"}
    paddingY={rem(18)}
    paddingX={rem(4)}
  />
);
