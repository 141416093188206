import React, { useState } from "react";

import ModalsView from "./modals-view";

const ModalsContainer = () => {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const handleConfirmVisible = () => {
    setConfirmVisible(!confirmVisible);
  };

  const handleModalVisible = () => {
    setModalVisible(!modalVisible);
  };

  return (
    <ModalsView
      modalVisible={modalVisible}
      confirmVisible={confirmVisible}
      handleConfirmVisible={handleConfirmVisible}
      handleModalVisible={handleModalVisible}
    />
  );
};

export default ModalsContainer;
