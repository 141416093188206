import React, { FC } from "react";
import { Button, ConfirmWithInput, Icon, Paper } from "../../ui-kit";
import STRINGS from "../../localization";
import { getBeautyEngDateString } from "../../utils/datetime";
import { FirebaseImage } from "../../components";
import colors from "../../styles/colors";
import { ApiAlertWithDependencies, ApiContactTypeEnum } from "../../types";
import { Link } from "react-router-dom";

interface AlertCardProps extends Record<string, any> {
  alert: ApiAlertWithDependencies;
}

export const AlertCard: FC<AlertCardProps> = ({ alert, ...props }) => {
  const isPetsAlert = props.tab === props.TABS.LOST_PETS || props.tab === props.TABS.FOUND_PETS;
  const isReportedTab = props.tab === props.TABS.REPORTED;

  return (
    <React.Fragment key={alert._id}>
      <Paper className="paper" key={alert._id}>
        <>
          <div className={isReportedTab ? "content-all border" : "content-all"}>
            {isReportedTab && (
              <>
                <h4>{STRINGS.screens.alertScreen.type}</h4>
                <span>{alert.type}</span>
              </>
            )}
            <h4>{STRINGS.screens.alertScreen.author}</h4>
            {alert.authorId?.archivedAt ? (
              <> {alert.authorId?.nickname} (Deleted)</>
            ) : (
              <Link target="_blank" to={`/users/user?id=${alert.authorId._id}`}>
                {alert.authorId?.nickname}
              </Link>
            )}
            <div>
              {alert.authorId?.address.city}, {alert.authorId?.address.state}
            </div>

            <h4>{STRINGS.screens.alertScreen.date}</h4>
            <span>{alert.createdAt && getBeautyEngDateString(new Date(alert.createdAt))}</span>
            {isPetsAlert ? (
              <>
                <h4>{STRINGS.screens.alertScreen.description}</h4>
                <span>{alert.description}</span>
                <h4>{STRINGS.screens.alertScreen.contact}</h4>
                <span>{alert.contacts?.find((x) => x.type === ApiContactTypeEnum.Other)?.contact}</span>
                <h4>{STRINGS.screens.alertScreen.resolved}</h4>
                <span>{alert.resolved ? STRINGS.generic.yes : STRINGS.generic.no}</span>
              </>
            ) : null}
            <h4>{STRINGS.screens.alertScreen.coordinates}</h4>
            <a
              target="blank"
              href={`https://www.google.com/maps/search/?api=1&query=${alert.geopoint.latitude}%2C${alert.geopoint.longitude}`}
            >
              {alert.geopoint.latitude}, {alert.geopoint.longitude}
            </a>
            <div>
              {alert.city}, {alert.state}
            </div>
            <div>
              <h4>{STRINGS.screens.alertScreen.numberOfReports}</h4>
              <span>{alert.reportsCount}</span>
            </div>
            {alert.photo && (
              <div>
                <FirebaseImage url={alert.photo} className="alert__img" />
              </div>
            )}
          </div>
          <button
            className="delete"
            onClick={() => {
              props.setSelectedAlert(alert._id);
              props.opendeleteAlertModal(alert._id);
            }}
          >
            <Icon name="cross" width={24} height={24} color={colors.color_basic._0} />
          </button>
          {isReportedTab && (
            <div className="button">
              <Button
                type="primary"
                label={
                  alert.reportsCount === 1
                    ? STRINGS.screens.alertScreen.seeOneRetort
                    : STRINGS.screens.alertScreen.seeReports
                }
                onClick={() => props.openDrawerWithReports(alert._id)}
                className="openReports"
              />
            </div>
          )}
        </>
      </Paper>
      <ConfirmWithInput
        onClose={props.opendeleteAlertModal}
        title={STRINGS.screens.alertScreen.questionAboutDeletingAlert}
        visible={props.deleteModalVisible === alert._id}
        onOkClick={(notification: any) => props.deleteAlert(notification)}
        quantitySimbols={300}
        loading={props.loading}
      />
    </React.Fragment>
  );
};
